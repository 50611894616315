import { EditSingleGroupClassFormData } from "src/api/form-types";

export default function putAdminEditSingleGroupClass(data: EditSingleGroupClassFormData, groupClassId: number) {
  return fetch(`${global.API_v1}/admin/group-class/edit`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data, groupClassId: groupClassId }),
  });
}
