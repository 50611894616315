export default function postCustomerPendingQfCheckout(creditPackageId: number | undefined, qfOutTradeNo: string) {
  return fetch(`${global.API_v1}/customer/payment/qf-pending-checkout`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({creditPackageId, qfOutTradeNo}),
  });
}
