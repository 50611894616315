import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import { GroupClassInfo, CustomerGroupClassInfo, CustomerInfo } from 'src/hooks/swr/output-types';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';
import { theme } from 'src/styles/muiTheme';
import { useMemo } from 'react';

interface GroupClassBoxProps {
  groupClass: GroupClassInfo | CustomerGroupClassInfo,
  isBooking?: boolean,
  customerEnrolled?: boolean,
  customerInfo?: CustomerInfo,
  isCoach?: boolean,
  isAdmin?: boolean,
}

export default function GroupClassBox({
  groupClass,
  isBooking = false,
  customerEnrolled,
  customerInfo,
  isCoach = false,
  isAdmin = false,
}: GroupClassBoxProps) {
  const { t } = useTranslation(['coach', 'customer']);

  const classDayjsTime = useMemo(() => {
    if (groupClass) {
      return dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute)
    }
  }, [groupClass]);

  // customer can book any regular class in the future
  const isFuture = useMemo(() => {
    return dayjs().isBefore(classDayjsTime)
  }, [classDayjsTime]);

  /* customer can only book regular class 
   * 1. not enrolled
   * 2. in the future
   * 3. not exceeding 6 participants
   * [deprecated] 4. customer is_star_selected when the class is selected class
   */
  const canDisplayIndicator = useMemo(() => {
    if (groupClass && customerInfo) {
      return isFuture && groupClass.numEnrolled < 6
        // && (groupClass.isSelected ? customerInfo.is_star_selected : true)
        && (groupClass.genderRestrict ? groupClass.genderRestrict === customerInfo.gender : true)
    }
  }, [groupClass, isFuture, customerInfo]);
  
  return (
    <LinkWithoutStyle to={`${isCoach ? '/coach' : ''}${isAdmin ? '/admin' : ''}${isBooking ? '/book' : ''}/group-class/${groupClass.groupClassId}`}>
      <Box sx={{
        boxShadow: !groupClass.isSingleClassCancelled ? (`0px 4px 4px ${
          groupClass.isStar ?
            // tertiary.main
            'rgb(224, 242, 24, 0.25)'
            : (groupClass.isSelected ?
              // secondary.main
              'rgb(9, 164, 189, 0.25)'
              :
                // primary.dark
               'rgb(0, 57, 118, 0.25)'
              )
        }`) : 'unset',
        borderRadius: 2.5, // 10px
        border: '1px solid',
        borderColor: !groupClass.isSingleClassCancelled ? (
          groupClass.isStar ? 'tertiary.main' : (groupClass.isSelected ? 'secondary.main' : 'primary.dark')
        ) : 'displayGrey.main',
        background: '#FFFFFF',
        padding: 1,
        marginTop: 1,
        marginBottom: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Box>
          <Typography variant='body2' color={!groupClass.isSingleClassCancelled ? 'primary.dark' : 'displayGrey.main'}
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}>
            {/* no need for explicit name for group class now */}
            {/* <span style={{
              color: groupClass.isStar || groupClass.isSelected ? '#FFFFFF' : theme.palette.primary.dark,
              border: 5,
              backgroundColor: groupClass.isStar ? `${theme.palette.tertiary.light}` : (
                groupClass.isSelected ? theme.palette.secondary.main :
                  '#FFFFFF'
              ),
            }}>
              {(groupClass.isStar ? t('customer:star') : '')
                + (groupClass.isSelected ? t('customer:special_class') : '')
                + t('customer:dashboard.box.group_class')}
            </span>
            {' - '} */}

            {nameToCamelcase(groupClass.name)}
          </Typography>

          <Typography variant='body2' color={!groupClass.isSingleClassCancelled ? 'primary.dark' : 'displayGrey.main'}
            sx={{ marginBottom: 1 }}>
            {nameToCamelcase(groupClass.description)}
          </Typography>

          <Typography variant='body2' color={!groupClass.isSingleClassCancelled ? 'primary.dark' : 'displayGrey.main'}>
            {dayjs(groupClass.date).format('DD MMM[,] YYYY [-] ddd')}
          </Typography>

          <Typography variant='body2' color={!groupClass.isSingleClassCancelled ? 'primary.dark' : 'displayGrey.main'}>
            {`${groupClass.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${groupClass.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})} - ` + t('customer:dashboard.box.60_min')}
          </Typography>

          <Typography variant='body2' color={!groupClass.isSingleClassCancelled ? 'primary.dark' : 'displayGrey.main'}
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}>
            {`${nameToCamelcase(groupClass.coachPreferredName)} ${nameToCamelcase(groupClass.coachLastName)}`}
          </Typography>
        </Box>

        
        {canDisplayIndicator &&
          <Box sx={{
            height: 60,
            border: '0px solid',
            borderLeftWidth: 1,
            borderColor: 'secondary.main',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: isCoach ? 'space-between' : 'center',
            paddingLeft: 1,
            paddingTop: 0.5,
            paddingBottom: 0.5,
          }}>
            <Typography variant='body2'
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}
              color={'secondary.main'}>
              {/* {customerEnrolled ? t('customer:group_class.fields.status.booked') : t('customer:group_class.fields.status.available')} */}
              {/* To Refactor */}
              {!groupClass.isSingleClassCancelled &&
                <>
                  {(groupClass as any).status && (groupClass as any).status === 'pending' && t('customer:group_class.fields.status.pending')}
                  {!isCoach && (groupClass as any).status === '' && t('customer:group_class.fields.status.available')}
                  {(groupClass as any).status
                    && (groupClass as any).status !== ''
                    && (groupClass as any).status !== 'rejected'
                    && (groupClass as any).status !== 'pending'
                    && t('customer:group_class.fields.status.booked')}
                </>
              }
              {groupClass.isSingleClassCancelled &&
                t('customer:group_class.fields.status.unavailable')
              }
            </Typography>
            {!customerEnrolled && !groupClass.isSingleClassCancelled && <ChevronsRight stroke={theme.palette.secondary.main}/>}
          </Box>
        }

        {/* TODO - for coach to see current booking number */}
        {isCoach && groupClass.customers &&
          <Box sx={{
            height: 60,
            minWidth: 60,
            border: '0px solid',
            borderLeftWidth: 1,
            borderColor: !groupClass.isSingleClassCancelled ? 'secondary.main' : 'displayGrey.main',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: isCoach ? 'space-between' : 'center',
            paddingLeft: 1,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            marginLeft: 1,
          }}>
            <Typography variant='body2'
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}
              color={!groupClass.isSingleClassCancelled ? 'secondary.main' : 'displayGrey.main'}>
              {t('coach:group_class.box.current_booking')}
            </Typography>
            <Typography variant='body2'
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}
              color={!groupClass.isSingleClassCancelled ? 'secondary.main' : 'displayGrey.main'}>
              {groupClass.customers.length}
            </Typography>
          </Box>
        }
      </Box>
    </LinkWithoutStyle>
  )
}