import { useEffect, useMemo, useState } from 'react';
import { Typography, Grid, styled, TextField, Chip, InputLabel, NativeSelect, FormHelperText } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { CreateBookStudioFormData } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import useCoachUnavailableGroupTimeslotInSixMonth from 'src/hooks/swr/coach/use-coach-unavailable-group-timeslot-in-six-month';

import FormControl from '@mui/material/FormControl';
import useCoachAllTimeslot from 'src/hooks/swr/coach/use-coach-all-timeslot';
import { Timeslot } from 'src/hooks/swr/output-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAdminAllDirector from 'src/hooks/swr/coach/admin/use-admin-all-director';
import { nameToCamelcase } from 'src/utils/clean-name';

const fieldWidth = 250;

const RegisterTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

const CustomPickersDay = styled((props: PickersDayProps<Dayjs>) => (
  <PickersDay
    {...props}/>
), {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
}));

const durationList = [1, 1.5, 2, 2.5, 3];

interface DurationOptionsProps {
  durationList: number[],
  selectedTime: SelectTimeState,
  blockingTimeslot: string[],
  t: TFunction<'coach'>,
}

function DurationOptions ({
  durationList,
  selectedTime,
  blockingTimeslot,
  t,
}: DurationOptionsProps) {
  const availableTimeslotList = useMemo(() => {
    const timeList = durationList.map(duration => 
      dayjs().hour(selectedTime.hour).minute(selectedTime.minute).add(duration * 2 * 30, 'minute').format('HH:mm')
    );
    
    const timeslotList: {
      timeslot: string,
      endTime: string,
      duration: number,
    }[] = []
    for (let i=0; i<timeList.length; i++) {
      // same logic as group class, must have 1 hour for each chip
      // extra duration will be tested here
      const duration = durationList[i];
      const extraDuration = durationList[i] - 1;
      const time = dayjs().hour(selectedTime.hour).minute(selectedTime.minute).add(extraDuration * 2 * 30, 'minute').format('HH:mm');
      const endTime = dayjs().hour(selectedTime.hour).minute(selectedTime.minute).add(duration * 2 * 30, 'minute').format('HH:mm');

      if (!blockingTimeslot.includes(time)) {
        timeslotList.push({
          timeslot: time,
          endTime: endTime,
          duration: duration,
        })
      } else {
        break
      }
    }
    return timeslotList
  }, [durationList, selectedTime, blockingTimeslot]);

  return (
    <>
      {availableTimeslotList.map((item) => (
        <option value={item.duration} key={`duration-${item.duration}h`} aria-label={`duration-${item.duration}h`}>
          {t('book_studio.hour', {
            hour: item.duration,
            endTime: item.endTime,
          })}
        </option>
      ))
      }
    </>
  )
}

type fieldsDateTime = Dayjs | null;

interface SelectTimeState {
  hour: number,
  minute: number,
}

interface BookStudioFormProps {
  submitAction: (data: CreateBookStudioFormData) => void,
  isOtherEvent: boolean,
}

export default function BookStudioForm ({
  submitAction,
  isOtherEvent,
}: BookStudioFormProps) {
  const { t } = useTranslation('coach');

  const { allTimeslot } = useCoachAllTimeslot();
  const { allClass } = useCoachUnavailableGroupTimeslotInSixMonth();
  const { allDirector } = useAdminAllDirector();

  const allClassDateTime = useMemo(() => {
    if (allClass) {
      let dateTime: any = {};
      allClass.forEach(singleClass => {
        const day = dayjs(singleClass.date).format('YYYY-MM-DD');
        const time = `${singleClass.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${singleClass.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})}`;
        if (dateTime[day] === undefined) {
          dateTime[day] = [time];
        } else {
          (dateTime[day] as string[]).push(time);
        }
      })
      return dateTime
    }
  }, [allClass]);

  const [dummyDate, setDummyDate] = useState<fieldsDateTime>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs[]>([]);
  const [selectedDateFormatted, setSelectedDateFormatted] = useState<string[]>([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState<number | undefined>();
  const [selectedTime, setSelectedTime] = useState<SelectTimeState | undefined>();

  const [errorDialog, setErrorDialog] = useState({
    open: false,
    message: '',
  });

  const handleDeleteDate = (index: number) => {
    if (selectedDate) {
      const newDateList = selectedDate.filter((_, idx) => idx !== index);
      setSelectedDate(newDateList);
      // set dummy date to null for users to immediate select back the last selected but cancelled date
      setDummyDate(null);
    }
  };

  const handleChipClick = (timeslot: Timeslot) => {
    setSelectedTimeslot(timeslot.id);
    setSelectedTime({ hour: timeslot.hour, minute: timeslot.minute });
    setValue('startTimeslotId', timeslot.id);
    if (errors.startTimeslotId) {
      clearErrors('startTimeslotId');
    }
  }

  const { register, handleSubmit, setValue, setError, clearErrors, watch, control, formState: { errors } } = useForm<CreateBookStudioFormData>();

  register('name');
  register('coachId');
  register('selfIncome');
  register('selfBookingFee');
  register('date');
  register('startTimeslotId');
  register('duration');

  const selectedCoachId = watch('coachId');
  const selectedDuration = watch('duration');

  useEffect(() => {
    setValue('duration', 0);
  }, [setValue]);

  const onSubmit = handleSubmit(async (data: CreateBookStudioFormData) => {
    if (!isOtherEvent) {
      if (!data.coachId || data.coachId === 0) {
        setError('coachId', {
          type: 'empty',
          message: t('book_studio.error.empty', {
            field: t('book_studio.fields.coach')
          })
        });
        return
      }
      if (isNaN(parseInt(data.selfIncome))) {
        setError('selfIncome', {
          type: 'empty',
          message: t('book_studio.error.nan')
        });
        return
      }
      if (isNaN(parseInt(data.selfBookingFee))) {
        setError('selfBookingFee', {
          type: 'empty',
          message: t('book_studio.error.nan')
        });
        return
      }
    }
    if (selectedDate.length === 0) {
      setError('date', {
        type: 'empty',
        message: t('book_studio.error.empty', {
          field: t('book_studio.fields.date')
        })
      });
      return
    }
    if (!data.startTimeslotId) {
      setError('startTimeslotId', {
        type: 'empty',
        message: t('book_studio.error.empty', {
          field: t('book_studio.fields.time')
        })
      });
      return
    }
    const allDate = selectedDate.reduce((preValue, nowValue) => preValue + `,${nowValue.format('YYYY-MM-DD')}`, '');
    data.date = allDate;
    submitAction(data);
  });
  
  useEffect(() => {
    setSelectedDateFormatted(selectedDate.map(date => date.format('YYYY-MM-DD')));
  }, [selectedDate]);

  const renderPickerDay = (
    day: dayjs.Dayjs,
    selectedDays: dayjs.Dayjs[],
    pickersDayProps: PickersDayProps<dayjs.Dayjs>) => {

    let selected = false;

    if (selectedDateFormatted.includes(day.format('YYYY-MM-DD'))) {
      selected = true;
    }

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={selected} />
    )
  };
  
  const [blockingTimeslot, setBlockingTimeslot] = useState<string[]>([]);
  useEffect(() => {
    if (selectedDate.length !== 0 && allClassDateTime) {
      let timeslotList: string[] = [];
      for (const date of selectedDate) {
        const currentDateTime = allClassDateTime[date.format('YYYY-MM-DD')];
        if (currentDateTime !== undefined) {
          timeslotList = timeslotList.concat(currentDateTime);
        }
      }
      setBlockingTimeslot(timeslotList);
    }
  }, [selectedDate, allClassDateTime]);

  useEffect(() => {
    if (selectedDuration === 0) {
      setValue('duration', 1);
      return
    }
    if (selectedTime) {
      const time = selectedTime.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
        + ':' + selectedTime.minute.toLocaleString(undefined, {minimumIntegerDigits: 2});
      if (blockingTimeslot.includes(time)) {
        setSelectedTimeslot(undefined);
        setSelectedTime(undefined);
      }
      return
    }
  }, [selectedTime, blockingTimeslot, setValue, setSelectedTimeslot, selectedDuration]);

  // on missing empty input, show reminder dialog
  useEffect(() => {
    const errorNum = Object.keys(errors).length;
    if (errorNum !== 0) {
      setErrorDialog({
        open: true,
        message: t('book_studio.dialog.missing_fields'),
      });
    }
  }, [errors, t]);

  const closeErrorDialog = () => {
    setErrorDialog({
      open: false,
      message: '',
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={1.5}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {t(`book_studio.title${isOtherEvent ? '_event' : ''}`)}
            </Typography>
          </Grid>

          <Grid item>
            <Controller
              name='name'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
              <RegisterTextfield
                variant='outlined'
                  label={t('book_studio.fields.name')}
                  required
                  inputProps={{ 'aria-label': 'name' }}
                  {...field} />
                }
              />
          </Grid>

          {allDirector && !isOtherEvent &&
            <Grid item>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  {t('book_studio.fields.coach')}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'coachId', id: 'uncontrolled-native',
                  }}
                  value={selectedCoachId}
                  onChange={e => {
                    setValue('coachId', parseInt(e.target.value))
                    if (errors.coachId) {
                      clearErrors('coachId');
                    }
                  }}
                  sx={{ width: fieldWidth }}
                  error={!!errors.coachId}
                  required
                >
                  <option value={0} aria-label={`coach-select-default-0`}>
                    {t('book_studio.fields.select_coach')}
                  </option>
                  {allDirector.map((coach) => (
                    <option value={coach.id} key={`coach-${coach.id}`} aria-label={`coach-select-${nameToCamelcase(`${coach.preferred_name} ${coach.last_name}`)}`}>
                      {nameToCamelcase(`${coach.preferred_name} ${coach.last_name}`)}
                    </option>
                    ))
                  }
                </NativeSelect>

                {errors.coachId &&
                  <FormHelperText error id='mising-field'>
                    {errors.coachId.message}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          }

          {!isOtherEvent &&
            <Grid item>
              <Controller
                name='selfIncome'
                control={control}
                defaultValue=''
                rules={{ required: true }}
                render={({ field }) =>
                <RegisterTextfield
                  variant='outlined'
                    label={t('book_studio.fields.self_income')}
                    inputProps={{ 'aria-label': 'self_income' }}
                    error={!!errors.selfIncome}
                    helperText={errors.selfIncome && errors.selfIncome.message}
                    required
                    {...field} />
                  }
                />
            </Grid>
          }

          {!isOtherEvent &&
            <Grid item>
              <Controller
                name='selfBookingFee'
                control={control}
                defaultValue=''
                rules={{ required: true }}
                render={({ field }) =>
                <RegisterTextfield
                  variant='outlined'
                    label={t('book_studio.fields.self_charge')}
                    inputProps={{ 'aria-label': 'self_charge' }}
                    error={!!errors.selfBookingFee}
                    helperText={errors.selfBookingFee && errors.selfBookingFee.message}
                    required
                    {...field} />
                  }
                />
            </Grid>
          }
          
          <Grid item
            sx={{
              '& .MuiCalendarPicker-root': {
                width: 300,
              }
            }}>
            {/* <MobileDatePicker */}
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              openTo='day'
              label={t('book_studio.fields.date')}
              inputFormat='DD/MM/YYYY'
              value={dummyDate}
              renderDay={renderPickerDay}
              onChange={(newDate) => {
                if (!newDate) { return }
                setDummyDate(newDate);
                if (selectedDateFormatted.includes(newDate.format('YYYY-MM-DD'))) {
                  return
                }
                setSelectedDate(selectedDate.concat([newDate]).sort((a, b) => a.isAfter(b) ? 1 : -1));
                if (errors.date) {
                  clearErrors('date');
                }
              }}
              renderInput={(params) =>
                <TextField
                  inputProps={{'aria-label': 'dummyDate'}}
                  error={!!errors.date}
                  helperText={errors.date ? errors.date.message : 'dd/mm/yyyy'}
                  sx={{
                    // width: fieldWidth,
                    '& p': {
                      color: errors.date ? 'red' : '',
                    },
                    '& div>fieldset': {
                      borderColor: errors.date ? 'red' : ''
                    },
                  }}
                  {...params} />
              }
            />
          </Grid>

          <Grid item container
            sx={{
              marginTop: -5,
              justifyContent: 'center',
              width: 350,
            }}>
            {selectedDate.map((date, index) => (
              <Chip
                key={`date-${index}`}
                label={date.format('YYYY-MM-DD')}
                sx={{ margin: 0.5 }}
                onDelete={() => handleDeleteDate(index)}/>
            ))}
          </Grid>

          <Grid item>
            <Typography color='error.main'>
              {errors.date && errors.date.message}
            </Typography>
          </Grid>

          <Grid item container justifyContent='center'>
            {allTimeslot && allTimeslot
              .map(timeslot => {
                const time = timeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                  + ':' + timeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})
                return (
                  <Chip
                    variant={timeslot.id === selectedTimeslot ? 'filled' : 'outlined'}
                    color='primary'
                    sx={{
                      margin: 0.5,
                      borderColor: 'primary.dark',
                      border: `1px solid`
                    }}
                    disabled={blockingTimeslot.includes(time)}
                    label={time}
                    key={`${timeslot.hour}-${timeslot.minute}`}
                    onClick={() => handleChipClick(timeslot)}
                    />
                )})  
            }
          </Grid>

          <Grid item>
            <Typography color='error.main'>
              {errors.startTimeslotId && errors.startTimeslotId.message}
            </Typography>
          </Grid>

          {blockingTimeslot && 
            <Grid item>
              <FormControl fullWidth>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                  {t(`book_studio.fields.duration${isOtherEvent ? '_event' : ''}`)}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'duration', id: 'uncontrolled-native',
                  }}
                  defaultValue={1}
                  value={selectedDuration}
                  onChange={e => {
                    setValue('duration', parseFloat(e.target.value))
                    if (errors.duration) {
                      clearErrors('duration');
                    }
                  }}
                  sx={{ width: fieldWidth }}
                  required
                >
                  {!selectedTime &&
                    <option value={0} key={`duration-hour-0`} aria-label={`duration-hour-0`}>
                      {t('book_studio.fields.select_timeslot')}
                    </option>
                  }
                  {selectedTime &&
                    <DurationOptions
                      durationList={durationList} selectedTime={selectedTime} blockingTimeslot={blockingTimeslot} t={t}
                      />
                  }
                </NativeSelect>
              </FormControl>
            </Grid>
          }

          <Grid item>
            <ButtonPrimary
              status='primary'
              type='submit'
              label={t(`book_studio.btn.submit${isOtherEvent ? '_event' : ''}`)}
              aria-label='create-btn'
            />
          </Grid>
          
        </Grid>
      </form>
      <Dialog
        open={errorDialog.open}
        onClose={closeErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Typography variant='body1' color='error.main'>
            {errorDialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}