import { Box, Typography, styled, SxProps } from '@mui/material';
import { To } from 'react-router-dom';
import LinkWithoutStyle from '../LinkWithoutStyle';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';

const BookAClass = styled(Box)(({ theme }) => ({
  width: '70vw',
  maxWidth: 300,
  // height: 30,
  minHeight: 30,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: '5px 10px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface NavigateToProps {
  label: string,
  to: To,
  sx?: SxProps,
}

export default function NavigateTo({
  label,
  to,
  sx,
}: NavigateToProps) {
  return (
    <LinkWithoutStyle to={to}>
      <BookAClass sx={sx}>
        <Typography color='primary.main' fontWeight={'bold'}>
          {label}
        </Typography>
        <ChevronsRight/>
      </BookAClass>
    </LinkWithoutStyle>
  )
}