import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import useAdminAllPurchaseHistory from 'src/hooks/swr/coach/admin/use-admin-all-purchase-history';
import PaymentRecordListAdmin from 'src/components/payment-record/PaymentRecordListAdmin';

export default function PurchaseRecord() {
  const { t } = useTranslation('coach');
  const { allPurchaseRecord, mutate } = useAdminAllPurchaseHistory();

  const paymentPending = useMemo(() => (
    allPurchaseRecord ?
      allPurchaseRecord.filter(record => record.status === null ? false : (
        record.status === 'pending' ? true : false
      ))
    : undefined
  ), [allPurchaseRecord])

  const paymentConfirmed = useMemo(() => (
    allPurchaseRecord ?
      allPurchaseRecord.filter(record => record.status === null ? true : (
        record.status === 'pending' ? false : true
      ))
    : undefined
  ), [allPurchaseRecord])

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Typography variant='h6' color='primary.main'>
        {t('purchase_record.title.to_confirm')}
      </Typography>

      <Grid
        item container
        xs={12}
        spacing={1}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        {paymentPending && paymentPending.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('purchase_record.empty', {status: t('status.pending')})}
          </Typography>
        }
        {paymentPending &&
          <PaymentRecordListAdmin
            allPurchaseRecord={paymentPending}
            mutate={mutate} />
        }
      </Grid>

      <Typography variant='h6' color='primary.main' sx={{ marginTop: 2 }}>
        {t('purchase_record.title.confirmed')}
      </Typography>

      <Grid
        item container
        xs={12}
        spacing={1}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        {paymentConfirmed && paymentConfirmed.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('purchase_record.empty', {status: t('status.confirmed')})}
          </Typography>
        }
        {paymentConfirmed &&
          <PaymentRecordListAdmin
            allPurchaseRecord={paymentConfirmed}
            mutate={mutate} />
        }
      </Grid>
    </Container>
  )
}