import { useMemo } from 'react';
import { Container, Grid, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameToCamelcase } from 'src/utils/clean-name';
import { styled } from '@mui/system';
import useAllCoach from 'src/hooks/swr/public/use-all-coach';
import { Helmet } from 'react-helmet-async';
import NavigateTo from 'src/components/navigate/NavigateTo';
import SocialMediaLinkBox from 'src/components/social-media/SocialMediaLinkBox';
import { theme } from 'src/styles/muiTheme';

const CoachIcon = styled('img')(({ theme }) => ({
  width: 200,
  height: 200,
  borderRadius: 250,
  // TODO - fix white line between border & pic
  border: `1px solid ${theme.palette.primary.main}`,
}));

export default function CoachPersonalPageDemo() {
  // demo only
  // const { coachId } = useParams();
  const { t } = useTranslation('customer');

  const { allCoach } = useAllCoach();
  const coach = useMemo(() => {
    if (allCoach) {
      return allCoach[0]
    }
  }, [allCoach])

  const coachFullName = useMemo(() => 
    coach ? nameToCamelcase(coach.preferred_name + ' ' + coach.last_name) : ''
  , [coach])

  const isYoutubeMaxWidth = useMediaQuery(theme.breakpoints.up(560));

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
      {coach && coachFullName &&
        <Helmet>
          <title>{`${coachFullName} - OPW Coach`}</title>
          <meta
            name='description'
            content='一加健預約系統 - One Plus Wellness OPW booking portal'
          />
          <link rel='icon' href={`${AWS_S3_BUCKET}/${coach.icon_path}`}  />

          <meta property='og:image' content={`${AWS_S3_BUCKET}/${coach.icon_path}`} />
          <meta property='og:image:type' content='image/png' />
          <meta property='og:image:width' content='300' />
          <meta property='og:image:height' content='300' />
          <meta property='og:description' content='OPW coach introduction 一加健教練簡介' />
          <meta property='og:url' content='%PUBLIC_URL%' />
          <meta property='og:title' content={`OPW coach ${coachFullName}`} />
          <meta property='og:site_name' content='OPW Portal 一加健預約系統' />

          <meta name='twitter:card' content={`OPW coach ${coachFullName} icon`} />
          <meta name='twitter:image' content={`${AWS_S3_BUCKET}/${coach.icon_path}`} />
          <meta name='twitter:site' content='@OPWGym' />
          <meta name='twitter:creator' content='@OPWGym' />
          <meta name='twitter:title' content={`OPW coach ${coachFullName}`} />
          <meta name='twitter:description' content='OPW coach introduction 一加健教練簡介' />
        </Helmet>
      }
      {allCoach && coach &&
        <Grid
          item container
          xs={12}
          spacing={1}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <CoachIcon
              src={`${AWS_S3_BUCKET}/${coach.icon_path}`}
              alt='icon for new coach'
              />
          </Grid>
          <Grid item>
            <Typography variant='h5' color='primary.main' sx={{ fontWeight: 'bold' }} >
              {coachFullName}
            </Typography>
          </Grid>

          <Grid item>
            <SocialMediaLinkBox
              linkFb={'https://www.facebook.com/onepluswellness/'}
              linkIg={'https://www.instagram.com/onepluswellness'}
              linkYoutube={'https://www.youtube.com/channel/UCLICOehHcWZoWyHyN8fkwTw/videos'}
              />
          </Grid>
          
          <Grid item>
            <iframe
              width={isYoutubeMaxWidth ? 520 : '100%'}
              height={isYoutubeMaxWidth ? 315 : '100%'}
              src="https://www.youtube.com/embed/QsVz4iMwpxc"
              title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid>

          <Grid item>
            <NavigateTo
              to={`/book/pt/${coach.id}`}
              label={t('coach_overview.btn.book_pt')}/>
          </Grid>

          <Grid item container justifyContent={'center'}>
            <ImageList sx={{ width: '80vw', height: 450 }} cols={3}
              // auto height from image
              // rowHeight={164}
              >
              {Array.from(new Array(16), (_, i) => i).map((item) => (
                <ImageListItem key={`${item}-image`}>
                  <img
                    src={`${AWS_S3_BUCKET}/default-logo.png`}
                    alt='icon for coach'
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>

          <Grid item container
            spacing={1}
            justifyContent={'flex-start'}>
            <Grid item container>
              <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                {t('coach_overview.intro')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {Array.from(new Array(16), (_, i) => i).map(() => t('coach_overview.intro'))}
              </Typography>
            </Grid>
            
            <Grid item container>
              <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                {t('coach_overview.award')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {Array.from(new Array(6), (_, i) => i).map(() => t('coach_overview.award'))}
              </Typography>
            </Grid>

            <Grid item container>
              <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                {t('coach_overview.speciality')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {Array.from(new Array(6), (_, i) => i).map(() => t('coach_overview.speciality'))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
    </Container>
  )
}