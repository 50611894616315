import useSWR from 'swr';
import axios from 'axios';
import { CoachDetail } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string, coachId: number) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { coachId: coachId }
    })
    .then(res => res.data);

export default function useCoachSingleCoachInfo(coachId: number, isCoachProfile: boolean = true) {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/${isCoachProfile ? 'coach/' : 'director/'}coach/single`, coachId],
    (url: string, coachId: number) => fetcher(url, token, coachId)
  );
  return {
    coachInfo: data as CoachDetail,
    mutate,
    error
  }
};
