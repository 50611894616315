
import { Container, Grid, GridProps, NativeSelect, styled, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { EditSingleGroupClassFormData } from 'src/api/form-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import ButtonPrimary from 'src/components/ButtonPrimary';
import NavigateBack from 'src/components/navigate/NavigateBack';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import putAdminEditSingleGroupClass from 'src/api/coach/admin/put-admin-edit-single-group-class';
import useAdminAllCoach from 'src/hooks/swr/coach/admin/use-admin-all-coach';
import useAdminAllGroupClass from 'src/hooks/swr/coach/admin/use-admin-all-group-class';

const fieldWidth = 250;

const RegisterTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

const ThemeSeparator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '70vw',
  maxWidth: 500,
  height: 1,
}));

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

export default function AdminGroupClassEditSingle() {
  const navigate = useNavigate();
  const { t } = useTranslation('coach');
  const { t: t_a } = useTranslation('admin');
  let { groupClassId } = useParams();
  // To Refactor
  const { allGroupClasses } = useAdminAllGroupClass();
  const { allCoach } = useAdminAllCoach();

  const groupClass = useMemo(() => {
    if (allGroupClasses) {
      return (allGroupClasses.filter(groupClass => groupClass.groupClassId === parseInt(groupClassId as string))[0])
    }
  }, [allGroupClasses, groupClassId]);

  const availableCoach = useMemo(() => {
    if (allCoach && groupClass) {
      if (groupClass.isStar) {
        return allCoach.filter(coach => coach.type === 'senior' || coach.type === 'director')
      } else if (groupClass.isSelected) {
        const currentCoachType = allCoach.filter(coach => coach.id === groupClass.coachId)[0].type;
        if (currentCoachType === 'junior') {
          return allCoach
        } else if (currentCoachType === 'normal') {
          return allCoach.filter(coach => coach.type !== 'junior')
        } else {
          return allCoach.filter(coach => coach.type === 'senior' || coach.type === 'director')
        }
      } else {
        return allCoach
      }
    }
  }, [allCoach, groupClass])

  const classDayjsTime = useMemo(() => {
    if (groupClass) {
      return dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute);
    }
  }, [groupClass]);

  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    dialogText: '',
    resSuccessful: false,
  });

  const { register, handleSubmit, setValue, control, watch } = useForm<EditSingleGroupClassFormData>();

  register('name');
  register('description');
  register('coachId');

  const selectedCoach = watch('coachId')

  useEffect(() => {
    if (groupClass) {
      setValue('name', groupClass.name);
      setValue('description', groupClass.description);
      setValue('coachId', groupClass.coachId);
    }
  }, [groupClass, setValue])

  const onSubmit = handleSubmit(async (data: EditSingleGroupClassFormData) => {
    const res = await putAdminEditSingleGroupClass(data, parseInt(groupClassId as string));
    const successful = res.status === 200;
    setDialogInfo({
      open: true,
      dialogText: t_a(`group_class.dialog.edit_${successful ? 'successful' : 'fail'}`),
      resSuccessful: successful,
    });
  });

  const handleDialogClose = () => {
    setDialogInfo({ ...dialogInfo, open: false });
    if (dialogInfo.resSuccessful) {
      navigate(`/coach/admin/group-class/${groupClassId}`);
    }
  };

  return (
    <Container sx={{ marginTop: 2, marginBottom: 3, padding: 0 }} maxWidth={'md'}>

      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item sx={{ marginBottom: 2 }}>
          <NavigateBack />
        </Grid>

        <form onSubmit={onSubmit}>
          <Grid
            item container
            xs={12}
            spacing={1.5}
            direction='column'
            justifyContent='center'
            alignItems='center'>
            <Grid item>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                rules={{ required: true }}
                render={({ field }) =>
                <RegisterTextfield
                  variant='outlined'
                    label={t('group_class.fields.name')}
                    required
                    inputProps={{ 'aria-label': 'name' }}
                    {...field} />
                  }
                />
            </Grid>

            <Grid item>
              <Controller
                name='description'
                control={control}
                defaultValue=''
                rules={{ required: true }}
                render={({ field }) =>
                <RegisterTextfield
                  variant='outlined'
                  multiline
                  rows={2}
                  label={t('group_class.fields.description')}
                    required
                    inputProps={{ 'aria-label': 'last_name' }}
                    {...field} />
                  }
                />
            </Grid>

            {groupClass && classDayjsTime &&
              <>
                <Grid item>
                  <ThemeSeparator/>
                </Grid>

                <TextGrid>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {t('coach')}
                    </Typography>
                  </Grid>
                  <Grid item>
                  {availableCoach &&
                    <NativeSelect
                      inputProps={{
                        // only for reference only, no particular use
                        name: 'coach', id: 'uncontrolled-native',
                      }}
                      value={selectedCoach}
                      onChange={e => {
                        setValue('coachId', parseInt(e.target.value))
                      }}
                      required
                    >
                      {availableCoach.map((coach, index) => (
                        <option value={coach.id} key={`coach-${index}`} aria-label={`coach-select-${coach.preferred_name}`}>
                          {nameToCamelcase(coach.preferred_name + ' ' + coach.last_name)}
                        </option>
                        ))
                      }
                    </NativeSelect>
                  }
                  </Grid>
                </TextGrid>

                <Grid item>
                  <ThemeSeparator/>
                </Grid>

                <TextGrid>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {t('group_class.fields.date')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' color='primary.main' textAlign='right'>
                      {classDayjsTime.format('DD MMM[,] YYYY [-] ddd[,] HH:mm')}
                    </Typography>
                  </Grid>
                </TextGrid>

                <TextGrid>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {t('group_class.fields.credit_charge')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' color='primary.main'>
                      {groupClass.creditCharge + ' ' + t('group_class.fields.credit')}
                    </Typography>
                  </Grid>
                </TextGrid>
                <Grid item>
                  <ThemeSeparator/>
                </Grid>
              </>
            }
            <Grid item>
              <ButtonPrimary
                status='primary'
                type='submit'
                label={t_a('group_class.btn.edit_class')}
                aria-label='create-btn'
              />
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Dialog
          open={dialogInfo.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}