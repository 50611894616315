import { Container, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomerLink from 'src/components/coach/customer-management/CustomerLink';
import useCoachSearchCustomer from 'src/hooks/swr/coach/use-coach-search-customer';
import adminCleanPhone from 'src/utils/admin-clean-phone';
import { nameToCamelcase } from 'src/utils/clean-name';
import stripeHKPhone from 'src/utils/stripe-hk-phone';

export default function SearchCustomer() {
  const { t } = useTranslation('coach');

  const [phone, setPhone] = useState('');
  // backend will only perform search when phone number digit >= 4
  // 204 no content if phone number digit is 0-3
  const { allCustomer } = useCoachSearchCustomer(phone);

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('search_customer.title')}
          </Typography>
        </Grid>

        <Grid item>
          <TextField
            variant='outlined'
            label={t('search_customer.fields.phone_num')}
            inputProps={{'aria-label': 'search-customer-number'}}
            value={phone}
            onChange={(e) => setPhone(e.target.value)} />
        </Grid>

        {!allCustomer &&
          <Grid item>
            <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}>
              {t('search_customer.text')}
            </Typography>
          </Grid>
        }
        
        {allCustomer && allCustomer.length === 0 &&
          <Grid item>
            <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}>
              {t('search_customer.no_result')}
            </Typography>
          </Grid>
        }

        {allCustomer && allCustomer.length !== 0 && allCustomer.map((customer) => (
          <Grid item key={customer.id}>
            <CustomerLink
              to={`/coach/customer/${stripeHKPhone(customer.phone_number)}`}
              name={nameToCamelcase(customer.preferred_name + ' ' + customer.last_name)}
              phoneNumber={adminCleanPhone(customer.phone_number)} />
          
          </Grid>
        ))

        }

      </Grid>
    </Container>
  )
}