import { IRootState } from 'src/store';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CoachDirectorPrivateRoute ({ children }: { children: JSX.Element }) {
  const isCoachDirector = useSelector((state: IRootState) => state.authCoach.accessRight === 'director');
  let location = useLocation();
  
  if (!isCoachDirector) {
    return <Navigate to='/coach/dashboard' state={{ from: location }} replace />
  }

  return children
};

export default CoachDirectorPrivateRoute;
