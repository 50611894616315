import { IRootState } from 'src/store';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CustomerPrivateRoute ({ children }: { children: JSX.Element }) {
  const isAuthenticated = useSelector((state: IRootState) => state.authCustomer.isAuthenticated);
  let location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return children
};

export default CustomerPrivateRoute;
  