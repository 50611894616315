import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import { InternalClass } from 'src/hooks/swr/output-types';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';
import { useMemo } from 'react';

interface InternalClassBoxProps {
  internalClass: InternalClass,
}

export default function InternalClassBox({
  internalClass,
}: InternalClassBoxProps) {
  const { t } = useTranslation('coach');

  // if the record from internal class table is other event
  // there will be no coach info
  const isOtherEvent = useMemo(() => {
    if (internalClass) {
      return internalClass.preferred_name === null
    }
  }, [internalClass])

  const timeInfo = useMemo(() => {
    if (internalClass) {
      const classDayjsTime = dayjs(internalClass.date).hour(internalClass.hour).minute(internalClass.minute)
      const durationMinute = parseFloat(internalClass.duration) * 2 * 30;
      return {
        startTime: classDayjsTime.format('HH:mm'),
        endTime: classDayjsTime.add(durationMinute, 'minute').format('HH:mm'),
        duration: durationMinute,
      }
    }
  }, [internalClass]);
  
  const isConfirmed = useMemo(() => {
    if (internalClass) {
      return internalClass.status === 'confirmed';
    }
  }, [internalClass])

  return (
    <LinkWithoutStyle to={`/coach/director/book-studio/${internalClass.id}`}>
      {timeInfo &&
        <Box sx={{
          boxShadow: `0px 4px 4px rgb(0, 57, 118, 0.25)`,
          borderRadius: 2.5,
          border: '1px solid',
          borderColor: 'primary.dark',
          background: '#FFFFFF',
          padding: 1,
          marginTop: 1,
          marginBottom: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Box>
            <Typography variant='body2' color='primary.dark'
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}>
              {(t(`book_studio.${isOtherEvent ? 'other_event' : 'internal_class'}`))
                + ' - ' + nameToCamelcase(internalClass.name)
              }
            </Typography>

            <Typography variant='body2' color='primary.dark'>
              {dayjs(internalClass.date).format('DD MMM[,] YYYY [-] ddd')}
            </Typography>

            <Typography variant='body2' color='primary.dark'>
              {`${timeInfo.startTime} - ${timeInfo.endTime} / ${timeInfo.duration} ` + t('minute')}
            </Typography>

            {!isOtherEvent &&
              <Typography variant='body2' color='primary.dark'
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                }}>
                {`${nameToCamelcase(internalClass.preferred_name + ' ' + internalClass.last_name)}`}
              </Typography>
            }
          </Box>
          <Box sx={{
            height: 60,
            border: '0px solid',
            borderLeftWidth: 1,
            borderColor: isConfirmed ? 'secondary.main' : 'disableGrey.main',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 1,
            paddingTop: 0.5,
            paddingBottom: 0.5,
          }}>
            <Typography variant='body2'
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}
              color={isConfirmed ? 'secondary.main' : 'disableGrey.main'}>
              {t(`book_studio.status.${internalClass.status}`)}
            </Typography>
          </Box>
        </Box>
      }
    </LinkWithoutStyle>
  )
}