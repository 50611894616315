import { Container, Grid, GridProps, styled, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NavigateBack from 'src/components/navigate/NavigateBack';
import PaymentRecordListAdmin from 'src/components/payment-record/PaymentRecordListAdmin';
import useCoachSingleCustomer from 'src/hooks/swr/coach/use-coach-single-customer';
import useCoachSingleCustomerAllPurchaseHistory from 'src/hooks/swr/coach/use-coach-single-customer-all-purchase-history';
import { nameToCamelcase } from 'src/utils/clean-name';
import stripeHKPhone from 'src/utils/stripe-hk-phone';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Collapse from '@mui/material/Collapse';
import { ReactComponent as ChevronUp } from 'src/icons/components/chevron-up.svg';
import { theme } from 'src/styles/muiTheme';
import AddSubtractBtn from 'src/components/coach/credit/AddSubtractBtn';
import { Controller, useForm } from 'react-hook-form';
import { AdminManualChangeCreditFormData } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import postAdminCreditProof from 'src/api/coach/admin/post-admin-credit-proof';
import postAdminManualModifyCredit from 'src/api/coach/admin/post-admin-manual-modify-credit';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.displayGrey.main,
  width: '70vw',
  maxWidth: 500,
  height: 1,
}));

const fieldWidth = 250;

const InputTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

export default function CreditManualChange() {
  const { t } = useTranslation('coach');
  const { customerPhone } = useParams();
  const navigate = useNavigate();
  // only return unique customer with unique phone number
  const { allCustomer, mutate: mutateAllCustomer } = useCoachSingleCustomer(customerPhone !== undefined ? customerPhone : '');

  const customerInfo = useMemo(() => {
    if (allCustomer) {
      return allCustomer[0]
    }
  }, [allCustomer]);

  const { allPurchaseRecord, mutate: mutateAllPurchaseRecord } = useCoachSingleCustomerAllPurchaseHistory(customerInfo ? customerInfo.id : undefined);

  const [modifyType, setModifyType] = useState<'add' | 'subtract'>('add');
  const [purchaseRecordVisible, setPurchaseRecordVisible] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    text: '',
  });

  const handleDialogClose = () => {
    setDialog({ ...dialog, open: false });
    navigate(`/coach/customer/${customerPhone}`);
  };

  const photoProofRef = useRef(null);
  const [photoProofLoading, setPhotoProofLoading] = useState(false);

  const { register, handleSubmit, setValue, setError, watch, control, formState: { errors } } = useForm<AdminManualChangeCreditFormData>();

  register('credit');
  register('reason');
  register('type');
  register('customerId');
  register('creditProofPath');

  useEffect(() => {
    setValue('type', modifyType);
  }, [modifyType, setValue])

  useEffect(() => {
    if (customerInfo) {
      setValue('customerId', customerInfo.id);
    }
  }, [customerInfo, setValue])

  const uploadedCreditProofPath = watch('creditProofPath');
  const inputCredit = watch('credit');

  const uploadCreditProof = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhotoProofLoading(true);
    
    const formData = new FormData();
    const foo = await event.target.files?.item(0);
    formData.append('creditProof', foo as File);
    
    const res = await postAdminCreditProof(formData);
    const { paymentProofPath } = await res.json();
    
    setValue('creditProofPath', paymentProofPath);
    setPhotoProofLoading(false);
  }

  const onSubmit = handleSubmit(async (data: AdminManualChangeCreditFormData) => {
    if (!data.credit || parseInt(data.credit) === 0) {
      setError('credit', {
        type: 'empty',
        message: t('manual_credit.error.empty_credit',
          { type: t(`manual_credit.${modifyType}`) }
      )});
      return
    }

    if (isNaN(parseInt(data.credit))) {
      setError('credit', {
        type: 'empty',
        message: t('manual_credit.error.nan_credit',
          { type: t(`manual_credit.${modifyType}`) }
      )});
      return
    }

    if (!data.reason) {
      setError('reason', {
        type: 'empty',
        message: t('manual_credit.error.empty_reason',
          { type: t(`manual_credit.${modifyType}`) }
      )});
      return
    }
    
    const res = await postAdminManualModifyCredit(data);
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      mutateAllCustomer();
      mutateAllPurchaseRecord();
      setDialog({
        title: t('manual_credit.dialog.success_title', { type: t(`manual_credit.${modifyType}`) }),
        text: t('manual_credit.dialog.success_text', {
          name: customerInfo.preferred_name,
          type: t(`manual_credit.${modifyType}`),
          credit: inputCredit,
        }),
        open: true,
      });
    } else {
      setDialog({
        title: t('manual_credit.dialog.fail_title', { type: t(`manual_credit.${modifyType}`) }),
        text: t('manual_credit.dialog.fail_text'),
        open: true,
      });
    }
  });

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <NavigateBack />
        </Grid>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('manual_credit.title')}
          </Typography>
        </Grid>

        {customerInfo &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.preferred_name + ' ' + customerInfo.last_name)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.gender')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.gender)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.phone_number')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {stripeHKPhone(customerInfo.phone_number)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.available_credit')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {customerInfo.available_credit}
                </Typography>
              </Grid>
            </TextGrid>
          </>
        }
        
        <form onSubmit={onSubmit}>
          <Grid
            item container
            xs={12}
            spacing={1.5}
            direction='column'
            justifyContent='center'
            alignItems='center'>
            <TextGrid
              sx={{
                maxWidth: '300px !important',
              }}>
              <Grid item>
                <AddSubtractBtn
                  enabled={modifyType === 'add'}
                  type='add'
                  onClick={() => {
                    setModifyType('add');
                  }} />
              </Grid>
              <Grid item>
                <AddSubtractBtn
                  enabled={modifyType === 'subtract'}
                  type='subtract'
                  onClick={() => {
                    setModifyType('subtract');
                  }} />
              </Grid>
            </TextGrid>

            <Grid item>
              <Controller
                name='credit'
                control={control}
                defaultValue=''
                render={({ field }) =>
                <InputTextfield
                  variant='outlined'
                    label={t('manual_credit.fields.credit', { type: t(`manual_credit.${modifyType}`)})}
                    error={!!errors['credit']}
                    helperText={!errors['credit']
                      ? t('manual_credit.helper.credit')
                      : errors['credit'].message}
                    inputProps={{ 'aria-label': 'credit' }}
                    {...field} />
                  }
                />
            </Grid>

            <Grid item>
              <Controller
                name='reason'
                control={control}
                defaultValue=''
                render={({ field }) =>
                <InputTextfield
                  variant='outlined'
                    label={t('manual_credit.fields.reason', { type: t(`manual_credit.${modifyType}`)})}
                    error={!!errors['reason']}
                    helperText={!errors['reason'] ? undefined : errors['reason'].message}
                    inputProps={{ 'aria-label': 'reason' }}
                    {...field} />
                  }
                />
            </Grid>

            <Grid item>
              <ButtonPrimary
                status='primary'
                isLoading={photoProofLoading}
                label={t('manual_credit.btn.upload_proof')}
                onClick={() => photoProofRef.current && (photoProofRef.current as any).click()} />
            </Grid>

            <Grid item>
              <input
                type='file'
                ref={photoProofRef}
                accept='image/*'
                style={{ display: 'none' }}
                onChange={(event) => uploadCreditProof(event)}
                />
            </Grid>

            <Grid item>
              {uploadedCreditProofPath &&
                <img
                  src={`${AWS_S3_BUCKET}/${uploadedCreditProofPath}`}
                  alt='proof for admin manual credit'
                  style={{width: '70vw', maxWidth: 400}}/>
              }
            </Grid>

            <Grid item>
              <ButtonPrimary
                status='primary'
                type='submit'
                label={t('manual_credit.btn.submit', { type: t(`manual_credit.${modifyType}`)})}
                aria-label='submit-btn'
                isLoading={photoProofLoading}
              />
          </Grid>
          </Grid>
        </form>

        {/* informational purchase record */}
        <TextGrid
          item container xs={12}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setPurchaseRecordVisible(!purchaseRecordVisible);
          }}>
          <Grid
            item container
            direction='row'
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
              sx={{
                marginTop: 1,
                marginBottom: 1,
                marginRight: 1,
              }}>
              {t('purchase_record.purchase_record')}
            </Typography>
            <ChevronUp
              stroke={theme.palette.displayGrey.main}
              style={{
                transform: purchaseRecordVisible ? 'rotate(180deg)' : 'unset',
                transitionDuration: '500ms',
              }}/>
          </Grid>
          <Separator/>
        </TextGrid>

        {allPurchaseRecord &&
          <Grid item>
            <Collapse in={purchaseRecordVisible} timeout={'auto'}>
              <Grid
                item container
                xs={12}
                spacing={1}
                direction='column'
                justifyContent='center'
                alignItems='center'
                sx={{ marginTop: 1 }}>
                {allPurchaseRecord && allPurchaseRecord.length === 0 &&
                  <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
                    sx={{
                      marginTop: 1,
                      marginBottom: 1,
                    }}>
                    {t('purchase_record.empty', {status: ''})}
                  </Typography>
                }
                {allPurchaseRecord &&
                  <PaymentRecordListAdmin
                    allPurchaseRecord={allPurchaseRecord}
                    mutate={mutateAllPurchaseRecord}
                    isReadOnly />
                }
              </Grid>
            </Collapse>
          </Grid>
        }

      </Grid>

      <Dialog
        open={dialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
              color='primary'
              fontSize={18}
              sx={{ marginBottom: 1 }}>

            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}