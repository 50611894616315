import { useEffect, useState } from 'react';
import { Container, Grid, Typography, TextField, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import { IRootState } from 'src/store';
import NavigateBack from 'src/components/navigate/NavigateBack';
import postCustomerResetPasswordSms from 'src/api/post-customer-reset-password-sms';
import postCustomerResetPassword from 'src/api/post-customer-reset-password';
import { ResetPasswordFormData } from 'src/api/form-types';

export default function ResetPassword() {
  // redirect to customer dashboard if logged in
  const isCustomerAuthenticated = useSelector((state: IRootState) => state.authCustomer.isAuthenticated);

  const [smsState, setSmsState] = useState({ loading: false, sec: 0 });

  const navigate = useNavigate();
  useEffect(() => {
    if (isCustomerAuthenticated) {
      navigate('/dashboard');
    }
  }, [isCustomerAuthenticated, navigate])

  const { t } = useTranslation('customer');

  const { register, handleSubmit, control, watch, setError, formState: { errors }, clearErrors } = useForm<ResetPasswordFormData>();

  register('phoneNumber');
  register('smsCode');
  register('newPassword');
  register('confirmPassword');

  const inputPhoneNumber = watch('phoneNumber');
  
  const onSubmit = handleSubmit(async (data: ResetPasswordFormData) => {
    if (data.newPassword !== data.confirmPassword) {
      setError('confirmPassword', { type: 'empty', message: t('reset_password.error.need_same_password')});
      return
    }

    const res = await postCustomerResetPassword(data);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      window.alert(t('reset_password.result.reset_success'));
      navigate('/login');
    } else { 
      window.alert(t('reset_password.result.reset_fail'));
    }
  });
  
  const handleObtainSmsCode = async () => {
    if (!inputPhoneNumber || inputPhoneNumber === '') {
      setError('phoneNumber', { type: 'empty', message: t('reset_password.error.need', {type: t('reset_password.phone_number')})});
      return
    }
    const res = await postCustomerResetPasswordSms(inputPhoneNumber);
    const { successful } = await res.json();
    if (successful) {
      const waitSec = 60;
      clearErrors('phoneNumber');
      setSmsState({ loading: true, sec: waitSec });
      setInterval(() => {
        setSmsState(prev => ({...prev, sec: prev.sec - 1}));
      }, 1000);
      setTimeout(() => {
        setSmsState({ loading: false, sec: 0 });
      }, waitSec * 1000);
      return
    } else {
      setError('phoneNumber', { type: 'empty', message: t('reset_password.error.need', {type: t('reset_password.phone_number')})});
      return
    }
  }

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 6 }}>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={3}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <NavigateBack />
          </Grid>
          <Grid item>
            <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: 32, color: '#4c4348', textAlign: 'center' }} >
              {t('reset_password.title')}
            </Typography>
          </Grid>

          <Grid item>
            <Controller
              name='phoneNumber'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
                <TextField
                  variant='outlined'
                  label={t('reset_password.phone_number')}
                  required
                  inputProps={{ 'aria-label': 'phone-number' }}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber && errors.phoneNumber.message}
                  {...field} />
                }
              />
          </Grid>
          <Grid item>
            <Button variant='contained' disabled={smsState.loading} onClick={handleObtainSmsCode}>
              {smsState.loading && t('reset_password.after_second', {sec: smsState.sec})}
              {t('reset_password.obtain') + t('reset_password.sms_code')}
            </Button>
          </Grid>
          <Grid item>
            <Controller
              name='smsCode'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
              <TextField
                variant='outlined'
                  label={t('reset_password.sms_code')}
                  required
                  inputProps={{ 'aria-label': 'sms-code' }}
                  {...field} />
                }
              />
          </Grid>
          <Grid item>
            <Controller
              name='newPassword'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
                <TextField
                  variant='outlined'
                  type='password'
                  label={t('reset_password.new_password')}
                  required
                  inputProps={{ 'aria-label': 'new-password' }}
                  {...field} />
                }
              />
          </Grid>
          <Grid item>
            <Controller
              name='confirmPassword'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
                <TextField
                  variant='outlined'
                  type='password'
                  label={t('reset_password.confirm_password')}
                  required
                  inputProps={{ 'aria-label': 'confirm-password' }}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword && errors.confirmPassword.message}
                  {...field} />
                }
              />
          </Grid>
          <Grid item>
            <ButtonPrimary
              status='primary'
              type='submit'
              label={t('reset_password.reset_password')}
              aria-label='reset-password-btn'
              sx={{ width: 150 }}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}