import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/system';
import { BtnStatus } from 'src/components/types';

interface CustomCircularProgressProps extends CircularProgressProps {
  status: BtnStatus,
  isFilledBtn?: boolean,
}

export interface ButtonLoadingProps extends ButtonProps {
  status: BtnStatus,
  isLoading?: boolean,
  isFilledBtn?: boolean,
  loadingProps?: CircularProgressProps,
}

const CustomCircularProgress = styled(CircularProgress, {
  // this 'status' is consumed in ButtonLoading
  // no need to pass prop 'status' into Circular Progress
  // prop === 'status',
  shouldForwardProp: (prop) => false,
  name: 'CustomCircularProgress',
  slot: 'Root',
  overridesResolver: (props, styles) => [
    styles.root,
    props.status === 'primary' && styles.primary,
    props.status === 'secondary' && styles.secondary,
    props.status === 'disabled' && styles.disabled,
    props.status === 'warning' && styles.warning,
    props.isFilledBtn && styles.filledImportant,
  ],
}) <CustomCircularProgressProps> (( () => ({

})));

export default function ButtonLoading(
  props: ButtonLoadingProps,
) {
  const {
    isLoading,
    isFilledBtn,
    loadingProps,
    status,
    ...buttonProps
    } = props;
  return (
    // TODO - when isLoading is true, should disable onClick; or even disable &:hover
    <Button disabled={status === 'disabled' || isLoading} {...buttonProps}>
      {!isLoading && 
        <>
          {props.children}
        </>
      }
      {isLoading && <CustomCircularProgress isFilledBtn={isFilledBtn} status={status} />}
    </Button>
  )
}
