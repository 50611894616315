import  { useEffect, useMemo, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';
import { Box, SxProps } from '@mui/material';
import { theme } from 'src/styles/muiTheme';

interface StyledQRCodeForClassAttendanceProps {
  data: any,
  sx?: SxProps,
}

export default function StyledQRCodeForClassAttendance({
  data,
  sx,
}: StyledQRCodeForClassAttendanceProps) {
  const ref = useRef<null | HTMLElement>(null);
  const qrCode = useMemo(() => new QRCodeStyling({
    width: 250,
    height: 250,
    data: data,
    // iOS specific issue - cannot draw image into QR code
    // image: `${CURRENT_SITE}/opw_logo_only_192_bg.png`,
    // iOS specific & general solution
    // get image from the same folder
    
    // to-fix - why not reading the qr-code?
    // image: `./opw_logo_only_192_bg.png`,
    dotsOptions: {
      color: theme.palette.primary.main,
      type: 'rounded',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 5,
      imageSize: 0.3,
    }
  }), [data]);

  useEffect(() => {
    qrCode.append(ref.current as HTMLElement);
  }, [qrCode]);

  return (
    <Box ref={ref}
      sx={{
        ...sx,
        margin: 1,
      }} />
    )
}