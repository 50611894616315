import { Container, Grid, IconButton, SvgIcon, Tab, Tabs, Typography, styled, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PTCoach from 'src/components/customer/book/PTCoach';
import GroupClassBox from 'src/components/group-class/GroupClassBox';
import useCustomerAllGroupClass from 'src/hooks/swr/customer/use-customer-all-group-class';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import useCustomerInfo from 'src/hooks/swr/customer/use-customer-info';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import { ReactComponent as DiamondSVG } from 'src/icons/book/diamond-google-font.svg';
import { ReactComponent as DoubleStarSVG } from 'src/icons/book/double-start-google-font.svg';
import { ReactComponent as AlphabetSSVG } from 'src/icons/book/alphabet-s.svg';
import StarIcon from '@mui/icons-material/Star';

type ClassType = 'regular' | 'star-regular' | 'special-regular' | '1-to-1' | 'star-1-to-1' | 
  // Todo - coming soon
  'private-class';

const TabCustomized = styled(Tab)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 4,
  paddingRight: 4,
}));

export default function BookDashboard() {
  const { t } = useTranslation('customer');

  const { allGroupClasses } = useCustomerAllGroupClass();
  const { customerInfo } = useCustomerInfo();

  const [classType, setClassType] = useState<ClassType>('regular');

  const [selectedWeek, setSelectedWeek] = useState(0);
  // useEffect(() => {
  //   const dashboardWeek = localStorage.getItem('customerDashboardWeek');
  //   console.log(dashboardWeek)
  //   if (dashboardWeek) {
  //     console.log(dashboardWeek)
  //     setSelectedWeek(parseInt(dashboardWeek));
  //   };
  // }, [])

  const isSun = useMemo(() => dayjs().day() === 0, []);
  const weekMon = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(1).hour(0).minute(0).second(1)
  , [selectedWeek, isSun]);
  
  const weekSun = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(7).hour(23).minute(59).second(59)
  , [selectedWeek, isSun]);

  const allGroupClassesInWeekType = useMemo(() => {
    if (allGroupClasses) {
      return allGroupClasses
        .filter(groupClass => !groupClass.isSingleClassCancelled)
        .filter(groupClass => dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute).isBetween(weekMon, weekSun))
        .filter(groupClass => {
          if (classType === 'star-regular') {
            return groupClass.isStar
          } else if (classType === 'special-regular') {
            return groupClass.isSelected
          } else if (classType === 'regular') {
            return (!groupClass.isStar && !groupClass.isSelected)
          }
          return true
        })
    }
  }, [allGroupClasses, weekMon, weekSun, classType]);

  const handleChangeClassType = (event: React.SyntheticEvent, newValue: ClassType) => {
    setClassType(newValue);
  };

  const up600 = useMediaQuery('(min-width: 600px)');

  return (
    <React.Fragment>
      <Typography
        variant='h6' color='primary.main'
        textAlign='center'
        sx={{ marginLeft: 2, marginTop: 1 }}>
        {t('book.title.page')}
      </Typography>
      <Tabs
        value={classType}
        onChange={handleChangeClassType}
        variant={up600 ? 'standard' : 'scrollable'}
        centered={up600}
        scrollButtons
        allowScrollButtonsMobile
        aria-label='icon label tabs for different class type'>
        <TabCustomized icon={<FitnessCenterIcon />} value={'regular'} label={t('book.class_type.regular')} />
        <TabCustomized icon={<StarIcon />} value={'star-regular'}  label={t('book.class_type.star-regular')} />
        <TabCustomized icon={<SvgIcon><AlphabetSSVG /></SvgIcon>} value={'special-regular'} label={t('book.class_type.special-regular')} />
        <TabCustomized icon={<SportsKabaddiIcon />} value={'1-to-1'} label={t('book.class_type.1-to-1')} />
        <TabCustomized icon={<SvgIcon><DoubleStarSVG /></SvgIcon>} value={'star-1-to-1'} label={t('book.class_type.star-1-to-1')} />
        <TabCustomized icon={<SvgIcon><DiamondSVG /></SvgIcon>} value={'private-class'} label={t('book.class_type.private_class')} />
      </Tabs>
      <Container sx={{ padding: 2 }} maxWidth={'md'}>
        <Grid
          item container
          xs={12}
          spacing={2}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          
          {!(classType === '1-to-1' || classType === 'star-1-to-1' || classType === 'private-class') &&
            <React.Fragment>
              {/* <Grid item container>
                <Typography variant='h6' color='primary.main'>
                  {t('book.title.regular_class')}
                </Typography>
              </Grid> */}
              
              <Grid
                item container
                justifyContent='space-between'
                alignItems='center'>
                <Grid item>
                  <IconButton aria-label='previous-week' color='primary'
                    onClick={() => {
                      setSelectedWeek(selectedWeek - 1)
                      // localStorage.setItem('customerDashboardWeek', (selectedWeek - 1).toString());
                    }}>
                    <ArrowBackIosIcon/>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton aria-label='next-week' color='primary'
                    onClick={() => {
                      setSelectedWeek(selectedWeek + 1)
                      // localStorage.setItem('customerDashboardWeek', (selectedWeek + 1).toString());
                    }}>
                    <ArrowForwardIosIcon/>
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item container justifyContent={'center'}>
                {allGroupClassesInWeekType && (
                  allGroupClassesInWeekType.length === 0 &&
                    <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
                      sx={{
                        marginTop: 1,
                        marginBottom: 1,
                      }}>
                      {t('group_class.empty', {status: t('status.confirmed')})}
                    </Typography>
                  )
                }
                {allGroupClassesInWeekType && (
                  allGroupClassesInWeekType.length !== 0 && allGroupClassesInWeekType.map((groupClass, index) => (
                    <Grid item xs={12} sm={8} key={`group-class-${index}`}>
                      <GroupClassBox
                        isBooking
                        groupClass={groupClass}
                        customerEnrolled={groupClass.enrolled}
                        customerInfo={customerInfo} />
                    </Grid>
                  ))
                  )
                }
              </Grid>
            </React.Fragment>
          }

          {(classType === '1-to-1' || classType === 'star-1-to-1') &&
            <PTCoach isStar={classType === 'star-1-to-1'} />
          }

          {classType === 'private-class' &&
            <PTCoach isPrivateClass />
          }

        </Grid>

      </Container>
    </React.Fragment>
  )
}