import {
  Box, Container, Grid, GridProps, styled, Typography, Slide, NativeSelect, TextField, InputAdornment,
  // CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCustomerAllCreditPackage from 'src/hooks/swr/customer/use-customer-all-credit-package';
import useCustomerAvailableCredit from 'src/hooks/swr/customer/use-customer-available-credit';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';
import { CreditPackage, PromotionCode } from 'src/hooks/swr/output-types';
import { useParams, Link, useNavigate } from 'react-router-dom';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  useEffect,
  useState
} from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { sha256 } from 'js-sha256';
import postCustomerPendingQfCheckout from 'src/api/customer/post-customer-pending-qf-checkout';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import NextBox from 'src/components/navigate/NextBox';
import BackBox from 'src/components/navigate/BackBox';
import { theme } from 'src/styles/muiTheme';
import OfflinePurchaseForm from 'src/pages/customer/membership/OfflinePurchaseForm';
import OfflinePurchaseFormCustom from 'src/pages/customer/membership/OfflinePurchaseFormCustom';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import useCustomerAllPromotionCode from 'src/hooks/swr/customer/use-customer-all-promotion-code';

// const handleFee = 3.4;

function paramStringify(json: any, flag?: any) {
  let str = '';
  let keysArr = Object.keys(json);
  keysArr.sort().forEach(val => {
    if (!json[val]) return;
    str += `${val}=${flag ? encodeURIComponent(json[val]) : json[val]}&`;
  });
  return str.slice(0, -1);
}

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const TextGridRadio = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='center'
    {...props} />
))(({ theme }) => ({
  minWidth: 240,
  marginTop: 5,
  marginBottom: 5,
}));

const QFPayBox = styled(Box)(({ theme }) => ({
  width: '70vw',
  maxWidth: 300,
 // height: 30,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: '5px 10px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

// const StripeBox = styled(Box)(({ theme }) => ({
//   width: '70vw',
//   maxWidth: 300,
//   boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
//   borderRadius: 10,
//   padding: '5px 10px',
//   cursor: 'pointer',
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
// }));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '70vw',
  maxWidth: 500,
  height: 1.5,
}));

export default function Membership() {
  const customerId = useSelector((state: IRootState) => state.authCustomer.customerId);

  let { qfPayResult } = useParams();
  const [dialogOpen, setDialogOpen] = useState(qfPayResult !== undefined);
  
  // starts with step 2
  const [isStepTwo, setIsStepTwo] = useState(true);
  const [isStepThree, setIsStepThree] = useState(false);

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (qfPayResult && qfPayResult === 'success') {
      navigate('/record');
    }
  };

  const { t } = useTranslation('customer');
  const { allCreditPackage } = useCustomerAllCreditPackage();
  const { allPromotionCode } = useCustomerAllPromotionCode();
  const { credit } = useCustomerAvailableCredit();

  const [selectedCredit, setSelectedCredit] = useState<number | 'promotion' | 'custom'>('custom');
  const [customCredit, setCustomCredit] = useState<number>(3000);
  const [selectedCreditPackage, setSelectedCreditPackage] = useState<CreditPackage>();
  const [qfOutTradeNo, setQfOutTradeNo] = useState('');
  const [selectedPromotion, setSelectedPromotion] = useState<PromotionCode>();
  // const [isStripeLoading, setIsStripeLoading] = useState(false);
  
  useEffect(() => {
    if (allPromotionCode) {
      setSelectedPromotion(allPromotionCode[0])
    }
  }, [allPromotionCode])

  const handleCreditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = (event.target as HTMLInputElement).value;
    const targetValueInt = parseInt((event.target as HTMLInputElement).value);
    if (isNaN(targetValueInt)) {
      setSelectedCredit(targetValue as 'promotion' | 'custom');
      setSelectedCreditPackage(undefined);
    } else {
      setSelectedCredit(targetValueInt);
    }
  };

  // temporary disable this function to debug the QF Pay “No such purchase history” transaction
  const postPendingPurchaseHistory = () => {
    if (selectedCreditPackage) {
      postCustomerPendingQfCheckout(selectedCreditPackage.id, qfOutTradeNo);
    } else {
      postCustomerPendingQfCheckout(undefined, qfOutTradeNo);
    }
  };

  // To Refactor
  // no need to pre-fill credit package now
  // useEffect(() => {
  //   if (allCreditPackage) {
  //     setSelectedCredit(parseFloat(allCreditPackage[0].credit_number));
  //   }
  // }, [allCreditPackage]);

  // QF Pay - for UAT only
  useEffect(() => {
    // To do
    if (!allCreditPackage || !customerId) {return}

    // shared code
    let standard = document.getElementById('standard');
    let origin = QF_PAY_ORIGIN;

    // qf_out_trade_no - out_trade_no
    // unix timestamp in seconds, 1656819533
    // plus +
    // random 5 digits

    const outTradeNo = `${dayjs().unix() + Math.floor(Math.random() * 10000).toLocaleString(undefined, {minimumIntegerDigits: 5}).replace(',', '')}-${customerId}`;

    setQfOutTradeNo(outTradeNo);
    // end of shared code

    // to refactor
    if (selectedCredit ===  'custom' || selectedCredit === 'promotion') {
      if (selectedCredit ===  'custom') {
        let obj = {
          appcode: QF_PAY_APP_CODE,
          goods_name: `custom_credit`,
          out_trade_no: outTradeNo,
          paysource: 'remotepay_checkout',
          // test only
          return_url: CURRENT_SITE + '/membership/success',
          failed_url: CURRENT_SITE + '/membership/fail',
          notify_url: `${API_v1}/qf-pay-notify-url-post/`,
          sign_type: 'sha256',
          txamt: customCredit * 100,
          txcurrcd: 'HKD',
          txdtm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        }
  
        let api_key = QF_PAY_API_KEY;
        let params = paramStringify(obj) 
        let sign = sha256(`${params}${api_key}`)
        standard?.setAttribute('href', `${origin}${paramStringify(obj,true)}&sign=${sign}`)
      } else if (selectedCredit === 'promotion' && selectedPromotion) {
        let obj = {
          appcode: QF_PAY_APP_CODE,
          goods_name: `promotion_${selectedPromotion.code}`,
          out_trade_no: outTradeNo,
          paysource: 'remotepay_checkout',
          // test only
          return_url: CURRENT_SITE + '/membership/success',
          failed_url: CURRENT_SITE + '/membership/fail',
          notify_url: `${API_v1}/qf-pay-notify-url-post/`,
          sign_type: 'sha256',
          txamt: selectedPromotion.price,
          txcurrcd: 'HKD',
          txdtm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        }
  
        let api_key = QF_PAY_API_KEY;
        let params = paramStringify(obj) 
        let sign = sha256(`${params}${api_key}`)
        standard?.setAttribute('href', `${origin}${paramStringify(obj,true)}&sign=${sign}`)
      }
    } else {
      const currentCreditPackage = allCreditPackage.filter(creditPackage => parseFloat(creditPackage.credit_number) === selectedCredit)[0];

      setSelectedCreditPackage(currentCreditPackage);
      
      let obj = {
        appcode: QF_PAY_APP_CODE,
        goods_name: currentCreditPackage ? currentCreditPackage.qf_goods_name : '',
        out_trade_no: outTradeNo,
        paysource: 'remotepay_checkout',
        // test only
        return_url: CURRENT_SITE + '/membership/success',
        failed_url: CURRENT_SITE + '/membership/fail',
        notify_url: `${API_v1}/qf-pay-notify-url-post/`,
        sign_type: 'sha256',
        txamt: currentCreditPackage ? currentCreditPackage.price : selectedCredit * 100,
        txcurrcd: 'HKD',
        txdtm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      }
  
      let api_key = QF_PAY_API_KEY;
      let params = paramStringify(obj) 
      let sign = sha256(`${params}${api_key}`)
      standard?.setAttribute('href', `${origin}${paramStringify(obj,true)}&sign=${sign}`)
    }
  }, [selectedCredit, allCreditPackage, customCredit, selectedPromotion, customerId]);

  // To Refactor
  // hardcoded valid for period
  const hardcodedValidForMonth = (credit: string) => {
    let validFor = 2;
    const creditInt = parseInt(credit);
    switch (creditInt) {
      case 1100:
        validFor = 2;
        break
      case 3520:
        validFor = 5;
        break
      case 11000:
        validFor = 12;
        break
      default:
        break
    }
    return validFor
  }

  // unused urgent Stripe integration
  // const handleStripePost = async () => {
  //   let currentCreditPackage;
  //   if (selectedCredit ===  'custom' || selectedCredit === 'promotion') {
  //     // if (selectedCredit ===  'custom') {
  //     //   console.log({customCredit})
  //     // } else if (selectedCredit === 'promotion') {
  //     //   console.log({})
  //     // }
  //   } else {
  //     currentCreditPackage = allCreditPackage.filter(creditPackage => parseFloat(creditPackage.credit_number) === selectedCredit)[0];
  //   }
    
  //   // start ofurgent
  //   setIsStripeLoading(true);

  //   const res = await fetch(`${global.API_v1}/customer/payment/stripe/checkout`, {
  //     method: 'POST',
  //     headers: {
  //       "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       customCredit: selectedCredit ===  'custom' ? customCredit : undefined,
  //       selectedPromotion: selectedCredit ===  'promotion' ? selectedPromotion : undefined,
  //       currentCreditPackageId: currentCreditPackage ? currentCreditPackage.id : undefined,
  //     }),
  //   });

  //   // only Stripe checkout URL is returned in json
  //   const json = await res.json();

  //   if (res.status === 200 && json) {
  //     window.location.assign(json);
  //   }
  //   setIsStripeLoading(false);

  //   // end of urgent
  // }

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4, paddingLeft: 0, paddingRight: 0 }}>

      <Helmet>
        <script src='https://cdn.bootcss.com/blueimp-md5/2.10.0/js/md5.js'></script>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.min.js'></script>
      </Helmet>
      
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('top_bar.membership')}
          </Typography>
        </Grid>

        {credit &&
          <TextGrid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {t('membership.current_credit')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'
                color='secondary.main'
                sx={{
                  padding: 0.7,
                  boxShadow: `0px 4px 8px ${theme.palette.secondary.main}40`,
                  borderRadius: 2,
                }}>
                {credit.currentAvailableCredit + ' ' + t('credit')}
              </Typography>
            </Grid>
          </TextGrid>
        }

        <Grid item>
          <Separator />
        </Grid>

        {allCreditPackage &&
          <Grid
            item container
            xs={12}
            spacing={1}
            direction='column'
            justifyContent='center'
            alignItems='center'>
            
            <Grid item>
              <FormControl>
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  name='radio-buttons-group'
                  sx={{
                    // justifyContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                  value={selectedCredit}
                  onChange={handleCreditChange} >
                  {/* custom / promotional credits */}
                  {allCreditPackage.map((creditPackage) => (
                    <FormControlLabel
                      disabled={isStepThree}
                      value={parseFloat(creditPackage.credit_number)}
                      control={<Radio />}
                      label={
                        <TextGridRadio>
                          <Grid item>
                            <Typography variant='body1' color='primary.main'>
                              {`$${(creditPackage.price / 100).toLocaleString()}`}
                            </Typography>
                          </Grid>
                          {/* <Grid item container> */}
                          <Grid item container
                            xs={9}
                            direction='column'
                            justifyContent='flex-end'>
                            <Grid item>
                              <Typography variant='body1' color='primary.main' textAlign={'right'}>
                                {t('membership.credit', {
                                  number: parseInt(creditPackage.credit_number).toLocaleString(undefined, { minimumFractionDigits: 0 })
                                })}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                color='primary.main' textAlign={'right'}
                                sx={{
                                  fontSize: 10,
                                }}>
                                {t('membership.valid_for', { month: hardcodedValidForMonth(creditPackage.credit_number) })}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TextGridRadio>
                      }
                      key={creditPackage.name}/>
                  ))}
                
                  {/* custom / promotional credits */}
                  <FormControlLabel
                    disabled={isStepThree}
                    value={'custom'}
                    control={<Radio />}
                    label={
                      <Grid item container
                        direction='column'
                        spacing={0.3}
                        sx={{
                          marginTop: 0.5,
                          marginBottom: 0.5,
                        }}>
                        <Grid item>
                          <Typography variant='body1' color='primary.main'>
                            {t('membership.credit', { number: t('membership.custom') + ' $1 = 1' })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <TextField
                            disabled={selectedCredit !== 'custom' || isStepThree}
                            variant='standard'
                            InputProps={{
                              endAdornment: <InputAdornment position='end'>{t('credit')}</InputAdornment>,
                              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                            }}
                            value={customCredit}
                            // label={t('membership.custom') + t('membership.credit', { number: '' })}
                            sx={{
                              width: 200,
                            }}
                            onChange={v => {
                              const newNumber = parseInt(v.target.value);
                              if (!isNaN(newNumber)) {
                                // if customer credit is 0
                                // user enter number after 0 is normal, e.g. 0123 -> 123
                                // user enter number before 0 should clear the original 0, e.g. 1230 -> 123                              
                                if (customCredit === 0 && v.target.value[v.target.value.length - 1] === '0') {
                                  setCustomCredit(parseInt(v.target.value.slice(0, -1)));
                                } else {
                                  setCustomCredit(parseInt(v.target.value));
                                }
                              } else {
                                setCustomCredit(0);
                              }
                            }} />
                        </Grid>
                      </Grid>
                    }/>

                  <FormControlLabel
                    disabled={isStepThree}
                    value={'promotion'}
                    control={<Radio />}
                    label={
                      <Grid item container
                        direction='column'
                        spacing={0.3}
                        sx={{
                          marginTop: 0.5,
                          marginBottom: 0.5,
                        }}>
                        <Grid item>
                          <Typography variant='body1' color='primary.main'>
                            {t('membership.promotional')}
                          </Typography>
                        </Grid>
                        {allPromotionCode &&
                          <Grid item>
                            <FormControl fullWidth disabled={selectedCredit !== 'promotion' || isStepThree}>
                              {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                {t('membership.promotion_code')}
                              </InputLabel> */}
                              <NativeSelect
                                inputProps={{
                                  // only for reference only, no particular use
                                  name: 'promotion', id: 'uncontrolled-native',
                                }}
                                value={selectedPromotion?.code}
                                onChange={e => {
                                  const target = e.target.value;
                                  const promotion = allPromotionCode.filter(p => p.code === target)[0];
                                  setSelectedPromotion(promotion);
                                  setCustomCredit(promotion.credit);
                                }}
                                sx={{ width: 200 }}
                                required
                              >
                                {/* <option value={'WELCOME220'} aria-label={`coach-select-default-0`}>
                                  {'WELCOME220'}
                                </option>
                                <option value={'WELCOME5000'} aria-label={`coach-select-default-0`}>
                                  {'WELCOME5000'}
                                </option> */}
                                {allPromotionCode.map(code => (
                                  <option value={code.code} aria-label={`coach-select-default-0`} key={`promotion-code-${code.id}`}>
                                    {code.code}
                                  </option>
                                ))}
                              </NativeSelect>
                            </FormControl>
                          </Grid>
                        }
                        {/* To Refactor */}
                        <Grid item
                          sx={{ width: 200 }}>
                          <Typography variant='body2' color='primary.main'>
                            {t('membership.promotion', {
                              credit: selectedPromotion?.credit,
                              price: (selectedPromotion?.price as number) / 100,
                            })}
                          </Typography>
                        </Grid>
                        <Grid item
                          sx={{ width: 200 }}>
                          <Typography variant='body2' color='primary.main'>
                            {selectedPromotion && selectedPromotion.code !== '8WEEKS6688' ?
                              t('membership.promotion_valid', {
                                // count: selectedPromotion === 'WELCOME220' ? 1 : 6,
                                count: 2,
                              })
                              :
                              t('membership.promotion_valid_special')
                            }
                          </Typography>
                        </Grid>
                        {/* To Refactor */}
                      </Grid>
                    }/>

                </RadioGroup>
              </FormControl>
            </Grid>            
          </Grid>
        }

        {/* now starts with step 2 */}
        {/* step 1 */}
        {/* <Slide direction='up' in={!isStepTwo && !isStepThree}
          mountOnEnter unmountOnExit
          >
          <Grid item>
            <NextBox
              label={
                selectedCredit !== 0 ?
                  t('membership.btn.purchase_credit') + ' + ' + t('membership.credit', { number: selectedCredit })
                :
                  t('membership.btn.purchase') + t('membership.custom_promotional') + t('membership.credit', { number: '' })
              }
              onClick={() => setIsStepTwo(true)} />
          </Grid>
        </Slide> */}

        {/* step 2 */}
        <Slide direction='up' in={isStepTwo}
          mountOnEnter unmountOnExit>
          <Grid item>
            <Typography>
              <Link to='/docu/refund.pdf' target='_blank' rel='noopener noreferrer'>
                {t('membership.refund_policy')}
              </Link>
            </Typography>
          </Grid>
        </Slide>

        {/* for UAT only, not for production */}
        {selectedCredit !== 0 &&
          <Slide direction='up' in={isStepTwo}
            // mountOnEnter unmountOnExit
            >
            <Grid item>
              <a id='standard' href='/' style={{ textDecoration: 'none', color: 'inherit' }}
                onClick={postPendingPurchaseHistory}>
                <QFPayBox>
                  <Typography color='primary.main' fontWeight={'bold'}>
                    {(selectedCredit !== 'custom' && selectedCredit !== 'promotion') &&
                      // t('membership.btn.online_purchase') + ' ' + t('membership.credit', {
                      //   number: selectedCredit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                      // })
                      t('membership.btn.online_purchase') + ' ' + t('membership.charge_amount', {
                        number: ((selectedCreditPackage?.price as number) / 100).toLocaleString(undefined, { minimumFractionDigits: 0 })
                      })
                    }
                    {selectedCredit === 'custom' &&
                      // t('membership.btn.online_purchase') + ' ' + t('membership.credit', {
                      //   number: customCredit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                      // })
                      t('membership.btn.online_purchase') + ' ' + t('membership.charge_amount', {
                        number: customCredit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                      })
                    }
                    {selectedCredit === 'promotion' &&
                      // t('membership.btn.online_purchase') + ' ' + t('membership.credit', {
                      //   number: selectedPromotion?.credit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                      // })
                      t('membership.btn.online_purchase') + ' ' + t('membership.charge_amount', {
                        number: ((selectedPromotion?.price as number) / 100).toLocaleString(undefined, { minimumFractionDigits: 0 })
                      })
                    }
                  </Typography>
                  <ChevronsRight/>
                </QFPayBox>
              </a>
            </Grid>
          </Slide>
        }
        {/* for UAT only, not for production */}

        {/* {selectedCredit !== 0 &&
          <Slide direction='up' in={isStepTwo}
            mountOnEnter unmountOnExit
            >
            <Grid item>
              <NextBox
                label={
                  t('membership.btn.online_purchase') + ' ' + (
                    (selectedCredit !== 'custom' && selectedCredit !== 'promotion') ?
                      t('membership.credit', { number: selectedCredit })
                    :
                      ''
                  )
                  +
                  t('membership.btn.not_available')
                }
                sx={{
                  boxShadow: `0px 4px 8px ${theme.palette.disableGrey.main}40`,
                }}
                sxTy={{ color: 'disableGrey.main'}}
                arrowProps={{ stroke: theme.palette.disableGrey.main }} />
            </Grid>
          </Slide>
        } */}

        {/* {selectedCredit !== 0 && !isStripeLoading &&
          <Slide direction='up' in={isStepTwo}
            // mountOnEnter unmountOnExit
            >
            <Grid item>
              <StripeBox onClick={handleStripePost}>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('membership.btn.online_purchase') + ' ' + (
                    (selectedCredit !== 'custom' && selectedCredit !== 'promotion') ?
                      t('membership.credit', { number: selectedCredit })
                    :
                      ''
                  ) + t('membership.btn.handle_fee', { fee: handleFee })
                  }
                </Typography>
                <ChevronsRight/>
              </StripeBox>
            </Grid>
          </Slide>
        } */}

        {/* {isStripeLoading &&
          <CircularProgress sx={{
            color: 'primary.main',
            alignSelf: 'center',
            marginTop: 2,
            marginBottom: 2,
            height: '15vw !important',
            width: '15vw !important',
          }}/>
        } */}
        
        <Slide direction='up' in={isStepTwo}
          mountOnEnter unmountOnExit
          >
          <Grid item>
            <NextBox
              label={
                (selectedCredit !== 'custom' && selectedCredit !== 'promotion') ?
                  // t('membership.btn.offline_purchase') + ' ' + t('membership.credit', {
                  //   number: selectedCredit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                  // })
                  t('membership.btn.offline_purchase') + ' ' + t('membership.charge_amount', {
                    number: ((selectedCreditPackage?.price as number) / 100).toLocaleString(undefined, { minimumFractionDigits: 0 })
                  })
                : 
                  selectedCredit === 'custom' ?
                    // t('membership.btn.offline_purchase') + ' ' + t('membership.credit', {
                    //   number: customCredit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                    // })
                    t('membership.btn.offline_purchase') + ' ' + t('membership.charge_amount', {
                      number: customCredit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                    })
                  :
                  selectedCredit === 'promotion' ?
                    // t('membership.btn.offline_purchase') + ' ' + t('membership.credit', {
                    //   number: selectedPromotion?.credit.toLocaleString(undefined, { minimumFractionDigits: 0 })
                    // })
                    t('membership.btn.offline_purchase') + ' ' + t('membership.charge_amount', {
                      number: ((selectedPromotion?.price as number) / 100).toLocaleString(undefined, { minimumFractionDigits: 0 })
                    })
                  : ''
              }
              onClick={() => {
                setIsStepTwo(false);
                setIsStepThree(true);
              }}/>
          </Grid>
        </Slide>

        <Slide direction='up' in={isStepTwo}
          mountOnEnter unmountOnExit
          >
          <Grid item>
            <BackBox
              onClick={() => setIsStepTwo(false)}/>
          </Grid>
        </Slide>

        {/* stpe 3 */}
        <Slide direction='up' in={isStepThree}
          mountOnEnter unmountOnExit
          >
          <Grid item>
            {selectedCreditPackage &&
              <OfflinePurchaseForm
                selectedCreditPackage={selectedCreditPackage}
                setIsStepTwo={setIsStepTwo}
                setIsStepThree={setIsStepThree} />
            }

            {/* for custom / promotional purchase */}
            {!selectedCreditPackage &&
              <OfflinePurchaseFormCustom
                setIsStepTwo={setIsStepTwo}
                setIsStepThree={setIsStepThree}
                credit={customCredit}
                promotionalCode={selectedCredit === 'promotion' ? selectedPromotion?.code : undefined} />
            }
          </Grid>
        </Slide>
      </Grid>


      {qfPayResult &&
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {t('membership.dialog.purchase') + t(`membership.dialog.${qfPayResult as 'success' | 'fail'}`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(`membership.dialog.${qfPayResult as 'success' | 'fail'}_text`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>
              {qfPayResult && qfPayResult === 'success' && t('btn.confirm')}
              {qfPayResult && qfPayResult === 'fail' && t('btn.back')}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Container>
  )
}