import { Button, Dialog, DialogActions, DialogContent, Grid, GridProps, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import putAdminEditPromotionCode from 'src/api/coach/admin/put-admin-edit-promotion-code';
import { EditPromotionCode } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import useCoachAllPromotionCode from 'src/hooks/swr/coach/admin/use-coach-all-promotion-code';
import useCoachSingleCoachInfo from 'src/hooks/swr/use-coach-single-coach-info';
import { IRootState } from 'src/store';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '80vw',
  maxWidth: 600,
  height: 1,
  marginTop: 10,
}));

const initialDialogState = {
  open: false,
  message: '',
};

export default function PromotionCodeViewEdit() {
  const coachId = useSelector((state: IRootState) => state.authCoach.coachId);
  const { coachInfo } = useCoachSingleCoachInfo(coachId, true);
  const isAdminDirector = coachInfo && (coachInfo.access_right === 'admin' || coachInfo.access_right === 'director');

  const { t } = useTranslation('coach');
  const{ allPromotionCode, mutate } = useCoachAllPromotionCode();

  const [dialog, setDialog] = useState(initialDialogState);

  const { register, handleSubmit, setValue, control } = useForm<EditPromotionCode>();

  register('promotionCodeId');
  register('code');
  register('credit');
  register('price');

  const closeEditDialog = () => {
    setDialog(initialDialogState)
  };
  
  const onSubmit = handleSubmit(async (data: EditPromotionCode) => {
    // credit charge is in cent
    data['price'] = (parseInt(data['price']) * 100).toString();
    
    const res = await putAdminEditPromotionCode(data);
    const { successful } = await res.json();
    
    let msg: string;
    if (successful && res.status === 200) {
      msg = t('promotion_code.dialog.successful')
    } else {
      msg = t('promotion_code.dialog.unsuccessful')
    }
    
    setDialog({
      open: true,
      message: msg,
    });
    mutate();

    return
  });

  return (
    <React.Fragment>
      <Grid
        item container
        xs={12}
        spacing={1}
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: 2 }}>
        <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('promotion_code.title')}
          </Typography>
        </Grid>
        
        {allPromotionCode && allPromotionCode.map((promotionCode) => (
          <TextGrid key={promotionCode.code}>
            <Grid item xs={12}>
              <Typography variant='body1' color='primary.main' textAlign='left'
                sx={{
                  borderBottom: '1px solid',
                  marginBottom: 1,
                  fontWeight: 500,
                }}>
                {promotionCode.code}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1' color='primary.main' textAlign='right'>
                {`$ ${(promotionCode.price / 100).toLocaleString()}`}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant='body1' color='primary.main'
                textAlign={'right'}
                >
                {t('promotion_code.credit', { number: promotionCode.credit})}
              </Typography>
            </Grid>
            {isAdminDirector &&
              <Grid container item xs={4} justifyContent='flex-end'>
                <ButtonPrimary
                  status='secondary'
                  label={t('promotion_code.btn.change')}
                  onClick={() => {
                    setValue('promotionCodeId', promotionCode.id);
                    setValue('code', promotionCode.code);
                    setValue('price', (promotionCode.price / 100).toString());
                    setValue('credit', promotionCode.credit.toString());
                  }} />
              </Grid>
            }
          </TextGrid>
        ))}

        <Grid item>
          <Separator />
        </Grid>

        <Grid item>
          <form onSubmit={onSubmit}>
            <Grid container
              spacing={2}
              justifyContent='flex-start'
              alignItems='flex-start'>
              <Grid container item xs={12}
                justifyContent='center'>
                <Grid item>
                  <Controller
                    name='code'
                    control={control}
                    defaultValue=''
                    rules={{ required: true }}
                    render={({ field }) =>
                      <TextField
                        variant='standard'
                        label={t('promotion_code.fields.code')}
                        required
                        inputProps={{ 'aria-label': 'promotion-code' }}
                        {...field} />
                      }
                    />
                </Grid>
              </Grid>
              <Grid item container
                direction='row'
                spacing={2}
                justifyContent='center'
                alignItems='flex-start'>
                <Grid item>
                  <Controller
                    name='price'
                    control={control}
                    defaultValue=''
                    rules={{ required: true }}
                    render={({ field }) =>
                      <TextField
                        variant='standard'
                        label={t('promotion_code.fields.charge')}
                        required
                        inputProps={{ 'aria-label': 'promotion-charge' }}
                        {...field} />
                      }
                    />
                </Grid>
                <Grid item>
                  <Controller
                    name='credit'
                    control={control}
                    defaultValue=''
                    rules={{ required: true }}
                    render={({ field }) =>
                      <TextField
                        variant='standard'
                        label={t('promotion_code.fields.credit')}
                        required
                        inputProps={{ 'aria-label': 'promotion-credit' }}
                        {...field} />
                      }
                    />
                </Grid>
              </Grid>
              <Grid item container
                direction='row'
                spacing={2}
                justifyContent='center'
                alignItems='flex-start'>
                <Grid item>
                  <ButtonPrimary
                    type='submit'
                    status='primary'
                    label={t('promotion_code.btn.edit_package')}
                    sx={{ width: 150 }} />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item>
          <Separator />
        </Grid>

      </Grid>

      <Dialog
        open={dialog.open}
        onClose={closeEditDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <Typography>
            {dialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditDialog} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}