import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import useCoachAllClassTimetable from 'src/hooks/swr/coach/use-coach-all-class-timetable';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { useMemo } from 'react';
import { ClassTimetableItemDisplay } from 'src/hooks/swr/output-types';
import dayjs from 'dayjs';

export default function Timetable () {
  const { t } = useTranslation('coach');
  const { allClassTimetable: allClassTimetableRaw } = useCoachAllClassTimetable();
  
  const isCoachAdmin = useSelector((state: IRootState) =>
    (state.authCoach.accessRight === 'director' || state.authCoach.accessRight === 'admin' || state.authCoach.accessRight === 'manager')
  );
  
  const isCoachDirector = useSelector((state: IRootState) => state.authCoach.accessRight === 'director');

  const allClassTimetable: ClassTimetableItemDisplay[] | undefined = useMemo(() => {
    if (allClassTimetableRaw) {
      // url proxy from is coach admin
      if (isCoachAdmin) {
        return allClassTimetableRaw.map(item => {
          const isPtClass = item.classType === 'PT' || item.classType === 'private-class';
          const isGroupClass = item.classType === 'special-regular' || item.classType === 'star-regular' || item.classType === 'regular';
          const isNormalClass = isPtClass || isGroupClass;

          let returnItem: ClassTimetableItemDisplay = item;
          if (isCoachDirector) {
            if (isNormalClass) {
              returnItem.url = `/coach/admin/${isPtClass ? 'pt-class' : ''}${isGroupClass ? 'group-class' :''}/${item.classId}`;
            } else {
              returnItem.url = `/coach/director/book-studio/${item.classId}`;
            }
          } else {
            if (isNormalClass) {
              returnItem.url = `/coach/admin/${isPtClass ? 'pt-class' : ''}${isGroupClass ? 'group-class' :''}/${item.classId}`;
            } else {
              returnItem.url = `/coach/admin/director-book/${item.classId}`;
            }
          }

          return returnItem
        });
      } else {
        return allClassTimetableRaw
      }
    }
  }, [allClassTimetableRaw, isCoachAdmin, isCoachDirector]);

  return (
    <Container
      sx={{ padding: 2 }}>
      <Typography variant='h6' color='primary.main' textAlign='center'
        sx={{ marginBottom: 1.5 }}>
        {t('timetable.title')}
      </Typography>

      {allClassTimetable &&
        <FullCalendar
          height={'70vh'}
          plugins={[ timeGridPlugin ]}
          initialView='timeGridTwoDay'
          headerToolbar={{
            left: 'prev,next today',
            // hiding the title because it only display date range & have ugly style
            // Aug 14 – 20, 2022
            // center: 'title',
            right: 'timeGridWeek,timeGridTwoDay'
          }}
          // will not need the all day slot 
          allDaySlot={false}
          nowIndicator={true}
          scrollTime={'10:00:00'}
          views={{
            timeGridTwoDay: {
              type: 'timeGrid',
              duration: { days: 2 },
              buttonText: '2 day'
            }
          }}
          weekNumberCalculation={'ISO'}
          events={allClassTimetable}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false,
          }}
          dayHeaderFormat={(date) => {
            return dayjs(date.date.marker).format('ddd D/M')
          }}
          slotMinTime={'06:00:00'}
          slotMaxTime={'24:00:00'} />
      }
    </Container>
  )
}