import { Chip, Container, Grid,GridProps, styled, TextField, Typography, DialogContentTextProps, CircularProgress, Box, InputLabel, FormControl, NativeSelect } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import useCustomerCoachAllPTTimeslot from 'src/hooks/swr/customer/use-customer-coach-all-pt-timeslot';
import useCustomerSingleCoachInfo from 'src/hooks/swr/customer/use-customer-single-coach-info';
import { nameToCamelcase } from 'src/utils/clean-name';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { PTTimeslot } from 'src/api/form-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import postCustomerNewPtClass from 'src/api/customer/post-customer-new-pt-class';
import useCustomerAvailableCredit from 'src/hooks/swr/customer/use-customer-available-credit';
import useCustomerCoachUnavailablePtTimeslotInSixMonth from 'src/hooks/swr/customer/use-customer-coach-unavailable-pt-timeslot-in-six-month';
import useCustomerAllPtDateTimeAvailablity from 'src/hooks/swr/customer/use-customer-all-pt-date-time-availablity';

interface AvailableConditions {
  nowDayjs: Dayjs,
  timeslot: PTTimeslot,
  ptTime: Dayjs,
  formatedTimeslot: string,
  availableTime: string[],
  ptTimeBookedTimeslot: number[],
};

// if selected date is today
// - disable today's past timeslot
// - return remaining available hour, minus those booked timeslot
// 
// if selected date is future date
// - return all available hour, minus those booked timeslot

function determineChipAvailable ({
  nowDayjs,
  timeslot,
  ptTime,
  formatedTimeslot,
  availableTime,
  ptTimeBookedTimeslot,
}: AvailableConditions) {
  const byDateTimeslotAvailable = availableTime.includes(formatedTimeslot) && !ptTimeBookedTimeslot.includes(timeslot.id);
  if (nowDayjs.isSame(ptTime, 'day')) {
    if (timeslot.hour < nowDayjs.hour()) {
      return false
    } else {
      if (timeslot.hour === nowDayjs.hour() && timeslot.minute <= nowDayjs.minute()) {
        return false
      } else {
        return byDateTimeslotAvailable
      }
    }
  } else {
    return byDateTimeslotAvailable
  }
};

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const MyDialogContentText = styled((props: DialogContentTextProps) => (
  <DialogContentText
    color='primary.dark'
    fontWeight={500}
    {...props} />
))(({ theme }) => ({
  marginBottom: 10,
}));

const initialPtTimeslot: PTTimeslot = {
  id: 0,
  weekday: '0',
  is_available: false,
  hour: 0,
  minute: 0,
}

interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
  isAvailable: boolean,
}

const CustomPickersDay = styled((props: CustomPickersDayProps) => (
  <PickersDay
    {...props}/>
), {
  shouldForwardProp: (prop) => prop !== "isAvailable"
})(({ theme, selected, isAvailable }) => ({
  ...(isAvailable && {
    backgroundColor: `${theme.palette.primary.main}4D`,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: `${theme.palette.primary.dark}4D`,
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
}));

export default function PTBookScreen() {
  const { t } = useTranslation('customer');
  let { coachId } = useParams();
  const [searchParams, ] = useSearchParams();
  const isPrivateClass = searchParams.get('type') === 'private-class';

  const navigate = useNavigate();
  const { timeslotData } = useCustomerCoachAllPTTimeslot(parseInt(coachId as string));
  const { allDateTimeAvailablity } = useCustomerAllPtDateTimeAvailablity(parseInt(coachId as string));
  const { coachInfo } = useCustomerSingleCoachInfo(parseInt(coachId as string));
  const { allClass: allClassRaw } = useCustomerCoachUnavailablePtTimeslotInSixMonth(parseInt(coachId as string));
  const allClass = useMemo(() => (allClassRaw ? allClassRaw.map(singleClass =>
    ({
      date: dayjs(singleClass.date),
      id: singleClass.id
    }))
    : undefined
  ) , [allClassRaw]);

  const { credit } = useCustomerAvailableCredit();

  const [ptTime, setPtTime] = useState<Dayjs>(dayjs());
  const [ptWeekday, setPtWeekday] = useState<number>(dayjs().day());
  const [ptTimeslot, setPtTimeslot] = useState<PTTimeslot>(initialPtTimeslot);
  const [ptTimeBookedTimeslot, setStTimeBookedTimeslot] = useState<number[]>([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [noCredit, setNoCredit] = useState(false);

  const [dateAvailablity, setDateAvailablity] = useState<undefined | string[]>();
  const [selectedDuration, setSelectedDuration] = useState<number>(60);

  const availableTime = useMemo(() => {
    if (allDateTimeAvailablity) {
      const list = allDateTimeAvailablity[ptTime.format('YYYY-MM-DD')];
      return list ? list : []
    }
  }, [allDateTimeAvailablity, ptTime])

  useEffect(() => {
    if (allClass) {
      const timeslotList = [];
      for (let i=0; i<allClass.length; i++) {
        if (ptTime.isSame(allClass[i].date, 'day')) {
          timeslotList.push(allClass[i].id);
        }
      }
      setStTimeBookedTimeslot(timeslotList);
    }
  }, [ptTime, allClass])
  
  const handleChipClick = (timeslot: PTTimeslot) => {
    setPtTimeslot(timeslot);
  };

  const handleBookSession = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (bookingSuccess) {
      navigate('/dashboard');
    }
  };

  const confirmBookSession = async () => {
    if (ptTimeslot.id === 0) { return }
    if (parseFloat(credit.currentAvailableCredit) <
      parseFloat(isPrivateClass ? coachInfo.private_class_charge : coachInfo.pt_credit_charge)
    ) {
      setNoCredit(true)
      return
    }
    setBookingLoading(true);

    const res = await postCustomerNewPtClass({
      coachId: parseInt(coachId as string),
      date: ptTime.format('YYYY-MM-DD'),
      coachTimeslotId: ptTimeslot.id,
      isPrivateClass,
      duration: selectedDuration,
    });

    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setTimeout(() => {
        setBookingLoading(false);
        setBookingSuccess(true);
      }, 1000)
    }
  }

  useEffect(() => {
    if (allDateTimeAvailablity) {
      setDateAvailablity(Object.keys(allDateTimeAvailablity));
    }
  }, [allDateTimeAvailablity])

  const renderPickerDay = (
    day: dayjs.Dayjs,
    selectedDays: dayjs.Dayjs[],
    pickersDayProps: PickersDayProps<dayjs.Dayjs>
  ) => {
    let isAvailable = false;
    if (dateAvailablity) {
      if (dateAvailablity.includes(day.format('YYYY-MM-DD'))) {
        isAvailable = true;
      }
    }
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        isAvailable={isAvailable} />
    )
  };

  // TODO - add gender field for coach?

  return (
    <Container sx={{ padding: 2 }} maxWidth={'sm'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {!isPrivateClass && t('book.title.pt_session')}
            {isPrivateClass && t('book.title.private_class')}
          </Typography>
        </Grid>

        {timeslotData && coachInfo && credit && availableTime &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('coach')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(coachInfo.preferred_name + ' ' + coachInfo.last_name)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book.title.credit_charge')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {!isPrivateClass && (parseInt(coachInfo.pt_credit_charge) + ' ' + t('book.credit_per_hour'))}
                  {isPrivateClass && (parseInt(coachInfo.private_class_charge) + ' ' + t('book.credit_per_hour'))}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book.title.current_credit')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {credit.currentAvailableCredit + ' ' + t('credit')}
                </Typography>
              </Grid>
            </TextGrid>

            <Grid item>
              <StaticDatePicker
                displayStaticWrapperAs='desktop'
                minDate={dayjs()}
                // maxDate={dayjs().add(6, 'month')}
                renderDay={renderPickerDay}
                maxDate={dayjs().add(4, 'week')}
                openTo='day'
                value={ptTime}
                onChange={(newValue) => {
                  if (newValue === null) { return }
                  setPtTime(newValue);
                  setPtWeekday(newValue.day());
                  // reset timeslot id specific to weekday
                  setPtTimeslot(initialPtTimeslot);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>

            {/* to do - only private class can now select duration */}
            {/* to refactor - hardcoded duration option */}
            {isPrivateClass &&
              <Grid item sx={{ marginTop: -5 }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native-accessRight">
                    {t('book.fields.duration')}
                  </InputLabel>
                  <NativeSelect
                    inputProps={{
                      // only for reference only, no particular use
                      name: 'duration', id: 'uncontrolled-native-duration',
                    }}
                    variant='standard'
                    value={selectedDuration}
                    onChange={e => {
                      setSelectedDuration(parseInt(e.target.value));
                    }}
                    sx={{ width: 200 }}
                  >
                    <option value={60} aria-label={`duration-60-mins`}>
                      {60 + t('mins')}
                    </option>
                    {isPrivateClass &&
                      <option value={90} aria-label={`duration-90-mins`}>
                        {90 + t('mins')}
                      </option>
                    }
                    {isPrivateClass &&
                      <option value={120} aria-label={`duration-60-mins`}>
                        {120 + t('mins')}
                      </option>
                    }
                  </NativeSelect>
                </FormControl>
              </Grid>
            }

            <Grid item container justifyContent='center'
              sx={{ marginTop: isPrivateClass ? 0 : -5 }}>
              {timeslotData && timeslotData
                .filter(timeslot => parseInt(timeslot.weekday) === ptWeekday)
                .map(timeslot => {
                  const nowDayjs = dayjs();
                  const formatedTimeslot = timeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                    + ':'
                    + timeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2});

                  const isAvailable = determineChipAvailable({
                    nowDayjs,
                    timeslot,
                    ptTime,
                    formatedTimeslot,
                    availableTime,
                    ptTimeBookedTimeslot,
                  });

                  return (
                    <Chip
                      variant={ptTimeslot.id === timeslot.id ? 'filled' : 'outlined'}
                      disabled={!isAvailable}
                      color={isAvailable ? 'primary' : 'default'}
                      sx={{
                        margin: 0.5,
                        borderColor: 'primary.dark',
                        border: `1px solid`,
                      }}
                      label={formatedTimeslot}
                      key={formatedTimeslot}
                      onClick={() => handleChipClick(timeslot)}
                      />
                )})  
              }
            </Grid>
            
            <Grid item>
              <ButtonPrimary
                status='primary'
                label={t('book.btn.book_now')}
                onClick={handleBookSession} />
            </Grid>
          </>
        }
      </Grid>

      {coachInfo &&
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'xs'}>
          <DialogTitle id="alert-dialog-title">
            {bookingSuccess ? t('book.dialog.success') : t('book.dialog.title')}
          </DialogTitle>
          <DialogContent>
            {!noCredit && <>
            {!bookingLoading &&
              <>
                {ptTimeslot.id !== 0 &&
                  <>
                    <MyDialogContentText
                      id="alert-dialog-description">
                      {isPrivateClass ?  t('book.dialog.private_class') : t('book.dialog.pt_session')}
                    </MyDialogContentText>
                    <MyDialogContentText
                      id="alert-dialog-description">
                      {nameToCamelcase(coachInfo.preferred_name + ' ' + coachInfo.last_name)}
                    </MyDialogContentText>
                    <MyDialogContentText
                      id="alert-dialog-description">
                      {ptTime.format('DD MMM[,] YYYY [-] ddd')}
                    </MyDialogContentText>
                    <MyDialogContentText
                      id="alert-dialog-description">
                      {
                        ptTimeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                        + ':' +
                        ptTimeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})
                        + ' - ' + selectedDuration + t('mins')
                      }
                    </MyDialogContentText>
                  </>
                }
                {ptTimeslot.id === 0 &&
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {t('book.dialog.select_time')}
                  </MyDialogContentText>
                }
              </>
            }</>}
            {noCredit &&
              <MyDialogContentText
                id="alert-dialog-description">
                {t('book.dialog.no_credit')}
              </MyDialogContentText>
            }
            {bookingLoading &&
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CircularProgress sx={{
                  color: 'primary.main',
                  alignSelf: 'center',
                  height: '15vw !important',
                  width: '15vw !important',
                }}/>
              </Box>
            }
          </DialogContent>
          {!bookingLoading && 
            <DialogActions>
              <Button onClick={handleDialogClose}>
                {t('btn.back')}
              </Button>
              {ptTimeslot.id !== 0 && !bookingSuccess && !noCredit &&
                <Button onClick={confirmBookSession} autoFocus>
                  {t('btn.confirm')}
                </Button>
              }
            </DialogActions>
          }
        </Dialog>
      }

    </Container>
  )
}