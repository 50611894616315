import { Box } from '@mui/material';
import { ReactComponent as LogoFb } from 'src/icons/components/social-media/facebook.svg';
import { ReactComponent as LogoIg } from 'src/icons/components/social-media/instagram.svg';
import { ReactComponent as LogoYoutube } from 'src/icons/components/social-media/youtube.svg';
import { theme } from 'src/styles/muiTheme';

interface SocialMediaLinkBoxProps {
  linkFb?: string,
  linkIg?: string,
  linkYoutube?: string,
}

export default function SocialMediaLinkBox({
  linkFb,
  linkIg,
  linkYoutube,
}: SocialMediaLinkBoxProps) {

  const totalLink =
    (linkFb ? 1 : 0) +
    (linkIg ? 1 : 0) +
    (linkYoutube ? 1 : 0)
  ;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: 200,
      justifyContent: totalLink === 1 ? 'center' : 'space-between',
    }}>
      {/* for external link, use a tag instead of react router dom v6 Link under LinkWithoutStyle */}
      {linkFb &&
        <a href={linkFb} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
            }}>
            <LogoFb width={28} height={28} />
          </Box>
        </a>
      }

      {linkIg &&
        <a href={linkIg} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
            }}>
            <LogoIg width={28} height={28} />
          </Box>
        </a>
      }

      {linkYoutube &&
        <a href={linkYoutube} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
            }}>
            <LogoYoutube width={28} height={28} />
          </Box>
        </a>
      }
    </Box>
  )
}