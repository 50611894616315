import { OfflinePurchaseCustomFormData } from "src/api/form-types";

export default function postOfflinePurchaseHistoryCustom(data: OfflinePurchaseCustomFormData) {
  return fetch(`${global.API_v1}/customer/payment/offline/custom`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
