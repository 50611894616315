/* group class status:
 * enum(
 * 'pending', 'rejected'
 * 'confirmed', 'cancelled', 
 * 'attended', 'absent',
 * 'cancelled_by_coach',
 * )
 *
 */
export function translateStatusZh(name: string) {
  switch (name) {
    case ('pending'):
      return '待確認課堂'
    case ('rejected'):
      return '已拒絕'
    case ('confirmed'):
      return '已確認課堂'
    case ('cancelled'):
      return '已取消課堂'
    case ('attended'):
      return '已出席'
    case ('absent'):
      return '缺席'
    case ('cancelled_by_coach'):
      return '已由教練取消課堂'
    default:
      return name
  }
}
