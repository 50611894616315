import { useState } from 'react';
import ButtonOutline from 'src/components/ButtonOutline';
import { useTranslation } from 'react-i18next';
import postAdminSalaryXlsx from 'src/api/coach/admin/post-admin-salary-xlsx';
import { nameToLowercase } from 'src/utils/clean-name';

interface Props {
  year: number,
  month: number,
}

export default function SalaryExportExcelBtn({
  year,
  month,
}: Props) {
  const { t } = useTranslation('coach');
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const exportExcel = async () => {
    setExportLoading(true);
    postAdminSalaryXlsx(year, month)
      .then(res => {
        if (res.status === 200) {
          return res.blob()
        } else {
          alert(t('coach_man.result.csv_fail', {type: nameToLowercase(t('coach_man.subtitle.salary'))}));
          return null
        }
      })
      .then(blob => {
        if (!blob) {return}
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `coach-salary-${year}-${month.toLocaleString(undefined, {minimumIntegerDigits: 2})}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert(t('coach_man.result.csv_success', {type: nameToLowercase(t('coach_man.subtitle.salary'))}));
      })
      .finally(() => {
        setExportLoading(false);
      });
  }

  return (
    <ButtonOutline
      label={t('coach_man.btn.month', { month: month })}
      status='primary'
      isLoading={exportLoading}
      onClick={exportExcel} />
  )
}