export default function putDirectorMarkAttendPtClass(ptClassId: number) {
  return fetch(`${global.API_v1}/director/pt-class/make-up/attend`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ptClassId }),
  });
}
