export default function putCustomerCancelGroupClass(groupClassId: number) {
  return fetch(`${global.API_v1}/customer/group-class/cancel`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({groupClassId}),
  });
}
