import { Dispatch, SetStateAction, useEffect } from 'react';
import { Typography, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
import { Controller, useForm } from 'react-hook-form';
import { NewMemberCreditUpdate } from 'src/api/form-types';
import { CreditTextfield } from 'src/pages/coach/director/coach-credit/CreditManagement';
import useLatestNewMemberCredit from 'src/hooks/swr/use-latest-new-member-credit';
import postNewMemberCredit from 'src/api/post-new-member-credit';

interface NewMemberCreditFormProps {
  setDialogOpen: Dispatch<SetStateAction<boolean>>,
  setDialogMessage: Dispatch<SetStateAction<string>>,
}

export default function NewMemberCreditForm({
  setDialogOpen,
  setDialogMessage,
}: NewMemberCreditFormProps) {
  const { t } = useTranslation('coach');
  const { handleSubmit, setValue, control, formState: { errors } } = useForm<NewMemberCreditUpdate>();
  const { newMemberCredit, mutate } = useLatestNewMemberCredit();

  useEffect(() => {
    if (newMemberCredit) {
      setValue('credit', newMemberCredit.credit);
      setValue('creditCoach', newMemberCredit.coach_referral);
      setValue('creditCustomer', newMemberCredit.customer_referral);
    }
  }, [newMemberCredit, setValue]);

  const onSubmitCoachCredit = handleSubmit(async (data: NewMemberCreditUpdate) => {
    const res = await postNewMemberCredit(data);
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      setDialogMessage(t('credit.result.new_member'))
      setDialogOpen(true);
      mutate();
    }
  });

  return (
    <form onSubmit={onSubmitCoachCredit}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: 2 }}>
        
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('credit.new_credit')}
          </Typography>
        </Grid>
        
        {newMemberCredit &&
          <>
            <Grid item container
              alignItems='center'
              justifyContent='center'
              sx={{ width: 350}}>
              <Controller
                name={'credit'}
                control={control}
                defaultValue={0}
                rules={{ required: true }}
                render={({ field }) =>
                  <CreditTextfield
                    variant='standard'
                    label={nameToCamelcase(t('credit.fields.welcome_credit'))}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*', 'aria-label': 'phone_num' }}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    }}
                    error={!!errors['credit']}
                    helperText={errors['credit'] && errors['credit'].message}
                    {...field} />
                  }
                />
            </Grid>
            <Grid item container
              alignItems='center'
              justifyContent='center'
              sx={{ width: 350}}>
              <Controller
                name={'creditCustomer'}
                control={control}
                defaultValue={0}
                rules={{ required: true }}
                render={({ field }) =>
                  <CreditTextfield
                    variant='standard'
                    label={nameToCamelcase(t('credit.fields.customer_credit'))}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*', 'aria-label': 'phone_num' }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    error={!!errors['creditCustomer']}
                    helperText={errors['creditCustomer'] && errors['creditCustomer'].message}
                    {...field} />
                  }
                />
            </Grid>
            <Grid item container
              alignItems='center'
              justifyContent='center'
              sx={{ width: 350}}>
              <Controller
                name={'creditCoach'}
                control={control}
                defaultValue={0}
                rules={{ required: true }}
                render={({ field }) =>
                  <CreditTextfield
                    disabled
                    variant='standard'
                    label={nameToCamelcase(t('credit.fields.coach_credit'))}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*', 'aria-label': 'phone_num' }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    error={!!errors['creditCoach']}
                    helperText={errors['creditCoach'] && errors['creditCoach'].message}
                    {...field} />
                  }
                />
            </Grid>
          </>
        }
        <Grid item>
          <ButtonPrimary
            status='primary'
            type='submit'
            label={nameToCamelcase(t('update'))}
            aria-label='update-btn'
            sx={{ width: 150 }}
          />
        </Grid>
      </Grid>
    </form>
  )
}