import { Container, Grid, GridProps, styled, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import NavigateBack from 'src/components/navigate/NavigateBack';
import useCoachSingleCustomer from 'src/hooks/swr/coach/use-coach-single-customer';
import { nameToCamelcase } from 'src/utils/clean-name';
import stripeHKPhone from 'src/utils/stripe-hk-phone';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Controller, useForm } from 'react-hook-form';
import { AdminManualChangeClientPassword } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import postAdminManualModifyClientPassword from 'src/api/coach/admin/post-admin-manual-modify-client-password';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const fieldWidth = 250;

const InputTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

export default function CustomerPasswordManualChange() {
  const { t } = useTranslation('coach');
  const { customerPhone } = useParams();
  const navigate = useNavigate();
  // only return unique customer with unique phone number
  const { allCustomer } = useCoachSingleCustomer(customerPhone !== undefined ? customerPhone : '');

  const customerInfo = useMemo(() => {
    if (allCustomer) {
      return allCustomer[0]
    }
  }, [allCustomer]);

  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    text: '',
  });

  const handleDialogClose = () => {
    setDialog({ ...dialog, open: false });
    navigate(`/coach/customer/${customerPhone}`);
  };

  const { register, handleSubmit, setValue, setError, control, formState: { errors } } = useForm<AdminManualChangeClientPassword>();

  register('password');
  register('confirmPassword');
  register('customerId');

  useEffect(() => {
    if (customerInfo) {
      setValue('customerId', customerInfo.id);
    }
  }, [customerInfo, setValue]);

  const onSubmit = handleSubmit(async (data: AdminManualChangeClientPassword) => {
    if (!data.password || !data.confirmPassword) {
      setError('confirmPassword', {
        type: 'empty',
        message: t('manual_password.error.password_empty')}
      );
      return
    }

    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'password_not_same',
        message: t('manual_password.error.password_not_same')}
      );
      return
    }
    
    const res = await postAdminManualModifyClientPassword(data);
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      setDialog({
        title: t('manual_password.dialog.success_title'),
        text: t('manual_password.dialog.success_text', {
          name: customerInfo.preferred_name,
        }),
        open: true,
      });
    } else {
      setDialog({
        title: t('manual_password.dialog.fail_title'),
        text: t('manual_password.dialog.fail_text'),
        open: true,
      });
    }
  });

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <NavigateBack />
        </Grid>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('manual_password.title')}
          </Typography>
        </Grid>

        {customerInfo &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.preferred_name + ' ' + customerInfo.last_name)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.gender')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.gender)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.available_credit')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {customerInfo.available_credit}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('manual_password.fields.username')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {stripeHKPhone(customerInfo.phone_number)}
                </Typography>
              </Grid>
            </TextGrid>
          </>
        }
        
        <form onSubmit={onSubmit}>
          <Grid
            item container
            xs={12}
            spacing={1.5}
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ marginTop: 2 }} >

            <Grid item>
              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({ field }) =>
                  <InputTextfield
                    variant='outlined'
                    type='password'
                    label={t('manual_password.fields.password_new')}
                    error={!!errors['password']}
                    helperText={!!errors['password'] && errors['password']?.message}
                    inputProps={{ 'aria-label': 'password' }}
                    {...field} />
                  }
                />
            </Grid>
            <Grid item>
              <Controller
                name='confirmPassword'
                control={control}
                defaultValue=''
                render={({ field }) =>
                  <InputTextfield
                    variant='outlined'
                    type='password'
                    label={t('manual_password.fields.password_confirm')}
                    error={!!errors['confirmPassword']}
                    helperText={!!errors['confirmPassword'] && errors['confirmPassword'].message}
                    inputProps={{ 'aria-label': 'confirmPassword' }}
                    {...field} />
                  }
                />
            </Grid>

            <Grid item>
              <ButtonPrimary
                status='primary'
                type='submit'
                label={t('manual_password.btn.submit')}
                aria-label='submit-btn'
              />
          </Grid>
          </Grid>
        </form>
      </Grid>

      <Dialog
        open={dialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
              color='primary'
              fontSize={18}
              sx={{ marginBottom: 1 }}>
            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}