import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'src/styles/muiTheme';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import store from 'src/store';
import WebAppBar from 'src/components/WebAppBar';
import 'src/App.css';

import useOnInitChangeLanguage from 'src/hooks/use-on-init-change-language';

import CustomerLogin from 'src/pages/customer/CustomerLogin';
import Mainpage from 'src/pages/customer/Mainpage';
import CustomerRegister from 'src/pages/customer/Register';
import CustomerPrivateRoute from 'src/components/customer/CustomerPrivateRoute';
import Dashboard from 'src/pages/customer/Dashboard';
import CoachOverview from 'src/pages/customer/coach-overview/CoachOverview';

import CoachLogin from 'src/pages/coach/CoachLogin';
import CoachPrivateRoute from 'src/components/coach/CoachPrivateRoute';
import CoachDashboard from 'src/pages/coach/CoachDashboard';
import CoachSchedule from 'src/pages/coach/CoachSchedule';
// import CoachPTTimeManagement from 'src/pages/coach/PTTimeManagement/PTTimeManagement';
import CoachProfile from 'src/pages/coach/profile/CoachProfile';
import CoachMembership from 'src/pages/coach/admin/membership/Membership';
import CoachReferral from 'src/pages/coach/referral/Referral';

import CoachDirectorClassManagement from 'src/pages/coach/director/CoachDirectorClassManagement';
import CoachDirectorCreditManagement from 'src/pages/coach/director/coach-credit/CreditManagement';
import AdminDirectorCoachManagement from 'src/pages/coach/admin-director/coach-management/AdminDirectorCoachManagement';
import CoachDirectorPrivateRoute from 'src/components/coach/CoachDirectorPrivateRoute';
import AddCoach from 'src/pages/coach/director/coach-management/AddCoach';
import EditCoach from 'src/pages/coach/director/coach-management/EditCoach';
import BookDashboard from 'src/pages/customer/book/BookDashboard';
import PTBookScreen from 'src/pages/customer/book/PTBookScreen';
import CoachPTConfirmScreen from 'src/pages/coach/pt-class/CoachPTConfirmScreen';
import CoachPTClassDetails from 'src/pages/coach/pt-class/CoachPTClassDetails';
import CoachPersonalPage from 'src/pages/customer/coach-overview/CoachPersonalPage';
import CoachPersonalPageDemo from 'src/pages/customer/coach-overview/CoachPersonalPageDemo';
import NotFoundPage from 'src/pages/customer/NotFoundPage';
import CustomerPTClassDetails from 'src/pages/customer/pt-class/CustomerPTClassDetails';
import CoachGroupClassManagement from 'src/pages/coach/group-class/CoachGroupClassManagement';
import AdminGroupClassCreate from 'src/pages/coach/admin/group-class/AdminGroupClassCreate';
import CustomerRecord from 'src/pages/customer/record/CustomerRecord';
import Membership from 'src/pages/customer/membership/Membership';
import GroupClassBookScreen from 'src/pages/customer/book/GroupClassBookScreen';
import QFPayPayment from 'src/pages/customer/payment/QFPayPayment';
import ViewCoachStatictics from './pages/coach/admin-director/coach-management/ViewCoachStatictics';
import Referral from 'src/pages/customer/referral/Referral';
import CoachAdminPrivateRoute from 'src/components/coach/CoachAdminPrivateRoute';
import PurchaseRecord from 'src/pages/coach/admin/purchase-record/PurchaseRecord';
import CoachGroupClassDetails from 'src/pages/coach/group-class/CoachGroupClassDetails';
import CoachSearchCustomer from 'src/pages/coach/search-customer/SearchCustomer';
import CoachCustomerSingle from 'src/pages/coach/customer/CustomerSingle';
import CustomerProfile from 'src/pages/customer/profile/Profile';
import AdminGroupClassManagement from 'src/pages/coach/admin/group-class/AdminGroupClassManagement';
import AdminGroupClassDetails from 'src/pages/coach/admin/group-class/AdminGroupClassDetails';
import CourseCharge from 'src/pages/customer/course-charge/CourseCharge';
import PTTimeManagementByDate from 'src/pages/coach/PTTimeManagement/PTTimeManagementByDate';
import AdminCreditManualChange from 'src/pages/coach/admin/credit/CreditManualChange';
import CoachTimetable from 'src/pages/coach/timetable/Timetable';
import DirectorBookStudio from 'src/pages/coach/director/book-studio/DirectorBookStudio';
import DirectorBookStudioManagement from 'src/pages/coach/director/book-studio/DirectorBookStudioManagement';
import DirectorInternalClassDetails from 'src/pages/coach/director/book-studio/DirectorInternalClassDetails';
import AdminGroupClassEditSingle from 'src/pages/coach/admin/group-class/AdminGroupClassEditSingle';
import AdminAllClassManagement from 'src/pages/coach/admin/all-class/AdminAllClassManagement';
import AdminPtClassDetails from 'src/pages/coach/admin/pt-class/AdminPtClassDetails';
import ResetPassword from 'src/pages/customer/ResetPassword';
import AdminInternalClassDetails from 'src/pages/coach/admin-director/director-book/AdminInternalClassDetails';
import ChangePassword from 'src/pages/customer/change-password/ChangePassword';
import DirectorPtClassMakeup from 'src/pages/coach/director/pt-class/DirectorPtClassMakeup';
import AdminCustomerPasswordManualChange from 'src/pages/coach/customer/CustomerPasswordManualChange';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.tz.setDefault('Asia/Hong_Kong')

function App() {
  useOnInitChangeLanguage();
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <WebAppBar/>
            <Routes>
              <Route path='/'>
                {/* experiment routes */}
                <Route path='test' element={<QFPayPayment />} />
                
                {/* public routes */}
                <Route path='' element={<Mainpage />} />
                <Route path='login' element={<CustomerLogin />} />
                <Route path='register' element={<CustomerRegister />} />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='coach-overview'>
                  <Route path='' element={<CoachOverview />} />
                  <Route path='coach/:coachId' element={<CoachPersonalPage />} />
                  <Route path='coach/test' element={<CoachPersonalPageDemo />} />
                </Route>
                
                {/* customer routes */}
                <Route path='dashboard' element={
                  <CustomerPrivateRoute>
                    <Dashboard />
                  </CustomerPrivateRoute>}
                />

                <Route path='referral' element={
                  <CustomerPrivateRoute>
                    <Referral />
                  </CustomerPrivateRoute>}
                />

                <Route path='book'>
                  <Route path='' element={
                    <CustomerPrivateRoute>
                      <BookDashboard />
                    </CustomerPrivateRoute>}
                  />
                  <Route path='group-class'>
                    {/* redirect back to /book */}
                    <Route path='' element={ <Navigate to='/book' />} />

                    <Route path=':groupClassId' element={
                      <CustomerPrivateRoute>
                        <GroupClassBookScreen />
                      </CustomerPrivateRoute>}
                    />
                  </Route>
                  <Route path='pt'>
                    {/* redirect back to /book */}
                    <Route path='' element={ <Navigate to='/book' />} />

                    <Route path=':coachId' element={
                      <CustomerPrivateRoute>
                        <PTBookScreen />
                      </CustomerPrivateRoute>}
                    />
                  </Route>
                </Route>
                

                <Route path='group-class'>
                  {/* redirect back to /dashboard */}
                  <Route path='' element={ <Navigate to='/dashboard' />} />

                  <Route path=':groupClassId' element={
                    <CustomerPrivateRoute>
                      <GroupClassBookScreen />
                    </CustomerPrivateRoute>}
                  />
                </Route>

                <Route path='pt-class'>
                  {/* redirect back to /dashboard */}
                  <Route path='' element={ <Navigate to='/dashboard' />} />

                  <Route path=':ptClassId' element={
                    <CustomerPrivateRoute>
                      <CustomerPTClassDetails/>
                    </CustomerPrivateRoute>}/>
                </Route>

                <Route path='charge-list' element={
                    <CustomerPrivateRoute>
                      <CourseCharge />
                    </CustomerPrivateRoute>}/>

                <Route path='membership'>
                  <Route path='' element={
                    <CustomerPrivateRoute>
                      <Membership />
                    </CustomerPrivateRoute>}
                  />

                  <Route path=':qfPayResult' element={
                    <CustomerPrivateRoute>
                      <Membership/>
                    </CustomerPrivateRoute>}/>
                </Route>

                <Route path='record' element={
                  <CustomerPrivateRoute>
                    <CustomerRecord />
                  </CustomerPrivateRoute>}
                />

                <Route path='profile' element={
                  <CustomerPrivateRoute>
                    <CustomerProfile />
                  </CustomerPrivateRoute>}
                />

                <Route path='change-password' element={
                  <CustomerPrivateRoute>
                    <ChangePassword />
                  </CustomerPrivateRoute>}
                />

                {/* coach routes */}
                <Route path='coach'>
                  <Route path='login' element={<CoachLogin/>} />
                  <Route path='dashboard' element={
                    <CoachPrivateRoute>
                      <CoachDashboard/>
                    </CoachPrivateRoute>}
                  />

                  <Route path='schedule' element={
                    <CoachPrivateRoute>
                      <CoachSchedule/>
                    </CoachPrivateRoute>}
                  />

                  <Route path='charge-list' element={
                      <CoachPrivateRoute>
                        <CourseCharge />
                      </CoachPrivateRoute>}/>

                  <Route path='referral' element={
                    <CoachPrivateRoute>
                      <CoachReferral/>
                    </CoachPrivateRoute>}
                  />

                  <Route path='pt-class'>
                    <Route path='' element={
                      <CoachPrivateRoute>
                        {/* <CoachPTTimeManagement/> */}
                        <PTTimeManagementByDate/>
                      </CoachPrivateRoute>}/>

                    <Route path=':ptClassId' element={
                      <CoachPrivateRoute>
                        <CoachPTClassDetails/>
                      </CoachPrivateRoute>}/>
                    
                    <Route path='confirm'>
                      <Route path='' element={ <Navigate to='/coach/pt-class' />} />

                      <Route path=':ptClassId' element={
                        <CoachPrivateRoute>
                          <CoachPTConfirmScreen />
                        </CoachPrivateRoute>}
                      />
                    </Route>
                  </Route>

                  <Route path='group-class'>
                    <Route path='' element={
                      <CoachPrivateRoute>
                        <CoachGroupClassManagement/>
                      </CoachPrivateRoute>}/>
                    
                    <Route path=':groupClassId' element={
                      <CoachPrivateRoute>
                        <CoachGroupClassDetails/>
                      </CoachPrivateRoute>}/>
                  </Route>

                  <Route path='profile' element={
                    <CoachPrivateRoute>
                      <CoachProfile/>
                    </CoachPrivateRoute>}
                  />

                  <Route path='search-customer' element={
                    <CoachPrivateRoute>
                      <CoachSearchCustomer/>
                    </CoachPrivateRoute>}
                  />

                  <Route path='timetable' element={
                    <CoachPrivateRoute>
                      <CoachTimetable/>
                    </CoachPrivateRoute>}
                  />
                  
                  <Route path='customer'>
                    <Route path=':customerPhone' element={
                      <CoachPrivateRoute>
                        <CoachCustomerSingle/>
                      </CoachPrivateRoute>}
                    />
                  </Route>
                  
                  <Route path='admin'>
                    <Route path='membership' element={
                        <CoachAdminPrivateRoute>
                          <CoachMembership/>
                        </CoachAdminPrivateRoute>}
                      />
                    <Route path='purchase-record' element={
                        <CoachAdminPrivateRoute>
                          <PurchaseRecord/>
                        </CoachAdminPrivateRoute>}
                      />

                    <Route path='all-class'>
                      <Route path='' element={
                        <CoachAdminPrivateRoute>
                          <AdminAllClassManagement/>
                        </CoachAdminPrivateRoute>}/>
                    </Route>

                    <Route path='pt-class'>
                      <Route path=':ptClassId' element={
                        <CoachAdminPrivateRoute>
                          <AdminPtClassDetails/>
                        </CoachAdminPrivateRoute>}/>
                    </Route>

                    <Route path='group-class'>
                      <Route path='' element={
                        <CoachPrivateRoute>
                          <AdminGroupClassManagement/>
                        </CoachPrivateRoute>}/>
                      <Route path='create' element={
                        <CoachAdminPrivateRoute>
                          <AdminGroupClassCreate/>
                        </CoachAdminPrivateRoute>}/>
                      <Route path=':groupClassId' element={
                        <CoachAdminPrivateRoute>
                          <AdminGroupClassDetails/>
                        </CoachAdminPrivateRoute>}/>
                      <Route path='edit'>
                        <Route path=':groupClassId' element={
                          <CoachAdminPrivateRoute>
                            <AdminGroupClassEditSingle/>
                          </CoachAdminPrivateRoute>}
                        />
                      </Route>
                    </Route>

                    <Route path='credit'>
                      <Route path=':customerPhone' element={
                        <CoachAdminPrivateRoute>
                          <AdminCreditManualChange/>
                        </CoachAdminPrivateRoute>}
                      />
                    </Route>

                    <Route path='customer'>
                      <Route path=':customerPhone' element={
                        <CoachAdminPrivateRoute>
                          <AdminCustomerPasswordManualChange/>
                        </CoachAdminPrivateRoute>}
                      />
                    </Route>
                    

                    <Route path='coach-management'>  
                      <Route path='' element={
                        <CoachAdminPrivateRoute>
                          <AdminDirectorCoachManagement isAdmin/>
                        </CoachAdminPrivateRoute>}
                      />
                      <Route path='coach/'>
                        <Route path=':coachId' element={
                          <CoachAdminPrivateRoute>
                            <ViewCoachStatictics isAdmin/>
                          </CoachAdminPrivateRoute>}
                        />
                      </Route>
                    </Route>

                    <Route path='director-book'>
                      <Route path=':internalClassId' element={
                        <CoachAdminPrivateRoute>
                          <AdminInternalClassDetails/>
                        </CoachAdminPrivateRoute>}
                      />
                    </Route>

                  </Route>


                  <Route path='director'>
                    <Route path='class-management' element={
                      <CoachDirectorPrivateRoute>
                        <CoachDirectorClassManagement/>
                      </CoachDirectorPrivateRoute>}
                    />

                    <Route path='credit-management' element={
                      <CoachDirectorPrivateRoute>
                        <CoachDirectorCreditManagement/>
                      </CoachDirectorPrivateRoute>}
                    />
                    <Route path='coach-management'>  
                      <Route path='' element={
                        <CoachDirectorPrivateRoute>
                          <AdminDirectorCoachManagement/>
                        </CoachDirectorPrivateRoute>}
                      />
                      <Route path='coach/'>
                        <Route path=':coachId' element={
                          <CoachDirectorPrivateRoute>
                            <ViewCoachStatictics/>
                          </CoachDirectorPrivateRoute>}
                        />
                        <Route path=':coachId/edit' element={
                          <CoachDirectorPrivateRoute>
                            <EditCoach/>
                          </CoachDirectorPrivateRoute>}
                        />
                      </Route>
                    </Route>
                    <Route path='add-coach' element={
                      <CoachDirectorPrivateRoute>
                        <AddCoach/>
                      </CoachDirectorPrivateRoute>}
                    />

                    <Route path='book-studio'>
                      <Route path='' element={
                        <CoachDirectorPrivateRoute>
                          <DirectorBookStudioManagement/>
                        </CoachDirectorPrivateRoute>}
                      />
                      <Route path=':internalClassId' element={
                        <CoachDirectorPrivateRoute>
                          <DirectorInternalClassDetails/>
                        </CoachDirectorPrivateRoute>}
                      />
                      <Route path='book'>
                        <Route path='' element={
                          <CoachDirectorPrivateRoute>
                            <DirectorBookStudio/>
                          </CoachDirectorPrivateRoute>}
                        />
                        <Route path=':type' element={
                          <CoachDirectorPrivateRoute>
                            <DirectorBookStudio/>
                          </CoachDirectorPrivateRoute>}
                        />
                      </Route>
                    </Route>
                    
                    <Route path='pt-class'>
                      <Route path='makeup' element={
                        <CoachAdminPrivateRoute>
                          <DirectorPtClassMakeup/>
                        </CoachAdminPrivateRoute>}/>
                    </Route>
                    
                  </Route>
                </Route>
                {/* 404 page */}
                <Route path='*' element={<NotFoundPage />} />
              </Route>
            </Routes>
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
