import React, { Suspense } from 'react';
// React 18
// import ReactDOM from 'react-dom/client';
// React 17
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';


/* bundle with i18n
 * i18n translation is loaded asynchronously
 * <Suspense> is added to prevent error when i18n is not loaded yet
 */
import './i18n';

global.API_v1 = process.env.REACT_APP_API_SERVER_v1 as string;
global.AWS_S3_BUCKET = process.env.REACT_APP_WS_S3_BUCKET as string;
global.CURRENT_SITE = process.env.REACT_APP_CURRENT_SITE as string;
global.QF_PAY_ORIGIN = process.env.REACT_APP_QF_PAY_ORIGIN as string;
global.QF_PAY_APP_CODE = process.env.REACT_APP_QF_PAY_APP_CODE as string;
global.QF_PAY_API_KEY = process.env.REACT_APP_QF_PAY_API_KEY as string;
global.ATTEND_CLASS_HOUR = 2;

// React 18
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <Suspense fallback={FallbackLoading()}>
//       <App />
//     </Suspense>
//   </React.StrictMode>
// );

// React 17
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={FallbackLoading()}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

function FallbackLoading() {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 8px*2)',
        width: 'calc(100vw - 8px*2))',
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <CircularProgress sx={{
        color: 'primary.main',
        alignSelf: 'center',
        height: '15vw !important',
        width: '15vw !important',
      }}/>
    </Box>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
