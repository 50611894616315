export default function putDirectorCancelInternalClass(internalClassId: number) {
  return fetch(`${global.API_v1}/director/internal-class/status`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({internalClassId}),
  });
}
