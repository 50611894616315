import { Container, Grid, Typography, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GroupClassBox from 'src/components/group-class/GroupClassBox';
import useAllCoachAllGroupClass from 'src/hooks/swr/coach/use-all-coach-all-group-class';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateTo from 'src/components/navigate/NavigateTo';

export default function AdminGroupClassManagement() {
  const { t } = useTranslation('admin');
  // To Refactor
  const { allGroupClasses } = useAllCoachAllGroupClass();

  const [selectedWeek, setSelectedWeek] = useState(0);
  const isSun = useMemo(() => dayjs().day() === 0, []);
  const weekMon = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(1).hour(0).minute(0).second(1)
  , [selectedWeek, isSun]);
  
  const weekSun = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(7).hour(23).minute(59).second(59)
  , [selectedWeek, isSun]);

  const allGroupClassesInWeek = useMemo(() => {
    if (allGroupClasses) {
      return allGroupClasses.filter(groupClass => dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute).isBetween(weekMon, weekSun))
    }
  }, [allGroupClasses, weekMon, weekSun])
  
  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
        <Grid item>
          <NavigateTo
            to={'/coach/admin/group-class/create'}
            label={t('group_class.btn.create')}/>
        </Grid>
      
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('group_class.all_group_class')}
          </Typography>
        </Grid>

        <Grid
          item container
          justifyContent='space-between'
          alignItems='center'>
          <Grid item>
            <IconButton aria-label='previous-week' color='primary'
              onClick={() => {
                setSelectedWeek(selectedWeek - 1)
              }}>
              <ArrowBackIosIcon/>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label='next-week' color='primary'
              onClick={() => {
                setSelectedWeek(selectedWeek + 1)
              }}>
              <ArrowForwardIosIcon/>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container justifyContent={'center'}>
          {allGroupClassesInWeek && allGroupClassesInWeek.length === 0 &&
            <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}>
              {t('group_class.empty', {status: t('status.confirmed')})}
            </Typography>
          }
          {allGroupClassesInWeek && allGroupClassesInWeek.map((groupClass, index) => (
            <Grid item xs={12} key={`group-class-${index}`}>
              <GroupClassBox
                isCoach
                isAdmin
                groupClass={groupClass} />
            </Grid>
          ))}
        </Grid>
        
      </Grid>
    </Container>
  )
}