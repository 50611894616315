export default function putDirectorChangeGroupClassStatus(groupClassId: number, customerId: number, customerStatus: string) {
  return fetch(`${global.API_v1}/director/group-class/status`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({groupClassId, customerId, customerStatus}),
  });
}
