import React, { useMemo, useState } from 'react';
import { Container, TextField, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import { nameToCamelcase } from 'src/utils/clean-name';
import CoachCreditForm from 'src/components/coach/director/coach-credit/CoachCreditForm';
import NewMemberCreditForm from 'src/components/coach/director/coach-credit/NewMemberCreditForm';
import useCoachAllCoachType from 'src/hooks/swr/use-coach-all-coach-type';
import { CoachType } from 'src/api/form-types';

function determineType(type: CoachType) {
  switch (type) {
    case 'director':
      return 4
    case 'senior':
      return 3
    case 'normal':
      return 2
    case 'junior':
      return 1
    default:
      return 0
  }
};

export const CreditTextfield = styled(TextField)(({ theme }) => ({
  width: 150,
}));


export default function CreditManagement() {
  const { t } = useTranslation('coach');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(t('credit.fields.credit'));
  
  const { allCoachType: allCoachTypeRaw, mutate } = useCoachAllCoachType();

  const allCoachType = useMemo(() => {
    if (allCoachTypeRaw) {
      return allCoachTypeRaw.sort((a, b) => {
        return determineType(b.type) - determineType(a.type)
      });
    }
  }, [allCoachTypeRaw])
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  return (
    <Container sx={{ paddingBottom: 2 }} maxWidth={'md'}>

      {allCoachType &&
        <React.Fragment>
          <CoachCreditForm
            isCredit
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'pt_credit_charge'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          <CoachCreditForm
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'pt_credit_salary'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          <CoachCreditForm
            isCredit
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'private_class_charge'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          <CoachCreditForm
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'private_class_salary'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          <CoachCreditForm
            isCredit
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'group_class_charge'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          {/* hided temporarily for number-tier group class salary */}
          {/* <CoachCreditForm
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'group_class_salary'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/> */}

          <CoachCreditForm
            isCredit
            isStarGroupClass
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'star_group_class_charge'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          <CoachCreditForm
            isCredit
            isStarGroupClass
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'sepcial_group_class_charge'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/>

          {/* hided temporarily for unknown star class salary */}
          {/* <CoachCreditForm
            isStarGroupClass
            allCoachType={allCoachType}
            mutate={mutate}
            updateType={'star_group_class_salary'}
            setDialogOpen={setDialogOpen}
            setDialogMessage={setDialogMessage}/> */}
        </React.Fragment>
      }


      <NewMemberCreditForm
        setDialogOpen={setDialogOpen}
        setDialogMessage={setDialogMessage}/>
      
      <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {nameToCamelcase(t('credit.result.success'))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('credit.result.text', {type: dialogMessage})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>

    </Container>
  )
}