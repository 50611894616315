import { Container, Grid, styled, Typography, GridProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import NavigateBack from 'src/components/navigate/NavigateBack';
import useDirectorAllInternalClass from 'src/hooks/swr/coach/director/use-director-all-internal-class';
import putDirectorCancelInternalClass from 'src/api/coach/director/put-director-cancel-internal-class';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonOutline from 'src/components/ButtonOutline';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

export default function DirectorInternalClassDetails() {
  const { t } = useTranslation('coach');
  let { internalClassId } = useParams();
  const { allInternalClasses, mutate: mutateInternalClass } = useDirectorAllInternalClass();

  const [dialog, setDialog] = useState({
    open: false,
    message: '',
  });

  const internalClass = useMemo(() => {
    if (allInternalClasses) {
      return (allInternalClasses.filter(internalClass => internalClass.id === parseInt(internalClassId as string))[0])
    }
  }, [allInternalClasses, internalClassId])

  const classDayjsTime = useMemo(() => {
    if (internalClass) {
      return dayjs(internalClass.date).hour(internalClass.hour).minute(internalClass.minute);
    }
  }, [internalClass]);

  const cancelInternalClass = async () => {
    const res = await putDirectorCancelInternalClass(parseInt(internalClassId as string));
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setDialog({
        open: true,
        message: t('book_studio.dialog.cancel'),
      })
      mutateInternalClass();
    }
  };

  const closeDialog = () => {
    setDialog({
      open: false,
      message: '',
    });
  };


  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
        <Grid item>
          <NavigateBack />
        </Grid>
      
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('book_studio.title')}
          </Typography>
        </Grid>

        {internalClass && classDayjsTime &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book_studio.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(internalClass.name)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {nameToCamelcase(t('coach'))}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(internalClass.preferred_name + ' ' + internalClass.last_name)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book_studio.fields.date')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {classDayjsTime.format('DD MMM[,] YYYY [-] ddd')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book_studio.fields.time')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {`${classDayjsTime.format('HH:mm')} - ${classDayjsTime.add(parseFloat(internalClass.duration) * 2 * 30, 'minute').format('HH:mm')} / ${parseFloat(internalClass.duration) * 60} ` + t('minute')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book_studio.fields.self_charge')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {/* Todo - format currancy */}
                  {`$${internalClass.self_booking_fee}`}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book_studio.fields.self_income')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {/* Todo - format currancy */}
                  {`$${internalClass.self_income}`}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book_studio.status.state')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {t(`book_studio.status.${internalClass.status}`)}
                </Typography>
              </Grid>
            </TextGrid>

            {internalClass.status === 'confirmed' &&
              <Grid item>
                <ButtonOutline
                  status='primary'
                  label={t('book_studio.btn.cancel')}
                  onClick={cancelInternalClass} />
              </Grid>
            }
          </>
        }
      </Grid>
      <Dialog
        open={dialog.open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Typography variant='body1' color='error.main'>
            {dialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}