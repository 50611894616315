import { Container, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCoachPtClassWithStatus from 'src/hooks/swr/coach/use-coach-pt-class-with-status';
import PtClassBox from 'src/components/coach/dashboard/PtClassBox';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import useCoachAllGroupClass from 'src/hooks/swr/coach/use-coach-all-group-class';
import GroupClassBox from 'src/components/group-class/GroupClassBox';
import SubtitleGrid from 'src/components/dashboard/SubtitleGrid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function CoachDashboard() {
  const { t } = useTranslation('coach');
  const { ptClasses: ptClassesPendingRaw } = useCoachPtClassWithStatus('pending');
  const { ptClasses: ptClassesConfirmedCancelledRaw } = useCoachPtClassWithStatus('confirmed,cancelled,attended,absent');  
  const { allGroupClasses } = useCoachAllGroupClass();

  const [selectedWeek, setSelectedWeek] = useState(0);
  const isSun = useMemo(() => dayjs().day() === 0, []);
  const weekMon = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(1).hour(0).minute(0).second(1)
  , [selectedWeek, isSun]);
  
  const weekSun = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(7).hour(23).minute(59).second(59)
  , [selectedWeek, isSun]);


  // deprecated PT class list
  // const currentTime = useMemo(() => dayjs(), []);
  // const ptClassesPending = useMemo(() => {
  //   if (ptClassesPendingRaw) {
  //     return ptClassesPendingRaw.filter(
  //       ptClass => !currentTime.isAfter(dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute))
  //     )
  //   }
  // }, [currentTime, ptClassesPendingRaw]);
  // const ptClassesFuture = useMemo(() => {
  //   if (ptClassesFutureRaw) {
  //     return ptClassesFutureRaw.filter(
  //       ptClass => !currentTime.isAfter(dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute))
  //     )
  //   }
  // }, [currentTime, ptClassesFutureRaw]);
  
  const allGroupClassesInWeek = useMemo(() => {
    if (allGroupClasses) {
      return allGroupClasses
        .filter(groupClass => !groupClass.isSingleClassCancelled)
        .filter(groupClass => dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute).isBetween(weekMon, weekSun))
    }
  }, [allGroupClasses, weekMon, weekSun])

  const allPtClassesPendingInWeek = useMemo(() => {
    if (ptClassesPendingRaw) {
      return ptClassesPendingRaw.filter(ptClass => dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute).isBetween(weekMon, weekSun))
    }
  }, [ptClassesPendingRaw, weekMon, weekSun])

  const allPtClassesConfirmedCancelledInWeek = useMemo(() => {
    if (ptClassesConfirmedCancelledRaw) {
      return ptClassesConfirmedCancelledRaw
        .filter(ptClass => !ptClass.privateClassHash)
        .filter(ptClass => dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute).isBetween(weekMon, weekSun))
    }
  }, [ptClassesConfirmedCancelledRaw, weekMon, weekSun])

  const allPrivateClassesConfirmedCancelledInWeek = useMemo(() => {
    if (ptClassesConfirmedCancelledRaw) {
      return ptClassesConfirmedCancelledRaw
        .filter(ptClass => ptClass.privateClassHash)
        .filter(ptClass => dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute).isBetween(weekMon, weekSun))
    }
  }, [ptClassesConfirmedCancelledRaw, weekMon, weekSun])

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Typography variant='h6' color='primary.main'>
        {t('dashboard.title.to_confirm')}
      </Typography>

      <Grid
        item container
        justifyContent='space-between'
        alignItems='center'>
        <Grid item>
          <IconButton aria-label='previous-week' color='primary'
            onClick={() => {
              setSelectedWeek(selectedWeek - 1)
            }}>
            <ArrowBackIosIcon/>
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant='body1' color='primary.main'>
            {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label='next-week' color='primary'
            onClick={() => {
              setSelectedWeek(selectedWeek + 1)
            }}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </Grid>
      </Grid>

      <Grid item container justifyContent={'center'}>
        {allPtClassesPendingInWeek && allPtClassesPendingInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('status.pending')})}
          </Typography>
        }
        {allPtClassesPendingInWeek && allPtClassesPendingInWeek.map((ptClass) => (
          <Grid item key={`pt-class-${ptClass.ptClassId}`} xs={12} sm={8}>
            <PtClassBox
              isCoach
              ptClass={ptClass}/>
          </Grid>
        ))}
      </Grid>


      <Typography variant='h6' color='primary.main'>
        {t('dashboard.title.my_coming')}
      </Typography>
      
      <Grid
        item container
        justifyContent='space-between'
        alignItems='center'>
        <Grid item>
          <IconButton aria-label='previous-week' color='primary'
            onClick={() => {
              setSelectedWeek(selectedWeek - 1)
            }}>
            <ArrowBackIosIcon/>
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant='body1' color='primary.main'>
            {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label='next-week' color='primary'
            onClick={() => {
              setSelectedWeek(selectedWeek + 1)
            }}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </Grid>
      </Grid>

      <SubtitleGrid>
        <Typography>
          {t('dashboard.subtitle.pt_session')}
        </Typography>
      </SubtitleGrid>
      <Grid item container justifyContent={'center'}>
        {allPtClassesConfirmedCancelledInWeek && allPtClassesConfirmedCancelledInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('status.confirmed')})}
          </Typography>
        }
        {allPtClassesConfirmedCancelledInWeek && allPtClassesConfirmedCancelledInWeek.map((ptClass) => (
          <Grid item key={`pt-class-${ptClass.ptClassId}`} xs={12} sm={8}>
            <PtClassBox
              isCoach
              ptClass={ptClass}
              status={ptClass.status} />
          </Grid>
        ))}
      </Grid>

      <SubtitleGrid>
        <Typography>
          {t('dashboard.subtitle.private_class')}
        </Typography>
      </SubtitleGrid>
      <Grid item container justifyContent={'center'}>
        {allPrivateClassesConfirmedCancelledInWeek && allPrivateClassesConfirmedCancelledInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('status.confirmed')})}
          </Typography>
        }
        {allPrivateClassesConfirmedCancelledInWeek && allPrivateClassesConfirmedCancelledInWeek.map((ptClass) => (
          <Grid item key={`pt-class-${ptClass.ptClassId}`} xs={12} sm={8}>
            <PtClassBox
              isCoach
              ptClass={ptClass}
              status={ptClass.status} />
          </Grid>
        ))}
      </Grid>

      <SubtitleGrid>
        <Typography>
          {t('dashboard.subtitle.group_class')}
        </Typography>
      </SubtitleGrid>
      <Grid item container justifyContent={'center'}>
        {allGroupClassesInWeek && allGroupClassesInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('dashboard.subtitle.group_class')})}
          </Typography>
        }
        {allGroupClassesInWeek && allGroupClassesInWeek.map((groupClass) => (
          <Grid item key={`group-class-${groupClass.groupClassId}`} xs={12} sm={8}>
            <GroupClassBox
              isCoach
              groupClass={groupClass}/>
          </Grid>
        ))}
      </Grid>

    </Container>
  )
}