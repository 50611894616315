export default function putCoachMarkGroupClassAttend(groupClassId: number, customerId: number) {
  console.log({groupClassId})
  console.log({customerId})
  return fetch(`${global.API_v1}/coach/group-class/attend`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({groupClassId, customerId}),
  });
}
