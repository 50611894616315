import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledQRCode from 'src/components/qr-code/StyledQRCode';
import useLatestNewMemberCredit from 'src/hooks/swr/use-latest-new-member-credit';
import useCustomerInfo from 'src/hooks/swr/customer/use-customer-info';
import ButtonOutline from 'src/components/ButtonOutline';

export default function Referral() {
  const { t } = useTranslation('customer');
  const { customerInfo } = useCustomerInfo();
  const { newMemberCredit } = useLatestNewMemberCredit();

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('referral.title')}
          </Typography>
        </Grid>

        {customerInfo && newMemberCredit &&
          <>
            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.text_1')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography textAlign={'center'} color='primary.main' fontWeight='bold'>
                {t('referral.method_1.text')}
              </Typography>
            </Grid>
            <Grid item>
              <ButtonOutline
                status='primary'
                label={t('referral.btn.get_link')}
                onClick={() => {
                  navigator.clipboard.writeText(`${CURRENT_SITE}/register?referral=${customerInfo.referral_code}`)
                  window.alert(t('referral.alert.copied'))
                }} />
            </Grid>

            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.method_1.old')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.method_1.new')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography textAlign={'center'} color='primary.main' fontWeight='bold'>
                {t('referral.method_2.text')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={'center'} color='primary.main' fontWeight='bold' fontStyle='italic'>
                {customerInfo.referral_code}
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.method_2.old')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.method_2.new')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography textAlign={'center'} color='primary.main' fontWeight='bold'>
                {t('referral.method_3.text')}
              </Typography>
            </Grid>
            <Grid item>
              <StyledQRCode
                url={`${CURRENT_SITE}/register?referral=${customerInfo.referral_code}`} />
            </Grid>
            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.method_3.old')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={'center'}>
                {t('referral.method_3.new')}
              </Typography>
            </Grid>
          </>
        }

      </Grid>
    </Container>
  )
}