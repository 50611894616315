import { Dispatch } from 'redux';
import { IAuthCustomerActions, failed, loginSuccess, logoutSuccess } from './actions';
import { push } from 'connected-react-router';

const { REACT_APP_API_SERVER_v1 } = process.env;

export function login(username: string, password: string, whereTo: string | undefined) {
  
  return async (dispatch: Dispatch<IAuthCustomerActions>) => {
  
    const res = await fetch(`${REACT_APP_API_SERVER_v1}/auth/customer/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const result = await res.json();
    
    if (res.status !== 200) {
      dispatch(failed('LOGIN_CUSTOMER_FAILED', result.msg));
    } else {
      localStorage.setItem('authCustomerToken', result.authCustomerToken);
      dispatch(loginSuccess(result.authCustomerToken));
      // very preliminary fix for updated redux + outdated connected react router
      dispatch(push(whereTo ? whereTo : '/dashboard') as any)
      window.location.reload();
    };
  }
}

export function logout() {
  return async (dispatch: Dispatch<IAuthCustomerActions>) => {
    await localStorage.removeItem('authCustomerToken');
    dispatch(logoutSuccess());
    // very preliminary fix for updated redux + outdated connected react router
    dispatch(push('/') as any);
    window.location.reload();
  }
}
