import { Chip, Container, Grid, TextField, Typography, InputLabel, FormControl, NativeSelect } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { MakeupBookPtClass } from 'src/api/form-types';

import useDirectorAllClient from 'src/hooks/swr/coach/director/use-director-all-client';
import useAdminAllCoach from 'src/hooks/swr/coach/admin/use-admin-all-coach';
import { useForm } from 'react-hook-form';
import useCoachAllTimeslot from 'src/hooks/swr/coach/use-coach-all-timeslot';
import postDirectorMakeupBookPtClass from 'src/api/coach/director/post-director-makeup-book-pt-class';

const fieldWidth = 250;

export default function DirectorPtClassMakeup() {
  const { t } = useTranslation('admin');
  const [searchParams, ] = useSearchParams();
  const classType = searchParams.get('type') as '1-to-1' | 'star-1-to-1' | 'private-class';

  const isPrivateClass = useMemo(() => {
    if (classType) {
      return classType === 'private-class'
    }
  }, [classType]);

  const navigate = useNavigate();

  const { allClient } = useDirectorAllClient();
  const { allCoach } = useAdminAllCoach();
  const { allTimeslot } = useCoachAllTimeslot();

  const availableCoach = useMemo(() => {
    if (allCoach && classType) {
      if (classType === 'private-class') {
        return allCoach
      } else if (classType === '1-to-1') {
        return allCoach.filter(coach => (coach.type !== 'senior' && coach.type !== 'director'))
      } else if (classType === 'star-1-to-1') {
        return allCoach.filter(coach => (coach.type === 'senior' || coach.type === 'director'))
      }
    }
  }, [allCoach, classType]);

  const [selectedDay, setSelectedDay] = useState(dayjs());
  const [selectedTime, setSelectedTime] = useState({
    hour: 12,
    minute: 0,
  });
  const [selectedDuration, setSelectedDuration] = useState<number>(60);

  const { register, handleSubmit, setValue, watch } = useForm<MakeupBookPtClass>();


  register('coachId');
  register('customerId');
  register('day');
  register('hour');
  register('minute');
  register('duration');
  register('classType');

  const selectedCoachId = watch('coachId');
  const selectedCustomerId = watch('customerId');

  const onSubmit = handleSubmit(async (data: MakeupBookPtClass) => {
    const res = await postDirectorMakeupBookPtClass(data);
    const { successful } = await res.json();
    const customerName = allClient.filter(client => client.id === data.customerId)[0].name;
    if (res.status === 200 && successful) {
      window.alert(t('pt_class.make_up.alert.success', { name: customerName }));
      navigate('/coach/admin/all-class');
    } else {
      window.alert(t('pt_class.make_up.alert.fail', { name: customerName }));
    }
  });

  useEffect(() => {
    if (classType){
      setValue('day', dayjs().format('YYYY-MM-DD'));
      setValue('hour', 12);
      setValue('minute', 0);
      setValue('duration', 60);
      setValue('classType', classType);
    }
  }, [setValue, classType])


  return (
    <Container sx={{ padding: 2 }} maxWidth={'sm'}>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={2}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {t('pt_class.make_up.title', { type: t(`pt_class.make_up.type.${classType}`)})}
            </Typography>
          </Grid>
          <Grid item>
            {allClient &&
              <FormControl fullWidth>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                  {t('pt_class.make_up.customer')}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'make-up-client', id: 'uncontrolled-native',
                  }}
                  value={selectedCustomerId}
                  onChange={e => {
                    setValue('customerId', parseInt(e.target.value));
                  }}
                  sx={{ width: fieldWidth }}
                  required>
                  <option value={''} aria-label={`customer-select-default-null`}>
                    {t(`group_class.make_up.customer`)}
                  </option>
                  {allClient.map((client, index) => (
                    <option value={client.id} key={`customer-${client.id}`} aria-label={`client-select-${client.id}`}>
                      {client.name}
                    </option>
                    ))
                  }
                </NativeSelect>
              </FormControl>
            }
          </Grid>

          <Grid item>
            {availableCoach &&
              <FormControl fullWidth>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                  {t('pt_class.make_up.coach')}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'make-up-coach', id: 'uncontrolled-native',
                  }}
                  value={selectedCoachId}
                  onChange={e => {
                    setValue('coachId', parseInt(e.target.value));
                  }}
                  sx={{ width: fieldWidth }}
                  required>
                  <option value={''} aria-label={`coach-select-default-null`}>
                    {t(`pt_class.make_up.coach`)}
                  </option>
                  {availableCoach.map((coach, index) => (
                    <option value={coach.id} key={`coach-${coach.id}`} aria-label={`coach-select-${coach.id}`}>
                      {nameToCamelcase(coach.preferred_name + ' ' + coach.last_name)}
                    </option>
                    ))
                  }
                </NativeSelect>
              </FormControl>
            }
          </Grid>


          <Grid item>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              openTo='day'
              value={selectedDay}
              onChange={(newValue) => {
                if (newValue === null) { return }
                setSelectedDay(newValue);
                setValue('day', newValue.format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>

          {isPrivateClass &&
            <Grid item sx={{ marginTop: -5 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native-accessRight">
                  {t('pt_class.make_up.duration')}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'duration', id: 'uncontrolled-native-duration',
                  }}
                  variant='standard'
                  value={selectedDuration}
                  onChange={e => {
                    const minDuration = parseInt(e.target.value);
                    setSelectedDuration(minDuration);
                    setValue('duration', minDuration);
                  }}
                  sx={{ width: 200 }}
                >
                  <option value={60} aria-label={`duration-60-mins`}>
                    {60 + t('mins')}
                  </option>
                  {isPrivateClass &&
                    <option value={90} aria-label={`duration-90-mins`}>
                      {90 + t('mins')}
                    </option>
                  }
                  {isPrivateClass &&
                    <option value={120} aria-label={`duration-60-mins`}>
                      {120 + t('mins')}
                    </option>
                  }
                </NativeSelect>
              </FormControl>
            </Grid>
          }

          <Grid item container justifyContent='center'
            sx={{ marginTop: isPrivateClass ? 0 : -5 }}>
            {allTimeslot && allTimeslot
              .map(timeslot => {
                const formatedTimeslot: string = timeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                  + ':'
                  + timeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2});
                return (
                  <Chip
                    variant={(selectedTime.hour === timeslot.hour && selectedTime.minute === timeslot.minute) ? 'filled' : 'outlined'}
                    color={'primary'}
                    sx={{
                      margin: 0.5,
                      borderColor: 'primary.dark',
                      border: `1px solid`,
                    }}
                    label={formatedTimeslot}
                    key={formatedTimeslot}
                    onClick={() => {
                      setSelectedTime({ hour: timeslot.hour, minute: timeslot.minute });
                      setValue('hour', timeslot.hour);
                      setValue('minute', timeslot.minute);
                    }}
                    />
              )})
            }
          </Grid>
          
          <Grid item>
            <ButtonPrimary
              type='submit'
              status='primary'
              label={t('pt_class.make_up.btn.makeup')} />
          </Grid>
        </Grid>

      {/* <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'xs'}>
        <DialogTitle id="alert-dialog-title">
          {bookingSuccess ? t('book.dialog.success') : t('book.dialog.title')}
        </DialogTitle>
        <DialogContent>
          
        </DialogContent>
        {!bookingLoading && 
          <DialogActions>
            <Button onClick={handleDialogClose}>
              {t('btn.back')}
            </Button>
            {ptTimeslot.id !== 0 && !bookingSuccess && !noCredit &&
              <Button onClick={confirmBookSession} autoFocus>
                {t('btn.confirm')}
              </Button>
            }
          </DialogActions>
        }
      </Dialog> */}
      </form>
    </Container>
  )
}