import React, { useEffect } from 'react';

/* detect clicks outside of language menu & close menu
 * divRef - wrap whole component by div, initial dummy ref by useRef(document.createElement('div'));
 * hiddenStateSetFn - const [state, setState] = useState controlling popup display state
 */

export default function usePopupGlobalClickCollapse(
  divRef: React.MutableRefObject<HTMLDivElement>,
  hiddenStateSetFn: React.Dispatch<React.SetStateAction<boolean>>,
) {
  useEffect(() => {
    const detectClickOutside = (event: Event) => {
      if (!divRef.current.contains(event.target as Node)) {
        hiddenStateSetFn(false);
      }
    }
    document.addEventListener('mousedown', detectClickOutside);
    return (() => {
      document.removeEventListener('mousedown', detectClickOutside)
    })
  }, [divRef, hiddenStateSetFn])
}