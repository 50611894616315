import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigateBack from 'src/components/navigate/NavigateBack';

export default function NotFoundPage() {
  const { t } = useTranslation('customer');

  return (
    <Container maxWidth='sm' sx={{ paddingTop: 2 }}>
      <Grid
        item container
        xs={12}
        spacing={3}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('404.title')}
          </Typography>
        </Grid>

        <Grid item container justifyContent={'center'}>
          <NavigateBack label={t('404.btn')}/>
        </Grid>

      </Grid>
    </Container>
  )
}