import { NewMemberCreditUpdate } from 'src/api/form-types';

export default function postNewMemberCredit(data: NewMemberCreditUpdate) {
  return fetch(`${global.API_v1}/director/credit/new-member-credit/`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
