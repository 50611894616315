import { Container, Grid, Typography, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InternalClassBox from 'src/components/coach/director/book-studio/InternalClassBox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateTo from 'src/components/navigate/NavigateTo';
import useDirectorAllInternalClass from 'src/hooks/swr/coach/director/use-director-all-internal-class';

export default function DirectorBookStudioManagement() {
  const { t } = useTranslation('coach');
  const { allInternalClasses } = useDirectorAllInternalClass();

  const [selectedWeek, setSelectedWeek] = useState(0);
  const isSun = useMemo(() => dayjs().day() === 0, []);
  const weekMon = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(1).hour(0).minute(0).second(1)
  , [selectedWeek, isSun]);
  
  const weekSun = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(7).hour(23).minute(59).second(59)
  , [selectedWeek, isSun]);

  const allInternalClassesInWeek = useMemo(() => {
    if (allInternalClasses) {
      return allInternalClasses.filter(internalClass => dayjs(internalClass.date).hour(internalClass.hour).minute(internalClass.minute).isBetween(weekMon, weekSun))
    }
  }, [allInternalClasses, weekMon, weekSun])
  
  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
        <Grid item>
          <NavigateTo
            to={'/coach/director/book-studio/book'}
            label={t('book_studio.btn.create')}/>
        </Grid>

        <Grid item>
          <NavigateTo
            to={'/coach/director/book-studio/book/other-event'}
            label={t('book_studio.btn.create_event')}/>
        </Grid>
      
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('book_studio.all_internal_class')}
          </Typography>
        </Grid>

        <Grid
          item container
          justifyContent='space-between'
          alignItems='center'>
          <Grid item>
            <IconButton aria-label='previous-week' color='primary'
              onClick={() => {
                setSelectedWeek(selectedWeek - 1)
              }}>
              <ArrowBackIosIcon/>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label='next-week' color='primary'
              onClick={() => {
                setSelectedWeek(selectedWeek + 1)
              }}>
              <ArrowForwardIosIcon/>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container justifyContent={'center'}>
          {allInternalClassesInWeek && allInternalClassesInWeek.length === 0 &&
            <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}>
              {t('book_studio.empty', {status: t('status.confirmed')})}
            </Typography>
          }
          {allInternalClassesInWeek && allInternalClassesInWeek.map((internalClass, index) => (
            <Grid item xs={12} key={`group-class-${index}`}>
              <InternalClassBox
                internalClass={internalClass} />
            </Grid>
          ))}
        </Grid>
        
      </Grid>
    </Container>
  )
}