import useSWR from 'swr';
import axios from 'axios';
import { CoachTypeInfo } from 'src/hooks/swr/output-types';

const fetcher = (url: string) => 
  axios
    .get(url)
    .then(res => res.data);

export default function useAllCourseCharge() {
  const { data, mutate, error } = useSWR(`${process.env.REACT_APP_API_SERVER_v1}/course-charge`, url => fetcher(url));
  return {
    allCoachTypeCharge: data as CoachTypeInfo[],
    mutate,
    error
  }
};
