import { CreateGroupClassFormData } from 'src/api/form-types';

export default function postCoachCreateGroupClass(data: CreateGroupClassFormData) {
  return fetch(`${global.API_v1}/coach/group-class/`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
