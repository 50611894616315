
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import postCoachCreateGroupClass from 'src/api/coach/post-coach-create-group-class';
import { CreateGroupClassFormData } from 'src/api/form-types';
import GroupClassForm from 'src/components/coach/group-class/GroupClassForm';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function AdminGroupClassCreate() {
  const navigate = useNavigate();
  const { t } = useTranslation('coach');

  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    dialogText: '',
    resSuccessful: false,
  });


  const createGroupClass = async (data: CreateGroupClassFormData) => {
    const res = await postCoachCreateGroupClass(data);
    const { successful } = await res.json();
    setDialogInfo({
      open: true,
      dialogText: t(`group_class.dialog.create_${(successful && res.status === 200) ? 'successful' : 'fail'}`),
      resSuccessful: (successful && res.status === 200),
    });
  }

  const handleDialogClose = () => {
    setDialogInfo({ ...dialogInfo, open: false });
    if (dialogInfo.resSuccessful) {
      navigate('/coach/admin/all-class');
    }
  }

  return (
    <Container sx={{ marginTop: 2, marginBottom: 3, padding: 0 }} maxWidth={'md'}>
      <GroupClassForm submitAction={createGroupClass}/>

      <Dialog
          open={dialogInfo.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}