import useSWR from 'swr';
import axios from 'axios';
import { PtClassDetails } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string, ptClassId: number) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { ptClassId: ptClassId }
    })
    .then(res => res.data);

export default function useCustomerPtClassDetails(ptClassId: number) {
  const token = localStorage.getItem('authCustomerToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/customer/pt-class/single`, ptClassId],
    (url: string, ptClassId: number) => fetcher(url, token, ptClassId)
  );
  return {
    ptClassDetails: data as PtClassDetails,
    mutate,
    error
  }
};
