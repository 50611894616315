export default function postAdminSalaryXlsx(year: number, month: number) {
  return fetch(`${global.API_v1}/admin/statistics/coach-salary-xlsx`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authCoachToken')}`,
    },
    body: JSON.stringify({ year, month }),
  });
}
