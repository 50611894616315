import { Container, Grid, styled, Typography, GridProps, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCoachAllGroupClass from 'src/hooks/swr/coach/use-coach-all-group-class';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import CustomerLink from 'src/components/coach/customer-management/CustomerLink';
import adminCleanPhone from 'src/utils/admin-clean-phone';
import NavigateBack from 'src/components/navigate/NavigateBack';
import stripeHKPhone from 'src/utils/stripe-hk-phone';
import putCoachAcceptRejectSelectedCustomer from 'src/api/coach/put-coach-accept-reject-selected-customer';
import { GroupClassCustomerInfo } from 'src/hooks/swr/output-types';
import ButtonOutline from 'src/components/ButtonOutline';
import QrScanner from 'qr-scanner';
import putCoachMarkGroupClassAttend from 'src/api/coach/put-coach-mark-group-class-attend';

// const attendanceMinute = 30;
const attendanceMinute = 120;

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

export default function CoachGroupClassDetails() {
  const { t } = useTranslation('coach');
  let { groupClassId } = useParams();
  const { allGroupClasses, mutate } = useCoachAllGroupClass();

  const groupClass = useMemo(() => {
    if (allGroupClasses) {
      return (allGroupClasses.filter(groupClass => groupClass.groupClassId === parseInt(groupClassId as string))[0])
    }
  }, [allGroupClasses, groupClassId])
  
  const handleAcceptReject = async (customer: GroupClassCustomerInfo, status: 'confirmed' | 'rejected') => {
    if (!groupClass) { return }
    if (window.confirm(`Are you sure to ${status === 'confirmed' ? 'confirm' : 'reject' } ${nameToCamelcase(customer.perferredName + ' ' + customer.lastName)}'s booking?`)) {
      const res = await putCoachAcceptRejectSelectedCustomer(groupClass.groupClassId, customer.id, status);
      if (res.status === 200) {
        // to refactored
        window.alert(`${nameToCamelcase(status)} the reserveration`);
        mutate();
      }
    }
  }

  const classDayjsTime = useMemo(() => {
    if (groupClass) {
      return dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute);
    }
  }, [groupClass]);

  const canTakeAttendance = useMemo(() => {
    if (!classDayjsTime || !groupClass) { return }
    return dayjs().isBetween(classDayjsTime.subtract(attendanceMinute, 'minute'), classDayjsTime.add(attendanceMinute + 60, 'minutes'))
  }, [groupClass, classDayjsTime]);
  const [cameraOn, setCameraOn] = useState(false);
  const [qrScanner, setQrScanner] = useState<QrScanner>();
  const [isOnQrResult, setIsOnQrResult] = useState(false);


  useEffect(() => {
    if (!cameraOn) { return }
    const video = document.getElementById('qr-video');
    if (video) {
      const qrScanner = new QrScanner(
        video as HTMLVideoElement,
        async (result) => {
          console.log(isOnQrResult);
          if (isOnQrResult) { return }
          console.log('decoded qr code:', result);
          console.log('put request is sent');
          setIsOnQrResult(true);
          const dataList = result.data.split('-');
          // window.alert(`Decoded qr code is ${result.data}`)
          const res = await putCoachMarkGroupClassAttend(parseInt(dataList[1]), parseInt(dataList[3]));
          console.log(res);
          if (res.status === 200 && mutate) {
            mutate();
            window.alert(`Attendance is taken!`);
            setIsOnQrResult(false);
          } else if (res.status === 202) {
            window.alert(`Attendance is taken!`);
          }
        }
      , {});
      setQrScanner(qrScanner);
      // console.log(qrScanner.hasCamera())
      // console.log(qrScanner.listCamera())
      // QrScanner.hasCamera()
      //   .then((v) => console.log(v))
      // QrScanner.listCameras()
      //   .then((v) => console.log(v));
      // qrScanner.start();
      return () => {
        qrScanner.stop();
        // qrScanner.destroy();
      }
    }
  }, [cameraOn, isOnQrResult, mutate]);


  const handleAttendCamera = (cameraOn: boolean) => {
    console.log()
    setCameraOn(!cameraOn);
    if (!qrScanner) { return }
    if (!cameraOn) {
      qrScanner.start();
    } else {
      qrScanner.stop();
    }
  };

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
        <Grid item>
          <NavigateBack />
        </Grid>
      
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('group_class.title')}
          </Typography>
        </Grid>

        <Grid item>
          <ButtonOutline
            status={'primary'}
            // To Do
            // disable except between 30 mins before & 30 mins after the class
            // disabled={false}
            disabled={!canTakeAttendance}
            label={t('attendance.btn.take_attendance', {action: cameraOn ? t('attendance.btn.finish') : ''})}
            onClick={() => handleAttendCamera(cameraOn)} />
        </Grid>

        {canTakeAttendance &&
          <div id='video-container'
            style={{
              maxWidth: '100vw',
              display: cameraOn ? 'unset' : 'none',
            }}>
            <video id='qr-video' style={{ maxWidth: '100vw' }}>
            </video>
          </div>
        }

        {groupClass && classDayjsTime &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.name)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.description')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.description)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {nameToCamelcase(t('coach'))}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.coachPreferredName + ' ' + groupClass.coachLastName)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.date')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {classDayjsTime.format('DD MMM[,] YYYY [-] ddd[,] HH:mm')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.credit_charge')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {groupClass.creditCharge + ' ' + t('group_class.fields.credit')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.enrolled_num')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {groupClass.customers.length}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.gender_restrict')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {groupClass.genderRestrict === 'male' && t('group_class.fields.male')}
                  {groupClass.genderRestrict === 'female' && t('group_class.fields.female')}
                  {!groupClass.genderRestrict && t('group_class.fields.gender_all')}
                </Typography>
              </Grid>
            </TextGrid>

            <Grid item container 
              spacing={groupClass.isSelected ? 2 : 0.5}
              direction='column'
              justifyContent='center'
              alignItems='center'>
              {groupClass.customers.map((customer, index) => (
                <Grid item
                  key={`${index}-${customer.perferredName}`}>
                  <CustomerLink
                    to={`/coach/customer/${stripeHKPhone(customer.phoneNum)}`}
                    name={nameToCamelcase(customer.perferredName + ' ' + customer.lastName)}
                    phoneNumber={adminCleanPhone(customer.phoneNum)}/>
                  
                  {groupClass.isSelected &&
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Typography>
                        {nameToCamelcase(customer.status)}
                      </Typography>
                      <Button color='error' disabled={customer.status !== 'pending'}
                        // To Refactor
                        onClick={() => handleAcceptReject(customer, 'rejected')}>
                        {t('group_class.btn.reject')}
                      </Button>
                      <Button disabled={customer.status !== 'pending'}
                        onClick={() => handleAcceptReject(customer, 'confirmed')}>
                        {t('group_class.btn.accept')}
                      </Button>
                    </Box>
                  }
                </Grid>
              ))}
            </Grid>
          </>
        }

        
      </Grid>
    </Container>
  )
}