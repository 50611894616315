import { Container, Grid, GridProps, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import NavigateBack from 'src/components/navigate/NavigateBack';
import { nameToCamelcase } from 'src/utils/clean-name';
import stripeHKPhone from 'src/utils/stripe-hk-phone';

import useCustomerInfo from 'src/hooks/swr/customer/use-customer-info';
import NavigateTo from 'src/components/navigate/NavigateTo';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

export default function Profile() {
  const { t } = useTranslation('customer');
  // use customer-specific get info hook
  const { customerInfo } = useCustomerInfo();

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('profile.title')}
          </Typography>
        </Grid>

        {customerInfo &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.preferred_name + ' ' + customerInfo.last_name)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.birthday')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {dayjs(customerInfo.birthday).format('YYYY MMM DD')}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.gender')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.gender)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.phone_number')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {stripeHKPhone(customerInfo.phone_number)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.username')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {stripeHKPhone(customerInfo.username)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.available_credit')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {customerInfo.available_credit}
                </Typography>
              </Grid>
            </TextGrid>

            {/* for is star selected for selected class & is VIP for 5 / 24 hour cancellation
              * disable user's other option
              *
              * only display is-VIP for VIPs, normal customer will not have this view
              */}
            {/* <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.is_selected')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color={customerInfo.is_star_selected ? 'primary.main' : 'disableGrey.main'}>
                  {customerInfo.is_star_selected ? t('profile.fields.can_attend_special') : t('profile.fields.cannot_attend_special')}
                </Typography>
              </Grid>
            </TextGrid> */}

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('profile.fields.is_vip')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color={'primary.main'}>
                  {customerInfo.is_vip ? t('profile.fields.vip') : t('profile.fields.ordinary_member')}
                </Typography>
              </Grid>
            </TextGrid>
            
            {customerInfo.referred_last_name !== null && customerInfo.referred_preferred_name !== null &&
              <TextGrid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {t('profile.fields.referred_by')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2' color={'primary.main'}>
                  {nameToCamelcase(customerInfo.referred_preferred_name + ' ' + customerInfo.referred_last_name)}
                  </Typography>
                </Grid>
              </TextGrid>
            }
            
            {customerInfo.referred_coach_last_name !== null && customerInfo.referred_coach_preferred_name !== null &&
              <TextGrid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {t('profile.fields.referred_by_coach')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2' color={'primary.main'}>
                  {nameToCamelcase(customerInfo.referred_coach_preferred_name + ' ' + customerInfo.referred_coach_last_name)}
                  </Typography>
                </Grid>
              </TextGrid>
            }

          </>
        }

        <Grid item>
          <NavigateTo label={t('profile.btn.change_password')} to={'/change-password'} />
        </Grid>

        <Grid item>
          <NavigateBack />
        </Grid>

      </Grid>
    </Container>
  )
}