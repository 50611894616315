import useSWR from 'swr';
import axios from 'axios';
import { PtClassDetails } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string, statusString: string) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { status: statusString },
    })
    .then(res => res.data);

export default function useCoachPtClassWithStatus(statusString: string) {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/coach/pt-class/with-status`, statusString],
    (url: string, statusString: string) => fetcher(url, token, statusString)
  );
  return {
    ptClasses: data as PtClassDetails[],
    mutate,
    error
  }
};
