export default function postAdminClientDataXlsx(year?: number, month?: number) {
  return fetch(`${global.API_v1}/admin/statistics/client-data-xlsx`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authCoachToken')}`,
    },
    body: JSON.stringify({ year, month }),
  });
}
