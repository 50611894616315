import { ReactComponent as PlusSVG } from 'src/icons/components/credit/plus.svg';
import { ReactComponent as SubtractSVG } from 'src/icons/components/credit/minus.svg';
import { Box, styled, Typography } from '@mui/material';
import { theme } from 'src/styles/muiTheme';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';

export interface AddSubtractBtnProps {
  enabled: boolean,
  type: 'add' | 'subtract',
  onClick?: MouseEventHandler<HTMLDivElement>,
};
const AddSubtractBtnWrapper = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  marginTop: 10,
  marginBottom: 10,
  background: 'white.main',
  padding: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

function AddSubtractBtn(
  props: AddSubtractBtnProps,
) {
  const { t } = useTranslation('coach');
  const { enabled, type, onClick } = props;
  return (
    <AddSubtractBtnWrapper
      sx={{
        boxShadow: `0px 4px 8px ${theme.palette[enabled ? 'primary' : 'disableGrey'].main}40`,
      }}
      onClick={onClick}>
      <Typography variant='body2'
        color={enabled ? 'primary.main' : 'disableGrey.main'}>
        {type === 'add' && t('manual_credit.btn.add')}
        {type === 'subtract' && t('manual_credit.btn.subtract')}
      </Typography>
      {type === 'add' &&
        <PlusSVG
          width={20} height={20}
          stroke={theme.palette[enabled ? 'primary' : 'disableGrey'].main} />
      }
      {type === 'subtract' &&
        <SubtractSVG
          width={20} height={20}
          stroke={theme.palette[enabled ? 'primary' : 'disableGrey'].main} />
      }
    </AddSubtractBtnWrapper>
  )
}

export default AddSubtractBtn;
