import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigateBack from 'src/components/navigate/NavigateBack';
import useCustomerAllPurchaseHistory from 'src/hooks/swr/customer/use-customer-all-purchase-history';
import PaymentRecordList from 'src/components/payment-record/PaymentRecordList';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export default function CustomerRecord() {
  const location = useLocation();
  const { t } = useTranslation('customer');
  const { allPurchaseRecord } = useCustomerAllPurchaseHistory();

  const [stripeDialog, setStripeDialog] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (location.search && location.search === '?stripe_success=true') {
      setStripeDialog({
        open: true,
        message: t('record.stripe.success'),
      })
    }
  }, [location, t])

  const closeStripeDialog = () => {
    setStripeDialog({
      open: false,
      message: '',
    });
  };

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
      <Grid
        item container
        xs={12}
        spacing={1}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('record.title')}
          </Typography>
        </Grid>

        <PaymentRecordList
          allPurchaseRecord={allPurchaseRecord} />
        
        {allPurchaseRecord && allPurchaseRecord.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('record.empty')}
          </Typography>
        }

        <Grid item container justifyContent={'center'}>
          <NavigateBack label={t('btn.back')}/>
        </Grid>
      </Grid>

      <Dialog
        open={stripeDialog.open}
        onClose={closeStripeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Typography variant='body1' color='primary.main' fontStyle='bold'>
            {stripeDialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStripeDialog} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}