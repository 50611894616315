import { Container, Grid, styled, Typography, GridProps, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAdminAllGroupClass from 'src/hooks/swr/coach/admin/use-admin-all-group-class';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import NavigateBack from 'src/components/navigate/NavigateBack';
import NavigateTo from 'src/components/navigate/NavigateTo';
import ButtonOutline from 'src/components/ButtonOutline';
import putAdminCancelSingleGroupClass from 'src/api/coach/admin/put-admin-cancel-single-group-class';
import stripeHKPhone from 'src/utils/stripe-hk-phone';
import adminCleanPhone from 'src/utils/admin-clean-phone';
import CustomerLink from 'src/components/coach/customer-management/CustomerLink';
import putCoachAcceptRejectSelectedCustomer from 'src/api/coach/put-coach-accept-reject-selected-customer';
import { GroupClassCustomerInfo } from 'src/hooks/swr/output-types';
import { translateStatusZh } from 'src/utils/translate-status-zh';
import putDirectorChangeGroupClassStatus from 'src/api/coach/director/put-director-change-group-class-status';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import AdminDirectorGroupClassMakeUp from 'src/components/admin/AdminDirectorGroupClassMakeUp';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const ThemeSeparator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '70vw',
  maxWidth: 500,
  height: 1,
}));

export default function AdminGroupClassDetails() {
  const { t } = useTranslation('admin');
  let { groupClassId } = useParams();
  const isCoachDirector = useSelector((state: IRootState) => state.authCoach.accessRight === 'director');
  // To Refactor
  const { allGroupClasses, mutate } = useAdminAllGroupClass();

  const groupClass = useMemo(() => {
    if (allGroupClasses) {
      return (allGroupClasses.filter(groupClass => groupClass.groupClassId === parseInt(groupClassId as string))[0])
    }
  }, [allGroupClasses, groupClassId]);

  const classDayjsTime = useMemo(() => {
    if (groupClass) {
      return dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute);
    }
  }, [groupClass]);

  const isFutureClass = useMemo(() => {
    if (classDayjsTime) {
      return dayjs().isBefore(classDayjsTime)
    }
  }, [classDayjsTime]);

  const handleCancelClass = async () => {
    const res = await putAdminCancelSingleGroupClass(parseInt(groupClassId as string));
    if (res.status === 200) {
      window.alert(t('group_class.alert.cancel_successful'));
      mutate();
    } else {
      window.alert(t('group_class.alert.cancel_fail'));
    }
  };

  const handleAcceptReject = async (customer: GroupClassCustomerInfo, status: 'confirmed' | 'rejected') => {
    if (!groupClass) { return }
    if (window.confirm(`Are you sure to ${status === 'confirmed' ? 'confirm' : 'reject' } ${nameToCamelcase(customer.perferredName + ' ' + customer.lastName)}'s booking?`)) {
      const res = await putCoachAcceptRejectSelectedCustomer(groupClass.groupClassId, customer.id, status);
      if (res.status === 200) {
        // to refactored
        window.alert(`${nameToCamelcase(status)} the reserveration`);
        mutate();
      }
    }
  };

  const handleStatusMakeup = async (customer: GroupClassCustomerInfo, status: 'confirmed' | 'attended') => {
    if (!groupClass) { return }
    if (window.confirm(t('group_class.alert.status_change_confirm', {name: customer.perferredName, status: translateStatusZh(status)}))) {
      const res = await putDirectorChangeGroupClassStatus(groupClass.groupClassId, customer.id, status);
      if (res.status === 200) {
        window.alert(t('group_class.alert.status_change_success', {name: customer.perferredName, status: translateStatusZh(status)}));
        mutate();
      }
    }
  };
  
  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
        <Grid item>
          <NavigateBack />
        </Grid>
      

        {groupClass && classDayjsTime &&
          <>
            <Grid item>
              <Typography variant='h6' color={!groupClass.isSingleClassCancelled ? 'primary.main' : 'displayGrey.main'}>
                {(groupClass.isSingleClassCancelled ? `[${t('group_class.fields.cancelled')}]` : '')
                  + (groupClass.isStar ? t('star') : '')
                  + (groupClass.isSelected ? t('special_class') : '')
                  + t('group_class.title')
                }
              </Typography>
            </Grid>
            
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.name)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid alignItems='flex-start'>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.description')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {nameToCamelcase(groupClass.description)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('coach')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.coachPreferredName + ' ' + groupClass.coachLastName)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.date')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {classDayjsTime.format('DD MMM[,] YYYY [-] ddd[,] HH:mm')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.credit_charge')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {groupClass.creditCharge + ' ' + t('group_class.fields.credit')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.enrolled_num')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {groupClass.customers.length}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.class_status')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {isFutureClass && !groupClass.isSingleClassCancelled &&
                    t('group_class.fields.available')
                  }
                  {isFutureClass && groupClass.isSingleClassCancelled &&
                    t('group_class.fields.cancelled')
                  }
                  {!isFutureClass &&
                    t('group_class.fields.past')
                  }
                </Typography>
              </Grid>
            </TextGrid>
            
            <Grid item container 
              spacing={groupClass.isSelected ? 2 : 0.5}
              direction='column'
              justifyContent='center'
              alignItems='center'>
              {groupClass.customers.map((customer, index) => (
                <Grid item
                  key={`${index}-${customer.perferredName}`}>
                  <CustomerLink
                    to={`/coach/customer/${stripeHKPhone(customer.phoneNum)}`}
                    name={nameToCamelcase(customer.perferredName + ' ' + customer.lastName)}
                    phoneNumber={adminCleanPhone(customer.phoneNum)}/>
                  
                  {groupClass.isSelected &&
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <Typography>
                        {nameToCamelcase(customer.status)}
                      </Typography>
                      <Button color='error' disabled={customer.status !== 'pending'}
                        // To Refactor
                        onClick={() => handleAcceptReject(customer, 'rejected')}>
                        {t('group_class.btn.reject')}
                      </Button>
                      <Button disabled={customer.status !== 'pending'}
                        onClick={() => handleAcceptReject(customer, 'confirmed')}>
                        {t('group_class.btn.accept')}
                      </Button>
                    </Box>
                  }

                  {isCoachDirector &&
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: 0.2,
                    }}>
                      <Typography variant='body2'>
                        {translateStatusZh(customer.status)}
                      </Typography>
                      <Button variant='text'
                        onClick={() => handleStatusMakeup(customer, 'confirmed')}>
                        {t('group_class.btn.not_attended')}
                      </Button>
                      <Button variant='text'
                        onClick={() => handleStatusMakeup(customer, 'attended')}>
                        {t('group_class.btn.attended')}
                      </Button>
                    </Box>
                  }
                </Grid>
              ))}
            </Grid>

            <Grid item container
              xs={12}
              spacing={1}
              direction='column'
              justifyContent='center'
              alignItems='center'>
              <Grid item>
                <ThemeSeparator/>
              </Grid>
              <Grid item>
                <NavigateTo
                  label={t('group_class.btn.edit_class')}
                  to={`/coach/admin/group-class/edit/${groupClassId}`}/>
              </Grid>
              {isCoachDirector &&
                <Grid item>
                  <AdminDirectorGroupClassMakeUp
                    groupClassId={parseInt(groupClassId as string)}
                    mutate={mutate} />
                </Grid>
              }
              <Grid item>
                <ThemeSeparator/>
              </Grid>
              <Grid item>
                <ButtonOutline
                  status={(isFutureClass && !groupClass.isSingleClassCancelled) ?
                    'warning' : 'disabled'
                  }
                  label={t('group_class.btn.cancel_class')}
                  onClick={handleCancelClass}/>
              </Grid>
            </Grid>
          </>
        }
      </Grid>

      
    </Container>
  )
}