import { Container, Grid,GridProps, styled, Typography, DialogContentTextProps, CircularProgress, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useCustomerAllGroupClass from 'src/hooks/swr/customer/use-customer-all-group-class';
import useCustomerAvailableCredit from 'src/hooks/swr/customer/use-customer-available-credit';
import NavigateBack from 'src/components/navigate/NavigateBack';
import postCustomerNewGroupClass from 'src/api/customer/post-customer-new-group-class';
import ButtonOutline from 'src/components/ButtonOutline';
import useCustomerIsVip from 'src/hooks/swr/customer/use-customer-is-vip';
import putCustomerCancelGroupClass from 'src/api/customer/put-customer-cancel-group-class';
import useCustomerInfo from 'src/hooks/swr/customer/use-customer-info';

import StyledQRCodeForClassAttendance from 'src/components/customer/qr-code/StyledQRCodeForClassAttendance';
import { theme } from 'src/styles/muiTheme';

// const attendanceMinute = 30;
const attendanceMinute = 120;

const MyDialogContentText = styled((props: DialogContentTextProps) => (
  <DialogContentText
    color='primary.dark'
    fontWeight={500}
    {...props} />
))(({ theme }) => ({
  marginBottom: 10,
}));

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

export default function GroupClassBookScreen() {
  const { t } = useTranslation('customer');
  let { groupClassId } = useParams();

  const [noCredit, setNoCredit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelDialog, setCancelDialog] = useState({
    open: false,
    text: '',
  });

  // To Refactor
  // use cache only, will refactor to get single group class;
  const { allGroupClasses, mutate: mutateGroupClass } = useCustomerAllGroupClass();
  const { credit, mutate: mutateCredit} = useCustomerAvailableCredit();
  const { isVip } = useCustomerIsVip();
  const { customerInfo } = useCustomerInfo();

  const groupClass = useMemo(() => {
    if (!allGroupClasses) {return undefined}
    return allGroupClasses.filter(groupClass => groupClass.groupClassId === parseInt(groupClassId as string))[0]
  }, [allGroupClasses, groupClassId]);

  const confirmBooking = async () => {
    if (!groupClass) {return}
    if (parseFloat(credit.currentAvailableCredit) < parseFloat(groupClass.creditCharge)) {
      setNoCredit(true)
      return
    }
    setBookingLoading(true);

    const res = await postCustomerNewGroupClass(parseInt(groupClassId as string));

    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setTimeout(() => {
        setBookingLoading(false);
        setBookingSuccess(true);
        mutateGroupClass();
        mutateCredit();
      }, 700)
    }
  }

  const handleBookSession = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    setBookingSuccess(false);
  };

  const classDayjsTime = useMemo(() => {
    if (groupClass) {
      return dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute)
    }
  }, [groupClass]);

  const canCancel = useMemo(() => {
    if (classDayjsTime && groupClass) {
      return dayjs().isBefore(classDayjsTime) && groupClass.enrolled
    }
  }, [classDayjsTime, groupClass])

  const handleCancelSession = async () => {
    setCancelLoading(true);
    const res = await putCustomerCancelGroupClass(parseInt(groupClassId as string));
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setTimeout(() => {
        setCancelDialog({
          open: true,
          text: t('group_class.cancel.text'),
        });
        setCancelLoading(false);
        mutateGroupClass();
        mutateCredit();
        setCancelSuccess(true);
      }, 700)
    }
  }

  const handleCancelDialogClose = () => {
    setCancelDialog({
      open: false,
      text: '',
    });
    setCancelSuccess(false);
  };

  // customer can book any regular class in the future
  const isFuture = useMemo(() => {
    return dayjs().isBefore(classDayjsTime)
  }, [classDayjsTime]);

  /* customer can only book regular class 
   * 1. not enrolled
   * 2. in the future
   * 3. not exceeding 6 participants
   * [deprecated] 4. customer is_star_selected when the class is selected class
   */
  const canEnroll = useMemo(() => {
    if (groupClass && customerInfo) {
      return isFuture && groupClass.numEnrolled < 6
        // && (groupClass.isSelected ? customerInfo.is_star_selected : true)
        && (groupClass.genderRestrict ? groupClass.genderRestrict === customerInfo.gender : true)
    }
  }, [groupClass, isFuture, customerInfo]);

  const canTakeAttendance = useMemo(() => {
    if (!classDayjsTime || !groupClass) { return }
    return dayjs().isBetween(classDayjsTime.subtract(attendanceMinute, 'minute'), classDayjsTime.add(attendanceMinute + 60, 'minutes'))
  }, [groupClass, classDayjsTime]);

  return (
    <Container sx={{ padding: 2 }} maxWidth={'sm'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <NavigateBack />
        </Grid>
        
        {groupClass && credit && classDayjsTime &&
          <>
            <Grid item>
              <Typography variant='h6' color='primary.main'>
                {(groupClass.isSelected ? t('special_class') : '')
                  + t('group_class.title', {star: groupClass.isStar ? t('star') : ''})}
              </Typography>
            </Grid>

            {canTakeAttendance && customerInfo && groupClass.enrolled &&
              <StyledQRCodeForClassAttendance
                data={`class-${groupClass.groupClassId}-customerId-${customerInfo.id}`} />
            }

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.name', {star: groupClass.isStar ? t('star') : ''})}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.name)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.description', {star: groupClass.isStar ? t('star') : ''})}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.description)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('coach')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(groupClass.coachPreferredName + ' ' + groupClass.coachLastName)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.date')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {classDayjsTime.format('DD MMM[,] YYYY [-] ddd[,] HH:mm')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.charge')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {groupClass.creditCharge + ' ' + t('credit')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('book.title.current_credit')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {credit.currentAvailableCredit + ' ' + t('credit')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('group_class.fields.status.text')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {/* To do - fix all the status in all 6 states */}
                  {/* To Refactor - may be deprecated */}
                  {/* {canEnroll ?
                    groupClass.enrolled ? t('group_class.fields.status.booked') : t('group_class.fields.status.available')
                  :
                    t('group_class.fields.status.unavailable')
                  } */}
                      {!groupClass.isSingleClassCancelled &&
                        <>
                          {groupClass.status === 'pending' && t('group_class.fields.status.pending')}
                          {groupClass.status === 'confirmed' && t('group_class.fields.status.booked')}
                          {groupClass.status === 'attended' && t('group_class.fields.status.attended')}
                          {groupClass.status === '' && ( canEnroll ?
                                t('group_class.fields.status.available')
                              : 
                                t('group_class.fields.status.unavailable')
                          )}
                        </>
                      }
                      {groupClass.isSingleClassCancelled &&
                        t('group_class.fields.status.unavailable')
                      }
                </Typography>
              </Grid>
            </TextGrid>
            <Grid item>
              {groupClass.isSelected && 
                <Typography variant='body2' color='secondary.dark' fontStyle='italic' textAlign={'center'}>
                  {t('group_class.special.selected_0')
                    + (groupClass.status === '' ? t('group_class.special.selected_1') : '')
                    + ((groupClass.status === '' || groupClass.status === 'pending') ? t('group_class.special.selected_2') : '')}
                </Typography>
              }
              {groupClass.genderRestrict !== null &&
                <Typography variant='body2' color='disableGrey.main' fontStyle='italic'>
                  <span style={{ color: theme.palette.error.main }}>
                    {t(`group_class.special.${groupClass.genderRestrict as 'male' | 'female'}`)}
                  </span>
                  {t('group_class.special.text')}
                </Typography>
              }
            </Grid>

            {canEnroll && !groupClass.enrolled && !groupClass.isSingleClassCancelled &&
              <Grid item>
                <ButtonPrimary
                  status='primary'
                  label={t('group_class.btn.book', {star: groupClass.isStar ? t('star') : ''})}
                  onClick={() => handleBookSession()}/>
              </Grid>
            }

            {canCancel && isVip &&
              <Grid
                item container
                xs={12}
                spacing={1}
                direction='column'
                justifyContent='center'
                alignItems='center'>
                <Grid item>
                  <Typography color='disableGrey.main'>
                    {t('group_class.refund.reminder', { hour: (isVip && isVip.is_vip) ? 5 : 24 })}
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonOutline
                    status='primary'
                    label={t('group_class.btn.cancel')}
                    onClick={() => {
                      setCancelDialog({
                        open: true,
                        text: t('group_class.cancel.reminder')
                      });
                    }}/>
                </Grid>
              </Grid>
            }
          </>
        }
      </Grid>

      {groupClass &&
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'xs'}>
          <DialogTitle id="alert-dialog-title">
            {bookingSuccess ? t('book.dialog.success') : t('book.dialog.title')}
          </DialogTitle>
          <DialogContent sx={{ minHeight: bookingLoading ? '17vw' : 'unset' }}>
            {!noCredit && <>
              {!bookingLoading &&
                <>
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {t('group_class.title', {star: groupClass.isStar ? t('star') : ''})}
                  </MyDialogContentText>
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {nameToCamelcase(groupClass.name)}
                  </MyDialogContentText>
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {nameToCamelcase(groupClass.description)}
                  </MyDialogContentText>
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {nameToCamelcase(groupClass.coachPreferredName + ' ' + groupClass.coachLastName)}
                  </MyDialogContentText>
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {dayjs(groupClass.date).format('DD MMM[,] YYYY [-] ddd')}
                  </MyDialogContentText>
                  <MyDialogContentText
                    id="alert-dialog-description">
                    {
                      groupClass.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                      + ':' +
                      groupClass.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})
                      + ' - ' + t('book.dialog.60_min')
                    }
                  </MyDialogContentText>
                </>
              }
            </>}
            {noCredit &&
              <MyDialogContentText
                id="alert-dialog-description">
                {t('book.dialog.no_credit')}
              </MyDialogContentText>
            }
            {bookingLoading &&
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <CircularProgress sx={{
                  color: 'primary.main',
                  alignSelf: 'center',
                  height: '15vw !important',
                  width: '15vw !important',
                }}/>
              </Box>
            }
          </DialogContent>
          {!bookingLoading && 
            <DialogActions>
              <Button onClick={handleDialogClose}>
                {t('btn.back')}
              </Button>
              {!bookingSuccess && !noCredit &&
                <Button onClick={confirmBooking} autoFocus>
                  {t('btn.confirm')}
                </Button>
              }
            </DialogActions>
          }
        </Dialog>
      }

      <Dialog
        open={cancelDialog.open}
        onClose={handleCancelDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'xs'}>
        <DialogTitle id="alert-dialog-title">
          {t('group_class.cancel.title')}
        </DialogTitle>
        <DialogContent sx={{ minHeight: cancelLoading ? '17vw' : 'unset' }}>
          {!cancelLoading &&
            <DialogContentText id="alert-dialog-description">
              {cancelDialog.text}
            </DialogContentText>
          }
          {cancelLoading &&
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgress sx={{
                color: 'primary.main',
                alignSelf: 'center',
                height: '15vw !important',
                width: '15vw !important',
              }}/>
            </Box>
          }
        </DialogContent>
        <DialogActions>
        {!cancelLoading &&
          <>
            <Button onClick={handleCancelDialogClose} autoFocus>
              {t('btn.back')}
            </Button>
            {!cancelSuccess &&
              <Button onClick={handleCancelSession} autoFocus>
                {t('group_class.cancel.title')}
              </Button>
            }
          </>
        }
        </DialogActions>
      </Dialog>
    </Container>
  )
}