import useSWR from 'swr';
import axios from 'axios';

const fetcher = (url: string, token: string, coachId: number) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { coachId: coachId }
    })
    .then(res => res.data);

export default function useCustomerAllPtDateTimeAvailablity(coachId: number) {
  const token = localStorage.getItem('authCustomerToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/pt-timeslot/date-time`, coachId],
    (url, coachId) => fetcher(url, token, coachId),
  );
  return {
    allDateTimeAvailablity: data as any,
    mutate,
    error
  }
};
