import { Box, Container, Grid, Typography, IconButton} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCustomerAllPtClass from 'src/hooks/swr/customer/use-customer-all-pt-class';
import PtClassBox from 'src/components/coach/dashboard/PtClassBox';
import SubtitleGrid from 'src/components/dashboard/SubtitleGrid';
import NavigateTo from 'src/components/navigate/NavigateTo';
import useCustomerAllBookedGroupClass from 'src/hooks/swr/customer/use-customer-all-booked-group-class';
import GroupClassBox from 'src/components/group-class/GroupClassBox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import useCustomerInfo from 'src/hooks/swr/customer/use-customer-info';

export default function Dashboard() {
  const { t } = useTranslation('customer');
  const { allPtClass } = useCustomerAllPtClass();
  const { allBookedGroupClasses } = useCustomerAllBookedGroupClass();
  const { customerInfo } = useCustomerInfo();

  const [selectedWeek, setSelectedWeek] = useState(0);
  const isSun = useMemo(() => dayjs().day() === 0, []);
  const weekMon = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(1).hour(0).minute(0).second(1)
  , [selectedWeek, isSun]);
  
  const weekSun = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(7).hour(23).minute(59).second(59)
  , [selectedWeek, isSun]);

  const allPtClassInWeek = useMemo(() => {
    if (allPtClass) {
      return allPtClass
        .filter(ptClass => !ptClass.privateClassHash)
        .filter(ptClass => dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute).isBetween(weekMon, weekSun))
    }
  }, [allPtClass, weekMon, weekSun])

  const allPrivateClassInWeek = useMemo(() => {
    if (allPtClass) {
      return allPtClass
        .filter(ptClass => !!ptClass.privateClassHash)
        .filter(ptClass => dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute).isBetween(weekMon, weekSun))
    }
  }, [allPtClass, weekMon, weekSun])

  const allBookedGroupClassesInWeek = useMemo(() => {
    if (allBookedGroupClasses) {
      return allBookedGroupClasses.filter(groupClass => dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute).isBetween(weekMon, weekSun))
    }
  }, [allBookedGroupClasses, weekMon, weekSun])

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>      
      <Box sx={{
        display: 'flex',
        direction: 'column',
        justifyContent: 'center',
        marginBottom: 2,
      }}>
        <NavigateTo
          label={t('dashboard.btn.book_a_class')}
          to={`/book`}/>
      </Box>
      
      <Grid item container>
        <Typography variant='h6' color='primary.main'>
          {t('dashboard.coming_schedule')}
        </Typography>
      </Grid>
      
      <Grid
        item container
        justifyContent='space-between'
        alignItems='center'>
        <Grid item>
          <IconButton aria-label='previous-week' color='primary'
            onClick={() => {
              setSelectedWeek(selectedWeek - 1)
            }}>
            <ArrowBackIosIcon/>
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant='body1' color='primary.main'>
            {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label='next-week' color='primary'
            onClick={() => {
              setSelectedWeek(selectedWeek + 1)
            }}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </Grid>
      </Grid>

      <SubtitleGrid>
        <Typography>
          {t('dashboard.subtitle.pt_session')}
        </Typography>
      </SubtitleGrid>

      <Grid item container justifyContent={'center'}>
        {allPtClassInWeek && allPtClassInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('status.confirmed')})}
          </Typography>
        }
        {allPtClassInWeek && allPtClassInWeek.map((ptClass) => (
          <Grid item key={`pt-class-${ptClass.ptClassId}`} xs={12} sm={8}>
            <PtClassBox
              ptClass={ptClass}
              status={ptClass.status} />
          </Grid>
        ))}
      </Grid>

      <SubtitleGrid>
        <Typography>
          {t('dashboard.subtitle.private_class')}
        </Typography>
      </SubtitleGrid>

      <Grid item container justifyContent={'center'}>
        {allPrivateClassInWeek && allPrivateClassInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('status.confirmed')})}
          </Typography>
        }
        {allPrivateClassInWeek && allPrivateClassInWeek.map((ptClass) => (
          <Grid item key={`pt-class-${ptClass.ptClassId}`} xs={12} sm={8}>
            <PtClassBox
              ptClass={ptClass}
              status={ptClass.status} />
          </Grid>
        ))}
      </Grid>

      <SubtitleGrid>
        <Typography>
          {t('dashboard.subtitle.group_class')}
        </Typography>
      </SubtitleGrid>

      <Grid item container justifyContent={'center'}>
        {allBookedGroupClassesInWeek && allBookedGroupClassesInWeek.length === 0 &&
          <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
            sx={{
              marginTop: 1,
              marginBottom: 1,
            }}>
            {t('dashboard.empty', {status: t('status.confirmed')})}
          </Typography>
        }
        {allBookedGroupClassesInWeek && allBookedGroupClassesInWeek.map((groupClass, index) => (
          <Grid item xs={12} key={`group-class-${index}`}>
            <GroupClassBox
              isBooking
              groupClass={groupClass}
              customerEnrolled={groupClass.enrolled}
              customerInfo={customerInfo} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}