import { Container, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

import useCoachPtClassDetails from 'src/hooks/swr/coach/use-coach-pt-class-details';
import PTClassDetails from 'src/components/coach/pt-class/PtClassDetails';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import postCoachConfirmPtClass from 'src/api/coach/post-coach-confirm-pt-class';
import postCoachCancelPtClass from 'src/api/coach/post-coach-cancel-pt-class';
import ButtonOutline from 'src/components/ButtonOutline';
import dayjs from 'dayjs';
import useCoachUnavailablePtTimeslotInSixMonth from 'src/hooks/swr/coach/use-coach-unavailable-pt-timeslot-in-six-month';

import { IRootState } from 'src/store';
import { useSelector } from 'react-redux';

export default function CoachPTClassDetails() {
  const { t } = useTranslation('coach');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({
    title: '',
    dialogText: '',
  })
  // To Refactor
  const coachId = useSelector((state: IRootState) => state.authCoach.coachId);

  let { ptClassId } = useParams();
  const { ptClassDetails, mutate } = useCoachPtClassDetails(parseInt(ptClassId as string));
  const { allClass } = useCoachUnavailablePtTimeslotInSixMonth(coachId);

  // coach cannot confirm PT session if there is any blocking class timeslot from PT / group class
  const allClassDateTime = useMemo(() => {
    if (allClass) {
      let dateTime: any = {};
      allClass.forEach(singleClass => {
        const day = dayjs(singleClass.date).format('YYYY-MM-DD');
        const time = `${singleClass.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${singleClass.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})}`;
        if (dateTime[day] === undefined) {
          dateTime[day] = [time];
        } else {
          (dateTime[day] as string[]).push(time);
        }
      })
      return dateTime
    }
  }, [allClass]);

  const canConfirm = useMemo(() => {
    if (ptClassDetails && allClassDateTime) {
      const classDayjs = dayjs(ptClassDetails.date).hour(ptClassDetails.hour).minute(ptClassDetails.minute);
      const blockingTimeslot: string[] = allClassDateTime[classDayjs.format('YYYY-MM-DD')];
      if (blockingTimeslot) {
        const pendingClassTime = ptClassDetails.hour.toLocaleString(undefined, {minimumIntegerDigits: 2}) + ':' + ptClassDetails.minute.toLocaleString(undefined, {minimumIntegerDigits: 2});
        return !blockingTimeslot.includes(pendingClassTime)
      }
      return true
    }
  }, [ptClassDetails, allClassDateTime])

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
 
  const confirmBooking = async (ptClassId: number) => {
    const res = await postCoachConfirmPtClass(ptClassId);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setDialogInfo({
        title: isPrivateClass ? t('private_class.confirm.title') : t('pt_time.confirm.title'),
        dialogText: isPrivateClass ? t('private_class.confirm.text') : t('pt_time.confirm.text'),
      })
      setDialogOpen(true);
      mutate();
    }
  }

  const cancelBooking = async (ptClassId: number) => {
    const res = await postCoachCancelPtClass(ptClassId);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setDialogInfo({
        title: isPrivateClass ? t('private_class.cancel.title') : t('pt_time.cancel.title'),
        dialogText: isPrivateClass ? t('private_class.cancel.text') : t('pt_time.cancel.text'),
      })
      setDialogOpen(true);
      mutate();
    }
  }

  const isPrivateClass = useMemo(() => {
    if (ptClassDetails) {
      return !!ptClassDetails.privateClassHash
    }
  }, [ptClassDetails])

  const isFutureClass = useMemo(() => {
    if (ptClassDetails) {
      return dayjs().isBefore(dayjs(ptClassDetails.date).hour(ptClassDetails.hour).minute(ptClassDetails.minute))
    }
  }
  , [ptClassDetails])

  return (
    <Container sx={{ padding: 2 }} maxWidth={'sm'}>
      <PTClassDetails isCoach ptClassDetails={ptClassDetails} mutatePtClassDetails={mutate} canConfirm={canConfirm}/>

      {isFutureClass && ptClassDetails && ptClassDetails.status === 'pending' && canConfirm !== undefined && 
        <Grid
          item container
          xs={12}
          spacing={2}
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ marginTop: 1 }}>
          {canConfirm &&
            <Grid item>
              <ButtonPrimary
                status='primary'
                label={isPrivateClass ? t('private_class.btn.confirm') : t('pt_time.btn.confirm')}
                onClick={() => confirmBooking(parseInt(ptClassId as string))} />
            </Grid>
          }
  
          <Grid item>
            <ButtonOutline
              status='primary'
              label={isPrivateClass ? t('private_class.btn.cancel') : t('pt_time.btn.cancel')}
              onClick={() => cancelBooking(parseInt(ptClassId as string))} />
          </Grid>
        </Grid>
      }

      <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogInfo.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('pt_time.btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}