import useSWR from 'swr';
import axios from 'axios';
import { Coach } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string) => axios.get(url).then(res => res.data);

export default function useAllCoach() {
  const { data, mutate, error } = useSWR(`${process.env.REACT_APP_API_SERVER_v1}/coach`, fetcher);
  return {
    allCoach: data as Coach[],
    mutate,
    error
  }
};
