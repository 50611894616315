import React, { useEffect, useState, useMemo } from 'react';
import { Container, Grid, Typography, TextField, NativeSelect, InputLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToLowercase } from 'src/utils/clean-name';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import validator from 'validator';
import { CustomerRegisterFormData } from 'src/api/form-types';
import postCustomerRegister from 'src/api/post-customer-register';
import { loginSuccess } from 'src/redux/auth_customer/actions';
import useLatestNewMemberCredit from 'src/hooks/swr/use-latest-new-member-credit';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { theme } from 'src/styles/muiTheme';

const fieldWidth = 250;

type GenderType = 'male' | 'female' | 'non_binary';
const genderList: GenderType[] = ['male', 'female', 'non_binary'];
const genderValueList = ['male', 'female', 'non-binary'];
type fieldsDateTime = dayjs.Dayjs | null;

type CheckFieldType = 'firstName' | 'lastName' | 'preferredName' | 'phoneNum' | 'password' | 'confirmPassword';
const CheckFieldList: CheckFieldType[] = ['firstName', 'lastName', 'preferredName', 'phoneNum', 'password', 'confirmPassword'];

type CheckTextType = 'first_name' | 'last_name' | 'preferred_name' | 'phone_num' | 'password' | 'confirm_password';
const checkTextList: CheckTextType[] = ['first_name', 'last_name', 'preferred_name', 'phone_num', 'password', 'confirm_password'];

const RegisterTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

export default function Register() {
  const { search } = useLocation();
  const urlQuery = useMemo(() => new URLSearchParams(search), [search]);
  const referralCode = useMemo(() => urlQuery ? urlQuery.get('referral') : undefined, [urlQuery]);

  const { t } = useTranslation('customer');
  const { newMemberCredit } = useLatestNewMemberCredit();
  const { register, handleSubmit, setValue, setError, watch, formState: { errors } } = useForm<CustomerRegisterFormData>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [referralResult, setReferralResult] = useState({customerReferral: 0, coachReferral: 0});
  const [consentState, setConsentState] = useState({
    consentTAndC: false,
    consentHealth: false,
    consentUnder18: false,
  })
  const [consentError, setConsentError] = useState(false);

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsentState({
      ...consentState,
      [event.target.name]: event.target.checked,
    });
    setConsentError(false);
  };

  const { consentTAndC, consentHealth, consentUnder18 } = consentState;

  const handleDialogClose = useMemo(() => (() => navigate('/dashboard')), [navigate])

  const onSubmit = handleSubmit(async (data: CustomerRegisterFormData) => {
    const notConsentAll = [consentTAndC, consentHealth, consentUnder18].filter((v) => v)
      .length < (dayjs().diff(birthday, 'year') < 18  ? 3 : 2);
    if (notConsentAll) {
      setConsentError(true);
      return
    }

    for (let i=0; i<CheckFieldList.length; i++) {
      const checkValue = CheckFieldList[i];
      if (!data[checkValue] || data[checkValue] === '') {
        setError(checkValue, {
          type: 'empty',
          message: t(`register.error.empty`, {
            field: nameToLowercase(t(`register.fields.${checkTextList[i]}`))
          })
        });
        return
      }
    }

    if (!validator.isMobilePhone(data['phoneNum'], ['en-HK'])) {
      setError('phoneNum', {
        type: 'not-hk-mobile',
        message: t('register.error.empty', {
          field: t('register.error.hk_phone')
        })
      });
      return
    }

    if (!data.birthday) {
      setError('birthday', {
        type: 'empty',
        message: t('register.error.empty', {
          field: t('register.fields.birthday')
        })
      });
      return
    }

    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'password_not_same',
        message: t('register.error.password_not_same')
      });
      return
    }

    // existing customer only earn referral upon new customer buying credits
    // new customer cannot only earn referral under coach referral
    // commenting all the commands wrt to existing customer referral in Customer Register
    const res = await postCustomerRegister(data);
    const { authCustomerToken,
      // customerReferral, coachReferral
    } = await res.json();

    if (res.status === 200) {
      // setReferralResult({customerReferral, coachReferral});
      localStorage.setItem('authCustomerToken', authCustomerToken);
      dispatch(loginSuccess(authCustomerToken));
      setDialogOpen(true);
    }
  });

  // To Refactor - for MUI controlled components
  const { ref: refReferralCodeBy, ...inputPropsReferralCodeBy } = register('referralCodeBy');
  const { ref: refFirstName, ...inputPropsFirstName } = register('firstName');
  const { ref: refLastName, ...inputPropsLastName } = register('lastName');
  const { ref: refPreferredName, ...inputPropsPreferredName } = register('preferredName');
  const { ref: refPhoneNum, ...inputPropsPhoneNum } = register('phoneNum');
  // const { ref: refUsername, ...inputPropsUsername } = register('username');
  register('username');
  const { ref: refPassword, ...inputPropsPassword } = register('password');
  const { ref: refConfirmPassword, ...inputPropsConfirmPassword } = register('confirmPassword');
  register('gender');
  register('birthday');

  const selectedPhoneNum = watch('phoneNum');
  const selectedGender = watch('gender');
  // const selectedReferralCodeBy = watch('referralCodeBy')

  const [birthday, setBirthday] = useState<fieldsDateTime>(null);

  // disabled prefilling gender: prefill as 'Non-binary'
  // useEffect(() => {
  //   setValue('gender', genderValueList[2]);
  //   setValue('gender', '');
  // }, [setValue]);

  useEffect(() => {
    setValue('username', selectedPhoneNum);
  }, [setValue, selectedPhoneNum])

  useEffect(() => {
    if (referralCode) {
      setValue('referralCodeBy', referralCode);
    }
  }, [referralCode, setValue])

  return (
    <Container sx={{ paddingTop: 4, paddingBottom: 6 }}>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={1.5}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: 32, color: '#4c4348', textAlign: 'center' }} >
              {t('register.title')}
            </Typography>
          </Grid>
          {newMemberCredit &&
            <>
              <Grid item>
                <Typography color='primary.main' fontWeight={500} fontStyle={'italic'}>
                  {t('register.welcome_credit', {credit: newMemberCredit.credit})}
                </Typography>
              </Grid>
              <Grid item>
                <RegisterTextfield
                  variant='outlined'
                  label={t('register.fields.referral_code_by')}
                  inputProps={{'aria-label': 'referralCodeBy'}}
                  inputRef={refReferralCodeBy}
                  disabled={referralCode ? true : false}
                  error={!!errors.referralCodeBy}
                  helperText={errors.referralCodeBy && errors.referralCodeBy}
                  {...inputPropsReferralCodeBy}
                />
              </Grid>
              <Grid item>
                <Typography color='primary.main' fontWeight={500} fontStyle={'italic'}>
                  {t('register.refer_credit.0')}
                </Typography>
              </Grid>
              {/* <Grid item>
                <Typography color='primary.main' fontWeight={500} fontStyle={'italic'}>
                  {t('register.refer_credit.1', {credit: newMemberCredit.customer_referral})}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color='primary.main' fontWeight={500} fontStyle={'italic'}>
                  {t('register.refer_credit.2', {credit: newMemberCredit.coach_referral})}
                </Typography>
              </Grid> */}
            </>
          }
          <Grid item>
            <RegisterTextfield
              variant='outlined'
              label={t('register.fields.first_name')}
              inputProps={{'aria-label': 'firstName'}}
              inputRef={refFirstName}
              error={!!errors.firstName}
              helperText={errors.firstName && errors.firstName.message}
              {...inputPropsFirstName}
            />
          </Grid>
          <Grid item>
            <RegisterTextfield
              variant='outlined'
              label={t('register.fields.last_name')}
              inputProps={{'aria-label': 'lastName'}}
              inputRef={refLastName}
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              {...inputPropsLastName}
            />
          </Grid>
          <Grid item>
            <RegisterTextfield
              variant='outlined'
              label={t('register.fields.preferred_name')}
              inputProps={{'aria-label': 'preferredName'}}
              inputRef={refPreferredName}
              error={!!errors.preferredName}
              helperText={errors.preferredName && errors.preferredName.message}
              {...inputPropsPreferredName}
            />
          </Grid>
          <Grid item>
            <RegisterTextfield
              variant='outlined'
              label={t('register.fields.phone_num')}
              inputProps={{'aria-label': 'phoneNum'}}
              inputRef={refPhoneNum}
              error={!!errors.phoneNum}
              helperText={errors.phoneNum && errors.phoneNum.message}
              {...inputPropsPhoneNum}
            />
          </Grid>
          <Grid container item
            justifyContent='space-between'
            sx={{ width: 280 }}>
            <Grid item xs={4}>
              <Typography>
                {t('register.fields.username')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography textAlign='right'>
                {selectedPhoneNum ? selectedPhoneNum : t('register.fields.same_as_phone')}
              </Typography>
            </Grid>
            {/* <RegisterTextfield
              variant='outlined'
              label={t('register.fields.username')}
              inputProps={{'aria-label': 'username'}}
              value={selectedPhoneNum}
              inputRef={refUsername}
              error={!!errors.username}
              helperText={errors.username && errors.username.message}
              disabled
              {...inputPropsUsername}
            /> */}
          </Grid>
          <Grid item>
            <RegisterTextfield
              variant='outlined'
              label={t('register.fields.password')}
              inputProps={{'aria-label': 'password'}}
              inputRef={refPassword}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              type='password'
              {...inputPropsPassword}
            />
          </Grid>
          <Grid item>
            <RegisterTextfield
              variant='outlined'
              label={t('register.fields.confirm_password')}
              inputProps={{'aria-label': 'confirm-password'}}
              inputRef={refConfirmPassword}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword && errors.confirmPassword.message}
              type='password'
              {...inputPropsConfirmPassword}
            />
          </Grid>
          <Grid item>
            <Typography>
              {t('register.fields.gender.explanation')}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                {t('register.fields.gender.text')}
              </InputLabel>
              <NativeSelect
                inputProps={{
                  // only for reference only, no particular use
                  name: 'gender', id: 'uncontrolled-native',
                }}
                value={selectedGender}
                onChange={e => {
                  setValue('gender', e.target.value)
                }}
                sx={{ width: fieldWidth }}
                required
              >
                <option value={''} aria-label={`gender-select-default-null`}>
                  {t(`register.fields.gender.text`)}
                </option>
                {genderList.map((gender, index) => (
                  <option value={genderValueList[index]} key={`gender-${gender}`} aria-label={`gender-select-${gender}`}>
                    {t(`register.fields.gender.${gender}`)}
                  </option>
                  ))
                }
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item>
            <MobileDatePicker
              label={t('register.fields.birthday')}
              inputFormat="DD/MM/YYYY"
              value={birthday}
              maxDate={dayjs()}
              onChange={(newDate) => {
                if (!newDate) { return }
                setValue('birthday', newDate?.format('YYYY-MM-DD'));
                setBirthday(newDate);
              }}
              renderInput={(params) =>
                <TextField
                  inputProps={{'aria-label': 'birthday'}}
                  error={!!errors.birthday}
                  helperText={errors.birthday ? errors.birthday.message : 'dd/mm/yyyy'}
                  sx={{
                    width: fieldWidth,
                    '& p': {
                      color: errors.birthday ? 'red' : '',
                    },
                    '& div>fieldset': {
                      borderColor: errors.birthday ? 'red' : ''
                    }
                  }}
                  {...params} />
              }
            />
          </Grid>
          
          <Grid item>
            <FormControl
              required
              error={consentError}
              component="fieldset"
              sx={{
                width: '100%',
                maxWidth: 450,
              }}
              variant="standard">
              <FormLabel component="legend">{t('register.terms.title')}</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={consentTAndC} onChange={handleConsentChange} name='consentTAndC' />
                  }
                  label={
                    <Typography>
                      {t('register.terms.t_and_c.0')}
                      <Link to='/docu/t-and-c.pdf' target='_blank' rel='noopener noreferrer'>
                        {t('register.terms.t_and_c.text')}
                      </Link>
                      {t('register.terms.t_and_c.and')}
                      <Link to='/docu/info.pdf' target='_blank' rel='noopener noreferrer'>
                        {t('register.terms.t_and_c.privacy')}
                      </Link>
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={consentHealth} onChange={handleConsentChange} name='consentHealth' />
                  }
                  label={
                    <Typography>
                      {t('register.terms.health.0')}
                      <Link to='/docu/health-declaration.pdf' target='_blank' rel='noopener noreferrer'>
                        {t('register.terms.health.text')}
                      </Link>
                      {t('register.terms.health.1')}
                    </Typography>
                  }
                />
                {dayjs().diff(birthday, 'year') < 18 &&
                  <FormControlLabel
                  control={
                    <Checkbox checked={consentUnder18} onChange={handleConsentChange} name='consentUnder18' />
                  }
                    label={
                      <Typography>
                        {t('register.terms.under_18.0')}

                        <Link to='/docu/under-18.pdf' target='_blank' rel='noopener noreferrer'>
                          {t('register.terms.under_18.1')}
                        </Link>
                      </Typography>
                    }
                  />
                }
              </FormGroup>
              {consentError && <FormHelperText>{t('register.terms.error_text')}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item>
            <ButtonPrimary
              status='primary'
              type='submit'
              label={t('register.btn.register')}
              aria-label='register-btn'
              sx={{ width: 150 }}
            />
          </Grid>

        </Grid>
      </form>
      {selectedPhoneNum && newMemberCredit &&
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('register.success.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* {t('register.success.text_0', { username: `${selectedPhoneNum} / ${cleanHKPhone(selectedPhoneNum)}`})} */}
              {t('register.success.text_0')}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" fontWeight={'bold'} fontStyle={'italic'} color={theme.palette.primary.main}>
              {/* {referralResult.customerReferral === 0 && referralResult.coachReferral === 0 && selectedReferralCodeBy !== '' &&
                t('register.success.refer_fail')
              } */}
              {t('register.success.welcome_credit', {
                credit: newMemberCredit.credit,
                additional: '',
                  // (referralResult.customerReferral === 0 && referralResult.coachReferral === 0) ?
                  // ''
                  // :
                  // t('register.success.refer_credit', {
                  //   credit: referralResult.customerReferral + referralResult.coachReferral,
                  //   role:referralResult.customerReferral === 0 ? t('register.success.coach') : t('register.success.friend'),
                  // })
              })}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {/* {t('register.success.text_1', { username: `${selectedPhoneNum} / ${cleanHKPhone(selectedPhoneNum)}`})} */}
              {t('register.success.text_1', { username: `${selectedPhoneNum}`})}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>
              {t('register.success.go_to_dashboard')}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </Container>
  )
}
// color
// border-color
// #FA0D0D