import { Button, Container, Dialog, DialogActions, DialogContent, Grid, GridProps, styled, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import putAdminEditCreditPackage from 'src/api/coach/admin/put-admin-edit-credit-package';
import { EditPackage } from 'src/api/form-types';
import PromotionCodeViewEdit from 'src/components/admin/PromotionCodeViewEdit';
import ButtonPrimary from 'src/components/ButtonPrimary';
import useCoachAllCreditPackage from 'src/hooks/swr/coach/use-coach-all-credit-package';
import useCoachSingleCoachInfo from 'src/hooks/swr/use-coach-single-coach-info';
import { IRootState } from 'src/store';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '80vw',
  maxWidth: 600,
  height: 1,
  marginTop: 10,
}));

const initialDialogState = {
  open: false,
  message: '',
};

export default function Membership() {
  const coachId = useSelector((state: IRootState) => state.authCoach.coachId);
  const { coachInfo } = useCoachSingleCoachInfo(coachId, true);
  const isAdminDirector = coachInfo && (coachInfo.access_right === 'admin' || coachInfo.access_right === 'director');

  const { t } = useTranslation('coach');
  const{ allCreditPackage, mutate } = useCoachAllCreditPackage();

  const [dialog, setDialog] = useState(initialDialogState);

  const { register, handleSubmit, setValue, control } = useForm<EditPackage>();

  register('creditPackageId');
  register('credit');
  register('charge');

  const closeEditDialog = () => {
    setDialog(initialDialogState)
  };
  
  const onSubmit = handleSubmit(async (data: EditPackage) => {
    // credit charge is in cent
    data['charge'] = (parseInt(data['charge']) * 100).toString();
    
    const res = await putAdminEditCreditPackage(data);
    const { successful } = await res.json();
    
    let msg: string;
    if (successful && res.status === 200) {
      msg = t('membership.dialog.successful')
    } else {
      msg = t('membership.dialog.unsuccessful')
    }
    
    setDialog({
      open: true,
      message: msg,
    });
    mutate();

    return
  });

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
      <Grid
        item container
        xs={12}
        spacing={1}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('top_bar.admin.membership')}
          </Typography>
        </Grid>
        
        {allCreditPackage && allCreditPackage.map((creditPackage) => (
          <TextGrid key={creditPackage.name}>
            <Grid item xs={isAdminDirector ? 3 : 6}>
              <Typography variant='body1' color='primary.main' textAlign='right'>
                {`$ ${(creditPackage.price / 100).toLocaleString()}`}
              </Typography>
            </Grid>
            <Grid item xs={isAdminDirector ? 5 : 6}>
              <Typography variant='body1' color='primary.main'
                textAlign={'right'}
                >
                {t('membership.credit', { number: creditPackage.credit_number})}
              </Typography>
            </Grid>
            {isAdminDirector &&
              <Grid container item xs={4} justifyContent='flex-end'>
                <ButtonPrimary
                  status='secondary'
                  label={t('membership.btn.change')}
                  onClick={() => {
                    setValue('creditPackageId', creditPackage.id);
                    setValue('charge', (creditPackage.price / 100).toString());
                    setValue('credit', creditPackage.credit_number.toString());
                  }} />
              </Grid>
            }
          </TextGrid>
        ))}

        <Grid item>
          <Separator />
        </Grid>

        <Grid item>
          <form onSubmit={onSubmit}>
            <Grid container
              spacing={2}
              justifyContent='flex-start'
              alignItems='flex-start'>
              <Grid item container
                direction='row'
                spacing={2}
                justifyContent='center'
                alignItems='flex-start'>
                <Grid item>
                  <Controller
                    name='charge'
                    control={control}
                    defaultValue=''
                    rules={{ required: true }}
                    render={({ field }) =>
                      <TextField
                        variant='standard'
                        label={t('membership.fields.charge')}
                        required
                        inputProps={{ 'aria-label': 'med-category' }}
                        {...field} />
                      }
                    />
                </Grid>
                <Grid item>
                  <Controller
                    name='credit'
                    control={control}
                    defaultValue=''
                    rules={{ required: true }}
                    render={({ field }) =>
                      <TextField
                        variant='standard'
                        label={t('membership.fields.credit')}
                        required
                        inputProps={{ 'aria-label': 'med-category' }}
                        {...field} />
                      }
                    />
                </Grid>
              </Grid>
              <Grid item container
                direction='row'
                spacing={2}
                justifyContent='center'
                alignItems='flex-start'>
                <Grid item>
                  <ButtonPrimary
                    type='submit'
                    status='primary'
                    label={t('membership.btn.edit_package')}
                    sx={{ width: 150 }} />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item>
          <Separator />
        </Grid>
      </Grid>

      <PromotionCodeViewEdit />

      <Dialog
        open={dialog.open}
        onClose={closeEditDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogContent>
          <Typography>
            {dialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditDialog} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}