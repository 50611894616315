import useSWR from 'swr';
import axios from 'axios';

const fetcher = (url: string, token: string) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => res.data);

export default function useCoachAllPtDateTimeAvailablity() {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(
    `${process.env.REACT_APP_API_SERVER_v1}/coach/pt-timeslot/date-time`,
    (url: string) => fetcher(url, token)
  );
  return {
    allDateTimeAvailablity: data as any,
    mutate,
    error
  }
};
