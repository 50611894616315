import { Container, Grid, Typography, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import NavigateBack from 'src/components/navigate/NavigateBack';
import postCustomerChangePassword from 'src/api/customer/post-customer-change-password';
import { ChangePasswordFormData } from 'src/api/form-types';

export default function ChangePassword() {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();

  const { register, handleSubmit, control, setError, formState: { errors } } = useForm<ChangePasswordFormData>();

  register('newPassword');
  register('confirmPassword');

  
  const onSubmit = handleSubmit(async (data: ChangePasswordFormData) => {
    if (data.newPassword !== data.confirmPassword) {
      setError('confirmPassword', { type: 'empty', message: t('change_password.error.need_same_password')});
      return
    }
    const res = await postCustomerChangePassword(data);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      window.alert(t('change_password.result.reset_success'));
      navigate('/profile');
    } else { 
      window.alert(t('change_password.result.reset_fail'));
    }
  });

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 6 }}>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={3}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <NavigateBack />
          </Grid>
          <Grid item>
            <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: 32, color: '#4c4348', textAlign: 'center' }} >
              {t('change_password.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Controller
              name='newPassword'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
                <TextField
                  variant='outlined'
                  type='password'
                  label={t('change_password.new_password')}
                  required
                  inputProps={{ 'aria-label': 'new-password' }}
                  {...field} />
                }
              />
          </Grid>
          <Grid item>
            <Controller
              name='confirmPassword'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
                <TextField
                  variant='outlined'
                  type='password'
                  label={t('change_password.confirm_password')}
                  required
                  inputProps={{ 'aria-label': 'confirm-password' }}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword && errors.confirmPassword.message}
                  {...field} />
                }
              />
          </Grid>
          <Grid item>
            <ButtonPrimary
              status='primary'
              type='submit'
              label={t('change_password.change_password')}
              aria-label='change-password-btn'
              sx={{ width: 150 }}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}