export default function postCustomerNewGroupClass(groupClassId: number) {
  return fetch(`${global.API_v1}/customer/group-class/book`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({groupClassId}),
  });
}
