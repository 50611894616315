import { CoachTypeCredit, CreditUpdateType } from 'src/api/form-types';

export default function postCoachType(data: CoachTypeCredit[], updateType: CreditUpdateType) {
  return fetch(`${global.API_v1}/director/coach-type/`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({data, updateType}),
  });
}
