import { Grid, styled, Typography } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';
import useAllCoach from 'src/hooks/swr/public/use-all-coach';
import { nameToCamelcase } from 'src/utils/clean-name';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';
import { useMemo } from 'react';
import { coachOrder } from 'src/pages/customer/coach-overview/CoachOverview';

const BookAClass = styled(Grid)(({ theme }) => ({
  width: '70vw',
  maxWidth: 500,
  height: 87,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: 10,
  cursor: 'pointer',
}));

const CoachIcon = styled('img')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: 50,
  // TODO - fix white line between border & pic
  border: `1px solid ${theme.palette.primary.main}`,
}));

interface PTCoachProps {
  isStar?: boolean,
  isPrivateClass?: boolean,
}

export default function PTCoach({
  isStar = false,
  isPrivateClass = false,
}: PTCoachProps) {
  // const { t } = useTranslation('customer');
  const { allCoach } = useAllCoach();

  const sortedCoach = useMemo(() => {
    if (allCoach) {
      return allCoach
        .filter(coach => {
          // all coach should be in the private class, regardless of their coach type
          if (isPrivateClass) {
            return true
          }
          const isSenior = coach.type === 'senior' || coach.type === 'director';
          return isStar ? isSenior : !isSenior
        })
        .map(coach => {
          coach.order = coachOrder.indexOf(coach.preferred_name)
          return coach
        })
        .sort((a, b) => (a.order as number) - (b.order as number))
    }
  }, [allCoach, isStar, isPrivateClass]);

  return (
    <Grid item container spacing={2}>
      {/* <Grid item container>
        <Typography variant='h6' color='primary.main'>
          {t('book.title.pt_session')}
        </Typography>
      </Grid> */}

      <Grid item container justifyContent='center' spacing={1}>
        {sortedCoach && sortedCoach.map(coach => (
          <Grid item key={`coach-${coach.id}-pt`}>
            <LinkWithoutStyle to={`/book/pt/${coach.id}${isPrivateClass ? '?type=private-class': ''}`}>
              <BookAClass item container
                justifyContent='space-between'
                alignItems='center'>
                <Grid item container
                  justifyContent='space-between'
                  alignItems='center' xs={3}>
                  <CoachIcon
                    src={`${AWS_S3_BUCKET}/${coach.icon_path}`}
                    alt='icon for coach' />
                </Grid>
                <Grid item xs={7}>
                  <Typography color='primary.main' fontWeight={'bold'}>
                    {nameToCamelcase(coach.preferred_name + ' ' + coach.last_name)}
                  </Typography>
                </Grid>
                <Grid item container xs={2}
                  alignItems='center'
                  justifyContent='flex-end'>
                  <ChevronsRight/>
                </Grid>
              </BookAClass>
            </LinkWithoutStyle>
          </Grid>
        ))}

        {/* no need to hide PT coach box yet */}
        {/* <Grid item>
          <ButtonPrimary
            status='secondary'
            label={t('book.btn.more')} />
        </Grid> */}
      </Grid>

    </Grid>
  )
}