import { MakeupBookPtClass } from 'src/api/form-types';

export default function postDirectorMakeupBookPtClass(data: MakeupBookPtClass) {
  return fetch(`${global.API_v1}/director/pt-class/makeup-book`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
