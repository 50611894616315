import { useState } from 'react';
import { Collapse, Container, Grid, GridProps, styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigateTo from 'src/components/navigate/NavigateTo';
import useAdminCoachCustomerReferral from 'src/hooks/swr/coach/admin/use-admin-coach-customer-referral';
import SessionTitleCollapsable from 'src/components/customer/SessionTitleCollapsable';
import React from 'react';
import stripeHKPhone from 'src/utils/stripe-hk-phone';
import { nameToCamelcase } from 'src/utils/clean-name';
import adminCleanPhone from 'src/utils/admin-clean-phone';
import CustomerLink from 'src/components/coach/customer-management/CustomerLink';
import NavigateBack from 'src/components/navigate/NavigateBack';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.displayGrey.main,
  width: '70vw',
  maxWidth: 500,
  height: 1,
}));

interface ViewCoachStaticticsProps {
  isAdmin?: boolean,
}

export default function ViewCoachStatictics ({
  isAdmin = false,
}: ViewCoachStaticticsProps) {
  let { coachId } = useParams();
  const { t } = useTranslation('coach');

  const { allReferredCustomer } = useAdminCoachCustomerReferral(parseInt(coachId as string));

  const [referralVisible, setReferralVisible] = useState(false);

  return (
    <Container sx={{ padding: 2 }}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Typography variant='h6' color='primary.main' textAlign='center'
            sx={{ marginBottom: 1.5 }}>
            {t('coach_stat.title')}
          </Typography>
        </Grid>

        {/* only director can edit coach information */}
        {!isAdmin &&
          <Grid item>
            <NavigateTo
              to={`/coach/director/coach-management/coach/${coachId}/edit`}
              label={t('coach_stat.edit')}/>
          </Grid>
        }

        <Grid item>
          <Typography variant='body1' color='primary.main' textAlign='center'
            sx={{ marginBottom: 1.5 }}>
            to be confirmed, what else coach information will be required?
          </Typography>
        </Grid>
        
        <SessionTitleCollapsable
          state={referralVisible}
          setState={setReferralVisible}
          label={t('customer.referral.title')} />

        {allReferredCustomer &&
          <Grid item>
            <Collapse in={referralVisible} timeout={'auto'}>
              <Grid
                item container
                xs={12}
                spacing={1}
                direction='column'
                justifyContent='center'
                alignItems='center'>
                {allReferredCustomer.length === 0 &&
                  <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
                    sx={{
                      marginTop: 1,
                      marginBottom: 1,
                    }}>
                    {t('customer.referral.empty', {status: ''})}
                  </Typography>
                }
                {allReferredCustomer.length !== 0 &&
                  <React.Fragment>
                    <TextGrid>
                      <Grid item>
                        <Typography variant='body1' color='primary.main'>
                          {t('customer.referral.count')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body1' color='primary.main' fontWeight='bold' fontStyle='italic'>
                          {allReferredCustomer.length}
                        </Typography>
                      </Grid>
                    </TextGrid>
                    {allReferredCustomer.map((customer) => (
                      <Grid item key={customer.id}>
                        <CustomerLink
                          to={`/coach/customer/${stripeHKPhone(customer.phone_number)}`}
                          name={nameToCamelcase(customer.preferred_name + ' ' + customer.last_name)}
                          phoneNumber={adminCleanPhone(customer.phone_number)} />
                      </Grid>
                    ))}
                  </React.Fragment>
                }
                <Grid item>
                  <Separator />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        }

        <Grid item>
          <NavigateBack />
        </Grid>
      </Grid>
    </Container>
  )
}