export default function postCoachConfirmPtClass(ptClassId: number) {
  return fetch(`${global.API_v1}/coach/pt-class/confirm`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ptClassId}),
  });
}
