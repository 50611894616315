import { Container, Grid, GridProps, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAllCourseCharge from 'src/hooks/swr/use-customer-all-course-charge';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '70vw',
  maxWidth: 500,
  height: 2,
}));

export default function CourseCharge() {
  const { t } = useTranslation('customer');
  const { allCoachTypeCharge } = useAllCourseCharge(); 

  const { juniorCharge, normalCharge, seniorCharge } = useMemo(() => {
    if (allCoachTypeCharge) {
      const juniorCharge = allCoachTypeCharge.filter(charge => charge.type === 'junior')[0];
      const normalCharge = allCoachTypeCharge.filter(charge => charge.type === 'normal')[0];
      const seniorCharge = allCoachTypeCharge.filter(charge => charge.type === 'senior')[0];
      return { juniorCharge, normalCharge, seniorCharge }
    } else {
      return { undefined }
    }
  }, [allCoachTypeCharge]);

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>

      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
      {/* To Refactor, hardcoded charge list */}
        <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('membership.charge_list')}
          </Typography>
        </Grid>

        <Grid item>
          <Separator />
        </Grid>

        <TextGrid>
          <Grid item>
            <Typography variant='body2' color='primary.main'>
              {t('membership.course.text.mins', { min: 50 })}
            </Typography>
          </Grid>
        </TextGrid>

        {juniorCharge && normalCharge && seniorCharge &&
          <React.Fragment>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('membership.course.regular')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary.main' textAlign={'right'}>
                  {t('membership.credit', { number: juniorCharge.group_class_charge })}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('membership.course.star_regular')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary.main' textAlign={'right'}>
                  {t('membership.credit', { number: seniorCharge.star_group_class_charge })}
                </Typography>
              </Grid>
            </TextGrid>

            <Grid item>
              <Separator />
            </Grid>

            <TextGrid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {t('membership.course.text.mins', { min: 60 })}
                </Typography>
              </Grid>
            </TextGrid>
            
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('membership.course.special')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary.main' textAlign={'right'}>
                  {t('membership.credit', { number: juniorCharge.sepcial_group_class_charge })}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('membership.course.private')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary.main' textAlign={'right'}>
                  {t('membership.credit', { number: juniorCharge.pt_credit_charge })}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('membership.course.private_group')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary.main' textAlign={'right'}>
                  {t('membership.credit', { number: juniorCharge.private_class_charge })}
                </Typography>
              </Grid>
            </TextGrid>

            {/* to do - English text will drop credit number to next line */}
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('membership.course.star_private')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body1' color='primary.main' textAlign={'right'}>
                  {t('membership.credit', { number: seniorCharge.pt_credit_charge })}
                </Typography>
              </Grid>
            </TextGrid>
            {/* To Refactor, hardcoded charge list */}
          </React.Fragment>
        }
      </Grid>
    </Container>
  )
}