import { Container, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useCustomerPtClassDetails from 'src/hooks/swr/customer/use-customer-pt-class-details';
import PTClassDetails from 'src/components/coach/pt-class/PtClassDetails';
import { useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useCustomerIsVip from 'src/hooks/swr/customer/use-customer-is-vip';
import ButtonOutline from 'src/components/ButtonOutline';
import putCustomerCancelPtClass from 'src/api/customer/put-customer-cancel-pt-class';

export default function CustomerPTClassDetails() {
  const { t } = useTranslation('customer');
  const [dialogOpen, setDialogOpen] = useState({
    open: false,
    title: '',
    text: '',
  });

  let { ptClassId } = useParams();
  const { ptClassDetails, mutate } = useCustomerPtClassDetails(parseInt(ptClassId as string));
  const { isVip } = useCustomerIsVip();

  const handleDialogClose = () => {
    setDialogOpen({
      ...dialogOpen,
      open: false,
    });
  };

  const cancelBooking = async (ptClassId: number) => {
    const res = await putCustomerCancelPtClass(ptClassId);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setDialogOpen({
        open: true,
        title: isPrivateClass ? t('private_class.cancel.title') : t('pt_class.cancel.title'),
        text: isPrivateClass ? t('private_class.cancel.text') : t('pt_class.cancel.text'),
      });
      mutate();
    }
  }

  const classDayjsTime = useMemo(() => {
    if (ptClassDetails) {
      return dayjs(ptClassDetails.date).hour(ptClassDetails.hour).minute(ptClassDetails.minute)
    }
  }, [ptClassDetails]);

  const isPrivateClass = useMemo(() => {
    if (ptClassDetails) {
      return !!ptClassDetails.privateClassHash
    }
  }, [ptClassDetails])

  // can only attend future 2 hour PT session
  // TODO - display QR code

  const canCancel = useMemo(() => {
    if (ptClassDetails) {
      return dayjs().isBefore(classDayjsTime) && ptClassDetails.status === 'confirmed'
    }
  }, [classDayjsTime, ptClassDetails])
 
  return (
    <Container sx={{ padding: 2 }} maxWidth={'sm'}>
      <PTClassDetails ptClassDetails={ptClassDetails}/>

      {canCancel && isVip &&
        <Grid
          item container
          xs={12}
          spacing={2}
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ marginTop: 1 }}>
          <Grid item>
            <Typography color='disableGrey.main'>
              {isPrivateClass ?
                t('private_class.refund.reminder', { hour: isVip.is_vip ? 5 : 24 })
              :
                t('pt_class.refund.reminder', { hour: isVip.is_vip ? 5 : 24 })
              }
            </Typography>
          </Grid>
          <Grid item>
            <ButtonOutline
              status='primary'
              label={isPrivateClass ? t('private_class.btn.cancel') : t('pt_class.btn.cancel')}
              onClick={() => cancelBooking(parseInt(ptClassId as string))} />
          </Grid>
        </Grid>
      }

      <Dialog
        open={dialogOpen.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogOpen.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogOpen.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}