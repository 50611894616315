import {
  Container, Grid, Typography, IconButton, styled, Tab,
  SvgIcon,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GroupClassBox from 'src/components/group-class/GroupClassBox';
import useAllCoachAllGroupClass from 'src/hooks/swr/coach/use-all-coach-all-group-class';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateTo from 'src/components/navigate/NavigateTo';

import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import { ReactComponent as DiamondSVG } from 'src/icons/book/diamond-google-font.svg';
import { ReactComponent as DoubleStarSVG } from 'src/icons/book/double-start-google-font.svg';
import { ReactComponent as AlphabetSSVG } from 'src/icons/book/alphabet-s.svg';
import StarIcon from '@mui/icons-material/Star';
import useAdminAllPtClass from 'src/hooks/swr/coach/admin/use-admin-all-pt-class';
import AdminPtClassBox from 'src/components/admin/AdminPtClassBox';

// tab setting from customer/book
type ClassType = 'regular' | 'star-regular' | 'special-regular' | '1-to-1' | 'star-1-to-1' | 'private-class';

const TabCustomized = styled(Tab)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 4,
  paddingRight: 4,
}));
// end of tab setting from customer/book

export default function AdminAllClassManagement() {
  const { t } = useTranslation('admin');
  const { t: t_cus } = useTranslation('customer');

  const { allGroupClasses } = useAllCoachAllGroupClass();
  const { allPtClasses } = useAdminAllPtClass();

  const [selectedWeek, setSelectedWeek] = useState(0);
  useEffect(() => {
    const dashboardWeek = localStorage.getItem('adminDashboardWeek');
    if (dashboardWeek) {
      setSelectedWeek(parseInt(dashboardWeek));
    };
  }, [])

  const isSun = useMemo(() => dayjs().day() === 0, []);
  const weekMon = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(1).hour(0).minute(0).second(1)
  , [selectedWeek, isSun]);
  
  const weekSun = useMemo(() =>
    dayjs().add((isSun ? -1 : 0) + selectedWeek, 'week').day(7).hour(23).minute(59).second(59)
  , [selectedWeek, isSun]);
  
  // tab setting from customer/book
  const [classType, setClassType] = useState<ClassType>('regular');

  const handleChangeClassType = (event: React.SyntheticEvent, newValue: ClassType) => {
    setClassType(newValue);
    localStorage.setItem('adminDashboardClassType', newValue);
  };
  useEffect(() => {
    const dashboardClassType = localStorage.getItem('adminDashboardClassType') as ClassType;
    if (dashboardClassType) {
      setClassType(dashboardClassType);
    };
  }, [])

  const up600 = useMediaQuery('(min-width: 600px)');
  // end of tab setting from customer/book

  const isGroupClass = useMemo(() => {
    return classType.match(`regular`) !== null
  }, [classType])

  const allGroupClassesInWeekType = useMemo(() => {
    if (allGroupClasses) {
      return allGroupClasses
        .filter(groupClass => dayjs(groupClass.date).hour(groupClass.hour).minute(groupClass.minute).isBetween(weekMon, weekSun))
        .filter(groupClass => {
          if (classType === 'star-regular') {
            return groupClass.isStar
          } else if (classType === 'special-regular') {
            return groupClass.isSelected
          } else if (classType === 'regular') {
            return (!groupClass.isStar && !groupClass.isSelected)
          }
          // for other type of class other than regular classes
          // '1-to-1' | 'star-1-to-1' | 'private-class'
          return false
        })
    }
  }, [allGroupClasses, weekMon, weekSun, classType]);

  const allPtClassesInWeekType = useMemo(() => {
    if (allPtClasses) {
      return allPtClasses
        .filter(ptClass => dayjs(ptClass.date).hour(ptClass.hour).minute(ptClass.minute).isBetween(weekMon, weekSun))
        .filter(ptClass => {
          // '1-to-1' | 'star-1-to-1' | 'private-class'
          if (classType === '1-to-1') {
            return ptClass.privateClassHash === null && (ptClass.coachType !== 'senior' && ptClass.coachType !== 'director')
          } else if (classType === 'star-1-to-1') {
            return ptClass.privateClassHash === null && (ptClass.coachType === 'senior' || ptClass.coachType === 'director')
          } else if (classType === 'private-class') {
            return ptClass.privateClassHash !== null
          }
          return false
        })
    }
  }, [allPtClasses, weekMon, weekSun, classType]);

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
      
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('all_class.title')}
          </Typography>
        </Grid>

        {/* from customer/book, to refactor into component */}
        <Grid item sx={{ padding: '0px !important', width: '100%' }}>
          <Tabs
            value={classType}
            onChange={handleChangeClassType}
            variant={up600 ? 'standard' : 'scrollable'}
            centered={up600}
            scrollButtons
            allowScrollButtonsMobile
            aria-label='icon label tabs for different class type'>
            <TabCustomized icon={<FitnessCenterIcon />} value={'regular'} label={t_cus('book.class_type.regular')} />
            <TabCustomized icon={<StarIcon />} value={'star-regular'}  label={t_cus('book.class_type.star-regular')} />
            <TabCustomized icon={<SvgIcon><AlphabetSSVG /></SvgIcon>} value={'special-regular'} label={t_cus('book.class_type.special-regular')} />
            <TabCustomized icon={<SportsKabaddiIcon />} value={'1-to-1'} label={t_cus('book.class_type.1-to-1')} />
            <TabCustomized icon={<SvgIcon><DoubleStarSVG /></SvgIcon>} value={'star-1-to-1'} label={t_cus('book.class_type.star-1-to-1')} />
            <TabCustomized icon={<SvgIcon><DiamondSVG /></SvgIcon>} value={'private-class'} label={t_cus('book.class_type.private_class')} />
          </Tabs>
        </Grid>

        <Grid
          item container
          justifyContent='space-between'
          alignItems='center'>
          <Grid item>
            <IconButton aria-label='previous-week' color='primary'
              onClick={() => {
                setSelectedWeek(selectedWeek - 1);
                localStorage.setItem('adminDashboardWeek', (selectedWeek - 1).toString());
              }}>
              <ArrowBackIosIcon/>
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant='body1' color='primary.main'>
              {weekMon.format('D MMM') + ' - ' + weekSun.format('D MMM')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label='next-week' color='primary'
              onClick={() => {
                setSelectedWeek(selectedWeek + 1);
                localStorage.setItem('adminDashboardWeek', (selectedWeek + 1).toString());
              }}>
              <ArrowForwardIosIcon/>
            </IconButton>
          </Grid>
        </Grid>

        {isGroupClass &&
          <Grid item>
            <NavigateTo
              to={'/coach/admin/group-class/create'}
              label={t('group_class.btn.create')}/>
          </Grid>
        }

        {!isGroupClass &&
          <Grid item>
            <NavigateTo
              to={`/coach/director/pt-class/makeup?type=${classType}`}
              label={t('pt_class.make_up.btn.makeup')}/>
          </Grid>
        }

        <Grid item container justifyContent={'center'}>
          {isGroupClass && allGroupClassesInWeekType && (
            allGroupClassesInWeekType.length === 0 &&
              <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                }}>
                {t('group_class.empty', {status: t('status.confirmed')})}
              </Typography>
            )
          }
          {isGroupClass && allGroupClassesInWeekType && (
            allGroupClassesInWeekType.length !== 0 && allGroupClassesInWeekType.map((groupClass, index) => (
              <Grid item xs={12} sm={8} key={`group-class-${index}`}>
                <GroupClassBox
                  isCoach
                  isAdmin
                  groupClass={groupClass} />
              </Grid>
            ))
            )
          }

          {!isGroupClass && allPtClassesInWeekType && allPtClassesInWeekType.length === 0 &&
            <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
              sx={{
                marginTop: 1,
                marginBottom: 1,
              }}>
              {t('all_class.pt_class.empty')}
            </Typography>
          }
          {!isGroupClass && allPtClassesInWeekType && allPtClassesInWeekType.map((ptClass) => (
            <Grid item key={`pt-class-${ptClass.ptClassId}`} xs={12} sm={8}>
              <AdminPtClassBox
                ptClass={ptClass}
                status={ptClass.status} />
            </Grid>
          ))}
        </Grid>
        
      </Grid>
    </Container>
  )
}