export function loginSuccess(token: string) {
  return {
    type: 'LOGIN_COACH' as 'LOGIN_COACH',
    token: token,
  }
}

export function logoutSuccess() {
  return {
    type: 'LOGOUT_COACH' as 'LOGOUT_COACH'
  }
}

type FAILED = 'LOGIN_COACH_FAILED';

type FAILED_ACTION = {
  type: FAILED,
  msg: string,
}

export function failed(type: FAILED, msg: string) {
  return {
    type,
    msg
  }
}

type AuthCoachActionCreators = typeof loginSuccess | typeof logoutSuccess;

export type IAuthCoachActions = ReturnType<AuthCoachActionCreators> | FAILED_ACTION;
