import { useEffect, useMemo, useState } from 'react';
import { Typography, Grid, styled, TextField, Chip, RadioGroup, Radio, InputLabel, NativeSelect, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { CoachType, CreateGroupClassFormData, GenderRestrict } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import useCoachUnavailableGroupTimeslotInSixMonth from 'src/hooks/swr/coach/use-coach-unavailable-group-timeslot-in-six-month';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useCoachAllTimeslot from 'src/hooks/swr/coach/use-coach-all-timeslot';
import { Timeslot } from 'src/hooks/swr/output-types';
import useAdminAllCoach from 'src/hooks/swr/coach/admin/use-admin-all-coach';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const fieldWidth = 250;

const RegisterTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

const CustomPickersDay = styled((props: PickersDayProps<Dayjs>) => (
  <PickersDay
    {...props}/>
), {
  shouldForwardProp: (prop) => prop !== "selected"
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
}));

type fieldsDateTime = Dayjs | null;

type SelectedTimeslot = {
  id: number,
  time: string,
}

interface GroupClassFormProps {
  submitAction: (data: CreateGroupClassFormData) => void,
}

export default function GroupClassForm ({
  submitAction,
}: GroupClassFormProps) {
  const { t } = useTranslation('coach');

  const { allTimeslot } = useCoachAllTimeslot();
  const { allClass } = useCoachUnavailableGroupTimeslotInSixMonth();
  const { allCoach } = useAdminAllCoach();

  // const allClassDate = useMemo(() => {
  //   if (allClass) {
  //     return allClass.map(singleClass => dayjs(singleClass.date).format('YYYY-MM-DD'))
  //   }
  // }, [allClass]);

  const allClassDateTime = useMemo(() => {
    if (allClass) {
      let dateTime: any = {};
      allClass.forEach(singleClass => {
        const day = dayjs(singleClass.date).format('YYYY-MM-DD');
        const time = `${singleClass.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${singleClass.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})}`;
        if (dateTime[day] === undefined) {
          dateTime[day] = [time];
        } else {
          (dateTime[day] as string[]).push(time);
        }
      })
      return dateTime
    }
  }, [allClass]);

  const [dummyDate, setDummyDate] = useState<fieldsDateTime>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs[]>([]);
  const [selectedDateFormatted, setSelectedDateFormatted] = useState<string[]>([]);
  // record both timeslot id & plain time text, e.g. 23:59, 08:00
  const [selectedTimeslot, setSelectedTimeslot] = useState<SelectedTimeslot>();
  const [gender, setGender] = useState<GenderRestrict>('all');
  const [isStar, setIsStar] = useState(false);
  const [isSelected, setIsPackage] = useState(false);
  const [selectedCoachType, setSelectedCoachType] = useState<CoachType>('normal');

  const [errorDialog, setErrorDialog] = useState({
    open: false,
    message: '',
  });

  const handleDeleteDate = (index: number) => {
    if (selectedDate) {
      const newDateList = selectedDate.filter((_, idx) => idx !== index);
      setSelectedDate(newDateList);
      // set dummy date to null for users to immediate select back the last selected but cancelled date
      setDummyDate(null);
    }
  };

  const handleChipClick = (timeslot: Timeslot) => {
    setSelectedTimeslot({
      id: timeslot.id,
      time: timeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
        + ':' + timeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2}),
    });
    setValue('timeslotId', timeslot.id);
    if (errors.timeslotId) {
      clearErrors('timeslotId');
    }
  }


  const { register, handleSubmit, setValue, resetField, setError, clearErrors, watch, control, formState: { errors } } = useForm<CreateGroupClassFormData>();

  register('name');
  register('description');
  register('coachId');
  register('date');
  register('timeslotId');
  register('isStar');
  register('isSelected');
  register('genderRestrict');

  const selectedCoachId = watch('coachId');
  
  useEffect(() => {
    if (!allCoach || !selectedCoachId) { return }
    const coachType = allCoach.filter(coach => coach.id === selectedCoachId)[0].type;
    setSelectedCoachType(coachType);
    if (coachType !== 'senior' && coachType !== 'director') {
      setIsStar(false);
    }
  }, [allCoach, selectedCoachId]) 


  const onSubmit = handleSubmit(async (data: CreateGroupClassFormData) => {
    if (!data.coachId || data.coachId === 0) {
      setError('coachId', {
        type: 'empty',
        message: t('group_class.error.empty', {
          field: t('group_class.fields.coach')
        })
      });
      return
    }
    if (selectedDate.length === 0) {
      setError('date', {
        type: 'empty',
        message: t('group_class.error.empty', {
          field: t('group_class.fields.date')
        })
      });
      return
    }
    if (!data.timeslotId) {
      setError('timeslotId', {
        type: 'empty',
        message: t('group_class.error.empty', {
          field: t('group_class.fields.time')
        })
      });
      return
    }
    const allDate = selectedDate.reduce((preValue, nowValue) => preValue + `,${nowValue.format('YYYY-MM-DD')}`, '');
    data.date = allDate;
    submitAction(data);
  });
  
  useEffect(() => {
    setSelectedDateFormatted(selectedDate.map(date => date.format('YYYY-MM-DD')));
  }, [selectedDate]);

  const renderPickerDay = (
    day: dayjs.Dayjs,
    selectedDays: dayjs.Dayjs[],
    pickersDayProps: PickersDayProps<dayjs.Dayjs>) => {

    let selected = false;

    if (selectedDateFormatted.includes(day.format('YYYY-MM-DD'))) {
      selected = true;
    }

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        selected={selected} />
    )
  };
  
  const [blockingTimeslot, setBlockingTimeslot] = useState<string[]>([]);
  useEffect(() => {
    if (selectedDate.length !== 0 && allClassDateTime) {
      let timeslotList: string[] = [];
      for (const date of selectedDate) {
        const currentDateTime = allClassDateTime[date.format('YYYY-MM-DD')];
        if (currentDateTime !== undefined) {
          timeslotList = timeslotList.concat(currentDateTime);
        }
      }
      setBlockingTimeslot(timeslotList);
    } else {
      // when last date is cleared out, blocking timeslot should be set to none & empty list
      setBlockingTimeslot([]);
    }
  }, [selectedDate, allClassDateTime])

  // if there is a timeslot selected, & new seleted date includes the existing timeslot
  // reset both form value & frontend chip selection to null
  useEffect(() => {
    if (selectedTimeslot && blockingTimeslot.includes(selectedTimeslot.time)) {
      setSelectedTimeslot(undefined);
      resetField('timeslotId');
    }
  }, [selectedTimeslot, blockingTimeslot, resetField])

  // on missing empty input, show reminder dialog
  useEffect(() => {
    const errorNum = Object.keys(errors).length;
    if (errorNum !== 0) {
      setErrorDialog({
        open: true,
        message: t('group_class.dialog.missing_fields'),
      });
    }
  }, [errors, t]);

  const closeErrorDialog = () => {
    setErrorDialog({
      open: false,
      message: '',
    });
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={1.5}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <Controller
              name='name'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
              <RegisterTextfield
                variant='outlined'
                  label={t('group_class.fields.name')}
                  required
                  inputProps={{ 'aria-label': 'name' }}
                  {...field} />
                }
              />
          </Grid>

          <Grid item>
            <Controller
              name='description'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field }) =>
              <RegisterTextfield
                variant='outlined'
                multiline
                rows={2}
                label={t('group_class.fields.description')}
                  required
                  inputProps={{ 'aria-label': 'last_name' }}
                  {...field} />
                }
              />
          </Grid>

          {allCoach &&
            <Grid item>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  {t('group_class.fields.coach')}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'coachId', id: 'uncontrolled-native',
                  }}
                  value={selectedCoachId}
                  onChange={e => {
                    setValue('coachId', parseInt(e.target.value))
                    if (errors.coachId) {
                      clearErrors('coachId');
                    }
                  }}
                  sx={{ width: fieldWidth }}
                  error={!!errors.coachId}
                  required
                >
                  <option value={0} aria-label={`coach-select-default-0`}>
                    {t('group_class.fields.select_coach')}
                  </option>
                  {allCoach.map((coach) => (
                    <option value={coach.id} key={`coach-${coach.id}`} aria-label={`coach-select-${gender}`}>
                      {`${coach.preferred_name} ${coach.last_name}`}
                    </option>
                    ))
                  }
                </NativeSelect>

                {errors.coachId &&
                  <FormHelperText error id='mising-field'>
                    {errors.coachId.message}
                  </FormHelperText>
                }
              </FormControl>
            </Grid>
          }
          
          <Grid item
            sx={{
              '& .MuiCalendarPicker-root': {
                width: 300,
              }
            }}>
            {/* <MobileDatePicker */}
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              openTo='day'
              label={t('group_class.fields.date')}
              inputFormat='DD/MM/YYYY'
              value={dummyDate}
              // temporary disabled minDate function
              // minDate={dayjs()}
              renderDay={renderPickerDay}
              onChange={(newDate) => {
                if (!newDate) { return }
                setDummyDate(newDate);
                if (selectedDateFormatted.includes(newDate.format('YYYY-MM-DD'))) {
                  return
                }
                setSelectedDate(selectedDate.concat([newDate]).sort((a, b) => a.isAfter(b) ? 1 : -1));
                if (errors.date) {
                  clearErrors('date');
                }
              }}
              renderInput={(params) =>
                <TextField
                  inputProps={{'aria-label': 'dummyDate'}}
                  error={!!errors.date}
                  helperText={errors.date ? errors.date.message : 'dd/mm/yyyy'}
                  sx={{
                    // width: fieldWidth,
                    '& p': {
                      color: errors.date ? 'red' : '',
                    },
                    '& div>fieldset': {
                      borderColor: errors.date ? 'red' : ''
                    },
                  }}
                  {...params} />
              }
            />
          </Grid>

          <Grid item container
            sx={{
              marginTop: -5,
              justifyContent: 'center',
              width: 350,
            }}>
            {selectedDate.map((date, index) => (
              <Chip
                key={`date-${index}`}
                label={date.format('YYYY-MM-DD')}
                sx={{ margin: 0.5 }}
                onDelete={() => handleDeleteDate(index)}/>
            ))}
          </Grid>

          <Grid item>
            <Typography color='error.main'>
              {errors.date && errors.date.message}
            </Typography>
          </Grid>

          <Grid item container justifyContent='center'>
            {allTimeslot && allTimeslot
              .map(timeslot => {
                const time = timeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                  + ':' + timeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})
                return (
                  <Chip
                    variant={(selectedTimeslot && timeslot.id === selectedTimeslot.id) ? 'filled' : 'outlined'}
                    color='primary'
                    sx={{
                      margin: 0.5,
                      borderColor: 'primary.dark',
                      border: `1px solid`
                    }}
                    disabled={blockingTimeslot.includes(time)}
                    label={time}
                    key={`${timeslot.hour}-${timeslot.minute}`}
                    onClick={() => handleChipClick(timeslot)}
                    />
                )})  
            }
          </Grid>

          <Grid item>
            <Typography color='error.main'>
              {errors.timeslotId && errors.timeslotId.message}
            </Typography>
          </Grid>

          <Grid item>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>{t('group_class.fields.gender_restrict')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue='all'
                name='radio-buttons-group'
                value={gender}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const target = (event.target as HTMLInputElement).value;
                  setGender(target as GenderRestrict);
                  setValue('genderRestrict', target as GenderRestrict);
                }}
              >
                <FormControlLabel value='female' control={<Radio />} label={t('group_class.fields.female')} />
                <FormControlLabel value='male' control={<Radio />} label={t('group_class.fields.male')} />
                <FormControlLabel value='all' control={<Radio />} label={t('group_class.fields.gender_all')} />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl disabled={selectedCoachType !== 'senior' && selectedCoachType !== 'director' }>
              <FormLabel id='demo-radio-buttons-group-label'>{t('group_class.fields.is_star')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={false}
                name='radio-buttons-group'
                value={isStar}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const target = (event.target as HTMLInputElement).value === 'true';
                  // setIsPackage(target);
                  // setValue('isSelected', target);
                  setIsStar(target);
                  setValue('isStar', target);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label='Star' />
                <FormControlLabel value={false} control={<Radio />} label='Not Star' />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl>
              <FormLabel id='demo-radio-buttons-group-label'>{t('group_class.fields.is_selected')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby='demo-radio-buttons-group-label'
                defaultValue={false}
                name='radio-buttons-group'
                value={isSelected}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const target = (event.target as HTMLInputElement).value === 'true';
                  setIsPackage(target);
                  setValue('isSelected', target);
                }}
              >
                <FormControlLabel value={true} control={<Radio />} label='Yes' />
                <FormControlLabel value={false} control={<Radio />} label='No' />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <ButtonPrimary
              status='primary'
              type='submit'
              label={t('group_class.btn.create')}
              aria-label='create-btn'
            />
          </Grid>
          
        </Grid>
      </form>
      <Dialog
        open={errorDialog.open}
        onClose={closeErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <Typography variant='body1' color='error.main'>
            {errorDialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}