import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { sha256 } from 'js-sha256';
import dayjs from 'dayjs';

function paramStringify(json: any, flag?: any) {
let str = '';
  let keysArr = Object.keys(json);
  keysArr.sort().forEach(val => {
    if (!json[val]) return;
    str += `${val}=${flag ? encodeURIComponent(json[val]) : json[val]}&`;
  });
  return str.slice(0, -1);
}

export default function QFPayPayment() {
  useEffect(() => {
    let standard = document.getElementById('standard')
    let origin = QF_PAY_ORIGIN;

    let obj = {
      appcode: QF_PAY_APP_CODE,
      goods_name: 'OPW-test-product-name',
      out_trade_no: '13322916216626239614',
      paysource: 'remotepay_checkout',
      // test only
      return_url: CURRENT_SITE,
      failed_url: CURRENT_SITE + '/fail',
      notify_url: `${API_v1}/qf-pay-notify-url-post/`,
      sign_type: 'sha256',
      txamt: '10',
      txcurrcd: 'HKD',
      txdtm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    }

    let api_key = QF_PAY_API_KEY;
    let params = paramStringify(obj) 
    let sign = sha256(`${params}${api_key}`)
    standard?.setAttribute('href', `${origin}${paramStringify(obj,true)}&sign=${sign}`)
  }, [])
 
  return (
    <>
      <Helmet>
        <script src='https://cdn.bootcss.com/blueimp-md5/2.10.0/js/md5.js'></script>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/js-sha256/0.9.0/sha256.min.js'></script>
      </Helmet>
      <a id='standard' href='/'>QFPay Online Checkout</a>
    </>
  )
}