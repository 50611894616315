import useSWR from 'swr';
import axios from 'axios';
import { CustomerGroupClassInfo } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => res.data);

export default function useCustomerAllGroupClass() {
  const token = localStorage.getItem('authCustomerToken') as string;
  const { data, mutate, error } = useSWR(
    `${process.env.REACT_APP_API_SERVER_v1}/customer/group-class/all`,
    (url: string) => fetcher(url, token)
  );
  return {
    allGroupClasses: data as CustomerGroupClassInfo[],
    mutate,
    error
  }
};
