import { useEffect, useMemo, useState } from 'react';
import { Container, Grid, Typography, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'src/redux/auth_customer/thunks';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { ReactComponent as LogoVertical } from 'src/icons/logo/logo-vertical.svg';
import useWindowDimensions from 'src/hooks/use-window-dimensions';
import { nameToLowercase } from 'src/utils/clean-name';
import { Link, useLocation, useNavigate, Location } from 'react-router-dom';
import { IRootState } from 'src/store';
import ButtonOutline from 'src/components/ButtonOutline';

type CustomerLoginFormData = {
  username: string,
  password: string,
}

export default function CustomerLogin() {
  // redirect to customer dashboard if logged in
  const isCustomerAuthenticated = useSelector((state: IRootState) => state.authCustomer.isAuthenticated);
  const loginErrorMessage = useSelector((state: IRootState) => state.authCustomer.msg);

  const [loginFailed, setLoginFailed] = useState(false);
  const [loginFailedData, setLoginFailedData] = useState<CustomerLoginFormData | undefined>();

  const location = useLocation();
  const whereTo = useMemo(() =>{
    if (location && location.state) {
      return ((location.state as unknown as any).from as Location).pathname
    }
    return undefined
  }, [location]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isCustomerAuthenticated) {
      navigate('/dashboard');
    }
  }, [isCustomerAuthenticated, navigate])

  const { t } = useTranslation('customer');
  const dispatch = useDispatch();

  const { register, handleSubmit, setError, watch, formState: { errors } } = useForm<CustomerLoginFormData>();

  const { ref: refUsername, ...inputPropsUsername } = register('username');
  const { ref: refPassword, ...inputPropsPassword } = register('password');

  const inputUsername = watch('username');
  const inputPassword = watch('password');
  
  const onSubmit = handleSubmit((data: CustomerLoginFormData) => {
    if (data.username && data.password) {
      const {username, password} = data;
      setLoginFailedData(data);
      dispatch(login(username, password, whereTo));
    } else if (!data.username) {
      setError('username', { type: 'empty', message: t('login.error.need', {type: nameToLowercase(t('login.username'))})});
    } else if (!data.password) {
      setError('password', { type: 'empty', message: t('login.error.need', {type: nameToLowercase(t('login.password'))})});
    }
  });
  
  const { width: viewportWidth } = useWindowDimensions();
  // eqv. to the logo having width & height of 70vw, at max 350 px
  const logoWidth = useMemo(() => viewportWidth * 0.7 > 350 ? 350 : viewportWidth * 0.7, [viewportWidth]);


  useEffect(() => {
    if (loginErrorMessage) {
      if (loginFailedData?.password === inputPassword && loginFailedData?.username === inputUsername) {
        setLoginFailed(true);
      } else {
        setLoginFailed(false);
      }
    }
  }, [inputUsername, inputPassword, loginErrorMessage, loginFailedData])

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 6 }}>
      <form onSubmit={onSubmit}>
        <Grid
          item container
          xs={12}
          spacing={3}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <LogoVertical height={logoWidth} width={logoWidth}/>
          </Grid>
          <Grid item>
            <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: 32, color: '#4c4348', textAlign: 'center' }} >
              {t('login.title')}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant='filled'
              label={t('login.username')}
              inputProps={{'aria-label': 'username'}}
              inputRef={refUsername}
              error={!!errors.username}
              helperText={errors.username && errors.username.message}
              {...inputPropsUsername}
            />
          </Grid>
          <Grid item>
            <TextField
              variant='filled'
              label={t('login.password')}
              type='password'
              inputProps={{'aria-label': 'password'}}
              inputRef={refPassword}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
              {...inputPropsPassword}
            />
          </Grid>
          {loginFailed &&
            <Grid item>
              <Typography variant='body1' sx={{ color: 'error.main' }} textAlign='center' aria-label='error-msg'>
                {t('login.incorrect')}
              </Typography>
            </Grid>
          }
          <Grid item>
            <ButtonPrimary
              status='primary'
              type='submit'
              label={t('login.login_btn')}
              aria-label='login-btn'
              sx={{ width: 150 }}
            />
          </Grid>

          <Grid item>
            <Link to='/reset-password' style={{ textDecoration: 'none' }}>
              <ButtonOutline
                status='primary'
                label={t('login.forget_password')}
                sx={{ width: 150 }}
              />
            </Link>
          </Grid>

          <Grid
            container item
            spacing={1}
            direction='column'
            justifyContent='center'
            alignItems='center'>
            <Grid item>
              <Typography>
                {t('login.register_text')}
              </Typography>
            </Grid>
            <Grid item>
              <Link to='/register' style={{ textDecoration: 'none' }}>
                <ButtonOutline
                  status='primary'
                  label={t('login.register')}
                  sx={{ width: 150 }}
                />
              </Link>
            </Grid>
          </Grid>

        </Grid>
      </form>
    </Container>
  )
}