export function nameToLowercase(name: string) {
  return name.replace(/[A-Z]/g, letter => letter.toLowerCase())
}

export function nameToCamelcase(name: string) {
  return name.replace(/\b[a-z]/g, letter => letter.toUpperCase())
}

export function nameSpaceToHyphen(name: string) {
  return name.replace(/( )/g, space => space = '-')
}
