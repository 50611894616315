import useSWR from 'swr';
import axios from 'axios';
import { CustomerInfoDetailByPhone } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string, coachId: number) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { coachId }
    })
    .then(res => res.data);

export default function useAdminCoachCustomerReferral(coachId: number) {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/admin/coach/referral`, coachId],
    (url, coachId) => fetcher(url, token, coachId));
  return {
    allReferredCustomer: data as CustomerInfoDetailByPhone[],
    mutate,
    error
  }
};
