import useSWR from 'swr';
import axios from 'axios';
import { CustomerInfoDetailByPhone } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string, phone: string) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { phone: phone}
    })
    .then(res => res.data);

export default function useCoachSearchCustomer(phone: string) {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/coach/all-customer/`, phone],
    (url, phone) => fetcher(url, token, phone));
  return {
    allCustomer: data as CustomerInfoDetailByPhone[],
    mutate,
    error
  }
};
