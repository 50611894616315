import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Grid, styled, TextField, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { OfflinePurchaseCustomFormData } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import BackBox from 'src/components/navigate/BackBox';
import postOfflinePurchaseProof from 'src/api/customer/post-offline-purchase-proof';
import postOfflinePurchaseHistoryCustom from 'src/api/customer/post-offline-purchase-history-custom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate } from 'react-router-dom';

const fieldWidth = 250;

const RegisterTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

const PaymentTitleTypography = styled((props: TypographyProps) => 
  <Typography
    color='primary.main'
    textAlign='left'
    fontWeight='bold'
    {...props} />
)(({ theme }) => ({
}));

const PaymentDetailTypography = styled((props: TypographyProps) => 
  <Typography
    color='primary.main'
    textAlign='left'
    {...props} />
)(({ theme }) => ({
  marginLeft: 10,
  width: 280,
}));

interface OfflinePurchaseFormCustomProps {
  setIsStepTwo: Dispatch<SetStateAction<boolean>>,
  setIsStepThree: Dispatch<SetStateAction<boolean>>,
  credit: number,
  promotionalCode?: string,
}

export default function OfflinePurchaseFormCustom ({
  setIsStepTwo,
  setIsStepThree,
  credit,
  promotionalCode,
}: OfflinePurchaseFormCustomProps) {
  const { t } = useTranslation('customer');
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate('/record');
  }

  const photoProofRef = useRef(null);
  const [photoProofLoading, setPhotoProofLoading] = useState(false);

  const { register, handleSubmit, setValue, watch, control } = useForm<OfflinePurchaseCustomFormData>();

  register('price');
  register('credit');
  register('promotionalCode');
  register('reason');
  register('paymentProofPath');

  useEffect(() => {
    setValue('credit', credit.toString());
    if (promotionalCode) {
      setValue('promotionalCode', promotionalCode);
    }
  }, [credit, promotionalCode, setValue])

  const uploadedPaymentProofPath = watch('paymentProofPath');

  const uploadCoachPic = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhotoProofLoading(true);
    
    const formData = new FormData();
    const foo = await event.target.files?.item(0);
    formData.append('paymentProof', foo as File);
    
    const res = await postOfflinePurchaseProof(formData);
    const { paymentProofPath } = await res.json();
    
    setValue('paymentProofPath', paymentProofPath);
    setPhotoProofLoading(false);
  }

  const onSubmit = handleSubmit(async (data: OfflinePurchaseCustomFormData) => {
    // To Refactor
    // since $1 = 1 credit
    // price is in cents
    data.price = parseInt(data.credit) * 100;

    const res = await postOfflinePurchaseHistoryCustom(data);
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      setPaymentSuccessful(true);
    } else {
      setPaymentSuccessful(false);
    }

    setDialogOpen(true);
  });


  return (
    <form onSubmit={onSubmit}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        {/* hardcoded payment methods */}
        <Grid item>
          <PaymentTitleTypography>
            {`1. 銀行轉帳`}
          </PaymentTitleTypography>
          <PaymentDetailTypography>
            {`銀行：中國銀行(香港)有限公司`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`帳戶號碼: 012-887-2-035727-6`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`帳戶名稱: ONE PLUS WELLNESS (HONG KONG)LIMITED`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`・ 付款後請上傳付款截圖`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`・ 職員將會手動審批並核實付款狀態`}
          </PaymentDetailTypography>
        </Grid>

        <Grid item>
          <ButtonPrimary
            status='primary'
            isLoading={photoProofLoading}
            label={t('membership.btn.upload_proof') + t('membership.btn.optional')}
            onClick={() => photoProofRef.current && (photoProofRef.current as any).click()} />
        </Grid>

        <Grid item>
          <PaymentTitleTypography>
            {`2. 支票`}
          </PaymentTitleTypography>
          <PaymentDetailTypography>
            {`支票抬頭：一加健(香港)有限公司`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`銀行：中國銀行(香港)有限公司`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`帳戶號碼: 012-887-2-035727-6`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`・ 請輸入支票左下角的支票號碼並上傳支票圖像`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`・ 請確保填上正確抬頭及銀碼，並檢查帳戶內有足夠餘額，避免退票`}
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`・ 職員將會手動審批並核實付款狀態`}
          </PaymentDetailTypography>
        </Grid>

        <Grid item>
          <ButtonPrimary
            status='primary'
            isLoading={photoProofLoading}
            label={t('membership.btn.upload_proof') + t('membership.btn.optional')}
            onClick={() => photoProofRef.current && (photoProofRef.current as any).click()} />
        </Grid>

        <Grid item>
          <PaymentTitleTypography>
            {`3. 現金`}
          </PaymentTitleTypography>
          <PaymentDetailTypography>
            {`・ 請預約職員收款` }
          </PaymentDetailTypography>
          <PaymentDetailTypography>
            {`・ `}
            <a href='https://wa.me/85262866580' target='_blank' rel='noopener noreferrer'>
              {`WhatsApp 6286 6580`}
            </a>
          </PaymentDetailTypography>
        </Grid>
        {/* hardcoded payment methods */}

        {/* refactor to the option list */}
        {/* <Grid item>
          <Controller
            name='credit'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) =>
              <RegisterTextfield
                variant='outlined'
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*', 'aria-label': 'credit' }}
                InputProps={{
                  endAdornment: <InputAdornment position='end'>credit</InputAdornment>,
                }}
                label={t('membership.custom') + t('membership.credit', { number: '' })}
                {...field} />
            }/>
        </Grid>

        <Grid item>
          <Controller
            name='promotionalCode'
            control={control}
            defaultValue=''
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('membership.promotion_code') + t('membership.btn.optional')}
                inputProps={{ 'aria-label': 'promotionalCode' }}
                {...field} />
              }
            />
        </Grid> */}

        <Grid item>
          <input
            type='file'
            ref={photoProofRef}
            accept='image/*'
            style={{ display: 'none' }}
            onChange={(event) => uploadCoachPic(event)}
            />
        </Grid>

        <Grid item>
          {uploadedPaymentProofPath &&
            <img
              src={`${AWS_S3_BUCKET}/${uploadedPaymentProofPath}`}
              alt='proof for offline payment'
              style={{width: '70vw', maxWidth: 400}}/>
          }
        </Grid>

        <Grid item>
          <Controller
            name='reason'
            control={control}
            defaultValue=''
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('membership.fields.reason') + t('membership.btn.optional')}
                inputProps={{ 'aria-label': 'reason' }}
                {...field} />
              }
            />
        </Grid>

        <Grid item>
          <ButtonPrimary
            status='primary'
            type='submit'
            label={t('membership.btn.offline_submit')}
            aria-label='submit-btn'
            isLoading={photoProofLoading}
          />
        </Grid>

        <Grid item sx={{ marginTop: 3 }}>
          <BackBox
            onClick={() => {
              setIsStepTwo(true);
              setIsStepThree(false);
            }}/>
        </Grid>
        
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {t('membership.dialog.offline_apply') + t(`membership.dialog.${paymentSuccessful ? 'success' : 'fail'}`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {paymentSuccessful ?
                t(`membership.dialog.offline_text`)
              :
                t(`membership.dialog.fail_text`)
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} autoFocus>
              {t('btn.back')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </form>
  )
}