import { useState } from 'react';
import ButtonOutline from 'src/components/ButtonOutline';
import { useTranslation } from 'react-i18next';
import postAdminClientClassDataXlsx from 'src/api/coach/admin/post-admin-client-class-data-xlsx';
import { nameToLowercase } from 'src/utils/clean-name';

// to do
// only all data now, will need to do by-month
interface Props {
  year?: number,
  month?: number,
  isAllData?: boolean,
}

export default function ClientClassExportExcelBtn({
  year,
  month,
  isAllData,
}: Props) {
  const { t } = useTranslation('coach');
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const exportExcel = async () => {
    setExportLoading(true);
    postAdminClientClassDataXlsx(year, month)
      .then(res => {
        if (res.status === 200) {
          return res.blob()
        } else {
          alert(t('data_stat.result.csv_fail', {type: nameToLowercase(t('data_stat.title.client_class'))}));
          return null
        }
      })
      .then(blob => {
        if (!blob) {return}
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = (year !== undefined && month !== undefined)
          ? `client-class-${year}-${month.toLocaleString(undefined, {minimumIntegerDigits: 2})}`
          : `client-class-all-data`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert(t('data_stat.result.csv_success', {type: nameToLowercase(t('data_stat.title.client_class'))}));
      })
      .finally(() => {
        setExportLoading(false);
      });
  }

  return (
    <ButtonOutline
      label={isAllData ? t('data_stat.btn.all_data') : t('data_stat.btn.month', {month: month})}
      status='primary'
      isLoading={exportLoading}
      onClick={exportExcel} />
  )
}