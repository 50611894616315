import { Grid, GridProps, styled } from "@mui/material";

const SubtitleGrid = styled((props: GridProps) => (
  <Grid item container
    color='disableGrey.main'
    justifyContent={'center'}
    {...props} />
))(({ theme }) => ({
  borderBottom: '1.8px solid',
  borderColor: theme.palette.disableGrey.main,
  marginTop: 10,
  marginBottom: 10,
}))

export default SubtitleGrid;