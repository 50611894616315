import { Box, Typography, styled, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronsLeft } from 'src/icons/components/chevrons-left.svg';

const paddingBoxWidth = 15;

const BackBoxComponent = styled(Box)(({ theme }) => ({
  height: 30,
  width: `calc(60vw - ${paddingBoxWidth}px - 10px)`,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  maxWidth: 300,
  paddingLeft: 10,
  paddingRight: paddingBoxWidth,
  paddingTop: 5,
  paddingBottom: 5,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface BackBoxProps {
  label?: string,
  onClick?: () => void,
  sx?: SxProps,
}

/**
 * TODO - add to props for a tag & new browser tab
 */
export default function BackBox({
  label,
  onClick,
  sx,
}: BackBoxProps) {
  const { t } = useTranslation('customer');
  return (
    <BackBoxComponent
      onClick={onClick}
      sx={sx}>
      <ChevronsLeft/>
      <Typography color='primary.main' fontWeight={'bold'}>
        {label !== undefined && label}
        {!label && t('btn.back')}
      </Typography>
    </BackBoxComponent>
  )
}