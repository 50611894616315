import { Box, Grid, styled, Typography } from '@mui/material';
import { To } from 'react-router-dom';
import { Coach } from 'src/hooks/swr/output-types';
import { nameToCamelcase } from 'src/utils/clean-name';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';

const CoachBoxWrapper = styled(Box)(({ theme }) => ({
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  width: '80vw',
  marginTop: 10,
  marginBottom: 10,
  background: 'white.main',
  padding: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: 900,
}));

const CoachIcon = styled('img')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: 50,
  // TODO - fix white line between border & pic
  border: `1px solid ${theme.palette.primary.main}`,
}));

interface CoachBoxProps {
  coach: Coach,
  to: To
}

export default function CoachBox({
  coach,
  to,
}: CoachBoxProps) {
  return (
    <LinkWithoutStyle to={to}>
      <CoachBoxWrapper>
        <CoachIcon
          src={`${AWS_S3_BUCKET}/${coach.icon_path}`}
          alt='icon for new coach'
          />
        <Grid item container
          sx={{
            marginLeft: 1,
            marginRight: 2,
          }}>
          <Grid item>
            <Typography color='primary.main' fontWeight={'bold'}>
              {nameToCamelcase(coach.preferred_name + ' ' + coach.last_name)}
            </Typography>
          </Grid>
        </Grid>
        <ChevronsRight/>
      </CoachBoxWrapper>
    </LinkWithoutStyle>
  )
}