import { Container, Grid, Typography, styled, GridProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CoachBox from 'src/components/CoachBox';
import useAllCoach from 'src/hooks/swr/public/use-all-coach';
import { nameToLowercase } from 'src/utils/clean-name';
import { Coach } from 'src/hooks/swr/output-types';
import { useMemo } from 'react';

const SubtitleGrid = styled((props: GridProps) => (
  <Grid item container
    color='disableGrey.main'
    justifyContent={'center'}
    {...props} />
))(({ theme }) => ({
}))

const indexName = (coach: Coach) => (nameToLowercase(coach.preferred_name) + '-' + nameToLowercase(coach.last_name));

// hardcoded coach sorting
export const coachOrder = [
  'Epsilon', 'Hugo', 'Iris', 'Grace', 'Nel', 'Keith', 'King', 'Barry', 'Flora', 'Garif', 'Nikita',
];

export default function CoachOverview() {
  const { t } = useTranslation('customer');
  const { allCoach } = useAllCoach();

  const coachFounder = useMemo(() => {
    if (allCoach) {
      return allCoach
        .filter(coach => indexName(coach) === 'hugo-ho' || indexName(coach) === 'epsilon-wong')
        .map(coach => {
          coach.order = coachOrder.indexOf(coach.preferred_name)
          return coach
        })
        .sort((a, b) => (a.order as number) - (b.order as number))
    }
  }, [allCoach]);

  const coachRegular = useMemo(() => {
    if (allCoach) {
      return allCoach.filter(coach => indexName(coach) !== 'hugo-ho' && indexName(coach) !== 'epsilon-wong')
        .map(coach => {
          coach.order = coachOrder.indexOf(coach.preferred_name)
          return coach
        })
        .sort((a, b) => (a.order as number) - (b.order as number))
    }
  }, [allCoach]);

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
      <Grid
        item container
        xs={12}
        spacing={1}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Typography variant='h5' color='primary.main' sx={{ fontWeight: 'bold' }} >
            {t('coach_overview.title')}
          </Typography>
        </Grid>

        <SubtitleGrid>
          <Typography>
            {t('coach_overview.founder')}
          </Typography>
        </SubtitleGrid>

        <Grid item container
          justifyContent='center'
          alignItems='center'
          xs={12} sm={8}>
          {coachFounder && coachFounder.map((coach) => (
            <CoachBox
              coach={coach} key={`coach-${coach.id}`}
              to={`/coach-overview/coach/${coach.id}`}
              />
          ))}
        </Grid>

        <SubtitleGrid>
          <Typography>
            {t('coach_overview.coach')}
          </Typography>
        </SubtitleGrid>

        <Grid item container
          justifyContent='center'
          alignItems='center'
          xs={12} sm={8}>
          {coachRegular && coachRegular.map((coach) => (
            <CoachBox
              coach={coach} key={`coach-${coach.id}`}
              to={`/coach-overview/coach/${coach.id}`}
              />
          ))}
        </Grid>
      </Grid>
    </Container>
  )
}