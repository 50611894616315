import { AddCoachFormData } from 'src/api/form-types';

export default function postCoachNew(data: AddCoachFormData) {
  return fetch(`${global.API_v1}/director/coach/`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
