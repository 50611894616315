import useSWR from 'swr';
import axios from 'axios';
import { StrapiSingleTypeSingleMultiPhoto } from 'src/hooks/swr/output-types';

const fetcher = (url: string) => axios.get(url).then(res => res.data);

export default function useCmsMainpagePhoto() {
  const { data, mutate, error } = useSWR(`${process.env.REACT_APP_API_SERVER_v1}/cms/get-mainpage-photo`, fetcher);
  return {
    allMainpagePhoto: data as StrapiSingleTypeSingleMultiPhoto[],
    mutate,
    error
  }
};
