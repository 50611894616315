import useSWR from 'swr';
import axios from 'axios';

const fetcher = (url: string, token: string) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(res => res.data);

export default function useCustomerIsVip() {
  const token = localStorage.getItem('authCustomerToken') as string;
  const { data, mutate, error } = useSWR(
    `${process.env.REACT_APP_API_SERVER_v1}/customer/vip`,
    (url: string) => fetcher(url, token)
  );
  return {
    isVip: data as {is_vip: boolean},
    mutate,
    error
  }
};
