import { useMemo } from 'react';
import { Container, Grid, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameToCamelcase, nameToLowercase } from 'src/utils/clean-name';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import useAllCoach from 'src/hooks/swr/public/use-all-coach';
import { Helmet } from 'react-helmet-async';
import NavigateTo from 'src/components/navigate/NavigateTo';
import coachInfo from 'src/pages/customer/coach-overview/CoachInfo';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as AwardIcon } from 'src/icons/coachPage/award.svg';
import { theme } from 'src/styles/muiTheme';
// import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SocialMediaLinkBox from 'src/components/social-media/SocialMediaLinkBox';
import useCmsCoachInfo from 'src/hooks/swr/public/use-cms-coach-info';

const CoachIcon = styled('img')(({ theme }) => ({
  width: 200,
  height: 200,
  borderRadius: 250,
  // TODO - fix white line between border & pic
  border: `1px solid ${theme.palette.primary.main}`,
}));

export default function CoachPersonalPage() {
  const { coachId } = useParams();
  const { t } = useTranslation('customer');

  const { allCoach } = useAllCoach();
  const coach = useMemo(() => {
    if (allCoach) {
      return allCoach.filter(coach => coach.id === parseInt(coachId as string))[0]
    }
  }, [allCoach, coachId]);

  const coachFullName = useMemo(() => 
    coach ? nameToCamelcase(coach.preferred_name + ' ' + coach.last_name) : ''
  , [coach]);

  const coachIndexName = useMemo(() => 
    coach ? (nameToLowercase(coach.preferred_name) + '-' + nameToLowercase(coach.last_name)) : ''
  , [coach]);

  const coachDetails = useMemo(() =>
    coachIndexName ? coachInfo[coachIndexName] : undefined
  , [coachIndexName]);

  
  // temporary block
  // const isYoutubeMaxWidth = useMediaQuery(theme.breakpoints.up(560));

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  // CMS version
  const { allCoachInfo } = useCmsCoachInfo();

  const coachInfoCms = useMemo(() => {
    if (allCoachInfo && coach) {
      const coachResult = allCoachInfo.filter(coachCms =>
        nameToLowercase(coachCms.attributes.First_Name) === nameToLowercase(coach.preferred_name) &&
        nameToLowercase(coachCms.attributes.Family_Name) === nameToLowercase(coach.last_name)
      )[0];
      return coachResult ? coachResult : undefined
    }
  }, [allCoachInfo, coach]);

  return (
    <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
      {coach && coachFullName &&
      // To Do
        <Helmet>
          <title>{`${coachFullName} - OPW Coach`}</title>
          <meta
            name='description'
            content='一加健預約系統 - One Plus Wellness OPW booking portal'
          />
          <link rel='icon' href={`${AWS_S3_BUCKET}/${coach.icon_path}`}  />

          <meta property='og:image' content={`${AWS_S3_BUCKET}/${coach.icon_path}`} />
          <meta property='og:image:type' content='image/png' />
          <meta property='og:image:width' content='300' />
          <meta property='og:image:height' content='300' />
          <meta property='og:description' content='OPW coach introduction 一加健教練簡介' />
          <meta property='og:url' content='%PUBLIC_URL%' />
          <meta property='og:title' content={`OPW coach ${coachFullName}`} />
          <meta property='og:site_name' content='OPW Portal 一加健預約系統' />

          <meta name='twitter:card' content={`OPW coach ${coachFullName} icon`} />
          <meta name='twitter:image' content={`${AWS_S3_BUCKET}/${coach.icon_path}`} />
          <meta name='twitter:site' content='@OPWGym' />
          <meta name='twitter:creator' content='@OPWGym' />
          <meta name='twitter:title' content={`OPW coach ${coachFullName}`} />
          <meta name='twitter:description' content='OPW coach introduction 一加健教練簡介' />
        </Helmet>
      }
      {allCoach && coach && coachDetails && coachInfoCms &&
        <Grid
          item container
          xs={12}
          spacing={1}
          direction='column'
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <CoachIcon
              src={`${AWS_S3_BUCKET}/${coach.icon_path}`}
              alt='icon for new coach'
              />
          </Grid>
          <Grid item>
            <Typography variant='h5' color='primary.main' sx={{ fontWeight: 'bold' }} >
              {coachFullName}
            </Typography>
          </Grid>

          <Grid item>
            {/* <SocialMediaLinkBox
              linkFb={coachDetails.linkFb}
              linkIg={coachDetails.linkIg}
              linkYoutube={coachDetails.linkYoutube}
              /> */}
            <SocialMediaLinkBox
              linkFb={coachInfoCms.attributes.Link_Facebook}
              linkIg={coachInfoCms.attributes.Link_Instagram}
              linkYoutube={coachInfoCms.attributes.Link_Youtube}
              />
          </Grid>
          
          {/* temporary block */}
          {/* <Grid item>
            <iframe
              width={isYoutubeMaxWidth ? 520 : '100%'}
              height={isYoutubeMaxWidth ? 315 : '100%'}
              src="https://www.youtube.com/embed/QsVz4iMwpxc"
              title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid> */}

          <Grid item>
            <NavigateTo
              to={`/book/pt/${coachId}`}
              label={t('coach_overview.btn.book_pt')}/>
          </Grid>

          {/* hardcoded photo gallary for Iris */}
          {/* Todo - resize & sizing */}
          {/* {coachIndexName === 'iris-kwok' &&
            <Grid item>
              <ImageList
                sx={{ width: '100%', height: 630 }}
                cols={3}
                variant='quilted'
                rowHeight={570}
                >
                {Array.from(new Array(10), (_, i) => i+1).map((item) => (
                  <ImageListItem
                    key={`${item}-image`}
                    cols={isSmUp ? 1 : 3}
                    rows={1}
                    >
                    <img
                      src={`${AWS_S3_BUCKET}/iris-kwok/${item}.jpg`}
                      alt='icon for Iris'
                      loading='lazy'
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          } */}

          {coachInfoCms.attributes.Photo.data &&
            <Grid item>
              <ImageList
                sx={{ width: '100%', height: 630 }}
                cols={3}
                variant='quilted'
                rowHeight={570}
                >
                {coachInfoCms.attributes.Photo.data.map((photo, index) => (
                  <ImageListItem
                    key={`${index}-image`}
                    cols={isSmUp ? 1 : 3}
                    rows={1}
                    >
                    <img
                      src={photo.attributes.url}
                      alt={photo.attributes.alternativeText}
                      loading='lazy'
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          }

          {/* temporary block */}
          {/* <Grid item container justifyContent={'center'}>
            <ImageList sx={{ width: '80vw', height: 450 }} cols={3}
              // auto height from image
              // rowHeight={164}
              >
              {Array.from(new Array(16), (_, i) => i).map((item) => (
                <ImageListItem key={`${item}-image`}>
                  <img
                    src={`${AWS_S3_BUCKET}/default-logo.png`}
                    alt='icon for coach'
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid> */}

          <Grid item container
            spacing={1}
            justifyContent={'flex-start'}>
            
            {/* {coachDetails.description &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.intro')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {coachDetails.description}
                  </Typography>
                  {coachIndexName === 'epsilon-wong' &&
                    <Typography variant='body1' color='primary.main' sx={{ marginTop: 0.5 }}>
                      {coachDetails.supplement_0}
                    </Typography>
                  }
                </Grid>
              </>
            } */}

            {coachInfoCms.attributes.Coach_Intro &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.intro')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {coachInfoCms.attributes.Coach_Intro}
                  </Typography>
                </Grid>
              </>
            }

            {coachInfoCms.attributes.Coach_Info_Award_List &&
              <Grid item>
                <List dense>
                {coachInfoCms.attributes.Coach_Info_Award_List.split('\n').map((title: string, index: number) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <WorkspacePremiumIcon color={'primary'} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: 'primary.main' }}
                      primary={title} />
                  </ListItem>
                ))}
                </List>
              </Grid>
            }

            {/* {coachIndexName === 'hugo-ho' &&
              <Grid item>
                <List dense>
                {coachDetails.work.map((work: string, index: number) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <FitnessCenterIcon color={'primary'} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: 'primary.main' }}
                      primary={work} />
                  </ListItem>
                ))}
                </List>
              </Grid>
            }

            {coachIndexName === 'epsilon-wong' &&
              <Grid item>
                <List dense>
                {coachDetails.title.map((title: string, index: number) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <WorkspacePremiumIcon color={'primary'} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: 'primary.main' }}
                      primary={title} />
                  </ListItem>
                ))}
                </List>
              </Grid>
            } */}
            
            {/* {coachDetails.award.length !== 0 &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.award')}
                  </Typography>
                </Grid>
                <Grid item>
                  <List dense>
                  {coachDetails.award.map((award: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <AwardIcon stroke={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'primary.main' }}
                        primary={award} />
                    </ListItem>
                  ))}
                  </List>
                </Grid>
              </>
            } */}

            {coachInfoCms.attributes.Coach_Award &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.award')}
                  </Typography>
                </Grid>
                <Grid item>
                  <List dense>
                  {coachInfoCms.attributes.Coach_Award.split('\n').map((award: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <AwardIcon stroke={theme.palette.primary.main} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'primary.main' }}
                        primary={award} />
                    </ListItem>
                  ))}
                  </List>
                </Grid>
              </>
            }

            {/* {coachIndexName === 'hugo-ho' &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {'部分學歷及專業資格'}
                  </Typography>
                </Grid>
                <Grid item>
                  <List dense>
                  {coachDetails.cert.map((work: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <WorkspacePremiumIcon color={'primary'} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'primary.main' }}
                        primary={work} />
                    </ListItem>
                  ))}
                  </List>
                </Grid>
              </>
            } */}

            {/* {coachIndexName === 'hugo-ho' &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {'部分學歷及專業資格'}
                  </Typography>
                </Grid>
                <Grid item>
                  <List dense>
                  {coachDetails.cert.map((work: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <WorkspacePremiumIcon color={'primary'} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'primary.main' }}
                        primary={work} />
                    </ListItem>
                  ))}
                  </List>
                </Grid>
              </>
            }


            {coachIndexName === 'epsilon-wong' &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {'專業資格'}
                  </Typography>
                </Grid>
                <Grid item>
                  <List dense>
                  {coachDetails.cert.map((work: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <WorkspacePremiumIcon color={'primary'} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'primary.main' }}
                        primary={work} />
                    </ListItem>
                  ))}
                  </List>
                </Grid>
              </>
            } */}

            {coachInfoCms.attributes.Professional_Accreditation &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.professional_accreditation')}
                  </Typography>
                </Grid>
                <Grid item>
                  <List dense>
                  {coachInfoCms.attributes.Professional_Accreditation.split('\n').map((credit: string, index: number) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <WorkspacePremiumIcon color={'primary'} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: 'primary.main' }}
                        primary={credit} />
                    </ListItem>
                  ))}
                  </List>
                </Grid>
              </>
            }
            
            {/* {coachDetails.tag &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.speciality')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {coachDetails.tag}
                  </Typography>
                </Grid>
              </>
            } */}

            {coachInfoCms.attributes.Sport_Speciality &&
              <>
                <Grid item container>
                  <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }} >
                    {t('coach_overview.speciality')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {coachInfoCms.attributes.Sport_Speciality}
                  </Typography>
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      }
    </Container>
  )
}