import { AdminManualChangeClientPassword } from "src/api/form-types";

export default function postAdminManualModifyClientPassword(data: AdminManualChangeClientPassword) {
  return fetch(`${global.API_v1}/admin/client/manual/modify`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
