import { useState } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
import adminCleanPhone from 'src/utils/admin-clean-phone';
import { CustomerAllPurchaseRecordAdmin } from 'src/hooks/swr/output-types';
import putAdminUpdatePurchaseStatus from 'src/api/coach/admin/put-admin-update-purchase-status';
import { KeyedMutator } from 'swr';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DownloadReceiptBtn from 'src/components/payment-record/DownloadReceiptBtn';

const RecordBox = styled(Box)(({ theme }) => ({
  width: '70vw',
  maxWidth: 320,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  // not redirect / navigate to record yet
  // cursor: 'pointer',
}));

const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

const getPaymentMethod = (code: string | undefined) => {
  let method: string;
  if (code === '801501' || code === '801501' || code === '801512' || code === '801512') {
    method = 'Alipay';
  } else if (code === '800201') {
    method = 'WeChat';
  } else if (code === '802001') {
    method = 'FPS';
  } else if (code === '800701') {
    method = 'UnionPay';
  } else {
    method = 'New Method'
  }
  return method
};

interface PaymentRecordListAdminProps {
  allPurchaseRecord: CustomerAllPurchaseRecordAdmin[],
  mutate: KeyedMutator<any>,
  isReadOnly?: boolean,
}

type ResultDialog = {
  open: boolean,
  status: 'approved' | 'rejected',
  purchaseRecord: CustomerAllPurchaseRecordAdmin | undefined,
  hasResultSuccess: boolean,
}

export default function PaymentRecordListAdmin({
  allPurchaseRecord,
  mutate,
  isReadOnly = false,
}: PaymentRecordListAdminProps) {
  const { t } = useTranslation('customer');
  const { t: t_c } = useTranslation('coach');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [proofPath, setProofPath] = useState('');
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  }
  
  const [resultDialog, setResultDialog] = useState<ResultDialog>({
    open: false,
    status: 'approved',
    purchaseRecord: undefined,
    hasResultSuccess: false,
  });

  const handleResultDialogClose = () => {
    setResultDialog({
      ...resultDialog,
      open: false,
      hasResultSuccess: false,
    })
  }

  const handleUpdatePurchaseHistory = async () => {
    if (!resultDialog.purchaseRecord) {
      return
    }
    const res = await putAdminUpdatePurchaseStatus(
      resultDialog.purchaseRecord.purchaseHistoryId,
      resultDialog.status,
    )
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      mutate();
      setResultDialog({
        ...resultDialog,
        hasResultSuccess: true,
      })
    }
  }

  return (
    <>
      {allPurchaseRecord && allPurchaseRecord.map((purchaseRecord, index) => (
        <Grid item key={`purchase-${index}`}>
          <RecordBox>
            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.name')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {nameToCamelcase(purchaseRecord.customerPreferredName + ' ' + purchaseRecord.customerLastName)}
              </Typography>
            </TextBox>

            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.phone')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {adminCleanPhone(purchaseRecord.customerPhoneNum)}
              </Typography>
            </TextBox>

            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.credit')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
              {purchaseRecord.credit ? purchaseRecord.credit : purchaseRecord.referCredit}
              {purchaseRecord.goodsName === 'promotion_WELCOME220' ? '220' : ''}
                {purchaseRecord.goodsName === 'promotion_WELCOME5000' ? '5000' : ''}
                {(purchaseRecord.goodsName === 'custom_credit' && purchaseRecord.amountPaid)
                  ? `${(purchaseRecord.amountPaid) / 100}` : ''}
              </Typography>
            </TextBox>

            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.amount')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {purchaseRecord.amountPaid && `$${(purchaseRecord.amountPaid) / 100}`}
                {purchaseRecord.traditionalAmount && `$${(purchaseRecord.traditionalAmount) / 100}`}
              </Typography>
            </TextBox>

            <TextBox sx={{ marginBottom: 0.5 }}>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.method')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {purchaseRecord.paymentMethod && getPaymentMethod(purchaseRecord.paymentMethod)}
                {purchaseRecord.status && t('record.fields.offline')}
              </Typography>
            </TextBox>

            {purchaseRecord.status &&
              <TextBox sx={{ marginBottom: 0.5 }}>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.status')}
                </Typography>
                <Typography color={purchaseRecord.status !== 'rejected' ? 'primary.main' : 'error.main'} fontWeight={'bold'}>
                  {nameToCamelcase(purchaseRecord.status)}
                </Typography>
              </TextBox>
            }

            <TextBox sx={{ marginBottom: 0.5 }}>
              <Typography color='primary.main' fontWeight={'bold'} sx={{ minWidth: 64 }}>
                {t('record.fields.notes')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'} sx={{ maxWidth: 'calc(100% - 64px - 10px - 8px)'}}>
              {/* To Refactor to function */}
              {purchaseRecord.traditionalReason ?
                  (purchaseRecord.traditionalReason === 'welcome_credit' ? t('record.reason.welcome') :
                    (purchaseRecord.traditionalReason === 'referral' ? t('record.reason.referral') : 
                      (purchaseRecord.traditionalReason === 'class_cancel_refund' ? t('record.reason.class_cancel_refund') : purchaseRecord.traditionalReason)
                    )
                  )
                :
                  '/'
              }
              </Typography>
            </TextBox>

            {purchaseRecord.promotionalCode &&
              <TextBox sx={{ marginBottom: 0.5 }}>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.promotion_code')}
                </Typography>
                <Typography
                  color={purchaseRecord.promotionalCodeRepeated ? 'error.main' : 'primary.light'}
                  fontWeight={'bold'} fontStyle={'italic'}>
                  {nameToCamelcase(purchaseRecord.promotionalCode)}
                </Typography>
              </TextBox>
            }

            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.coach')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {purchaseRecord.coachLastName === null && '/'}
                {purchaseRecord.coachLastName !== null &&
                  nameToCamelcase(purchaseRecord.coachPreferredName + ' ' + purchaseRecord.coachLastName)
                }
              </Typography>
            </TextBox>

            {purchaseRecord.traditionalPhotoProofPath &&
              <TextBox sx={{ marginBottom: 0.5, justifyContent: 'center'}}>
                <ButtonPrimary
                  label={t('record.fields.proof')}
                  status='primary'
                  onClick={() => {
                    setDialogOpen(true);
                    setProofPath(purchaseRecord.traditionalPhotoProofPath as string);
                  }} />
              </TextBox>
            }

            <DownloadReceiptBtn purchaseRecord={purchaseRecord}/>
            
            <Typography color='primary.main' fontWeight={'bold'} sx={{ alignSelf: 'flex-end' }}>
              {purchaseRecord.opwTransactionId && t('record.fields.opw_id') + purchaseRecord.opwTransactionId}
              {!purchaseRecord.opwTransactionId && t('record.fields.opw_id') + purchaseRecord.purchaseHistoryId}
            </Typography>

            <Typography color='primary.main' fontWeight={'bold'} sx={{ alignSelf: 'flex-end' }}>
              {purchaseRecord.transactionDateTime && dayjs(purchaseRecord.transactionDateTime).format('YYYY-MM-DD HH:mm:ss')}
              {!purchaseRecord.transactionDateTime && dayjs(purchaseRecord.purchaseHistoryTime).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>

            {purchaseRecord.status === 'pending' && !isReadOnly &&
              <>
                <Box
                  sx={{
                    borderBottom: '1.8px solid',
                    borderColor: 'disableGrey.main',
                    width: '90%',
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <ButtonPrimary
                    status='primary'
                    label={t_c('purchase_record.btn.approve')}
                    sx={{ width: 160 }}
                    onClick={() => {
                      setResultDialog({
                        ...resultDialog,
                        open: true,
                        status: 'approved',
                        purchaseRecord: purchaseRecord,
                      })
                    }} />
                  
                </Box>

                <Box
                  sx={{
                    width: '90%',
                    padding: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <ButtonPrimary
                    status='warning'
                    label={t_c('purchase_record.btn.reject')}
                    sx={{ width: 160 }}
                    onClick={() => {
                      setResultDialog({
                        ...resultDialog,
                        open: true,
                        status: 'rejected',
                        purchaseRecord: purchaseRecord,
                      })
                    }} />
                  
                </Box>
              </>
            }
          </RecordBox>
        </Grid>
      ))}

      {/* dialog to view photo proof */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <img
            src={`${AWS_S3_BUCKET}/${proofPath}`}
            alt='proof for offline payment'
            style={{ width: '100%' }}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog to confirm / reject traditional payment */}
      <Dialog
        open={resultDialog.open}
        onClose={handleResultDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          {!resultDialog.hasResultSuccess &&
            <DialogContentText
              color={resultDialog.status === 'approved' ? 'primary.main' : 'error.main'}
              fontSize={18}
              sx={{ marginBottom: 1 }}>
              {t_c('purchase_record.result_dialog.text', {action: resultDialog.status === 'approved' ? 'approve' : 'reject' })}
            </DialogContentText>
          }

          {resultDialog.hasResultSuccess &&
            <DialogContentText
              color={resultDialog.status === 'approved' ? 'primary.main' : 'error.main'}
              fontSize={18}
              sx={{ marginBottom: 1 }}>
              {/* Todo - translate to Traditional Chinese */}
              {t_c('purchase_record.result_dialog.updated', {status: resultDialog.status })}
            </DialogContentText>
          }

          {resultDialog.purchaseRecord &&
            <RecordBox>
              <TextBox>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.name')}
                </Typography>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {nameToCamelcase(resultDialog.purchaseRecord.customerPreferredName + ' ' + resultDialog.purchaseRecord.customerLastName)}
                </Typography>
              </TextBox>

              <TextBox>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.phone')}
                </Typography>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {adminCleanPhone(resultDialog.purchaseRecord.customerPhoneNum)}
                </Typography>
              </TextBox>

              <TextBox>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.credit')}
                </Typography>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {resultDialog.purchaseRecord.credit ? resultDialog.purchaseRecord.credit : resultDialog.purchaseRecord.referCredit}
                </Typography>
              </TextBox>

              <TextBox>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.amount')}
                </Typography>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {resultDialog.purchaseRecord.amountPaid && `$${(resultDialog.purchaseRecord.amountPaid) / 100}`}
                  {resultDialog.purchaseRecord.traditionalAmount && `$${(resultDialog.purchaseRecord.traditionalAmount) / 100}`}
                </Typography>
              </TextBox>

              <TextBox sx={{ marginBottom: 0.5 }}>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.method')}
                </Typography>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {resultDialog.purchaseRecord.paymentMethod && getPaymentMethod(resultDialog.purchaseRecord.paymentMethod)}
                  {resultDialog.purchaseRecord.status && t('record.fields.offline')}
                </Typography>
              </TextBox>

              <Typography color='primary.main' fontWeight={'bold'} sx={{ alignSelf: 'flex-end' }}>
                {resultDialog.purchaseRecord.transactionDateTime && dayjs(resultDialog.purchaseRecord.transactionDateTime).format('YYYY-MM-DD HH:mm:ss')}
                {!resultDialog.purchaseRecord.transactionDateTime && dayjs(resultDialog.purchaseRecord.purchaseHistoryTime).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </RecordBox>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResultDialogClose}>
            {t('btn.back')}
          </Button>
          {!resultDialog.hasResultSuccess &&
            <Button onClick={handleUpdatePurchaseHistory} autoFocus
              color={resultDialog.status === 'approved' ? 'primary' : 'error'} >
              {t('btn.confirm')}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </>
  )
}