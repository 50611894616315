import { Grid, GridProps, styled, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as ChevronUp } from 'src/icons/components/chevron-up.svg';
import { theme } from 'src/styles/muiTheme';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const Separator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.displayGrey.main,
  width: '70vw',
  maxWidth: 500,
  height: 1,
}));

interface SessionTitleCollapsableProps {
  state: boolean,
  setState: Dispatch<SetStateAction<boolean>>,
  label: string,
}

export default function SessionTitleCollapsable({
  state,
  setState,
  label
}: SessionTitleCollapsableProps) {
  return (
    <TextGrid
      item container xs={12}
      justifyContent='center'
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setState(!state);
      }}>
      <Grid
        item container
        direction='row'
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
          sx={{
            marginTop: 1,
            marginBottom: 1,
            marginRight: 1,
          }}>
          {label}
        </Typography>
        <ChevronUp
          stroke={theme.palette.displayGrey.main}
          style={{
            transform: state ? 'rotate(180deg)' : 'unset',
            transitionDuration: '500ms',
          }}/>
      </Grid>
      <Separator/>
    </TextGrid>
  )
}