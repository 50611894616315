import { Container, Grid, GridProps, styled, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import useAdminPtClassDetails from 'src/hooks/swr/coach/admin/use-admin-pt-class-details';
import { nameToCamelcase } from 'src/utils/clean-name';
import NavigateBack from 'src/components/navigate/NavigateBack';
import ButtonOutline from 'src/components/ButtonOutline';
import putDirectorCancelPtClass from 'src/api/coach/director/put-director-cancel-pt-class';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import putDirectorMarkAttendPtClass from 'src/api/coach/director/put-director-mark-attend-pt-class';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

export default function AdminPtClassDetails() {
  const isCoachDirector = useSelector((state: IRootState) => state.authCoach.accessRight === 'director');
  
  const { t } = useTranslation(['coach', 'customer', 'admin']);
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    title: '',
    dialogText: '',
  })

  let { ptClassId } = useParams();
  const { ptClassDetails, mutate } = useAdminPtClassDetails(parseInt(ptClassId as string));

  const handleDialogClose = () => {
    setDialogInfo({...dialogInfo, open: false});
  };

  const isPrivateClass = useMemo(() => {
    if (ptClassDetails) {
      return ptClassDetails.privateClassHash !== null
    }
  }, [ptClassDetails])

  const isClassInFuture = useMemo(() => {
    if (ptClassDetails) {
      return dayjs(ptClassDetails.date).hour(ptClassDetails.hour).minute(ptClassDetails.minute).isAfter(dayjs())
    }
  }, [ptClassDetails])

  const cancelBooking = async (ptClassId: number) => {
    const res = await putDirectorCancelPtClass(ptClassId);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setDialogInfo({
        open: true,
        title: isPrivateClass ? t('coach:private_class.cancel.title') : t('coach:pt_time.cancel.title'),
        dialogText: isPrivateClass ? t('coach:private_class.cancel.text') : t('coach:pt_time.cancel.text'),
      })
      mutate();
    }
  }

  const makeupAttend = async (ptClassId: number) => {
    const res = await putDirectorMarkAttendPtClass(ptClassId);
    const { status } = await res.json();
    if (status === 'attended' && res.status === 200) {
      setDialogInfo({
        open: true,
        title: t('admin:all_class.pt_class.makeup_attend'),
        dialogText: t('admin:all_class.pt_class.result.makeup_attend_success'),
      })
      mutate();
    }
  }

  return (
    <Container sx={{ padding: 2 }} maxWidth={'sm'}>
      {ptClassDetails &&
        <Grid
        item container
        xs={12}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <NavigateBack />
        </Grid>
        
        {ptClassDetails &&
          <Grid item>
            <Typography variant='h6' color='primary.main'>
              {!!ptClassDetails.privateClassHash && t('customer:book.title.private_class')}
              {!ptClassDetails.privateClassHash && t('customer:book.title.pt_session')}
            </Typography>
          </Grid>
        }

        {ptClassDetails &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer:book.fields.coach')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {nameToCamelcase(ptClassDetails.preferredNameCoach + ' ' + ptClassDetails.lastNameCoach)}
                </Typography>
              </Grid>
            </TextGrid>
            
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('admin:all_class.pt_class.client_name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {nameToCamelcase(ptClassDetails.preferredNameClient + ' ' + ptClassDetails.lastNameClient)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('admin:all_class.pt_class.coach_name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {nameToCamelcase(ptClassDetails.preferredNameCoach + ' ' + ptClassDetails.lastNameCoach)}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer:book.fields.date')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color={'primary.main'} textAlign='right'>
                  {dayjs(ptClassDetails.date).format('DD MMM[,] YYYY [-] ddd')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer:book.fields.time')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color={'primary.main'} textAlign='right'>
                  {
                    ptClassDetails.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                    + ':' +
                    ptClassDetails.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})
                  }
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer:book.fields.duration')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {ptClassDetails.duration + t('customer:mins')}
                </Typography>
              </Grid>
            </TextGrid>

            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer:book.fields.status')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2'
                  color={ptClassDetails.status !== 'cancelled' ? 'primary.main' : 'disableGrey.main'}
                  textAlign='right'>
                  {nameToCamelcase(ptClassDetails.status)}
                </Typography>
              </Grid>
            </TextGrid>
          </>
        }

        {isCoachDirector &&
          <>
            <Grid item>
              <ButtonOutline
                disabled={!isClassInFuture || (isPrivateClass && !isCoachDirector)}
                status='primary'
                label={isPrivateClass ? t('coach:private_class.btn.cancel') : t('coach:pt_time.btn.cancel')}
                onClick={() => cancelBooking(parseInt(ptClassId as string))} />
            </Grid>
            <Grid item>
              <ButtonOutline
                status='primary'
                label={t('admin:all_class.pt_class.makeup_attend')}
                onClick={() => makeupAttend(parseInt(ptClassId as string))} />
            </Grid>
          </>
        }
        {!isClassInFuture &&
          <Grid item>
            <Typography variant='body2' color='displayGrey.main'>
              {t('admin:all_class.pt_class.past_class_cannot_cancel')}
            </Typography>
          </Grid>
        }
        {isPrivateClass &&
          <Grid item>
            <Typography variant='body2' color='displayGrey.main'>
              {t('admin:all_class.pt_class.only_director_can_cancel_private_class')}
            </Typography>
          </Grid>
        }
  
        </Grid>
      }
      <Dialog
          open={dialogInfo.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogInfo.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('coach:pt_time.btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}