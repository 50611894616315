import useSWR from 'swr';
import axios from 'axios';
import { CustomerAllPurchaseRecordAdmin } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string, customerId?: number) => 
  axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
      params: { customerId },
    })
    .then(res => res.data);

export default function useAdminAllPurchaseHistory(customerId?: number) {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(
    [`${process.env.REACT_APP_API_SERVER_v1}/admin/purchase-history/all`, customerId],
    (url: string, customerId?: number) => fetcher(url, token, customerId)
  );
  return {
    allPurchaseRecord: data as CustomerAllPurchaseRecordAdmin[],
    mutate,
    error
  }
};
