import useSWR from 'swr';
import axios from 'axios';
import { Coach } from 'src/hooks/swr/output-types';

const fetcher = (url: string, token: string) => 
  axios
    .get(url, { headers: { Authorization: `Bearer ${token}` }})
    .then(res => res.data);

export default function useAdminDirectorAllCoach() {
  const token = localStorage.getItem('authCoachToken') as string;
  const { data, mutate, error } = useSWR(`${process.env.REACT_APP_API_SERVER_v1}/admin/coach/all`, url => fetcher(url, token));
  return {
    allCoach: data as Coach[],
    mutate,
    error
  }
};
