import { IRootState } from 'src/store';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CoachPrivateRoute ({ children }: { children: JSX.Element }) {
  const isAuthenticated = useSelector((state: IRootState) => state.authCoach.isAuthenticated);
  let location = useLocation();
  
  if (!isAuthenticated) {
    return <Navigate to='/coach/login' state={{ from: location }} replace />
  }

  return children
};

export default CoachPrivateRoute;
