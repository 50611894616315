import { useEffect, useState } from 'react';
import { Container, Typography, Grid, Chip, TextField, styled } from '@mui/material';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
import useCoachAllTimeslot from 'src/hooks/swr/coach/use-coach-all-timeslot';
import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import useCoachAllPtDateTimeAvailablity from 'src/hooks/swr/coach/use-coach-all-pt-date-time-availablity';
import postCoachPTDateTime from 'src/api/post-coach-pt-date-time';

interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
  isAvailable: boolean,
}

const CustomPickersDay = styled((props: CustomPickersDayProps) => (
  <PickersDay
    {...props}/>
), {
  shouldForwardProp: (prop) => prop !== "isAvailable"
})(({ theme, selected, isAvailable }) => ({
  ...(isAvailable && {
    backgroundColor: `${theme.palette.primary.main}4D`,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: `${theme.palette.primary.dark}4D`,
    },
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%"
  })
}));

export default function PTTimeManagementByDate() {
  const { t } = useTranslation('coach');
  const { allTimeslot } = useCoachAllTimeslot();
  const { allDateTimeAvailablity, mutate } = useCoachAllPtDateTimeAvailablity();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [currentAvailablity, setCurrentAvailablity] = useState<any>({});
  const [dateAvailablity, setDateAvailablity] = useState<undefined | string[]>();
  const [timeAvailablity, setTimeAvailablity] = useState<undefined | string[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (allDateTimeAvailablity) {
      setDateAvailablity(Object.keys(allDateTimeAvailablity));
      setCurrentAvailablity(allDateTimeAvailablity);
    }
  }, [allDateTimeAvailablity])

  const handleChipClick = (time: string) => {
    const date = selectedDate.format('YYYY-MM-DD');
    const dataTimeslot = currentAvailablity[date];
    const newAvailablity = Object.assign({}, currentAvailablity);
    if (timeAvailablity?.includes(time)) {
      const index = newAvailablity[date].indexOf(time);
      newAvailablity[date].splice(index, 1);
    } else {
      if (dataTimeslot) {
        newAvailablity[date].push(time);
      } else {
        newAvailablity[date] = [time];
      }
    }
    setCurrentAvailablity(newAvailablity);
  }

  const handleUpdate = async () => {
    setIsLoading(true);
    const result = await postCoachPTDateTime(currentAvailablity);
    const json: { successful: boolean } = await result.json();
    if (result.status === 200 && json.successful) {
      setDialogOpen(true);
      mutate();
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    setIsLoading(false);
  }

  useEffect(() => {
    const date = selectedDate.format('YYYY-MM-DD');
    setTimeAvailablity(currentAvailablity[date]);
    setDateAvailablity(Object.keys(currentAvailablity));
  }, [selectedDate, currentAvailablity])

  const renderPickerDay = (
    day: dayjs.Dayjs,
    selectedDays: dayjs.Dayjs[],
    pickersDayProps: PickersDayProps<dayjs.Dayjs>
  ) => {
    let isAvailable = false;
    if (dateAvailablity) {
      if (dateAvailablity.includes(day.format('YYYY-MM-DD'))) {
        isAvailable = true;
      }
    }
    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        isAvailable={isAvailable} />
    )
  };

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('pt_time.title')}
          </Typography>
        </Grid>

        <Grid item
          sx={{
            '& .MuiCalendarPicker-root': {
              width: 300,
            }
          }}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            openTo='day'
            label={t('group_class.fields.date')}
            inputFormat='DD/MM/YYYY'
            value={selectedDate}
            minDate={dayjs()}
            renderDay={renderPickerDay}
            onChange={(newDate) => {
              if (newDate === null) { return }
              setSelectedDate(newDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>


        <Grid item container justifyContent='center'>
          {allTimeslot && allTimeslot
            .map(timeslot => {
              const time = timeslot.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                + ':' + timeslot.minute.toLocaleString(undefined, {minimumIntegerDigits: 2});
              return (
                <Chip
                  variant={timeAvailablity?.includes(time) ? 'filled' : 'outlined'}
                  color='primary'
                  sx={{
                    margin: 0.5,
                    borderColor: 'primary.dark',
                    border: `1px solid`
                  }}
                  label={time}
                  key={time}
                  onClick={() => handleChipClick(time)}
                  />
              )
            })
          }
        </Grid>

        <Grid item>
          <ButtonPrimary
            status='primary'
            isLoading={isLoading}
            label={t('pt_time.btn.update')}
            aria-label='update-pt-time'
            onClick={handleUpdate}
            />
        </Grid>
        
        <Grid item container sx={{ marginTop: 5, width: 330 }} spacing={1}>
          <Grid item container>
            <Grid item xs={3}>
              <Chip
                variant='filled'
                color='primary'
                sx={{
                  borderColor: 'primary.dark',
                  border: `1px solid`
                }}
                label='12:00'
                onClick={() => {}}/>
            </Grid>
            <Grid item container xs={9} alignItems='center'>
              <Typography>
                {t('pt_time.explain.text', {state: t('pt_time.explain.available')})}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={3}>
              <Chip
                variant='outlined'
                color='primary'
                sx={{
                  borderColor: 'primary.dark',
                  border: `1px solid`
                }}
                label='12:00'
                onClick={() => {}}/>
            </Grid>
            <Grid item container xs={9} alignItems='center'>
              <Typography>
                {t('pt_time.explain.text', {state: t('pt_time.explain.unavailable')})}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {nameToCamelcase(t('pt_time.result.success'))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pt_time.result.text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('pt_time.btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}