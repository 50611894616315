export default function putAdminCancelSingleGroupClass(groupClassId: number) {
  return fetch(`${global.API_v1}/admin/group-class/cancel`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ groupClassId }),
  });
}
