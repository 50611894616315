export default function postCoachCancelPtClass(ptClassId: number) {
  return fetch(`${global.API_v1}/coach/pt-class/cancel`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ptClassId}),
  });
}
