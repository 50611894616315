import { Box, Typography } from '@mui/material';
import { theme } from 'src/styles/muiTheme';
import { useTranslation } from 'react-i18next';
import { nameToCamelcase } from 'src/utils/clean-name';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';
import dayjs from 'dayjs';
import { PtClassDetails, PTClassStatus } from 'src/hooks/swr/output-types';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';

interface ClassBoxProps {
  ptClass: PtClassDetails,
  isCoach?: boolean,
  status?: PTClassStatus,
}

export default function PtClassBox({
  ptClass,
  isCoach = false,
  status,
}: ClassBoxProps) {
  const { t } = useTranslation('coach');
  const isCoachAndPending = isCoach && ptClass.status === 'pending';

  return (
    <LinkWithoutStyle to={`${isCoach ? '/coach' : ''}/pt-class/${ptClass.ptClassId}`}>
      <Box sx={{
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(81, 143, 215, 0.25)',
        borderRadius: 2.5, // 10px
        border: '1px solid',
        borderColor: 'primary.dark',
        background: 'white.main',
        minWidth: 280,
        padding: 1,
        marginTop: 1,
        marginBottom: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box>
          <Typography variant='body2' color='primary.dark'
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}>
            {!ptClass.privateClassHash && t('dashboard.box.pt')}
            {!!ptClass.privateClassHash && t('dashboard.box.private_class')}
          </Typography>

          <Typography variant='body2' color='primary.dark'
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}>
            {isCoach && `${nameToCamelcase(ptClass.preferredNameClient)} ${nameToCamelcase(ptClass.lastNameClient)}`}
            {!isCoach && `${nameToCamelcase(ptClass.preferredNameCoach)} ${nameToCamelcase(ptClass.lastNameCoach)}`}
          </Typography>

          <Typography variant='body2' color='primary.dark'>
            {`${ptClass.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})}:${ptClass.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})} - ` + ptClass.duration + ' ' + t('minute')}
          </Typography>
          
          <Typography variant='body2' color='primary.dark'>
            {dayjs(ptClass.date).format('DD MMM[,] YYYY [-] ddd')}
          </Typography>
        </Box>

        <Box sx={{
          height: 60,
          border: '0px solid',
          borderLeftWidth: 1,
          borderColor: 'secondary.main',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: isCoachAndPending ? 'space-between' : 'center',
          paddingLeft: 1,
          paddingTop: 0.5,
          paddingBottom: 0.5,
        }}>
          <Typography variant='body2'
            sx={{
              fontSize: 16,
              fontWeight: 500,
            }}
            color={status !== 'cancelled' ? 'secondary.main' : 'disableGrey.main'}>
            {!status && t('dashboard.box.action')}
            {status && nameToCamelcase(status)}
          </Typography>
          {isCoachAndPending && <ChevronsRight stroke={theme.palette.secondary.main}/>}
        </Box>
      </Box>
    </LinkWithoutStyle>
  )
}