import { IRootState } from 'src/store';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// both director & admin has access right to admin
function CoachAdminPrivateRoute ({ children }: { children: JSX.Element }) {
  const isCoachDirector = useSelector((state: IRootState) =>
    (state.authCoach.accessRight === 'director' || state.authCoach.accessRight === 'admin' || state.authCoach.accessRight === 'manager')
  );
  let location = useLocation();
  
  if (!isCoachDirector) {
    return <Navigate to='/coach/dashboard' state={{ from: location }} replace />
  }

  return children
};

export default CoachAdminPrivateRoute;
