export default function postAdminPurchaseReceipt(purchaseHistoryId: number, isAdmin: boolean = true) {
  return fetch(`${global.API_v1}${isAdmin ? '/admin/purchase-history/receipt' : '/customer/payment/receipt'}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem(isAdmin ? 'authCoachToken': 'authCustomerToken')}`,
    },
    body: JSON.stringify({ purchaseHistoryId }),
  });
}
