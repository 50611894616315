/**
 * Not in use yet, SMS will direct coach to PT session details to confirm / cancel
 */

import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import postCoachConfirmPtClass from 'src/api/coach/post-coach-confirm-pt-class';

export default function CoachPTConfirmScreen() {
  const { t } = useTranslation('coach');
  let { ptClassId } = useParams();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const confirmBooking = async (ptClassId: number) => {
    const res = await postCoachConfirmPtClass(ptClassId);
    const { successful } = await res.json();
    if (successful && res.status === 200) {
      setSuccess(true);
      setDialogOpen(true);
    }
  }

  useEffect(() => {
    if (ptClassId) {
      confirmBooking(parseInt(ptClassId as string));
    }
  }, [ptClassId])

  const handleDialogClose = () => {
    setDialogOpen(false);
    navigate(`/coach/pt-class/${ptClassId}`)
  }

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('pt_time.confirm.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pt_time.confirm.text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('pt_time.btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
      {!success &&
        <Box
          sx={{
            width: 'calc(100vw - 8px*2))',
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress sx={{
            color: 'primary.main',
            alignSelf: 'center',
            height: '15vw !important',
            width: '15vw !important',
          }}/>
        </Box>
      }
    </Container>
  )
}