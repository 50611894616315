import ButtonLoading, { ButtonLoadingProps } from 'src/components/ButtonLoading';
import { styled } from '@mui/system';

export interface ButtonOutlineProps extends ButtonLoadingProps {
  label: string,
};

function ButtonLoadingForButtonOutline(
  props: ButtonOutlineProps,
) {
  const { status, label, ...allOtherButtonProps } = props;
  return (
    <ButtonLoading
      status={status}
      variant='outlined'
      {...allOtherButtonProps}
      >
      {label}
    </ButtonLoading>
  )
}

const ButtonOutline = styled(ButtonLoadingForButtonOutline, {
  // return true to return all props, including implict props.children
  // prop === 'status' || prop === 'isLoading' - will only pass these 2 props
  shouldForwardProp: (prop) => true,
  name: 'ButtonOutline',
  slot: 'Root',
  overridesResolver: (props, styles) => [
    styles.root,
    props.status === 'primary' && styles.primary,
    props.status === 'secondary' && styles.secondary,
    props.status === 'disabled' && styles.disabled,
    props.status === 'warning' && styles.warning,
  ],
})(( () => ({
})));

export default ButtonOutline;
