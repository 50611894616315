
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateBookStudioFormData, CreateEventStudioFormData } from 'src/api/form-types';
import BookStudioForm from 'src/components/coach/director/book-studio/BookStudioForm';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import postDirectorCreateInternalClass from 'src/api/coach/director/post-director-create-internal-class';
import postDirectorCreateOtherEvent from 'src/api/coach/director/post-director-create-other-event';

export default function DirectorBookStudio() {
  let { type } = useParams();
  const isOtherEvent = useMemo(() => type === 'other-event', [type]);

  const navigate = useNavigate();
  const { t } = useTranslation('coach');

  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    dialogText: '',
    resSuccessful: false,
  });

  const createInternalClass = async (data: CreateBookStudioFormData) => {
    const res = await postDirectorCreateInternalClass(data);
    const { successful } = await res.json();
    setDialogInfo({
      open: true,
      dialogText: t(`book_studio.dialog.create_${(successful && res.status === 200) ? 'successful' : 'fail'}`),
      resSuccessful: (successful && res.status === 200),
    });
  }

  const createOtherEvent = async (data: CreateEventStudioFormData) => {
    const res = await postDirectorCreateOtherEvent(data);
    const { successful } = await res.json();
    setDialogInfo({
      open: true,
      dialogText: t(`book_studio.dialog.create_${(successful && res.status === 200) ? 'successful' : 'fail'}_event`),
      resSuccessful: (successful && res.status === 200),
    });
  }

  const handleDialogClose = () => {
    setDialogInfo({ ...dialogInfo, open: false });
    if (dialogInfo.resSuccessful) {
      navigate('/coach/director/book-studio/');
    }
  }

  return (
    <Container sx={{ marginTop: 2, marginBottom: 3, padding: 0 }} maxWidth={'md'}>
      <BookStudioForm submitAction={isOtherEvent ? createOtherEvent : createInternalClass} isOtherEvent={isOtherEvent}/>

      <Dialog
        open={dialogInfo.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo.dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}