import { IAuthCustomerState, CustomerToken } from './state';
import { IAuthCustomerActions } from './actions';
import jwtSimple from 'jwt-simple';

const localtoken = localStorage.getItem('authCustomerToken');

let initalState: IAuthCustomerState;

/* TODO - refactor below json, repeating contents */

if (localtoken != null) {
  const decoded: CustomerToken = jwtSimple.decode(localtoken, 'no-secret-provided', true);
  initalState = {
    isAuthenticated: true,
    customerId: decoded.customerId,
    username: decoded.username,
    msg: '',
  };
} else {
  initalState = {
    isAuthenticated: false,
    customerId: 0,
    username: '',
    msg: '',
  };
}

export function authCustomerReducer(
  state: IAuthCustomerState = initalState,
  action: IAuthCustomerActions,
) {
  switch (action.type) {
    case 'LOGIN_CUSTOMER': 
      const decoded: CustomerToken = jwtSimple.decode(action.token, 'no-secret-provided', true);
      return {
        ...state,
        isAuthenticated: true,
        customerId: decoded.customerId,
        username: decoded.username,
        msg: '',
      };
    case 'LOGIN_CUSTOMER_FAILED':
      return {
        ...state,
        isAuthenticated: false,
        username: '',
        msg: action.msg,
      }
    case 'LOGOUT_CUSTOMER':
      return {
        isAuthenticated: false,
        customerId: 0,
        username: '',
        msg: '',
      }
    default:
      return state;
  }
}
