import { useState } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';

import { CustomerAllPurchaseRecord } from 'src/hooks/swr/output-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DownloadReceiptBtn from 'src/components/payment-record/DownloadReceiptBtn';

const RecordBox = styled(Box)(({ theme }) => ({
  width: '70vw',
  maxWidth: 320,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  // not redirect / navigate to record yet
  // cursor: 'pointer',
}));

const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

const getPaymentMethod = (code: string | undefined) => {
  let method: string;
  if (code === '801501' || code === '801501' || code === '801512' || code === '801512') {
    method = 'Alipay';
  } else if (code === '800201') {
    method = 'WeChat';
  } else if (code === '802001') {
    method = 'FPS';
  } else if (code === '800701') {
    method = 'UnionPay';
  } else {
    method = '/'
  }
  return method
};

interface PaymentRecordListProps {
  allPurchaseRecord: CustomerAllPurchaseRecord[],
}

export default function PaymentRecordList({
  allPurchaseRecord,
}: PaymentRecordListProps) {
  const { t } = useTranslation('customer');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [proofPath, setProofPath] = useState('');

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  return (
    <>
      {allPurchaseRecord && allPurchaseRecord.map((purchaseRecord, index) => (
        <Grid item key={`purchase-${index}`}>
          <RecordBox>
            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.credit')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {purchaseRecord.credit ? purchaseRecord.credit : purchaseRecord.referCredit}
                {purchaseRecord.goodsName === 'promotion_WELCOME220' ? '220' : ''}
                {purchaseRecord.goodsName === 'promotion_WELCOME5000' ? '5000' : ''}
                {(purchaseRecord.goodsName === 'custom_credit' && purchaseRecord.amountPaid)
                  ? `${(purchaseRecord.amountPaid) / 100}` : ''}
              </Typography>
            </TextBox>

            <TextBox>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.amount')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {purchaseRecord.amountPaid && `$${(purchaseRecord.amountPaid) / 100}`}
                {purchaseRecord.traditionalAmount && `$${(purchaseRecord.traditionalAmount) / 100}`}
              </Typography>
            </TextBox>

            <TextBox sx={{ marginBottom: 0.5 }}>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('record.fields.method')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'}>
                {purchaseRecord.paymentMethod && getPaymentMethod(purchaseRecord.paymentMethod)}
                {purchaseRecord.status && t('record.fields.offline')}
              </Typography>
            </TextBox>

            {purchaseRecord.status &&
              <TextBox sx={{ marginBottom: 0.5 }}>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.status')}
                </Typography>
                <Typography color={purchaseRecord.status !== 'rejected' ? 'primary.main' : 'error.main'} fontWeight={'bold'}>
                  {nameToCamelcase(purchaseRecord.status)}
                </Typography>
              </TextBox>
            }

            <TextBox sx={{ marginBottom: 0.5 }}>
              <Typography color='primary.main' fontWeight={'bold'} sx={{ minWidth: 64 }}>
                {t('record.fields.notes')}
              </Typography>
              <Typography color='primary.main' fontWeight={'bold'} sx={{ maxWidth: 'calc(100% - 64px - 10px - 8px)'}}>
                {/* To Refactor to function */}
                {purchaseRecord.traditionalReason ?
                    (purchaseRecord.traditionalReason === 'welcome_credit' ? t('record.reason.welcome') :
                      (purchaseRecord.traditionalReason === 'referral' ? t('record.reason.referral') :
                        (purchaseRecord.traditionalReason === 'class_cancel_refund' ? t('record.reason.class_cancel_refund') : purchaseRecord.traditionalReason)
                      )
                    )
                  :
                    '/'
                }
              </Typography>
            </TextBox>

            {purchaseRecord.promotionalCode &&
              <TextBox sx={{ marginBottom: 0.5 }}>
                <Typography color='primary.main' fontWeight={'bold'}>
                  {t('record.fields.promotion_code')}
                </Typography>
                <Typography
                  color={purchaseRecord.promotionalCodeRepeated ? 'error.main' : 'primary.light'}
                  fontWeight={'bold'} fontStyle={'italic'}>
                  {nameToCamelcase(purchaseRecord.promotionalCode)}
                </Typography>
              </TextBox>
            }

            {purchaseRecord.traditionalPhotoProofPath &&
              <TextBox sx={{ marginBottom: 0.5, justifyContent: 'center'}}>
                <ButtonPrimary
                  label={t('record.fields.proof')}
                  status='primary'
                  onClick={() => {
                    setDialogOpen(true);
                    setProofPath(purchaseRecord.traditionalPhotoProofPath as string);
                  }} />
              </TextBox>
            }

            <DownloadReceiptBtn purchaseRecord={purchaseRecord} isClient/>
            
            <Typography color='primary.main' fontWeight={'bold'} sx={{ alignSelf: 'flex-end' }}>
              {purchaseRecord.opwTransactionId && t('record.fields.opw_id') + purchaseRecord.opwTransactionId}
              {!purchaseRecord.opwTransactionId && t('record.fields.opw_id') + purchaseRecord.purchaseHistoryId}
            </Typography>

            <Typography color='primary.main' fontWeight={'bold'} sx={{ alignSelf: 'flex-end' }}>
              {purchaseRecord.transactionDateTime && dayjs(purchaseRecord.transactionDateTime).format('YYYY-MM-DD HH:mm:ss')}
              {!purchaseRecord.transactionDateTime && dayjs(purchaseRecord.purchaseHistoryTime).format('YYYY-MM-DD HH:mm:ss')}
            </Typography>
          </RecordBox>
        </Grid>
      ))}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <img
            src={`${AWS_S3_BUCKET}/${proofPath}`}
            alt='proof for offline payment'
            style={{ width: '100%' }}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}