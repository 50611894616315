import { useEffect, useRef, useState } from 'react';
import { Typography, Grid, styled, TextField, NativeSelect, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { AddCoachFormData, CoachType, CoachAccessRight, Gender } from 'src/api/form-types';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
// import useCoachAllCoachType from 'src/hooks/swr/use-coach-all-coach-type';
import validator from 'validator';
import postCoachPic from 'src/api/post-coach-pic';
import useCoachSingleCoachInfo from 'src/hooks/swr/use-coach-single-coach-info';

const fieldWidth = 250;

const RegisterTextfield = styled(TextField)(({ theme }) => ({
  width: fieldWidth,
}));

const coachTypeList: CoachType[] = ['junior', 'normal', 'senior', 'director'];
const accessRightList: CoachAccessRight[] = ['coach', 'manager', 'director', 'admin'];

type GenderType = 'male' | 'female' | 'non_binary';
const genderList: GenderType[] = ['male', 'female', 'non_binary'];
const genderValueList = ['male', 'female', 'non-binary'];

interface CoachFormProps {
  submitAction: (data: AddCoachFormData) => void,
  updateCoachId?: number,
  isCoachProfile?: boolean,
}

export default function CoachForm ({
  submitAction,
  updateCoachId,
  isCoachProfile = false,
}: CoachFormProps) {
  const { t } = useTranslation('coach');
  const isUpdate = updateCoachId !== undefined;
  const { coachInfo } = useCoachSingleCoachInfo(updateCoachId !== undefined ? updateCoachId : 0, isCoachProfile); 

  // const { allCoachType } = useCoachAllCoachType();
  const coachPicRef = useRef(null);
  const [coachPicLoading, setCoachPicLoading] = useState(false);

  const { register, handleSubmit, setValue, setError, watch, control, formState: { errors } } = useForm<AddCoachFormData>();

  register('firstName');
  register('lastName');
  register('preferredName');
  register('username');
  register('gender');
  register('password');
  register('confirmPassword');
  register('phoneNum');
  register('coachType');
  register('accessRight');

  const selectedCoachType = watch('coachType');
  const selectedAccessRight = watch('accessRight');
  const inputPreferredName = watch('preferredName');
  const uploadedCoachPicPath = watch('coachPicPath');
  const selectedGender = watch('gender');

  // fill existing info when in update / view page
  useEffect(() => {
    if (isUpdate && coachInfo && control) {
      setValue('firstName', coachInfo.first_name);
      setValue('lastName', coachInfo.last_name);
      setValue('preferredName', coachInfo.preferred_name);
      setValue('username', coachInfo.username);
      setValue('gender', coachInfo.gender);
      setValue('phoneNum', coachInfo.phone_number);
      setValue('coachType', coachInfo.type);
      setValue('accessRight', coachInfo.access_right);
      setValue('coachPicPath', coachInfo.icon_path);
    } else {
      // set default value for coach type & access right
      setValue('coachType', coachTypeList[0]);
      setValue('accessRight', accessRightList[0]);
    }
  }, [coachInfo, isUpdate, control, setValue])

  // const [creditInfo, setCreditInfo] = useState<string[][]>();

  // useEffect(() => {
  //   if (!allCoachType || !selectedCoachType) { return }
  //   const singleTypeCoachInfo: any = allCoachType.filter(type => type.type === selectedCoachType)[0];
  //   if (!singleTypeCoachInfo) { return }

  //   if (singleTypeCoachInfo['type'] !== undefined) {
  //     delete singleTypeCoachInfo.type;
  //   }

  //   const info = [];
  //   for (const infoKey in singleTypeCoachInfo) {
  //     info.push([nameToCamelcase(infoKey.replaceAll('_', ' ')), (singleTypeCoachInfo as any)[infoKey]]);
  //   }

  //   setCreditInfo(info);
  // }, [allCoachType, selectedCoachType])

  const uploadCoachPic = async (event: React.ChangeEvent<HTMLInputElement>, coachName: string) => {
    setCoachPicLoading(true);
    
    const formData = new FormData();
    const foo = await event.target.files?.item(0);
    formData.append('coachPic', foo as File);
    
    const res = await postCoachPic(formData);
    const { coachPicPath } = await res.json();
    
    setValue('coachPicPath', coachPicPath);
    setCoachPicLoading(false);
  }

  const onSubmit = handleSubmit(async (data: AddCoachFormData) => {
    if (!validator.isMobilePhone(data['phoneNum'], ['en-HK'])) {
      setError('phoneNum', {
        type: 'not-hk-mobile',
        message: t('add_coach.error.empty', {
          field: t('add_coach.error.hk_phone')
        })
      });
      return
    }

    if (!data.coachPicPath) {
      setError('coachPicPath', {
        type: 'empty',
        message: t('add_coach.error.need_coach_pic')
      });
      return
    }

    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'password_not_same',
        message: t('add_coach.error.password_not_same')
      });
      return
    }

    submitAction(data);
  });


  return (
    <form onSubmit={onSubmit}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Controller
            name='firstName'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('add_coach.fields.first_name')}
                required
                inputProps={{ 'aria-label': 'first_name' }}
                {...field} />
              }
            />
        </Grid>
        <Grid item>
          <Controller
            name='lastName'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('add_coach.fields.last_name')}
                required
                inputProps={{ 'aria-label': 'last_name' }}
                {...field} />
              }
            />
        </Grid>
        <Grid item>
          <Controller
            name='preferredName'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('add_coach.fields.preferred_name')}
                required
                inputProps={{ 'aria-label': 'preferred_name' }}
                {...field} />
              }
            />
        </Grid>
        <Grid item>
          <Controller
            name='username'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) =>
            <RegisterTextfield
              disabled={isUpdate}
              variant='outlined'
              label={t('add_coach.fields.username')}
              required
              inputProps={{ 'aria-label': 'username' }}
              {...field} />
            }
          />
        </Grid>

        <Grid item>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              {t('add_coach.fields.gender.text')}
            </InputLabel>
            <NativeSelect
              inputProps={{
                // only for reference only, no particular use
                name: 'gender', id: 'uncontrolled-native',
              }}
              value={selectedGender}
              onChange={e => {
                setValue('gender', e.target.value as Gender)
              }}
              sx={{ width: fieldWidth }}
              required
            >
              {/* is null default option is only rendered & preset in add new coach page */}
              {!isUpdate &&
                <option value={''} aria-label={`gender-select-default-null`}>
                  {t(`add_coach.fields.gender.text`)}
                </option>
              }
              {genderList.map((gender, index) => (
                <option value={genderValueList[index]} key={`gender-${gender}`} aria-label={`gender-select-${gender}`}>
                  {t(`add_coach.fields.gender.${gender}`)}
                </option>
                ))
              }
            </NativeSelect>
          </FormControl>
        </Grid>

        <Grid item>
          <Controller
            name='password'
            control={control}
            defaultValue=''
            rules={{ required: !isUpdate }}
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('add_coach.fields.password') + (isUpdate ? (' ' + t('add_coach.fields.optional')) : '')}
                type='password'
                required={!isUpdate}
                inputProps={{ 'aria-label': 'password' }}
                {...field} />
              }
            />
        </Grid>
        <Grid item>
          <Controller
            name='confirmPassword'
            control={control}
            defaultValue=''
            rules={{ required: !isUpdate }}
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('add_coach.fields.confirm_password') + (isUpdate ? (' ' + t('add_coach.fields.optional')) : '')}
                type='password'
                required={!isUpdate}
                inputProps={{ 'aria-label': 'confirm_password' }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword && errors.confirmPassword.message}
                {...field} />
              }
            />
        </Grid>
        <Grid item>
          <Controller
            name='phoneNum'
            control={control}
            defaultValue=''
            rules={{ required: true }}
            render={({ field }) =>
            <RegisterTextfield
              variant='outlined'
                label={t('add_coach.fields.phone_num')}
                required
                inputProps={{ 'aria-label': 'phone_num' }}
                error={!!errors.phoneNum}
                helperText={errors.phoneNum && errors.phoneNum.message}
                {...field} />
              }
            />
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native-accessRight">
              {t('add_coach.fields.coach_type')}
            </InputLabel>
            <NativeSelect
              disabled={isCoachProfile}
              inputProps={{
                // only for reference only, no particular use
                name: 'coachType', id: 'uncontrolled-native-coachType',
              }}
              variant='standard'
              value={selectedCoachType}
              onChange={e => {
                setValue('coachType', e.target.value as CoachType)
              }}
              sx={{ width: fieldWidth }}
            >
              {coachTypeList.map((coachType, index) => (
                <option value={coachTypeList[index]} key={`coach-type-${coachType}`} aria-label={`coach-type-select-${coachType}`}>
                  {nameToCamelcase(coachType)}
                </option>
                ))
              }
            </NativeSelect>
          </FormControl>
        </Grid>
        
        {/* To Refactor - charge & salary table for each type of coach */}
        {/* {creditInfo && creditInfo.map((list, index) => (
          <Grid item container direction='row' justifyContent='space-between' sx={{
            width: fieldWidth,
          }}
            key={`${list[0]}-${index}`}>
            <Grid item>
              <Typography textAlign={'right'}>
                {list[0] + ': '}
              </Typography>
            </Grid>
            <Grid item>
              <Typography textAlign={'right'}>
                {list[1] + ' ' + t('add_coach.fields.credit')}
              </Typography>
            </Grid>

          </Grid>
        ))} */}

        <Grid item>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native-accessRight">
              {t('add_coach.fields.access_right')}
            </InputLabel>
            <NativeSelect
              disabled={isCoachProfile}
              inputProps={{
                // only for reference only, no particular use
                name: 'accessRight', id: 'uncontrolled-native-accessRight',
              }}
              value={selectedAccessRight}
              onChange={e => {
                setValue('accessRight', e.target.value as CoachAccessRight)
              }}
              sx={{ width: fieldWidth }}
            >
              {accessRightList.map((accessRight, index) => (
                <option value={accessRightList[index]} key={`coach-type-${accessRight}`} aria-label={`coach-type-select-${accessRight}`}>
                  {nameToCamelcase(accessRight)}
                </option>
                ))
              }
            </NativeSelect>
          </FormControl>
        </Grid>

        <Grid item>
          <input
            type='file'
            ref={coachPicRef}
            accept='image/*'
            style={{ display: 'none' }}
            onChange={(event) => uploadCoachPic(event, inputPreferredName)}
            />
          <ButtonPrimary
            status='primary'
            isLoading={coachPicLoading}
            label={t('add_coach.btn.upload_pic')}
            onClick={() => coachPicRef.current && (coachPicRef.current as any).click()} />
        </Grid>

        <Grid item>
          <Typography>
            {t('add_coach.fields.coach_icon')}
          </Typography>
        </Grid>
        <Grid item>
          {uploadedCoachPicPath ?
            <img
              src={`${AWS_S3_BUCKET}/${uploadedCoachPicPath}`}
              alt='icon for new coach'
              style={{width: '70vw', maxWidth: 400}}/>
          :
            <Typography>
              {t('add_coach.fields.upload')}
            </Typography>
          }
        </Grid>
        {!!errors.coachPicPath && !uploadedCoachPicPath && 
          <Typography color='error.main'>
            {errors.coachPicPath.message}
          </Typography>
        }

        <Grid item>
          <ButtonPrimary
            status='primary'
            type='submit'
            label={isUpdate ? t('add_coach.btn.update') : t('add_coach.btn.register')}
            aria-label='register-btn'
            sx={{ width: 150 }}
          />
        </Grid>
        
      </Grid>
    </form>
  )
}