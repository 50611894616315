import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StyledQRCode from 'src/components/qr-code/StyledQRCode';
import { IRootState } from 'src/store';
import { useSelector } from 'react-redux';
import useCoachSingleCoachInfo from 'src/hooks/swr/use-coach-single-coach-info';
import useLatestNewMemberCredit from 'src/hooks/swr/use-latest-new-member-credit';
import ButtonOutline from 'src/components/ButtonOutline';

export default function Referral() {
  const { t } = useTranslation('coach');
  const coachId = useSelector((state: IRootState) => state.authCoach.coachId);
  const { coachInfo } = useCoachSingleCoachInfo(coachId);
  const { newMemberCredit } = useLatestNewMemberCredit();

  return (
    <Container sx={{ padding: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('referral.title')}
          </Typography>
        </Grid>

        {coachInfo && newMemberCredit &&
          <>
            <Grid item>
              <StyledQRCode
                ignoreImage
                url={`${CURRENT_SITE}/register?referral=${coachInfo.referral_code}`} />
            </Grid>

            <Grid item>
              <ButtonOutline
                status='primary'
                label={t('referral.btn.get_link')}
                onClick={() => {
                  navigator.clipboard.writeText(`${CURRENT_SITE}/register?referral=${coachInfo.referral_code}`)
                  window.alert(t('referral.alert.copied'))
                }} />
            </Grid>
            <Grid item>
              <Typography>
                {coachInfo.referral_code}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography>
                {t('referral.text', { credit: newMemberCredit.coach_referral })}
              </Typography>
            </Grid> */}
          </>
        }

      </Grid>
    </Container>
  )
}