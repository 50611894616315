import { CustomerStatus } from 'src/api/form-types';

export default function postCoachModifyCustomerStatus(data: CustomerStatus) {
  return fetch(`${global.API_v1}/coach/customer/status/`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
