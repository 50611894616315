import { ChangePasswordFormData } from 'src/api/form-types';

export default function postCustomerChangePassword(data: ChangePasswordFormData) {
  return fetch(`${global.API_v1}/auth/customer/change-password`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
}
