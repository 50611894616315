import { useMemo, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as ListIcon } from 'src/icons/components/WebAppBar/list.svg';
import { ReactComponent as GiftIcon } from 'src/icons/components/WebAppBar/gift.svg';
import { ReactComponent as LogoHorizontal } from 'src/icons/logo/logo-horizontal.svg';
import { theme } from 'src/styles/muiTheme';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/system';
import { useState } from 'react';
import usePopupGlobalClickCollapse from 'src/hooks/use-popup-global-click-collapse';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IRootState } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'src/redux/auth_customer/thunks';
import { logout as logoutCoach } from 'src/redux/auth_coach/thunks';

export const appBarHeightLarge = 64;
export const appBarHeightSmall = 56;

const originalLogoWidth = 572;
const originalLogoHeight = 200;

const logoMargin = 10;

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 0,
  boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: `${theme.spacing(1.2)} !important`,
  paddingRight: `${theme.spacing(1.2)} !important`,
}));

const BoxForBarMenu = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 10,
  right: 10,
  paddingLeft: 20,
  paddingRight: 20,
  boxShadow: `0px 0px 10px ${theme.palette.tertiary.dark}80`,
  borderRadius: 20,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.white.main,
  zIndex: 100,
  maxWidth: 600,
}));

const MenuOptionBox = styled(Box)(({ theme }) => ({
  paddingTop: 20,
  paddingBottom: 20,
  width: '100%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: `${theme.palette.primary.main}40`,
  },
}));

const MenuSeparator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  height: 1,
}));

const CoachDirectorSeparator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  width: '100%',
  height: 2,
}));

const LgnTextBox = styled('span')(({ theme }) => ({
  fontFamily: 'Noto Sans CJK TC,Roboto,Helvetica,Arial,sans-serif',
}));

const LinkWithoutStyle = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
}));

export default function WebAppBar() {
  const { t, i18n } = useTranslation('customer');
  const { t: t_c } = useTranslation('coach');

  const isCustomerAuthenticated = useSelector((state: IRootState) => state.authCustomer.isAuthenticated);
  const isCoachAuthenticated = useSelector((state: IRootState) => state.authCoach.isAuthenticated);
  const isCoachDirector = useSelector((state: IRootState) => state.authCoach.accessRight === 'director');
  const isCoachAdmin = useSelector((state: IRootState) =>
    (state.authCoach.accessRight === 'director' || state.authCoach.accessRight === 'admin' || state.authCoach.accessRight === 'manager')
  );

  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const logoHeight = useMemo(() => (isSmUp ? appBarHeightLarge : appBarHeightSmall) - logoMargin, [isSmUp]);
  const appBarHeight = useMemo(() => (isSmUp ? appBarHeightLarge : appBarHeightSmall), [isSmUp]);

  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(document.createElement('div'));  // dummy initial ref for type-checking;
  usePopupGlobalClickCollapse(menuRef, setMenuVisible);

  const handleChangeLgn = () => {
    const targetLanguage = i18n.language === 'en' ? 'tc' : 'en';
    i18n.changeLanguage(targetLanguage);
    localStorage.setItem('lgn', targetLanguage);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  const handleLogoutCoach = () => {
    dispatch(logoutCoach());
  };

  const loggedOutLinks = [
    { to: '', name: t('top_bar.mainpage') },
    { to: '/coach-overview', name: t('top_bar.coach_overview')},
    { to: '/login', name: t('top_bar.login', {id: t('id.member')}) },
    { to: '/coach/login', name: t('top_bar.login', {id: t('id.coach')}) },
  ];

  const loggedCustomerLinks = [
    { to: '/dashboard', name: t('top_bar.dashboard')},
    { to: '/coach-overview', name: t('top_bar.coach_overview')},
    { to: '/book', name: t('top_bar.book_a_class')},
    { to: '/membership', name: t('top_bar.membership')},
    { to: '/charge-list', name: t('top_bar.charge_list')},
    { to: '/record', name: t('top_bar.class_purchase_record')},
    { to: '/profile', name: t('top_bar.profile')},
    { to: '', name: t('top_bar.mainpage') },
  ];

  const loggedCoachLinks = [
    { to: '/coach/dashboard', name: t_c('top_bar.dashboard')},
    { to: '/coach/pt-class', name: t_c('top_bar.pt_time_man')},
    { to: '/coach/group-class', name: t_c('top_bar.group_class')},
    { to: '/coach/charge-list', name: t('top_bar.charge_list')},
    { to: '/coach/profile', name: t_c('top_bar.coach_profile')},
    { to: '/coach/timetable', name: t_c('top_bar.timetable')},
    { to: '/coach/search-customer', name: t_c('top_bar.search_customer')},
  ];

  const coachAdminLinks = [
    // TODO - coach director manage all classes
    // { to: '/coach/director/class-management', name: t_c('top_bar.director.class_man')},
    { to: '/coach/admin/coach-management/', name: t_c('top_bar.admin.coach_statistics')},
    { to: '/coach/admin/membership', name: t_c('top_bar.admin.membership')},
    { to: '/coach/admin/purchase-record', name: t_c('top_bar.admin.purchase_record')},
    // { to: '/coach/admin/group-class', name: t_c('top_bar.admin.group_class')},
    { to: '/coach/admin/all-class', name: t_c('top_bar.admin.all_class')},
  ]

  const coachDirectorLinks = [
    // TODO - coach director manage all classes
    // { to: '/coach/director/class-management', name: t_c('top_bar.director.class_man')},
    { to: '/coach/director/credit-management', name: t_c('top_bar.director.credit_man')},
    { to: '/coach/director/coach-management', name: t_c('top_bar.director.coach_man')},
    { to: '/coach/director/book-studio', name: t_c('top_bar.director.book_studio')},
  ]

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomAppBar position='static'>
        <CustomToolbar>
          <Grid container justifyContent={'flex-start'} alignItems={'center'}>
            <Grid item ref={menuRef}>
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='menu'
                onClick={() => setMenuVisible(!menuVisible)}
              >
                <ListIcon stroke={theme.palette.primary.light} />
              </IconButton>
              <BoxForBarMenu
                sx={{ top: appBarHeight + 12 }}
                onClick={() => setMenuVisible(false)}>
                <Collapse in={menuVisible}>
                  
                  {(!isCustomerAuthenticated && !isCoachAuthenticated) && loggedOutLinks.map(((item, index) => (
                    <div key={`topbar-link-${index}`}>
                      <LinkWithoutStyle to={item.to}>
                        <MenuOptionBox>
                          <Typography textAlign={'center'}>
                            {item.name}
                          </Typography>
                        </MenuOptionBox>
                      </LinkWithoutStyle>
                      <MenuSeparator/>
                    </div>
                  )))}

                  {isCustomerAuthenticated && loggedCustomerLinks.map(((item, index) => (
                    <div key={`topbar-link-${index}`}>
                      <LinkWithoutStyle to={item.to}>
                        <MenuOptionBox>
                          <Typography textAlign={'center'}>
                            {item.name}
                          </Typography>
                        </MenuOptionBox>
                      </LinkWithoutStyle>
                      <MenuSeparator/>
                    </div>
                  )))}

                  {isCoachAuthenticated && loggedCoachLinks.map(((item, index) => (
                    <div key={`topbar-link-${index}`}>
                      <LinkWithoutStyle to={item.to}>
                        <MenuOptionBox>
                          <Typography textAlign={'center'}>
                            {item.name}
                          </Typography>
                        </MenuOptionBox>
                      </LinkWithoutStyle>
                      {index !== loggedCoachLinks.length - 1 && <MenuSeparator/> }
                    </div>
                  )))}

                  {isCoachAdmin && 
                    <>
                      <Typography textAlign={'center'} sx={{ color: 'secondary.dark' }}>
                        {t_c('top_bar.admin.access')}
                      </Typography>
                      <CoachDirectorSeparator/>
                        {coachAdminLinks.map((item, index) => (
                          <div key={`topbar-link-${index}`}>
                            <LinkWithoutStyle to={item.to}>
                              <MenuOptionBox>
                                <Typography textAlign={'center'}>
                                  {item.name}
                                </Typography>
                              </MenuOptionBox>
                            </LinkWithoutStyle>
                            {index !== coachAdminLinks.length - 1 && <MenuSeparator/> }
                          </div>
                        ))}
                      {!isCoachDirector && <CoachDirectorSeparator/>}
                    </>
                  }

                  {isCoachDirector && 
                    <>
                      <Typography textAlign={'center'} sx={{ color: 'secondary.dark' }}>
                        {t_c('top_bar.director.access')}
                      </Typography>
                      <CoachDirectorSeparator/>
                        {coachDirectorLinks.map((item, index) => (
                          <div key={`topbar-link-${index}`}>
                            <LinkWithoutStyle to={item.to}>
                              <MenuOptionBox>
                                <Typography textAlign={'center'}>
                                  {item.name}
                                </Typography>
                              </MenuOptionBox>
                            </LinkWithoutStyle>
                            {index !== coachDirectorLinks.length - 1 && <MenuSeparator/> }
                          </div>
                        ))}
                      <CoachDirectorSeparator/>
                    </>
                  }

                  {!isCoachAuthenticated &&
                    <MenuOptionBox
                      onClick={handleChangeLgn}
                    >
                      <Typography textAlign={'center'}>
                        {t('top_bar.switch_lgn') + ' '}
                        <LgnTextBox sx={{ fontWeight: i18n.language === 'en' ? 700 : 400 }}>
                          {t(`both_lgn.en`)}
                        </LgnTextBox>
                        <LgnTextBox>
                          {' / '}
                        </LgnTextBox>
                        <LgnTextBox sx={{ fontWeight: i18n.language !== 'en' ? 700 : 400 }}>
                          {t(`both_lgn.tc`)}
                        </LgnTextBox>
                      </Typography>
                    </MenuOptionBox>
                  }

                  {isCustomerAuthenticated &&
                    <>
                      <MenuSeparator/>
                      <MenuOptionBox onClick={handleLogout}>
                        <Typography textAlign={'center'}>
                          {t('top_bar.logout')}
                        </Typography>
                      </MenuOptionBox>
                    </>
                  }

                  {isCoachAuthenticated &&
                    <>
                      {!(isCoachDirector || isCoachAdmin) && <MenuSeparator/>}
                      <MenuOptionBox onClick={handleLogoutCoach}>
                        <Typography textAlign={'center'}>
                          {t('top_bar.logout')}
                        </Typography>
                      </MenuOptionBox>
                    </>
                  }
                </Collapse>
              </BoxForBarMenu>
            </Grid>
            <Grid item
              sx={{
                height: appBarHeight,
                padding: logoMargin / 2 / 10
              }}>
              <Link 
                to={
                  isCoachAuthenticated || isCustomerAuthenticated ?
                    `${isCoachAuthenticated ? 'coach' : ''}/dashboard`
                  :
                    `/`
                }>
                <LogoHorizontal
                  height={logoHeight}
                  width={originalLogoWidth * logoHeight / originalLogoHeight}
                />
              </Link>
            </Grid>
          </Grid>

          <LinkWithoutStyle
            to={
              isCoachAuthenticated || isCustomerAuthenticated ?
                `${isCoachAuthenticated ? 'coach' : ''}/referral`
              :
                `/register`
            }>

            {/* icon-button version */}
            {/* <IconButton aria-label='refer' size='large'>
              <GiftIcon stroke={theme.palette.primary.main}/>
            </IconButton> */}

            <Button
              variant='text'
              startIcon={<GiftIcon/>}
              sx={{
                color: 'primary.main',
                border: 'none',
                minWidth: 0,
                width: 110,
                paddingLeft: 1.2,
                paddingRight: 1.2,
                '&:hover': {
                  border: 'none'
                }
              }}
            >
              {t('top_bar.referral')}
            </Button>
          </LinkWithoutStyle>

        </CustomToolbar>
      </CustomAppBar>
    </Box>
  );
}