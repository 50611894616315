import { Box, Typography, styled, SxProps } from '@mui/material';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';

const BookAClass = styled(Box)(({ theme }) => ({
  width: '70vw',
  maxWidth: 300,
  // height: 30,
  minHeight: 30,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: '5px 10px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface NextBoxProps {
  label: string,
  onClick? : () => void;
  sx?: SxProps,
  sxTy?: SxProps,
  arrowProps?: React.SVGProps<SVGSVGElement>,
}

export default function NextBox({
  label,
  onClick,
  sx,
  sxTy,
  arrowProps,
}: NextBoxProps) {
  return (
    <BookAClass sx={sx} onClick={onClick}>
      <Typography color='primary.main' fontWeight={'bold'}
        sx={sxTy}>
        {label}
      </Typography>
      <ChevronsRight {...arrowProps} />
    </BookAClass>
  )
}