export default function putAdminUpdatePurchaseStatus(purchaseHistoryId: number, status: 'approved' | 'rejected') {
  return fetch(`${global.API_v1}/coach/purchase-history/status`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({purchaseHistoryId, status}),
  });
}
