import { IAuthCoachState, CoachToken } from './state';
import { IAuthCoachActions } from './actions';
import jwtSimple from 'jwt-simple';

const localtoken = localStorage.getItem('authCoachToken');

let initalState: IAuthCoachState;

/* TODO - refactor below json, repeating contents */

if (localtoken != null) {
  const decoded: CoachToken = jwtSimple.decode(localtoken, 'no-secret-provided', true);
  initalState = {
    isAuthenticated: true,
    coachId: decoded.coachId,
    username: decoded.username,
    accessRight: decoded.accessRight,
    msg: '',
  };
} else {
  initalState = {
    isAuthenticated: false,
    coachId: 0,
    username: '',
    accessRight: '',
    msg: '',
  };
}

export function authCoachReducer(
  state: IAuthCoachState = initalState,
  action: IAuthCoachActions,
) {
  switch (action.type) {
    case 'LOGIN_COACH': 
      const decoded: CoachToken = jwtSimple.decode(action.token, 'no-secret-provided', true);
      return {
        ...state,
        isAuthenticated: true,
        coachId: decoded.coachId,
        username: decoded.username,
        accessRight: decoded.accessRight,
        msg: '',
      };
    case 'LOGIN_COACH_FAILED':
      return {
        ...state,
        isAuthenticated: false,
        username: '',
        accessRight: '',
        msg: action.msg,
      }
    case 'LOGOUT_COACH':
      return {
        isAuthenticated: false,
        coachId: 0,
        username: '',
        accessRight: '',
        msg: '',
      }
    default:
      return state;
  }
}
