import { PtClassForm } from 'src/api/form-types';

export default function postCustomerNewPtClass(data: PtClassForm) {
  return fetch(`${global.API_v1}/customer/pt-class/book`, {
    method: 'POST',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCustomerToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
