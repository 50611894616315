import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function useOnInitChangeLanguage() {
  const { i18n } = useTranslation();
  useEffect(() => {
    // default language is tc
    const targetLanguage = localStorage.getItem('lgn') === null ? 'tc' : localStorage.getItem('lgn') as unknown as string;
    i18n.changeLanguage(targetLanguage);
    localStorage.setItem('lgn', targetLanguage);
  }, [i18n])
}