import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkDispatch as OldThunkDispatch } from 'redux-thunk';
import { logger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { IAuthCustomerState } from 'src/redux/auth_customer/state';
import { IAuthCustomerActions } from 'src/redux/auth_customer/actions';
import { authCustomerReducer } from 'src/redux/auth_customer/reducer';
import { IAuthCoachState } from 'src/redux/auth_coach/state';
import { IAuthCoachActions } from 'src/redux/auth_coach/actions';
import { authCoachReducer } from 'src/redux/auth_coach/reducer';
import {
  RouterState,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any,
  }
  var API_v1: string;
  var AWS_S3_BUCKET: string;
  var CURRENT_SITE: string;
  var QF_PAY_ORIGIN: string;
  var QF_PAY_APP_CODE: string;
  var QF_PAY_API_KEY: string;
  var ATTEND_CLASS_HOUR: number;
}

export const history = createBrowserHistory();

export interface IRootState {
  authCustomer: IAuthCustomerState,
  authCoach: IAuthCoachState,
  router: RouterState,
}

type IRootAction = IAuthCustomerActions | IAuthCoachActions;

const rootReducer = combineReducers<IRootState>({
  authCustomer: authCustomerReducer,
  authCoach: authCoachReducer,
  router: connectRouter(history),
});

const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type ThunkDispatch = OldThunkDispatch<IRootState, null, IRootAction>;

export default createStore<IRootState, IRootAction, {}, {}>(
  rootReducer,

  composeEnhancers(
    /* order is important
     * redux-logger should be put last to await any middleware
     */
    applyMiddleware(thunk),
    applyMiddleware(logger),
    applyMiddleware(routerMiddleware(history)),
  )
)
