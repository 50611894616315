export interface CoachInfo {
  description?: string,
  award?: string[],
  tag: string,
  linkFb?: string,
  linkIg?: string,
  linkYoutube?: string,
};

const coachInfo: any = {
  'epsilon-wong': {
    'description': '於2005年獲選香港最具潛質運動員獎項 (首位及至現時唯一一位健美運動員獲此獎項)，在2008、2009及2011皆獲選香港十大傑出健身教練，2012年獲選香港工會聯合會傑出社區康體導師之卓越大獎，並於2013年成為首位華人獲選Life Fitness世界十大教練(至今唯一華人獲得該項殊榮)，2014年更獲選香港教練培訓委員會之香港優秀教練。',
    'supplement_0': '2004年起以香港隊代表身份代表香港參與多個國際賽事並贏得多屆香港女子健美錦標賽冠軍、亦連續多年贏得亞洲女子健美錦標賽及東亞健美錦標賽女子健美冠軍，於2010年在第四屆全國體育大會中贏得女子健美錦標賽二等獎，2013年為鼓勵學生及運動員再次亦是最後一次踏上健美比賽的舞台，更贏得女子國際健美錦標邀請賽冠軍。於2018年榮獲第五屆「青年夢想實踐家」獎項。',
    'award': [
      '2013 香港國際健美邀請賽 (International Bodybuilding Invitation Championship) - 女子健美冠軍1stplace',
      '2010 第四屆全國體育大會 (All-China Games) - 二等獎 2nd place',
      '2005-2008 東亞健美錦標賽 (East Asian) - 冠軍 1st place',
      '2003-2008 亞洲女子健美錦標 (Asian Women) - 冠軍 1st place',
      '2007 全港健美錦標賽 (HKCBBA) - 冠軍 1st place',
      '2006 全港健美錦標賽 (HKCBBA)- 亞軍及最佳造型 2nd place & Best Posting',
      '2003-2004 全港健美錦標賽 (HKCBBA) - 冠軍及最佳造型 1st place & Best Posting',
    ],
    'title': [
      '2021 年獲委任香港女童軍總會九龍地方協會名譽會長',
      '2020 年榮獲社會企業研究院院士',
      '第七屆「青年夢想實踐家」選舉召集人及終選評審',
      '2020 - 2022 年「青年夢想實踐家」第五屆理事會副主席',
      '2020 - 2021 年度公民女子足球隊體適能總教練',
      '2019 年樹仁大學體適能及健康導師證書課程課程總監',
      '2018 年獲選第五屆「青年夢想實踐家」',
      '2018 年《關懷糭有您》慈善活動召集人',
      '2016 - 2020 年香港健康產業年度大獎大會評審',
      '2017 年榮獲香港地品牌銀獎',
      '2016 年《隨時隨地輕運動 》作者',
      '2015 年榮獲香港健康產業大獎',
      '2015 中國香港空手道總會 東亞賽事統籌委員會 榮譽副會長',
      '2014 - 2019年中國香港健美總會一及二健身教練證書課課程統籌及總監',
      '獲選香港教練培訓委員會之 2014 香港優秀教練 (2014 HK Coaching Award)',
      '獲選 2012 年度香港工會聯合會 傑出社區康體導師獎勵計劃 卓越大獎',
      '2008, 2009, 2011 獲選香港十大傑出健身教練',
      '2005 獲選香港最具潛質運動員 (HK Potential Sports Star Award)',
      '具備中國一級健身指導員上崗資格 (CBBA Grade 1 Fitness Instructor)',
      '香港禁毒兵團會長',
    ],
    'cert': [
      'Certified Functional Strength Coach (CFSC)',
      'Stick Mobility Certificate Level 1',
      'Rehab Trainer Masterclass (ULRIK LARSEN)',
      'Rehab Trainer Rehab Essentials(ULRIK LARSEN)',
      'Redcord Active Introduction (Redcord TAIWAN)',
      'The Redcord Education Program – Neurac 1 (Redcord TAIWAN)',
      'Anatomy Trains Fundamental Certificate',
      'Silver Advance Personal Fitness Trainer',
      'Representant of Gymstick products in Hong Kong',
      'Crankit Master Trainer (SE Asia Region)',
      'TurboSonic & Sonix Master Trainer',
      'Kamagon Ball Hong Kong Senior Trainer',
      'Functional Movement Systems – Administer the FMS',
      'Anatomy Trains Fundamental Certificate',
      'HKFBF Power Lifting Technique Certificate',
      'Acti-Tape Pro & RockTape - Rock Doc Instructor',
      'Movement Efficiency Training (M.E.T.) Instructor',
      'ViPR Training、Agatsu Kettlebell Instructor',
      'TRX Group Suspension Training & TRX Suspension Training Instructor',
      'Blackroll Trainer',
      'Blomberg Rhythmic Movement Training Level 1 – Rhythmic',
      'Movement Training and Primitive Reflexes & Level 2 – Rhythmic Movements, emotions and Inner Leadership',
      'Thera-Band SDS Exercise Ball Training 及Application of Thera-Band in the Muscle Re-condition Training Instructor',
      'Pilates Instructor (MatWork), Pilates for Personal Trainers & Pilates Group Class Teaching - Program Design, Cueing & Movement Breakdown',
      'Boxing, Running, Fit-ball, Synthesis Aerobic, Step, Spinning Instructor',
      '健體教練培訓 (Beijing Sport University)',
      'Diploma in Recreation Management (CUHK – School of Continuing and Professional Studies)',
      'Foundation Certificate in Sport & Recreation Studies (HKUSpace)',
    ],
    'linkIg': 'https://www.instagram.com/epsilonwong/',
  },
  'hugo-ho': {
    'description': 'Hugo在運動及體適能行業工作超過15年。他除了先後於多間NGO及商業機構擔任健體中心主任、體育及康樂經理，亦於大學及大專院校任職兼職講師。Hugo多年來為不同的電視健康節目、報章及電台等媒體、大學研究及康體活動等作出運動介紹及示範、訪問、專欄及分享。近年，Hugo除了積極為本地運動隊伍提供專業體適能訓練，亦透過拓展運動社企以培訓及提攜更多的教練以回饋社會。',
    'award': [
      "2019《第一屆BeFit全民健身頒獎典禮》- 優質私人教練獎",
      "2018賽馬會香港優秀教練選舉-社區優秀教練獎",
      "2018 灣仔區傑出青年",
      "World Fitness Federation Grand Prix Asia 2018",
      "Champion at Men's Fitness Model (Tall) & Overall Champion at Men's Fitness Model",
      "2018全港健美錦標賽暨第九屆華南地區健美邀請賽",
      "華南男子 Sport Model B組冠軍; 及男子公開 Sport Model B組冠軍",
      "2016 香港中文大學「優秀運動員獎學金」得主",
      "2016 十大傑出健身教練獎",
    ],
    'work': [
      '運動社企 One Plus Wellness 創辦人',
      '運動醫學及健康科學理學碩士',
      '前香港健美代表及青訓代表隊教練',
      '香港活木球代表隊體能教練',
      '葵青女子排球隊體能教練',
      '公民女子足球隊副體能教練',
      '浸會大學女子排球隊體能教練',
      '浸會大學龍舟隊體能教練',
    ],
    'cert': [
      '香港中文大學運動醫學及健康科學理學碩士',
      '香港浸會大學體育及康樂管理(榮譽)學士',
      '香港中文大學運動創傷防護員',
      '美國運動醫學會私人教練',
      '懸吊式阻力訓練教練',
      '中國香港體適能總會健體球及橡筋帶教練',
      '中國香港體適能總會阻力訓練教練',
      '中國香港體適能總會優質健行領袖證書',
      '中國香港體適能總會體適能測試領袖證書',
      '亞洲運動及體適能專業學院 - 長者體適能訓練教練',
      '亞洲運動及體適能專業學院 - 伸展教練證書課程',
      // '香港中文大學運動醫學及健康科學理學碩士',
      // 'MSc in Sports Medicine and Health Sciences, CUHK',
      // '香港浸會大學體育及康樂管理(榮譽)學士',
      // 'BA(Hons) in Physical Education and Recreation Management, HKBU',
      // 'Certified Functional Strength Coach Level 1 Certification',
      // 'Stick Mobility Level 1 Certification',
      // 'EXOS Performance Specialist Certification',
      // 'Exxentric Level II Flywheel Trainer',
      // '香港中文大學運動創傷防護員',
      // 'CUHK Sports Trainer',
      // '美國運動醫學會私人教練',
      // 'The ACSM Certified Personal Trainer',
      // 'TRX懸吊式阻力訓練教練',
      // 'Certified TRX Group Suspension Training System Trainer',
      // '中國香港體適能總會健體球及橡筋帶教練',
      // 'HKPFA Fitball and Elastic band Instructor',
      // '中國香港體適能總會阻力訓練教練',
      // 'HKPFACertifiedResistance Training Instructor',
      // '中國香港體適能總會優質健行領袖證書',
      // 'HKPFA Quali-walk Leader Certificate',
      // '中國香港體適能總會體適能測試領袖證書',
      // 'HKPFA Physical Fitness Assessment Leader Certificate',
      // '亞洲運動及體適能專業學院 - 長者體適能訓練教練',
      // 'AASFP Certified Elderly Fitness Trainer',
      // '亞洲運動及體適能專業學院 - 伸展教練證書課程 ',
      // 'AASFP Certified Stretch Trainer'
    ],
    'linkIg': 'https://www.instagram.com/hugofitandwell/',
    'linkYoutube': 'https://www.youtube.com/channel/UCLICOehHcWZoWyHyN8fkwTw',
  },
  'iris-kwok': {
    'description': 'Iris 郭靖雯是前香港女足球員代表隊球員，擁有多年運動員的經驗，多次代表香港參與國際賽事。近年轉型做體適能教練，為運動隊伍進行體能訓練。除左走進社區及校園提供提體適能培訓，更是私人教練提供1對1課程及小組訓練班。',
    'award': [
      '2022 全港健美錦標賽 女子運動模特兒 第4名',
      'Certified FRC® Mobility Specialist (FRCms)',
      'Personal Trainer Certification',
      'Certification of Completion for Certified Functional Strength Coach Level 1',
      'Stick Mobility® level 1 movement Specialist',
      'Sports & Fitness Training Foundation Certificate',
      'Asian Football Confederation ‘C’ Coaching Certificate 2021-2022 賽馬會女子足球聯賽盃（甲組） 球隊最佳球員',
    ],
    'tag': '足球、籃球、排球、田徑',
    'linkFb': 'https://www.facebook.com/Kwok-Ching-Man-%E9%83%AD%E9%9D%96%E9%9B%AF-110811408222516/',
    'linkIg': 'https://www.instagram.com/chingman_bonfim/',
  },
  'keith-kwan': {
    'description': 'Keith是一名新晉體適能教練及健美運動員。於2022年更成為直立板教練。陽光開朗的他無時無刻都在挑戰自己，追求運動上的突破。',
    'award': [
      '2022年沙灘健美賽第四名',
      '香港健美總會教練一及二級',
      'Epfitpro 體式能的健康導師證書',
      '力量訓練表現教練一級證書',
      'Sports Massage Technique Certification',
      'Certification of Completion for Certified Functional Strength Coach Level 1',
      'Stick Mobility® level 1 movement Specialist',
      'ISA Flat water sup Instructor',      
    ],
    'tag': '徒手運動、直立板、室內賽艇（短途）',
    'linkIg': 'https://www.instagram.com/keithfit_sup/',
  },
  'nel-fung': {
    'description': 'Nel擁有多個體適能教練資格，並置力推廣Strength & Condition Training (肌力與體適力訓練)，為不同類別的人士及運動員提升運動表現、狀態及預防受傷。現為多支不同類別的球隊擔任體適能教練。',
    'award': [
      'Certification of Completion for Certified Functional Strength Coach Level 1',
      'Personal Trainer Certification',
      'Physical Fitness Foundation Certification',
      'Sports & Fitness Training Foundation Certificate',
      'Sports Massage Technique Certification (by Ep-fitpro Plus Body Sculpture Academy)',      
    ],
    'tag': '足球',
    'linkIg': 'https://www.instagram.com/nelxfit/',
  },
  'flora-yu': {
    'description': 'Flora  修習瑜伽多年，深明瑜伽對身心的重要性。2018年為了更深入了解瑜伽哲學，遠赴印度修讀第一個瑜伽導師課程，及後更定期到印度進修。她完成了500小時高級哈達瑜伽導師課程和頌缽療法課程，及後更熱衷學習健身和伸展教學。希望透過教授，向學生發放正面能量，活出健康人生。',
    'award': [

    ],
    'tag': '瑜伽',
  },
  'king-tsui': {
    'description': '阿敬擁有多個體適能導師資格，並具有豐富兒童及青少年田徑及肌力與體能訓練(Strength and Conditioning)的教學經驗。身為越野及長跑愛好者的他亦以業餘運動的身份致力推動大眾接觸運動，明白當中的好處及樂趣。',
    'award': [
      'Advanced Personal Fitness Trainer Certification',
      'Strength Performance Trainer Workshop - Level 1',
      'Level 1 Award in Coaching Weight Lifting',
      'Exercise Professional Personal Trainer Level',
    ],
    'tag': '越野跑、長跑',
  },
  'grace-li': {
    'description': 'Grace是現役女子甲一排球隊隊員，擁有豐富運動員經驗。除了走進不同社區及校園提供提體適能培訓，2022年她更獲取本地一級排球教練資格，盼透過推廣體適能訓練以提升球員運動表現、狀態及預防受傷。',
    'award': [
      '本地一級排球教練',
    ],
    'tag': '排球',
  },
  'barry-lee': {
    'description': '',
    'award': [
      'Certification of Completion for Certified Functional Strength Coach Level 1',
      'Certified FRC® Mobility Specialist (FRCms)',
      'Stick Mobility® level 1 movement Specialist',
      'Certified - Bronze (Professional Personal Fitness Trainer)',
      'Level 1 Athletic Coaching Course',
      'Pilates Matwork (Level One) Instructor',
    ],
    'tag': '',
  },
  'pui-yee': {
    'description': '',
    'award': [
      'Certification of Completion for Certified Functional Strength Coach Level 1',
      'Sports & Fitness Training Foundation Certificate',      
    ],
    'tag': '',
  },
  'garif-ng': {
    'description': '',
    'award': [
      'Certificate in First AID',
      'Sports & Fitness Training Foundation Certificate',
    ],
    'tag': '',
  },
  'tsz-kwan': {
    'description': '',
    'award': [
      'Certification of Completion for Certified Functional Strength Coach Level 1',
      'Sports & Fitness Training Foundation Certificate',
      'Sports Massage Technique Certification',
    ],
    'tag': '',
  },
  'nikita-leung': {
    'description': '',
    'award': [
      'Sports & Fitness Training Foundation Certificate',
    ],
    'tag': '排球',
  },
  // 'keith-kwan': {
  //   'description': '',
  //   'award': [

  //   ],
  //   'tag': '',
  // },
};

export default coachInfo;