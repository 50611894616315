import { styled, Typography, Box, FormControl, InputLabel, NativeSelect } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import useDirectorAllClient from 'src/hooks/swr/coach/director/use-director-all-client';
import { useForm } from 'react-hook-form';
import { MakeupBookGroupClass } from 'src/api/form-types';
import { KeyedMutator } from 'swr';
import postDirectorMakeupBookGroupClass from 'src/api/coach/director/post-director-makeup-book-group-class';

const fieldWidth = 250;

const RegularBox = styled(Box)(({ theme }) => ({
  width: '70vw',
  maxWidth: 300,
  minHeight: 30,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  padding: '5px 10px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  groupClassId: number,
  mutate: KeyedMutator<any>,
};

export default function AdminDirectorGroupClassMakeUp({
  groupClassId,
  mutate,
}: Props) {
  const { t } = useTranslation('admin');
  const [dialogOpen, setDialogOpen] = useState(false);

  const { allClient } = useDirectorAllClient();

  const { register, handleSubmit, setValue, watch } = useForm<MakeupBookGroupClass>();

  register('groupClassId');
  register('customerId');

  const selectedCustomerId = watch('customerId');

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onSubmit = handleSubmit(async (data: MakeupBookGroupClass) => {
    const res = await postDirectorMakeupBookGroupClass(data);
    const { successful } = await res.json();
    const customerName = allClient.filter(client => client.id === data.customerId)[0].name;
    if (res.status === 200 && successful) {
      window.alert(t('group_class.make_up.alert.success', { name: customerName }));
      mutate();
      setDialogOpen(false);
    } else {
      window.alert(t('group_class.make_up.alert.fail', { name: customerName }));
    }
  });

  useEffect(() => {
    setValue('groupClassId', groupClassId);
  }, [setValue, groupClassId]);

  return (
    <>
      <RegularBox onClick={() => { setDialogOpen(true) }}>
        <Typography color='primary.main' fontWeight={'bold'}>
          {t('group_class.box.make_up')}
        </Typography>
        <ChevronsRight/>
      </RegularBox>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <form onSubmit={onSubmit}>
          <DialogTitle id='alert-dialog-title'>
            {t('group_class.box.make_up')}
          </DialogTitle>
          {allClient &&
            <DialogContent>             
              <FormControl fullWidth>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                  {t('group_class.make_up.customer')}
                </InputLabel>
                <NativeSelect
                  inputProps={{
                    // only for reference only, no particular use
                    name: 'make-up-client', id: 'uncontrolled-native',
                  }}
                  value={selectedCustomerId}
                  onChange={e => {
                    setValue('customerId', parseInt(e.target.value));
                  }}
                  sx={{ width: fieldWidth }}
                  required>
                  <option value={''} aria-label={`customer-select-default-null`}>
                    {t(`group_class.make_up.customer`)}
                  </option>
                  {allClient.map((client, index) => (
                    <option value={client.id} key={`customer-${client.id}`} aria-label={`client-select-${client.id}`}>
                      {client.name}
                    </option>
                    ))
                  }
                </NativeSelect>
              </FormControl>
            </DialogContent>
          }
          <DialogActions >
            <Button type='submit' autoFocus>
              {t('btn.confirm')}
            </Button>
            <Button onClick={handleDialogClose} autoFocus>
              {t('btn.back')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}