import { EditPromotionCode } from "src/api/form-types";

export default function putAdminEditPromotionCode(data: EditPromotionCode) {
  return fetch(`${global.API_v1}/admin/promotion-code/edit`, {
    method: 'PUT',
    headers: {
      "Authorization":`Bearer ${localStorage.getItem('authCoachToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
