import  { useEffect, useMemo, useRef } from 'react';
import QRCodeStyling from 'qr-code-styling';
import { Box, SxProps } from '@mui/material';
import { theme } from 'src/styles/muiTheme';

interface ClassQRCodeProps {
  url: string,
  sx?: SxProps,
  ignoreImage?: boolean,
}

export default function StyledQRCode({
  url,
  sx,
  ignoreImage,
}: ClassQRCodeProps) {
  const ref = useRef<null | HTMLElement>(null);
  const qrCode = useMemo(() => new QRCodeStyling({
    width: 250,
    height: 250,
    data: url,
    // TODO
    // Safari specific issue - cannot draw image into QR code in 1st loading, but can render after refreshing

    // iOS specific issue - cannot draw image into QR code
    // image: `${CURRENT_SITE}/opw_logo_only_192_bg.png`,
    // iOS specific & general solution
    // get image from the same folder
    image: !ignoreImage ? `./opw_logo_only_192_bg.png` : undefined,
    dotsOptions: {
      color: theme.palette.primary.main,
      type: 'rounded',
    },
    imageOptions: {
      // fail safe for icon loading
      // set hideBackgroundDots to fill up center when the image is not properly loaded
      // caution: this will need a picture with same-colour background with designated QR code
      hideBackgroundDots: false,
      crossOrigin: 'anonymous',
      margin: 5,
      imageSize: 0.5,
    }
  }), [url, ignoreImage]);

  useEffect(() => {
    qrCode.append(ref.current as HTMLElement);
  }, [qrCode]);

  return (
    <Box ref={ref}
      sx={{
        ...sx,
        margin: 1,
      }} />
    )
}