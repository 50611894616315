import { Dispatch, SetStateAction, useEffect } from 'react';
import { Typography, Grid, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { nameToCamelcase } from 'src/utils/clean-name';
import { Controller, useForm } from 'react-hook-form';
import { CoachType, CoachCreditUpdate, CoachTypeCredit, CreditUpdateType } from 'src/api/form-types';
import putCoachType from 'src/api/coach/director/put-coach-type';
import { CreditTextfield } from 'src/pages/coach/director/coach-credit/CreditManagement';
import { CoachTypeInfo } from 'src/hooks/swr/output-types';
import { KeyedMutator } from 'swr';
import React from 'react';

interface CoachCreditFormProps {
  allCoachType: CoachTypeInfo[],
  mutate: KeyedMutator<any>,
  updateType: CreditUpdateType,
  setDialogOpen: Dispatch<SetStateAction<boolean>>,
  setDialogMessage: Dispatch<SetStateAction<string>>,
  isCredit?: boolean,
  isStarGroupClass?: boolean,
  isSpecialGroupClass?: boolean,
}

export default function CoachCreditForm({
  allCoachType,
  mutate,
  updateType,
  setDialogOpen,
  setDialogMessage,
  isCredit = false,
  isStarGroupClass = false,
  isSpecialGroupClass = false,
}: CoachCreditFormProps) {
  const { t } = useTranslation('coach');
  const { handleSubmit, setValue, control, formState: { errors } } = useForm<CoachCreditUpdate>();

  useEffect(() => {
    if (allCoachType) {
      allCoachType.forEach(coachType => setValue(coachType.type, coachType[updateType]))
    }
  }, [allCoachType, setValue, updateType]);

  const onSubmitCoachCredit = handleSubmit(async (data: CoachCreditUpdate) => {
    const parsedData: CoachTypeCredit[] = [];
    for (let key in data) {
      parsedData.push({
        type: key as CoachType,
        credit: data[key as CoachType]
      })
    }
    
    const res = await putCoachType(parsedData, updateType);
    const { successful } = await res.json();

    if (successful && res.status === 200) {
      setDialogMessage(t(`credit.title.${updateType}`))
      setDialogOpen(true);
      mutate();
    }
    
  });

  // To Refactor
  // hardcoded coach type
  // wrt block-scope variable isStarGroupClass
  const coachTypeTextTransform = (coachType: CoachType) => {
    let text = 'Coach';
    switch (coachType) {
      case 'junior':
        text = 'Junior Coach';
        break
      case 'normal':
        text = 'Coach';
        break
      case 'senior':
        text = 'Senior Coach';
        break
      case 'director':
        text = 'Director';
        break
    }
    return text
  }

  return (
    <form onSubmit={onSubmitCoachCredit}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginTop: 2 }}>
        
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t(`credit.title.${updateType}`)}
          </Typography>
        </Grid>
        
        {/* To Refactor - Grid -> key */}
        {allCoachType && allCoachType.map((coachType) => {
          if (isStarGroupClass || isSpecialGroupClass) {
            if (coachType.type !== 'senior' && coachType.type !== 'director') {
              return <React.Fragment key={`${updateType}-${coachType.type}`}></React.Fragment>
            }
          }
          return (
            <Grid item container key={`${updateType}-${coachType.type}`}
              alignItems='flex-end'
              sx={{ width: 350}}
              >
              <Grid item container xs={4} justifyContent='flex-end'>
                <Typography color='primary.dark' textAlign='right'>
                  {coachTypeTextTransform(coachType.type)}
                </Typography>
              </Grid>

              <Grid item container xs={8} justifyContent='center'>
                <Controller
                  name={coachType.type}
                  control={control}
                  defaultValue={0}
                  rules={{ required: true }}
                  render={({ field }) =>
                    <CreditTextfield
                      variant='standard'
                      label={coachTypeTextTransform(coachType.type)}
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9.]*', 'aria-label': 'phone_num' }}
                      InputProps={{
                        startAdornment: !isCredit && <InputAdornment position='start'>$</InputAdornment>,
                        endAdornment: isCredit && <InputAdornment position='end'>{t('credit.credits')}</InputAdornment>,
                      }}
                      error={!!errors[coachType.type]}
                      helperText={errors[coachType.type] && (errors[coachType.type] as {message: string}).message}
                      {...field} />
                    }
                  />
              </Grid>
            </Grid>
          )})}
        <Grid item>
          <ButtonPrimary
            status='primary'
            type='submit'
            label={nameToCamelcase(t('update'))}
            aria-label='update-btn'
            sx={{ width: 150 }}
          />
        </Grid>
      </Grid>
    </form>
  )
}