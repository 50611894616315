import { Container, Grid, GridProps, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import NavigateBack from 'src/components/navigate/NavigateBack';
import PaymentRecordListAdmin from 'src/components/payment-record/PaymentRecordListAdmin';
import useCoachSingleCustomer from 'src/hooks/swr/coach/use-coach-single-customer';
import useCoachSingleCustomerAllPurchaseHistory from 'src/hooks/swr/coach/use-coach-single-customer-all-purchase-history';
import { nameToCamelcase } from 'src/utils/clean-name';
import stripeHKPhone from 'src/utils/stripe-hk-phone';
import { CustomerStatus } from 'src/api/form-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import postCoachModifyCustomerStatus from 'src/api/coach/put-coach-modify-customer-status';

import Collapse from '@mui/material/Collapse';
import NavigateTo from 'src/components/navigate/NavigateTo';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import useCoachSingleCoachInfo from 'src/hooks/swr/use-coach-single-coach-info';
import useCoachCustomerReferral from 'src/hooks/swr/coach/use-coach-customer-referral';
import SessionTitleCollapsable from 'src/components/customer/SessionTitleCollapsable';
import adminCleanPhone from 'src/utils/admin-clean-phone';
import CustomerLink from 'src/components/coach/customer-management/CustomerLink';
import useCoachCustomerReferredBy from 'src/hooks/swr/coach/use-coach-customer-referral-by';

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

const ThemeSeparator = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '70vw',
  maxWidth: 500,
  height: 1,
}));

const MemberTypeButton = styled(Button)(({ theme }) => ({
  height: 30,
}));

export default function CustomerSingle() {
  const coachId = useSelector((state: IRootState) => state.authCoach.coachId);
  const { t } = useTranslation('coach');
  const { customerPhone } = useParams();
  // only return unique customer with unique phone number
  const { allCustomer, mutate } = useCoachSingleCustomer(customerPhone !== undefined ? customerPhone : '');
  const { allReferredCustomer } = useCoachCustomerReferral(customerPhone !== undefined ? customerPhone : '');
  const { referredBy } = useCoachCustomerReferredBy(customerPhone !== undefined ? customerPhone : '');

  const customerInfo = useMemo(() => {
    if (allCustomer) {
      return allCustomer[0]
    }
  }, [allCustomer]);

  const { allPurchaseRecord } = useCoachSingleCustomerAllPurchaseHistory(customerInfo ? customerInfo.id : undefined);

  const { coachInfo } = useCoachSingleCoachInfo(coachId, true);

  const [purchaseRecordVisible, setPurchaseRecordVisible] = useState(false);
  const [referralVisible, setReferralVisible] = useState(false);
  const [isVip, setIsVip] = useState<boolean | null>(null);
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    text: '',
  });

  useEffect(() => {
    if (customerInfo) {
      // setIsSeleted(customerInfo.is_star_selected);
      setIsVip(customerInfo.is_vip);
    }
  }, [customerInfo])

  const handleDialogClose = () => {
    setDialog({ ...dialog, open: false });
  };
  
  const putCustomerStatus = async (data: CustomerStatus) => {
    const res = await postCoachModifyCustomerStatus(data);
    if (res.status === 200) {
      setDialog({
        open: true,
        title: t('customer.dialog.title'),
        text: t('customer.dialog.text'),
      })
    }
    mutate();
  }

  const isAdminDirector = coachInfo && (coachInfo.access_right === 'admin' || coachInfo.access_right === 'director');

  return (
    <Container sx={{ padding: 0, paddingTop: 2 }} maxWidth={'md'}>
      <Grid
        item container
        xs={12}
        spacing={1.5}
        direction='column'
        justifyContent='center'
        alignItems='center'>

        <Grid item>
          <NavigateBack />
        </Grid>

        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {t('customer.title')}
          </Typography>
        </Grid>

        {customerInfo &&
          <>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.preferred_name + ' ' + customerInfo.last_name)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.birthday')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {dayjs(customerInfo.birthday).format('YYYY MMM DD')}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.gender')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {nameToCamelcase(customerInfo.gender)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.phone_number')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {stripeHKPhone(customerInfo.phone_number)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.username')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {stripeHKPhone(customerInfo.username)}
                </Typography>
              </Grid>
            </TextGrid>
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer.fields.available_credit')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main'>
                  {customerInfo.available_credit}
                </Typography>
              </Grid>
            </TextGrid>

            {/* <Grid item>
              <FormControl>
                <FormLabel id='demo-radio-buttons-group-label'>{t('customer.fields.is_selected')}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue={false}
                  name='radio-buttons-group'
                  value={isSeleted}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const target = (event.target as HTMLInputElement).value === 'true';
                    setIsSeleted(target);
                    putCustomerStatus({ type: 'is_star_selected', status: target, customerId: customerInfo.id});
                  }}
                >
                  <FormControlLabel value={true} control={<Radio />} label='Yes' />
                  <FormControlLabel value={false} control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>
            </Grid> */}

            {isAdminDirector && isVip !== null &&
              <React.Fragment>
                <TextGrid>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {t('customer.fields.member_type')}
                    </Typography>
                  </Grid>
                  <Grid item container
                    xs={8}
                    justifyContent='flex-end'
                    spacing={2}>
                    <Grid item>
                      <MemberTypeButton
                        variant={isVip ? 'contained' : 'outlined'}
                        sx={{
                          height: 30,
                        }}
                        onClick={() => {
                          const target = true;
                          setIsVip(target);
                          putCustomerStatus({ type: 'is_vip', status: target, customerId: customerInfo.id});
                        }}>
                        {t('customer.fields.vip')}
                      </MemberTypeButton>
                    </Grid>
                    <Grid item>
                      <MemberTypeButton
                        variant={!isVip ? 'contained' : 'outlined'}
                        onClick={() => {
                          const target = false;
                          setIsVip(target);
                          putCustomerStatus({ type: 'is_vip', status: target, customerId: customerInfo.id});
                        }}>
                        {t('customer.fields.ordinary_member')}
                      </MemberTypeButton>
                    </Grid>
                  </Grid>
                </TextGrid>
                <Grid item>
                  <ThemeSeparator />
                </Grid>
              </React.Fragment>
            }
            
            {!isAdminDirector && isVip !== null &&
              <TextGrid>
                <Grid item>
                  <Typography variant='body1' color='primary.main'>
                    {t('customer.fields.member_type')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2' color='primary.main'>
                    {isVip ? t('customer.fields.vip') : t('customer.fields.ordinary_member')}
                  </Typography>
                </Grid>
              </TextGrid>
            }

            {referredBy !== undefined && referredBy.phone_number !== undefined && referredBy.phone_number !== null &&
              <React.Fragment>
                <TextGrid alignItems='center'>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {t('customer.fields.referred_by')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CustomerLink
                      to={`/coach/customer/${stripeHKPhone(referredBy.phone_number)}`}
                      name={nameToCamelcase(referredBy.preferred_name + ' ' + referredBy.last_name)}
                      phoneNumber={adminCleanPhone(referredBy.phone_number)}
                      sx={{
                        width: 150,
                      }} />
                  </Grid>
                </TextGrid>
                <Grid item>
                  <ThemeSeparator />
                </Grid>
              </React.Fragment>
            }

            {referredBy !== undefined && referredBy.coach_id !== undefined && referredBy.coach_id !== null &&
              <React.Fragment>
                <TextGrid alignItems='center'>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {t('customer.fields.referred_by_coach')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body1' color='primary.main'>
                      {nameToCamelcase(referredBy.coach_preferred_name + ' ' + referredBy.coach_last_name)}
                    </Typography>
                  </Grid>
                </TextGrid>
                <Grid item>
                  <ThemeSeparator />
                </Grid>
              </React.Fragment>
            }
          </>
        }

        {isAdminDirector &&
          <Grid item>
            <NavigateTo
              to={`/coach/admin/customer/${customerPhone}`}
              label={t('customer.btn.manual_password')}
              />
          </Grid>
        }

        {isAdminDirector &&
          <Grid item>
            <NavigateTo
              to={`/coach/admin/credit/${customerPhone}`}
              label={t('customer.btn.manual_credit')}
              />
          </Grid>
        }
        
        <SessionTitleCollapsable
          state={purchaseRecordVisible}
          setState={setPurchaseRecordVisible}
          label={t('customer.purchase_record.title')} />

        {allPurchaseRecord &&
          <Grid item>
            <Collapse in={purchaseRecordVisible} timeout={'auto'}>
              <Grid
                item container
                xs={12}
                spacing={1}
                direction='column'
                justifyContent='center'
                alignItems='center'>
                {allPurchaseRecord && allPurchaseRecord.length === 0 &&
                  <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
                    sx={{
                      marginTop: 1,
                      marginBottom: 1,
                    }}>
                    {t('purchase_record.empty', {status: ''})}
                  </Typography>
                }
                {allPurchaseRecord &&
                  <PaymentRecordListAdmin
                    allPurchaseRecord={allPurchaseRecord}
                    mutate={mutate}
                    isReadOnly />
                }
              </Grid>
            </Collapse>
          </Grid>
        }

        <SessionTitleCollapsable
          state={referralVisible}
          setState={setReferralVisible}
          label={t('customer.referral.title')} />

        {allReferredCustomer &&
          <Grid item>
            <Collapse in={referralVisible} timeout={'auto'}>
              <Grid
                item container
                xs={12}
                spacing={1}
                direction='column'
                justifyContent='center'
                alignItems='center'>
                {allReferredCustomer.length === 0 &&
                  <Typography variant='body1' color='disableGrey.main' fontStyle='italic'
                    sx={{
                      marginTop: 1,
                      marginBottom: 1,
                    }}>
                    {t('customer.referral.empty', {status: ''})}
                  </Typography>
                }
                {allReferredCustomer.length !== 0 &&
                  <React.Fragment>
                    <TextGrid>
                      <Grid item>
                        <Typography variant='body1' color='primary.main'>
                          {t('customer.referral.count')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body1' color='primary.main' fontWeight='bold' fontStyle='italic'>
                          {allReferredCustomer.length}
                        </Typography>
                      </Grid>
                    </TextGrid>
                    {allReferredCustomer.map((customer) => (
                      <Grid item key={customer.id}>
                        <CustomerLink
                          to={`/coach/customer/${stripeHKPhone(customer.phone_number)}`}
                          name={nameToCamelcase(customer.preferred_name + ' ' + customer.last_name)}
                          phoneNumber={adminCleanPhone(customer.phone_number)} />
                      </Grid>
                    ))}
                  </React.Fragment>
                }
              </Grid>
            </Collapse>
          </Grid>
        }

      </Grid>

      <Dialog
        open={dialog.open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
              color='primary'
              fontSize={18}
              sx={{ marginBottom: 1 }}>

            {dialog.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            {t('btn.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}