import { Box } from '@mui/material';
import { ReactComponent as LogoFb } from 'src/icons/components/social-media/facebook.svg';
import { ReactComponent as LogoIg } from 'src/icons/components/social-media/instagram.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { theme } from 'src/styles/muiTheme';

interface SocialMediaLinkBoxProps {
  linkFbMessenger?: string,
  linkIgMessenger?: string,
  linkWhatsapp?: string,
  phoneNum?: string,
}

export default function SocialMediaLinkBox({
  linkFbMessenger,
  linkIgMessenger,
  linkWhatsapp,
  phoneNum,
}: SocialMediaLinkBoxProps) {

  const totalLink =
    (linkFbMessenger ? 1 : 0) +
    (linkIgMessenger ? 1 : 0) +
    (linkWhatsapp ? 1 : 0) +
    (phoneNum ? 1 : 0)
  ;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      width: 330,
      justifyContent: totalLink === 1 ? 'center' : 'space-between',
    }}>
      {/* for external link, use a tag instead of react router dom v6 Link under LinkWithoutStyle */}
      {linkFbMessenger &&
        <a href={linkFbMessenger} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
            }}>
            <LogoFb width={28} height={28} />
          </Box>
        </a>
      }

      {linkIgMessenger &&
        <a href={linkIgMessenger} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
            }}>
            <LogoIg width={28} height={28} />
          </Box>
        </a>
      }

      {linkWhatsapp &&
        <a href={linkWhatsapp} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
              // for MUI icons, clear out the a tag styling
              textDecoration: 'none',
              // color: 'inherit',
              color: theme.palette.primary.main,
            }}>
            <WhatsAppIcon width={28} height={28} />
          </Box>
        </a>
      }

      {phoneNum &&
        <a href={`tel:${phoneNum}`} target='_blank' rel="noreferrer">
          <Box
            sx={{
              width: 38,
              height: 38,
              // basic radius is 4px in MUI sx system
              borderRadius: 3,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1.7px solid',
              borderColor: 'primary.main',
              boxShadow: `0px 4px 6px ${theme.palette.primary.main}40`,
              // for MUI icons, clear out the a tag styling
              textDecoration: 'none',
              // color: 'inherit',
              color: theme.palette.primary.main,
            }}>
            <LocalPhoneIcon width={28} height={28} />
          </Box>
        </a>
      }
    </Box>
  )
}