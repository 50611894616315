import { Grid, GridProps, styled, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { PtClassDetails } from 'src/hooks/swr/output-types';
import dayjs from 'dayjs';
import { nameToCamelcase } from 'src/utils/clean-name';
import NavigateBack from 'src/components/navigate/NavigateBack';
import StyledQRCodeForClassAttendance from 'src/components/customer/qr-code/StyledQRCodeForClassAttendance';
// const QrScanner = require('qr-scanner');
import QrScanner from 'qr-scanner';
import { useEffect, useMemo, useState } from 'react';
import putCoachMarkPtClassAttend from 'src/api/coach/put-coach-mark-pt-class-attend';
import ButtonOutline from 'src/components/ButtonOutline';
import { KeyedMutator } from 'swr';

// const attendanceMinute = 30;
const attendanceMinute = 120;

const TextGrid = styled((props: GridProps) => (
  <Grid
    item container
    justifyContent='space-between'
    alignItems='flex-end'
    {...props} />
))(({ theme }) => ({
  width: '80%',
  maxWidth: '500px !important',
}));

interface Props {
  ptClassDetails: PtClassDetails,
  mutatePtClassDetails?: KeyedMutator<any>,
  isCoach?: boolean,
  canConfirm?: boolean,
}

export default function PTClassDetails({
  ptClassDetails,
  mutatePtClassDetails,
  isCoach = false,
  canConfirm = true,
}: Props) {
  const { t } = useTranslation(['coach', 'customer']);
  const isPendingNotConfirmable = !canConfirm && ptClassDetails.status === 'pending';

  const classDayjsTime = useMemo(() => {
    if (ptClassDetails) {
      return dayjs(ptClassDetails.date).hour(ptClassDetails.hour).minute(ptClassDetails.minute);
    }
  }, [ptClassDetails]);

  const canTakeAttendance = useMemo(() => {
    if (!classDayjsTime || !ptClassDetails) { return }
    return ptClassDetails.status === 'confirmed' && dayjs().isBetween(classDayjsTime.subtract(attendanceMinute, 'minute'), classDayjsTime.add(attendanceMinute + 60, 'minutes'))
  }, [ptClassDetails, classDayjsTime]);
  const [cameraOn, setCameraOn] = useState(false);
  const [qrScanner, setQrScanner] = useState<QrScanner>();
  const [isOnQrResult, setIsOnQrResult] = useState(false);

  useEffect(() => {
    console.log({ isCoach, cameraOn })
    if (!isCoach) { return }
    if (!cameraOn) { return }
    const video = document.getElementById('qr-video');
    if (video) {
      const qrScanner = new QrScanner(
        video as HTMLVideoElement,
        async (result) => {
          console.log('decoded qr code:', result);
          console.log(typeof result);
          if (isOnQrResult) { return }
          setIsOnQrResult(true);
          const dataList = result.data.split('-');
          // window.alert(`Decoded qr code is ${result.data}`)
          const res = await putCoachMarkPtClassAttend(parseInt(dataList[1]), parseInt(dataList[3]));
          if (res.status === 200 && mutatePtClassDetails) {
            mutatePtClassDetails();
            window.alert(`Attendance is taken!`);
            setIsOnQrResult(false);
          } else if (res.status === 202) {
            window.alert(`Attendance is taken!`);
          }
        }
      , {});
      console.log(qrScanner)
      setQrScanner(qrScanner);
      // console.log(qrScanner.hasCamera())
      // console.log(qrScanner.listCamera())
      // QrScanner.hasCamera()
      //   .then((v) => console.log(v))
      // QrScanner.listCameras()
      //   .then((v) => console.log(v));
      qrScanner.start();
      return () => {
        qrScanner.stop();
        // qrScanner.destroy();
      }

    }
  }, [isCoach, cameraOn, isOnQrResult, mutatePtClassDetails]);

  const handleAttendCamera = (cameraOn: boolean) => {
    setCameraOn(!cameraOn);
    if (!qrScanner) { return }
    if (!cameraOn) {
      qrScanner.start();
    } else {
      qrScanner.stop();
    }
  };

  const isConfirmed = useMemo(() => {
    if (ptClassDetails) {
      return ptClassDetails.status === 'confirmed';
    }
  }, [ptClassDetails]);

  return (
    <Grid
      item container
      xs={12}
      spacing={2}
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        <NavigateBack />
      </Grid>
      
      {ptClassDetails &&
        <Grid item>
          <Typography variant='h6' color='primary.main'>
            {!!ptClassDetails.privateClassHash && t('customer:book.title.private_class')}
            {!ptClassDetails.privateClassHash && t('customer:book.title.pt_session')}
          </Typography>
        </Grid>
      }

      {!isCoach && canTakeAttendance &&
        <StyledQRCodeForClassAttendance
          data={`class-${ptClassDetails.ptClassId}-customerId-${ptClassDetails.customerId}`} />
      }

      {isCoach &&
        <Grid item>
          <ButtonOutline
            status={isConfirmed ? 'primary' : 'disabled'}
            // To Do
            // disable except between 30 mins before & 30 mins after the class
            // disabled={false}
            disabled={!isConfirmed || !canTakeAttendance}
            label={t('coach:attendance.btn.take_attendance', {action: cameraOn ? t('coach:attendance.btn.finish') : ''})}
            onClick={() => handleAttendCamera(cameraOn)} />
        </Grid>
      }

      {isCoach && canTakeAttendance &&
        <div id='video-container'
          style={{
            maxWidth: '100vw',
            display: cameraOn ? 'unset' : 'none',
          }}>
          <video id='qr-video' style={{ maxWidth: '100vw' }}>
          </video>
        </div>
      }

      {ptClassDetails &&
        <>
          <TextGrid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {t('customer:book.fields.coach')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2' color='primary.main' textAlign='right'>
                {nameToCamelcase(ptClassDetails.preferredNameCoach + ' ' + ptClassDetails.lastNameCoach)}
              </Typography>
            </Grid>
          </TextGrid>
          
          {isCoach &&
            <TextGrid>
              <Grid item>
                <Typography variant='body1' color='primary.main'>
                  {t('customer:book.fields.customer')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' color='primary.main' textAlign='right'>
                  {nameToCamelcase(ptClassDetails.preferredNameClient + ' ' + ptClassDetails.lastNameClient)}
                </Typography>
              </Grid>
            </TextGrid>
          }

          <TextGrid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {t('customer:book.fields.date')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2' color={isPendingNotConfirmable ? 'error.main' : 'primary.main'} textAlign='right'>
                {dayjs(ptClassDetails.date).format('DD MMM[,] YYYY [-] ddd')}
              </Typography>
            </Grid>
          </TextGrid>

          <TextGrid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {t('customer:book.fields.time')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2' color={isPendingNotConfirmable ? 'error.main' : 'primary.main'} textAlign='right'>
                {
                  ptClassDetails.hour.toLocaleString(undefined, {minimumIntegerDigits: 2})
                  + ':' +
                  ptClassDetails.minute.toLocaleString(undefined, {minimumIntegerDigits: 2})
                }
              </Typography>
            </Grid>
          </TextGrid>

          <TextGrid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {t('customer:book.fields.duration')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2' color='primary.main' textAlign='right'>
                {ptClassDetails.duration + t('customer:mins')}
              </Typography>
            </Grid>
          </TextGrid>

          <TextGrid>
            <Grid item>
              <Typography variant='body1' color='primary.main'>
                {t('customer:book.fields.status')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2'
                color={ptClassDetails.status !== 'cancelled' ? 'primary.main' : 'disableGrey.main'}
                textAlign='right'>
                {nameToCamelcase(ptClassDetails.status)}
              </Typography>
            </Grid>
          </TextGrid>

        </>
      }
    </Grid>
  )
}