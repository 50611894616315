import useSWR from 'swr';
import axios from 'axios';
import { NewMemberCredit } from 'src/hooks/swr/output-types';

const fetcher = (url: string) => axios.get(url).then(res => res.data);

export default function useLatestNewMemberCredit() {
  const { data, mutate, error } = useSWR(`${process.env.REACT_APP_API_SERVER_v1}/credit/new-member-credit`, fetcher);
  return {
    newMemberCredit: data as NewMemberCredit,
    mutate,
    error
  }
};
