import { useMemo } from 'react';
import { Container, Grid, Box, Typography, styled, useMediaQuery, ImageList, ImageListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoVertical } from 'src/icons/logo/logo-vertical.svg';
import useWindowDimensions from 'src/hooks/use-window-dimensions';
import { ReactComponent as ChevronsRight } from 'src/icons/components/chevrons-right.svg';
import { ReactComponent as MainpageIcon1 } from 'src/icons/mainpage/asset_1.svg';
import { ReactComponent as MainpageIcon2 } from 'src/icons/mainpage/asset_2.svg';
import { ReactComponent as MainpageIcon3 } from 'src/icons/mainpage/asset_3.svg';
import { ReactComponent as MainpageIcon4 } from 'src/icons/mainpage/asset_4.svg';
import { ReactComponent as MainpageIcon5 } from 'src/icons/mainpage/asset_5.svg';
import { ReactComponent as MainpageIcon6 } from 'src/icons/mainpage/asset_6.svg';
import { theme } from 'src/styles/muiTheme';
import LinkWithoutStyle from 'src/components/LinkWithoutStyle';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import SocialMediaLinkBox from 'src/components/social-media/SocialMediaLinkBox';
import ContactUsLinkBox from 'src/components/contact-us/ContactUsLinkBox';
import useCmsMainpagePhoto from 'src/hooks/swr/public/use-cms-mainpage-photo';

const paddingBoxWidth = 10;
const maxSmWidth = 520;

const LoginBox = styled(Box)(({ theme }) => ({
  height: 30,
  boxShadow: `0px 4px 8px ${theme.palette.primary.main}40`,
  borderRadius: 10,
  paddingLeft: paddingBoxWidth,
  paddingRight: paddingBoxWidth,
  paddingTop: 5,
  paddingBottom: 5,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const beliefList =[
  {'title': '社會企業', 'text': '推廣運動與健康，重整體適能業界風氣', icon: <MainpageIcon1 width={70} />},
  {'title': '培訓新一代', 'text': '為有志人士提供實習和專業培訓，協助他們成為專業教練', icon: <MainpageIcon2 width={70} />},
  {'title': '自給自足', 'text': '聘用已完成培訓的優質人才，為他們提供就業機會', icon: <MainpageIcon3 width={70} />},
  {'title': '回饋體壇', 'text': '分享及交流經驗，培育高水平運動員', icon: <MainpageIcon4 width={70} />},
  {'title': '多元化體適能服務', 'text': '因應服務對象需要，安排相關訓練課程', icon: <MainpageIcon5 width={70} />},
  {'title': '專業教練團隊', 'text': '所有教練均具備專業認可資格，提供高質而有效的訓練', icon: <MainpageIcon6 width={70} />},
];


export default function Mainpage() {
  const { t } = useTranslation('customer');

  const isCustomerAuthenticated = useSelector((state: IRootState) => state.authCustomer.isAuthenticated);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { width: viewportWidth } = useWindowDimensions();
  // eqv. to the logo having width & height of 70vw, at max 350 px
  const logoWidth = useMemo(() => viewportWidth * 0.7 > 350 ? 350 : viewportWidth * 0.7, [viewportWidth]);

  // const isYoutubeMaxWidth = useMediaQuery(theme.breakpoints.up(560));

  const { allMainpagePhoto } = useCmsMainpagePhoto();

  return (
    <Container maxWidth='sm' sx={{ paddingTop: 2 }}>
      <Grid
        item container
        xs={12}
        spacing={3}
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{ marginBottom: 2 }}>
        
        <Grid item>
          <LogoVertical height={logoWidth} width={logoWidth}/>
        </Grid>

        <Grid item>
          <SocialMediaLinkBox
            linkFb={'https://www.facebook.com/onepluswellness/'}
            linkIg={'https://www.instagram.com/onepluswellness'}
            // Hugo's Youtube, hided it
            // linkYoutube={'https://www.youtube.com/channel/UCLICOehHcWZoWyHyN8fkwTw/videos'}
            />
        </Grid>

        {/* <Grid item>
          <iframe
            width={isYoutubeMaxWidth ? 520 : '100%'}
            height={isYoutubeMaxWidth ? 315 : '100%'}
            src="https://www.youtube.com/embed/QsVz4iMwpxc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </Grid> */}

        <Grid item container justifyContent={'center'}>
          <LinkWithoutStyle
            to={`/login`}
            style={{
              textDecoration: 'none',
              display: 'block',
            }}>
            <LoginBox sx={{
              width: isSmUp ? maxSmWidth : `calc(60vw - ${paddingBoxWidth}px * 2)`,
            }}>
              <Typography color='primary.main' fontWeight={'bold'}>
                {isCustomerAuthenticated ? t('mainpage.dashboard') : t('login.title')}
              </Typography>
              <ChevronsRight/>
            </LoginBox>
          </LinkWithoutStyle>
        </Grid>

        <Grid item container justifyContent={'center'}>
          <LinkWithoutStyle to={`/coach-overview`}>
            <LoginBox sx={{
              width: isSmUp ? maxSmWidth : `calc(60vw - ${paddingBoxWidth}px * 2)`,
            }}>
              <Typography color='primary.main' fontWeight={'bold'}>
                {t('coach_overview.title')}
              </Typography>
              <ChevronsRight/>
            </LoginBox>
          </LinkWithoutStyle>
        </Grid>

        {/* to refactor - hardcoded contents */}
        <Grid item>
          <Typography variant='body1' color='primary.main' fontWeight='bold'>
            {t('mainpage.contact_us')}
          </Typography>
        </Grid>
        <Grid item>
          <ContactUsLinkBox
            linkFbMessenger={'http://m.me/onepluswellness'}
            linkIgMessenger={'https://ig.me/m/onepluswellness'}
            linkWhatsapp={'https://api.whatsapp.com/send?phone=85262866580'}
            phoneNum={'+85262866580'} />
        </Grid>

        <Grid item container justifyContent={'center'}>
            {/* {Array.from(new Array(13), (_, i) => i+1).map((item) => (
              <ImageListItem key={`${item}-image`}>
                <img
                  src={`${AWS_S3_BUCKET}/mainpage/pictures/${item}.jpg`}
                  alt='icon for coach'
                  loading="lazy"
                />
              </ImageListItem>
            ))} */}

            {allMainpagePhoto && allMainpagePhoto.length !== 0 &&
              <ImageList sx={{ width: 'min(100%, 80vw)', height: allMainpagePhoto.length > 3 ? 450 : 250 }} cols={3}
                // auto height from image
                rowHeight={164}>
                {allMainpagePhoto.map((photo, index) => (
                  <ImageListItem key={`${index}-image`}>
                    <img 
                      src={photo.attributes.url}
                      alt={photo.attributes.alternativeText}
                      loading={'lazy'} />
                  </ImageListItem>
                ))}
              </ImageList>
            }
        </Grid>
        
        {/* <Grid item>
          <Typography variant='h6' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('mainpage.intro')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1' color='primary.main'>
            {Array.from(new Array(16), (_, i) => i).map(() => t('mainpage.intro'))}
          </Typography>
        </Grid> */}
        
        <Grid item>
          <Typography variant='h4' color='primary.main'
            sx={{ fontWeight: 'bold' }} >
            {t('mainpage.belief')}
          </Typography>
        </Grid>
        <Grid item>
          {beliefList.map((belief, index) => (
            <Box
              key={`belief-${index}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2,
              }}>
              {belief.icon}
              <Typography variant='h6' color='primary.main'
                sx={{ fontWeight: 'bold' }} >
                {belief.title}
              </Typography>
              <Typography variant='body1' color='primary.main'>
                {belief.text}
              </Typography>
            </Box>
          ))}
          {/* <Typography variant='body1' color='primary.main'>
            {Array.from(new Array(16), (_, i) => i).map(() => t('mainpage.belief'))}
          </Typography> */}
        </Grid>

      </Grid>
    </Container>
  )
}