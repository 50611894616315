import { useState } from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'src/components/ButtonPrimary';
import { CustomerAllPurchaseRecord } from 'src/hooks/swr/output-types';
import postAdminPurchaseReceipt from 'src/api/coach/admin/post-admin-purchase-receipt';
import dayjs from 'dayjs';

const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
}));

interface DownloadReceiptBtnProps {
  purchaseRecord: CustomerAllPurchaseRecord,
  isClient?: boolean,
}

export default function DownloadReceiptBtn({
  purchaseRecord,
  isClient = false,
}: DownloadReceiptBtnProps) {
  const { t } = useTranslation('customer');
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const exportReceipt = async () => {
    setExportLoading(true);
    postAdminPurchaseReceipt(purchaseRecord.purchaseHistoryId, !isClient)
      .then(res => {
        if (res.status === 200) {
          return res.blob()
        } else {
          return null
        }
      })
      .then(blob => {
        if (!blob) { return }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${dayjs(purchaseRecord.created_at).format('YYYY-MM-DD')}-${purchaseRecord.purchaseHistoryId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .finally(() => {
        setExportLoading(false);
      });
  }
  

  return (
    <TextBox sx={{ marginBottom: 0.5, justifyContent: 'center'}}>
      <ButtonPrimary
        isLoading={exportLoading}
        label={t('record.fields.receipt')}
        status='primary'
        onClick={exportReceipt} />
    </TextBox>
  )
}